import React from 'react';

const DocumentLargeIcon = () => {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99967 0.666016C1.53301 0.666016 0.346341 1.86602 0.346341 3.33268L0.333008 24.666C0.333008 26.1327 1.51967 27.3327 2.98634 27.3327H18.9997C20.4663 27.3327 21.6663 26.1327 21.6663 24.666V9.77268C21.6663 9.06602 21.3863 8.38602 20.8797 7.89268L14.4397 1.45268C13.9463 0.946016 13.2663 0.666016 12.5597 0.666016H2.99967ZM12.333 8.66602V2.66602L19.6663 9.99935H13.6663C12.933 9.99935 12.333 9.39935 12.333 8.66602Z"
        fill="#96A4B4"
      />
    </svg>
  );
};

export default DocumentLargeIcon
