/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LeftNavigation_viewer$ref = any;
type userMenu_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Layoutv2_viewer$ref: FragmentReference;
declare export opaque type Layoutv2_viewer$fragmentType: Layoutv2_viewer$ref;
export type Layoutv2_viewer = {|
  +id: string,
  +$fragmentRefs: LeftNavigation_viewer$ref & userMenu_viewer$ref,
  +$refType: Layoutv2_viewer$ref,
|};
export type Layoutv2_viewer$data = Layoutv2_viewer;
export type Layoutv2_viewer$key = {
  +$data?: Layoutv2_viewer$data,
  +$fragmentRefs: Layoutv2_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Layoutv2_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LeftNavigation_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "userMenu_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0afca0f2024dfeabfe4e8ef3af84d088';
module.exports = node;
