// i18next-parser.config.js, we don't want use babel
module.exports = {
  debug: false, // process.env.NODE_ENV !== 'production',

  ns: [
    'common',
    'front',
    'admin',
    'dashboard',
    'settings',
    'activities',
    'organisations',
    'deals',
    'users',
  ],

  defaultNS: 'common',

  fallbackLng: 'en',
  fallbackNS: 'common',

  whitelist: ['en', 'de', 'fr'],

  keySeparator: '.',

  interpolation: {
    escapeValue: false, // not needed for React
    formatSeparator: ',',
    // format: (value, format, lng) => {
    //   if (format === 'uppercase') return value.toUpperCase();
    //   return value;
    // },
  },

  // react: { useSuspense: false },

  returnEmptyString: false, // secondary languages might be empty strings after i18n-parser

  locizeBackend: {
    projectId: '69a6583a-6433-417b-8ff6-f4ef8a1b91d7',
    referenceLng: 'en',
    version:
      process.env.NODE_ENV === 'production' && process.env.REACT_APP_VERSION
        ? process.env.REACT_APP_VERSION
        : 'latest',
  },
};
