// @flow

import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import regexparam from 'regexparam';
import type { BrowserHistory, State, Path, To, Location } from 'history';
import { createBrowserHistory, parsePath } from 'history';
// $FlowFixMe
import { type Emitter, createNanoEvents } from 'nanoevents';

type RouteEvents = {|
  update: [],
|};

const RouterContext = React.createContext<{|
  history: BrowserHistory<State> | null,
  emitter: Emitter<RouteEvents> | null,
|}>({ history: null, emitter: null });

/*
From Ivan

Imagine you have a subscription on some event in few components, let it be <Parent><ChildA/><ChildB/></Parent> every one subscribed and do setState on event.
During subscription emit from any non react handler you have synchronous rendering - it means that every component updated alone and synchronously. Having that subscription order is undefined behaviour we can get that for example ChildA updated, then Parent and rerendering of Parent causes both childs to be rerendered, but state dependent on event data inside both are different now because ChildB still not received and event.
This can be avoided or by reading state from not event but some global state or by putting emit inside batched update, in case of batched update all setStates inside event handler will be delayed and finally just Parent will be rendered (and rerenders ChildX).

*/
export const RouterProvider = ({
  children,
}: {|
  children: React.Node,
|}): React.Node => {
  const historyRef = React.useRef(null);
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory();
  }
  const history = historyRef.current;

  const emitterRef = React.useRef(null);
  if (emitterRef.current == null) {
    emitterRef.current = createNanoEvents<RouteEvents>();
  }
  const emitter = emitterRef.current;

  const context = React.useMemo(() => ({ history, emitter }), [
    emitter,
    history,
  ]);

  React.useEffect(() => {
    const release = history.listen(() => {
      unstable_batchedUpdates(() => {
        emitter.emit('update');
      });
    });
    return release;
  }, [history, emitter]);

  return (
    <RouterContext.Provider value={context}>{children}</RouterContext.Provider>
  );
};

const getPathFromLocation = ({
  pathname,
  search,
  hash,
}: Location<State>): Path => ({
  pathname,
  search,
  hash,
});

const areLocationsEqual = (a, b) => {
  return (
    a.pathname === b.pathname && a.search === b.search && a.hash === b.hash
  );
};

const LinkInterceptorContext: React.Context<
  (Path) => Path,
> = React.createContext(value => value);

export const LinkInterceptorProvider = LinkInterceptorContext.Provider;

export const useHistory = (): BrowserHistory<State> => {
  const { history } = React.useContext(RouterContext);
  if (history == null) {
    throw Error(
      'You should not use router hooks and components outside a <RouterProvider>',
    );
  }
  return history;
};

export const useHistorySubscription = (handle: Path => void) => {
  const handleRef = React.useRef(handle);
  React.useLayoutEffect(() => {
    handleRef.current = handle;
  });
  const { history, emitter } = React.useContext(RouterContext);
  if (history == null || emitter == null) {
    throw Error(
      'You should not use router hooks and components outside a <RouterProvider>',
    );
  }
  const lastLocation = React.useRef(history.location);
  React.useEffect(() => {
    let mounted = true;
    // check location changed after render and before effect
    if (areLocationsEqual(lastLocation.current, history.location) === false) {
      handleRef.current(getPathFromLocation(history.location));
    }
    const unsubscribe = emitter.on('update', () => {
      // check stale listeners are not run
      if (mounted) {
        lastLocation.current = history.location;
        handleRef.current(getPathFromLocation(history.location));
      }
    });
    return () => {
      unsubscribe();
      mounted = false;
    };
  }, [history, emitter]);
};

export const useLocation = (): Path => {
  const history = useHistory();
  const [path, setPath] = React.useState(getPathFromLocation(history.location));
  useHistorySubscription(setPath);
  return path;
};

type Router = {|
  intercept: Path => Path,
  push: To => void,
  replace: To => void,
  transform: To => Path,
|};

const defaultPath = {
  pathname: '/',
  search: '',
  hash: '',
};

const parsePathWithDefault = (to: To): Path => {
  if (typeof to === 'string') {
    return { ...defaultPath, ...parsePath(to) };
  } else {
    return { ...defaultPath, ...to };
  }
};

export const useRouter = (): Router => {
  const history = useHistory();
  const intercept = React.useContext(LinkInterceptorContext);
  const transform = to => intercept(parsePathWithDefault(to));
  // spread is necessary to match partial path to strict path
  const push = to => history.push({ ...transform(to) });
  const replace = to => history.replace({ ...transform(to) });
  return {
    intercept,
    push,
    replace,
    transform,
  };
};

const matchPath = <T>(
  patternPath,
  path,
): null | {| url: string, path: string, params: T |} => {
  const { pattern, keys } = regexparam(patternPath, true);
  const matches = pattern.exec(path);
  if (matches == null) {
    return null;
  }
  const [url, ...values] = matches;
  const params: any = {};
  keys.forEach((key, index) => {
    params[key] = values[index] || null;
  });
  return {
    url,
    path: patternPath,
    params,
  };
};

type Match<T> = {|
  path: string,
  url: string,
  params: T,
|};

export const useMatch = <T: { [string]: string, ... }>(
  pattern: string,
): ?Match<T> => {
  const history = useHistory();
  const { intercept } = useRouter();
  const computeMatch = React.useCallback(
    l => {
      const patternPath = { pathname: pattern, search: '', hash: '' };
      return matchPath(intercept(patternPath).pathname, l.pathname);
    },
    [pattern, intercept],
  );
  const [match, setMatch] = React.useState(computeMatch(history.location));
  useHistorySubscription(l => setMatch(computeMatch(l)));
  return match;
};

const wantsNewTab = (event: SyntheticMouseEvent<HTMLAnchorElement>) => {
  return (
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.button === 2 ||
    event.currentTarget.target === '_blank'
  );
};

export type Link = {|
  href: string,
  pathname: string,
  onClick: (SyntheticMouseEvent<HTMLAnchorElement>) => void,
  active: boolean,
  navigate: () => void,
|};

const useBaseLink = (changeType, to) => {
  const history = useHistory();
  const location = useLocation();
  const { intercept } = useRouter();
  const interceptedPath = intercept(parsePathWithDefault(to));
  // add "?" to the start of the search string
  if (
    interceptedPath.search !== '' &&
    !interceptedPath.search.startsWith('?')
  ) {
    interceptedPath.search = `?${interceptedPath.search}`;
  }
  // spread is necessary to match partial path to strict path
  const href = history.createHref({ ...interceptedPath });
  const active = location.pathname.startsWith(interceptedPath.pathname);
  const navigate = () => {
    history[changeType](interceptedPath);
  };
  const onClick = event => {
    const tagName = event.currentTarget.tagName;
    if (tagName !== 'A') {
      console.error(
        `Warning: link.onClick is not allowed on "${tagName.toLowerCase()}".` +
          ` Use "a" element or link.navigate() instead.`,
      );
      return;
    }
    if (event.currentTarget.href === '') {
      console.error('Warning: link.href is not passed to element');
      return;
    }
    if (wantsNewTab(event) === false) {
      event.preventDefault();
      navigate();
    }
  };
  return {
    href,
    pathname: interceptedPath.pathname,
    onClick,
    active,
    navigate,
  };
};

export const useLink = (to: To): Link => {
  return useBaseLink('push', to);
};

export const useReplaceLink = (to: To): Link => {
  return useBaseLink('replace', to);
};

export const Prompt = ({ children }: {| children: string |}): React.Node => {
  const history = useHistory();
  React.useEffect(() => {
    const unblock = history.block(tx => {
      if (global.confirm(children)) {
        unblock();
        tx.retry();
      }
    });
    return unblock;
  }, [children, history]);
  return null;
};

export const Redirect = ({ link }: {| link: Link |}): React.Node => {
  const history = useHistory();
  React.useEffect(() => {
    history.replace(link.href);
  }, [history, link]);
  return null;
};
