import React from 'react';
import styled from 'styled-components';
import {
  AppBar,
  Grid,
  Divider,
  Box,
  Select as MuiSelect,
  DialogActions,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  compose,
  getContext,
  withProps,
  withState,
  defaultProps,
} from 'recompose';
import { graphql } from 'react-relay';
import { withFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import { addHours } from 'date-fns';
import idx from 'idx';
import { withTranslation as translate } from 'react-i18next';

import Button from '../../../components/ButtonProgress';
import { createFragmentContainerHOC } from '../../../utils/relay/index';
import { Input, DateTime, Checkbox } from '../controls/Formik';
import { createActivity, updateActivity } from './mutations/activities';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { ClassNames } from '@emotion/core';

const Root = styled.div``;

const Container = styled(Grid).attrs({ container: true, spacing: 2 })``;

const Item = styled(Grid).attrs({ item: true })`
  display: flex;
`;

const FormActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
`;

const TabsWrapper = styled(AppBar).attrs({
  position: 'static',
  color: 'default',
})`
  position: ${props => (props.elevation ? 'relative' : 'static')};
  ${props => !props.elevation && 'background: transparent;'};
`;

const TABS = {
  NOTE: 'NOTE',
  TASK: 'TASK',
};

const ActivityForm = ({
  root: { viewer },
  resetForm,
  handleSubmit,
  handleTabChange,
  setFieldValue,
  currentTab,
  dirty,
  isSubmitting,
  fixed,
  isNew,
  onCancel,
  formKey,
  setFormKey,
  t,
  disabledEdit,
}) => (
  <form key={formKey} onSubmit={handleSubmit}>
    <Root>
      {isNew && (
        <ClassNames>
          {({ css }) => (
            <Tabs
              value={currentTab}
              onChange={(e, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
              classes={{
                flexContainer: css`
                  justify-content: flex-end;
                `,
              }}
            >
              <Tab label={t('createTask')} value={TABS.TASK} />
              <Tab label={t('writeNote')} value={TABS.NOTE} />
            </Tabs>
          )}
        </ClassNames>
      )}
      <Box p={1}>
        {currentTab === TABS.NOTE && (
          <Box p={1}>
            <Field
              variant="filled"
              name="note"
              required
              label={t('noteText')}
              component={Input}
              margin="dense"
              fullWidth
              multiline
              autoFocus={!isNew}
              rowsMax={4}
            />
          </Box>
        )}
        {currentTab === TABS.TASK && (
          <Box p={1}>
            <Container>
              <Item xs={12}>
                <Field
                  variant="filled"
                  autoFocus
                  name="subject"
                  required
                  label={t('taskText')}
                  component={Input}
                  fullWidth
                  margin="dense"
                  multiline
                  rowsMax={4}
                />
              </Item>
              <Item xs={12} sm={6}>
                <FormControl margin="dense" fullWidth required>
                  <InputLabel htmlFor="assigneeId">
                    {t('assignedTo')}
                  </InputLabel>
                  <MuiSelect
                    variant="filled"
                    required
                    inputProps={{
                      id: 'assigneeId',
                    }}
                    native
                    children={
                      <>
                        <option value="" />
                        {viewer.organisation.users.map(user => (
                          <option value={user.id}>{user.fullName}</option>
                        ))}
                      </>
                    }
                    onChange={e => setFieldValue('assigneeId', e.target.value)}
                  />
                </FormControl>
              </Item>
              <Item xs={12} sm={6}>
                <Field
                  variant="filled"
                  name="dueAt"
                  label={t('dueAt')}
                  component={DateTime}
                  fullWidth
                  margin="dense"
                />
              </Item>
              <Item xs={12}>
                <Field
                  name="isDone"
                  label="Done"
                  component={Checkbox}
                  fullWidth
                  margin="dense"
                />
              </Item>
            </Container>
          </Box>
        )}
      </Box>
      <Divider />
      <DialogActions>
        <Button
          type="reset"
          onClick={() => {
            isNew ? resetForm() : onCancel();
            setFormKey(Math.random().toString());
          }}
          disabled={(!dirty && isNew) || disabledEdit}
        >
          {t('button.cancel')}
        </Button>
        <Button
          loading={isSubmitting}
          type="submit"
          color="primary"
          disabled={!dirty || disabledEdit}
          variant="contained"
        >
          {isNew ? t('button.create') : t('button.save')}
        </Button>
      </DialogActions>
    </Root>
  </form>
);

const activityFormEnhancer = compose(
  translate('activities'),
  defaultProps({
    activity: null,
  }),
  createFragmentContainerHOC({
    root: graphql`
      fragment ActivityForm_root on Query {
        viewer {
          organisation {
            users {
              id
              fullName
            }
          }
        }
      }
    `,
    activity: graphql`
      fragment ActivityForm_activity on Activity {
        id
        note
        subject
        assignee {
          id
        }
        dueAt
        isDone
        type
      }
    `,
  }),
  withProps(({ activity }) => ({
    isNew: !activity,
    activity: activity || {},
  })),
  withState('currentTab', 'handleTabChange', ({ activity, isNew }) =>
    isNew ? TABS.NOTE : TABS[activity.type],
  ),
  withState('formKey', 'setFormKey', 'form'),
  getContext({
    api: PropTypes.object.isRequired,
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ activity }) => ({
      note: activity.note || '',
      subject: activity.subject || '',
      assigneeId: idx(activity, _ => _.assignee.id) || '',
      dueAt: activity.dueAt || addHours(new Date(), 1),
      isDone: activity.isDone || false,
    }),
    handleSubmit: async (
      values,
      {
        props: {
          api,
          parentId,
          connectionKey,
          currentTab,
          isNew,
          activity,
          onCancel,
          setFormKey,
        },
        resetForm,
        setSubmitting,
      },
    ) => {
      const newValues = Object.assign({ type: TABS[currentTab] }, values);
      // delete empty values for these fields because they are not string types
      ['assigneeId', 'dueAt'].forEach(key => {
        if (newValues[key] === '') delete newValues[key];
      });
      try {
        if (isNew)
          await createActivity(api, {
            ...newValues,
            parentId,
            connectionKey,
          });
        else await updateActivity(api, { ...newValues, id: activity.id });
        resetForm();
        setFormKey(Math.random().toString());
        if (typeof onCancel === 'function') onCancel();
      } catch (err) {
        setSubmitting(false);
        console.error(err);
      }
    },
  }),
);

export default activityFormEnhancer(ActivityForm);
