// @flow

import * as React from 'react';
import { Chip, SvgIcon, useTheme } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { mdiCloseCircleOutline } from '@mdi/js';
import styled from 'styled-components';
import { boolean } from 'joi';

type AnimChipIconsProps = {|
  empty: boolean,
  timeout?: number,
  onClick?: (e: SyntheticEvent<EventTarget>) => void,
|};

const StyledChip = styled(Chip)`
  box-shadow: 0px 3px 10px rgba(40, 99, 166, 0.15);
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 8.5px;
  cursor: pointer;
`;

const StyledChipV2 = styled(Chip)`
  box-shadow: 0px 3px 10px rgba(40, 99, 166, 0.15);
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 8.5px 16px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  .MuiChip-label {
    overflow: visible;
    padding: 0;
    text-overflow: unset;
  }
`;

const IconHolder = ({ visible, timeout, children }) => {
  return (
    <div
      css={{
        lineHeight: 0,
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: visible ? 1 : 0,
        transition: `opacity ${timeout}ms ease`,
      }}
    >
      {children}
    </div>
  );
};

export const AnimChipIcons = ({
  empty,
  timeout = 250,
  onClick,
}: AnimChipIconsProps): React.Node => (
  <div
    css={`
      line-height: 0;
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 3px;
    `}
    onClick={onClick}
  >
    <IconHolder visible={empty === true} timeout={timeout}>
      <ExpandMore />
    </IconHolder>
    <IconHolder visible={empty === false} timeout={timeout}>
      <SvgIcon>
        <path d={mdiCloseCircleOutline} />
      </SvgIcon>
    </IconHolder>
  </div>
);

const IconHolderV2 = ({ visible, timeout, children }) => {
  return (
    <div
      css={{
        lineHeight: 0,
        left: 0,
        top: 0,
        opacity: visible ? 1 : 0,
        transition: `opacity ${timeout}ms ease`,
      }}
    >
      {children}
    </div>
  );
};

export const AnimChipIconsV2 = ({
  empty,
  timeout = 250,
  onClick,
}: AnimChipIconsProps): React.Node => (
  <div
    css={`
      line-height: 0;
      position: relative;
      display: flex;
      align-items: center;
    `}
    onClick={onClick}
  >
    {empty && (
      <div
        style={{ opacity: empty ? 1 : 0, width: 'auto', height: '100%' }}
        timeout={timeout}
      >
        <svg
          width="7.45"
          height="4.4"
          viewBox="0 0 7.45 4.8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.14167 0.999687L3.72833 3.58635L6.315 0.999687C6.575 0.739688 6.995 0.739688 7.255 0.999687C7.515 1.25969 7.515 1.67969 7.255 1.93969L4.195 4.99969C3.935 5.25969 3.515 5.25969 3.255 4.99969L0.195 1.93969C-0.065 1.67969 -0.065 1.25969 0.195 0.999687C0.455 0.746354 0.881667 0.739688 1.14167 0.999687V0.999687Z"
            fill="black"
          />
        </svg>
      </div>
    )}
    {!empty && (
      <IconHolderV2 visible={true} timeout={timeout}>
        <SvgIcon>
          <path d={mdiCloseCircleOutline} />
        </SvgIcon>
      </IconHolderV2>
    )}
  </div>
);

type Props = {|
  label: string,
  empty?: boolean,
  active?: boolean,
  onClick?: (e: SyntheticEvent<EventTarget>) => void,
  onDelete?: null | ((e: SyntheticEvent<EventTarget>) => void),
|};

export const AnimChip = ({
  label,
  empty = true,
  active = false,
  onClick,
  onDelete = () => {},
}: Props): React.Node => {
  return (
    <StyledChip
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<AnimChipIcons empty={empty} />}
      variant="outlined"
      color={active ? 'primary' : 'default'}
    />
  );
};

export const AnimChipV2 = ({
  label,
  empty = true,
  active = false,
  onClick,
  onDelete = () => {},
  width = 'auto',
}: any) => {
  return (
    <StyledChipV2
      width={width}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<AnimChipIconsV2 empty={empty} />}
      variant="outlined"
      color={active ? 'primary' : 'default'}
    />
  );
};

type AnimChipButtonProps = {|
  label: string,
  active?: boolean,
  onClick?: (e: SyntheticEvent<EventTarget>) => void,
|};

export const AnimChipButton = ({
  label,
  onClick = () => {},
  active = false,
}: AnimChipButtonProps): React.Node => {
  return (
    <AnimChip onDelete={null} label={label} active={active} onClick={onClick} />
  );
};
