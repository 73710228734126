/**
 * @flow
 * @relayHash 23a2d2d115851763de68c1d053ba8e29
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserAvatar_user$ref = any;
export type dealsFiltersUsersQueryVariables = {|
  search?: ?string
|};
export type dealsFiltersUsersQueryResponse = {|
  +users: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
        +email: string,
        +$fragmentRefs: UserAvatar_user$ref,
      |}
    |}>
  |},
  +viewer: ?{|
    +id: string,
    +fullName: ?string,
  |},
|};
export type dealsFiltersUsersQuery = {|
  variables: dealsFiltersUsersQueryVariables,
  response: dealsFiltersUsersQueryResponse,
|};
*/


/*
query dealsFiltersUsersQuery(
  $search: String
) {
  users(search: $search, first: 30) {
    edges {
      node {
        ...UserAvatar_user
        id
        fullName
        email
      }
    }
  }
  viewer {
    id
    fullName
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "viewer",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealsFiltersUsersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "UserAvatar_user",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      },
      (v5/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "dealsFiltersUsersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "primaryImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "dealsFiltersUsersQuery",
    "id": null,
    "text": "query dealsFiltersUsersQuery(\n  $search: String\n) {\n  users(search: $search, first: 30) {\n    edges {\n      node {\n        ...UserAvatar_user\n        id\n        fullName\n        email\n      }\n    }\n  }\n  viewer {\n    id\n    fullName\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '976c1efd896f5834a969a7e0b927a817';
module.exports = node;
