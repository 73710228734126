import * as React from 'react';
import { compose, defaultProps, getContext } from 'recompose';
import { QueryRenderer, graphql } from 'react-relay';

// to track appearance of new components
const tracker = ({ api, query, event, data }) => (
  <QueryRenderer
    environment={api.environment}
    query={query}
    variables={{
      event,
      data: JSON.stringify(data),
    }}
    render={() => null}
  />
);

const query = graphql`
  query TrackerQuery($event: String!, $data: String!) {
    tracker(event: $event, data: $data)
  }
`;

export default compose(
  defaultProps({
    query,
  }),
  getContext({
    api: () => {},
  }),
)(tracker);
