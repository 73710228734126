import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createOrganisationType = makeCreateDictionaryMutation(
  graphql`
    mutation organisationTypesCreateOrganisationTypeMutation(
      $input: CreateOrganisationTypeInput!
    ) {
      createOrganisationType(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createOrganisationType',
  'organisationTypes',
);

export const updateOrganisationType = makeUpdateDictionaryMutation(
  graphql`
    mutation organisationTypesUpdateOrganisationTypeMutation(
      $input: UpdateOrganisationTypeInput!
    ) {
      updateOrganisationType(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateOrganisationType',
  'organisationTypes',
);

export const deleteOrganisationType = makeDeleteDictionaryMutation(
  graphql`
    mutation organisationTypesDeleteOrganisationTypeMutation(
      $input: DeleteOrganisationTypeInput!
    ) {
      deleteOrganisationType(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteOrganisationType',
  'organisationTypes',
);
