import React from 'react';
import PropTypes from 'prop-types';
import { getContext, compose, mapProps } from 'recompose';
import styled from 'styled-components';
import { colors, Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const TitleHolder = styled(Typography).attrs({
  variant: 'subtitle1',
  noWrap: true,
})`
  display: flex;
  flex: 1;
`;

const Divider = styled.span`
  &::before {
    content: '|';
    display: inline-block;
    margin: 0 6px;
    opacity: 0.55;
  }
`;

const titleRendererHOC = compose(
  getContext({
    title: PropTypes.object,
  }),
  mapProps(props => ({
    objects: props.title.objects,
  })),
  withWidth(),
);

const TitleRenderer = ({ objects, width }) => {
  const firstTitle = objects[objects.length - 1].text;
  const lastTitle = objects.length > 1 ? objects[0].text : undefined;
  const showAll = isWidthDown(width, 'md');
  const items = showAll ? objects : [objects[objects.length - 1]];

  // label to visually detect env to prevent prod testing issues
  const isLocalhost = document.location.origin.includes('localhost');
  const isTest = document.location.origin.includes('crm-test');
  const envLabel = (isLocalhost || isTest) && (
    <span
      css={`
        font-family: monospace;
        color: #fff;
        padding: 2px;
        border-radius: 2px;
        background-color: ${isLocalhost ? colors.red.A400 : colors.indigo.A700};
        position: absolute;
        bottom: -4px;
        left: 0px;
        line-height: 1;
        z-index: 2;
      `}
    >
      {isLocalhost ? 'local' : 'test'}
    </span>
  );

  return (
    <>
      <TitleHolder>
        {items.map(({ component }, i, arr) => (
          <span key={i}>
            <span
              css={`
                position: relative;
              `}
            >
              {component}
              {i === 0 && envLabel}
            </span>
            {i + 1 !== arr.length && <Divider />}
          </span>
        ))}
      </TitleHolder>
    </>
  );
};

export default titleRendererHOC(TitleRenderer);
