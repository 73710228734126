// @flow

import * as React from 'react';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Divider,
  MenuItem,
  ListItemText,
  ListItemAvatar,
  Popover,
  ListItemSecondaryAction,
  CircularProgress,
  Box,
  Button,
  ListItem,
  MenuList,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-navi';
import { useFragment, graphql } from 'relay-hooks';
import styled from 'styled-components';
import ArrowDownIcon from 'routes/admin/Deals/Deal/DealActivities/Icons/ArrowDownIcon';

import UserAvatar from './UserAvatar';
import config from '../config';
import { UserInput } from '../controls/user-input';
import ButtonProgress from './ButtonProgress';
import { useMutation } from '../controls/relay';

import { type userMenu_viewer } from './__generated__/userMenu_viewer.graphql';
import { type userMenuLoginAsMutation } from './__generated__/userMenuLoginAsMutation.graphql';
import { type userMenuLogoutAsMutation } from './__generated__/userMenuLogoutAsMutation.graphql';
import { useTranslation } from 'react-i18next';

const fetchOptions = { method: 'POST', credentials: 'include' };

function handleLogout() {
  Promise.all([
    fetch(`${config.api.clientUrl}/login/clear`, fetchOptions),
    config.api.clientUrl !== undefined
      ? fetch('/logout', fetchOptions)
      : Promise.resolve(),
  ]).then(() => {
    window.location.href = '/';
  });
}

const fragmentViewer = graphql`
  fragment userMenu_viewer on User {
    ...UserAvatar_user
    id
    fullName
    isLoggedInAs
    isAdmin
    isSuperAdmin
    realUser {
      ...UserAvatar_user
      id
      fullName
    }
  }
`;

const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #333e48;
  span {
    display: flex;
    gap: 8px;
  }
`;

type Props = {|
  viewer: userMenu_viewer,
  refetch: () => void,
|};

export const UserMenu = (props: Props) => {
  const theme = useTheme();
  const viewer = useFragment(fragmentViewer, props.viewer);
  const popupState = usePopupState({ variant: 'popover', popupId: 'useMenu' });
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [loginAsOpen, setLoginAsOpen] = React.useState(false);
  const [
    loginAsUser,
    { loading: loginAsUserLoading },
  ] = useMutation<userMenuLoginAsMutation>(
    graphql`
      mutation userMenuLoginAsMutation($input: LoginAsUserInput!) {
        loginAsUser(input: $input) {
          result
          viewer {
            ...userMenu_viewer
          }
        }
      }
    `,
    {
      onCompleted: response => {
        if (response.loginAsUser) {
          setLoginAsOpen(false);
          props.refetch();
        }
      },
    },
  );
  const { t } = useTranslation();
  const [
    logoutAsUser,
    { loading: logoutAsUserLoading },
  ] = useMutation<userMenuLogoutAsMutation>(
    graphql`
      mutation userMenuLogoutAsMutation($input: LogoutAsUserInput!) {
        logoutAsUser(input: $input) {
          result
          viewer {
            ...userMenu_viewer
          }
        }
      }
    `,
    {
      onCompleted: () => props.refetch(),
    },
  );
  const isAdminPath = window.location.pathname.startsWith('/admin');

  return (
    <>
      <Dialog
        open={loginAsOpen}
        fullWidth={true}
        maxWidth="xs"
        onBackdropClick={() => setLoginAsOpen(false)}
      >
        <DialogTitle>{t('loginAsUser')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('youWillBeLoggedAs')}</DialogContentText>
          <Box>
            <UserInput
              value={selectedUser}
              onChange={setSelectedUser}
              variant="outlined"
              label={t('chooseUser')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loginAsUserLoading}
            onClick={() => setLoginAsOpen(false)}
          >
            {t('button.cancel')}
          </Button>
          <ButtonProgress
            disabled={selectedUser == null}
            loading={loginAsUserLoading}
            color="primary"
            onClick={() =>
              selectedUser && loginAsUser({ userId: selectedUser.id })
            }
          >
            {t('logIn')}
          </ButtonProgress>
        </DialogActions>
      </Dialog>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          {!isAdminPath && [
            <MenuItem component={Link} href="/profile" key="profile">
              {t('accountSettings')}
            </MenuItem>,
            <Divider key="divider1" />,
          ]}
          {(viewer.isSuperAdmin || viewer.isLoggedInAs) && (
            <MenuItem onClick={() => setLoginAsOpen(true)}>
              {t('loginAs')}
            </MenuItem>
          )}
          {viewer.realUser != null && [
            viewer.isLoggedInAs && <Divider key="divider2" />,
            <ListItem
              button
              disabled={logoutAsUserLoading}
              onClick={() => logoutAsUser({})}
              key="logout"
            >
              <ListItemAvatar>
                <UserAvatar user={viewer.realUser} />
              </ListItemAvatar>
              <ListItemText
                primary={t('returnToAccount')}
                secondary={viewer.realUser.fullName}
              />
              <ListItemSecondaryAction>
                {logoutAsUserLoading && <CircularProgress size={24} />}
              </ListItemSecondaryAction>
            </ListItem>,
            <Divider key="divider3" />,
          ]}
          <MenuItem onClick={handleLogout}>{t('logOut')}</MenuItem>
        </MenuList>
      </Popover>
      <StyledButton {...bindTrigger(popupState)}>
        {(viewer && viewer.fullName) || ''}
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.4134 1.19305L4.00006 3.77971L6.58673 1.19305C6.84673 0.933047 7.26673 0.933047 7.52673 1.19305C7.78673 1.45305 7.78673 1.87305 7.52673 2.13305L4.46673 5.19305C4.20673 5.45305 3.78673 5.45305 3.52673 5.19305L0.466729 2.13305C0.206729 1.87305 0.206729 1.45305 0.466729 1.19305C0.726728 0.939714 1.1534 0.933047 1.4134 1.19305Z"
            fill="black"
          />
        </svg>
      </StyledButton>
    </>
  );
};
