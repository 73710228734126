/* eslint-disable no-param-reassign */
import { compose, concat, pickBy, values, mergeWith } from 'ramda';

/**
 *   For any given pair - relay error array and withFormik value object
 *   returns an error object where all errors are combined and errors from
 *   non existed fields are added to $others key
 *   see example below
 *
 *   // Relay errors
 *   const err = [
 *    {
 *      message: 'The request is invalid.',
 *      state: {
 *        organisation: ['You must be an admin.'],
 *        name: ['Your name is invalid', 'Name must not be the Ivan'],
 *        notExistedProp: ['Some weird error on non existed field', 'La la'],
 *      },
 *    },
 *    {
 *      message: 'Something bad happened',
 *    },
 *    {
 *      message: 'The request is invalid.',
 *      state: {
 *        organisation: ['You are not alone.'],
 *      },
 *    },
 *  ];
 *
 *  // withFormik values
 *  const vals = { organisation: true, name: true };
 *
 *  // call
 *  parseErrors(err, vals), null, ' ');
 *
 *  // -------------------------------------------------------------------
 *  // output
 *  {
 *    "organisation": [
 *     "You must be an admin.",
 *     "You are not alone."
 *    ],
 *    "name": [
 *     "Your name is invalid",
 *     "Name must not be the Ivan"
 *    ],
 *    "$others": [
 *     [
 *      "Some weird error on non existed field",
 *      "La la"
 *     ]
 *    ]
 *  }
 */

const parseErrorsLegacy = (errs, vals) => {
  const res = errs.reduce((errResult, err) => {
    if (!err.state) {
      if (errResult.$others === undefined) errResult.$others = [err.message];
      return errResult;
    }
    // const errObj = map(v => v.join('\n'))(err.state);
    const valuesErr = pickBy((v, k) => k in vals)(err.state);

    const nonValuesErr = compose(
      values,
      pickBy((v, k) => !(k in vals)),
    )(err.state);

    const v1 = mergeWith(concat, errResult, valuesErr);
    return mergeWith(concat, v1, { $others: nonValuesErr });
  }, {});

  return {
    ...res,
    $others: res.$others && res.$others.length === 0 ? undefined : res.$others,
  };
};

export default parseErrorsLegacy;
