import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'recompose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation as translate } from 'react-i18next';
// import Divider from '@material-ui/core/Divider';
import styled, { css } from 'styled-components';
import {
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAccount, mdiAccountGroup, mdiViewDashboard } from '@mdi/js';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SettingsIcon from '@material-ui/icons/Settings';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { graphql } from 'react-relay';
import { Link, NavLink } from 'react-router-dom';

import MuiIcon from '@material-ui/core/Icon';
// import withWidth, { isWidthDown } from '@material-ui/core/utils/withWidth';
import { uniMaterial } from '../../routes/admin/controls/UniStyled';
import { LoadableOrganisationsList } from '../../routes/admin/Organisations';
import { LoadableUsersList } from '../../routes/admin/Users';
import { LoadableDealsList } from '../../routes/admin/Deals';
import { LoadableDashboard } from '../../routes/admin/Dashboard';
import { createFragmentContainerHOC } from '../../utils/relay';
import LanguagePicker from '../LanguagePicker';
import { useLink } from '../../hooks/router';

const FakeAppBar = styled(uniMaterial(AppBar))`
  background-color: #3455d1;
  box-shadow: unset;
  color: black;
  > div {
    margin-left: unset;
    padding-left: 5px;
  }

  ${({ open }) =>
    open &&
    css`
      align-items: center;
    `}
`;

export const MuiIconStyled = styled(MuiIcon)`
  margin-bottom: 6px;
  margin-right: 5px;
`;

export const ListItemIconStyle = styled(ListItemIcon)`
  min-width: 36px;
`;

export const LeftMenu = styled(Drawer)`
  background-color: #3455d1;
  z-index: ${props => (props.open ? 5 : 2)};
  > div {
    overflow-x: hidden;
  }

  ${({ open, ...p }) =>
    open
      ? css`
          width: ${p['data-max-w']}px;
        `
      : css`
          width: ${p['data-min-w']}px;
          @media (max-width: 575px) {
            width: 0;
          }
        `};
  ${p =>
    p['data-position'] === 'fixed'
      ? css`
          position: fixed;
          left: 0;
          bottom: 0;
          top: 0;
        `
      : css`
          position: relative;
          @media (max-width: 575px) {
            /* don't move content on mobile */
            width: 0;
          }
        `};
  transition: width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: unset;
    background-color: #3455d1;
  }
`;

const MyIconButton = styled(IconButton)`
  background: none !important;
  transition: none !important;

  .MuiIconButton-root {
    background: none !important;
    transition: none !important;
  }

  &:hover {
    background: none;
  }

  &:active {
    background: none;
  }
`;

const MenuList = styled(List)`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .MuiListItem-gutters {
    padding-left: 19px;
  }
  .Mui-selected::before {
    content: url('/icon/selected-item.svg');
    position: absolute;
    left: 0;
    margin-top: 6px;
  }
`;

const LinkText = styled(ListItemText)`
  .MuiTypography-noWrap {
    white-space: nowrap;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    overflow: unset;
    text-overflow: unset;
  }
`;

const FlexibleSpacer = styled.div`
  flex: 1;
`;

const ULanguagePicker = styled(LanguagePicker)`
  width: 100%;
`;

const SvgIconStyled = styled(SvgIcon)`
  width: 18px;
  height: 18px;
`;

const ListItemLink = props => <ListItem {...props} component={Link} />;

const StyledNavLink = styled(NavLink)`
  padding: 12px 16px 12px 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  white-space: nowrap;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  overflow: unset;
  text-overflow: unset;

  &.active {
    background: rgba(0, 0, 0, 0.08);

    &::before {
      content: url('/icon/selected-item.svg');
      position: absolute;
      left: 0;
      margin-top: 6px;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const StyledListItem = styled(ListItem)`
  display: block;
  padding: 0 !important;
  width: 100%;
`;

const leftNavigation = ({
  open,
  onClose,
  position,
  navMenuMinWidth,
  navMenuMaxWidth,
  viewer,
  t,
  onMenuClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const conditionalClose = () => (isMobile ? onClose() : null);

  const hardcodedStatusesString = `&dealStatusId_in=RGVhbFN0YXR1czo1MGNhZmM3MC04YmFlLTQ2OWMtOTMzNi1jYjE2NmU3YTBlOWI%253D&dealStatusId_in=RGVhbFN0YXR1czpiNzkwMGY5NS1iNGRkLTRjMWMtYjI5OC04YjJhZWE4ZmZjZTc%253D&dealStatusId_in=RGVhbFN0YXR1czo3NGM4MjhjMS1iYmY4LTQwNWMtYjhlYy1lYWM4M2U2Zjc5Zjc%253D&sortBy=updatedAt&sortDirection=DESC`;
  // const viewerString = viewer?.isSuperAdmin ? '' : `&brokerId_in=${viewer?.id}`;
  const viewerString = '';
  const dealsTo = `/deals/?view=list${viewerString}${hardcodedStatusesString}`;
  // deprecated react router isn't compatible with our useSearchParams hook, so as a temporary hack onClick is duplicated with `to`
  const link = useLink(`/admin${dealsTo}`);

  const [selectedMenuItem, setSelectedMenuItem] = React.useState('dashboard');

  const handleClickMenuItem = (item, needLinkNavigate = false) => {
    if (needLinkNavigate) {
      link.navigate();
    }
    setSelectedMenuItem(item);
    conditionalClose();
  };
  return (
    <div>
      <LeftMenu
        data-min-w={navMenuMinWidth}
        data-max-w={navMenuMaxWidth}
        open={open}
        anchor="left"
        variant="permanent"
      >
        <div />
      </LeftMenu>

      <LeftMenu
        open={open}
        anchor="left"
        variant="permanent"
        data-min-w={navMenuMinWidth}
        data-max-w={navMenuMaxWidth}
        data-position={position}
        onClose={onClose}
      >
        {/* use copy of original element to not implement media queries
          as even media will change Fake will preserve the same sizes
          as real element */}
        <FakeAppBar position="static" open={open}>
          <Toolbar>
            <MyIconButton
              data-open={open}
              color="default"
              aria-label="Menu"
              onClick={open ? onClose : onMenuClick}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              <MuiIconStyled open={open}>
                <img src="/icon/realadvisor-icon.svg" />
              </MuiIconStyled>
              {open && (
                <LinkText
                  style={{ color: '#FFFFFF' }}
                  primary={t('realAdvisor')}
                  primaryTypographyProps={{ noWrap: true }}
                />
              )}
            </MyIconButton>
          </Toolbar>
        </FakeAppBar>
        <MenuList>
          <StyledListItem>
            <StyledNavLink
              onMouseEnter={() => LoadableDashboard.preload()}
              onClick={() => handleClickMenuItem('dashboard')}
              to="/"
              strict={true}
              exact={true}
            >
              <ListItemIconStyle>
                <SvgIconStyled>
                  <Icon path={mdiViewDashboard} size={18} color="#FFFFFF" />
                </SvgIconStyled>
              </ListItemIconStyle>
              {t('dashboard')}
            </StyledNavLink>
          </StyledListItem>
          <StyledListItem>
            <StyledNavLink
              onMouseEnter={() => LoadableOrganisationsList.preload()}
              onClick={() => handleClickMenuItem('organisations')}
              to="/organisations"
              strict={true}
            >
              <ListItemIconStyle>
                <SvgIconStyled>
                  <Icon path={mdiAccountGroup} size={18} color="#FFFFFF" />
                </SvgIconStyled>
              </ListItemIconStyle>
              {t('organisations')}
            </StyledNavLink>
          </StyledListItem>
          <StyledListItem>
            <StyledNavLink
              onMouseEnter={() => LoadableUsersList.preload()}
              onClick={() => handleClickMenuItem('users')}
              to="/individuals"
              strict={true}
            >
              <ListItemIconStyle>
                <SvgIconStyled>
                  <Icon path={mdiAccount} size={18} color="#FFFFFF" />
                </SvgIconStyled>
              </ListItemIconStyle>
              {t('users')}
            </StyledNavLink>
          </StyledListItem>
          {!viewer?.isInvestor && (
            <StyledListItem>
              <StyledNavLink
                onMouseEnter={() => LoadableDealsList.preload()}
                onClick={() => handleClickMenuItem('deals', true)}
                to={dealsTo}
                strict={true}
              >
                <ListItemIconStyle>
                  <BusinessCenterIcon
                    style={{ width: '18px', height: '18px' }}
                    htmlColor="#FFFFFF"
                  />
                </ListItemIconStyle>
                {t('deals')}
              </StyledNavLink>
            </StyledListItem>
          )}

          <FlexibleSpacer />

          <Divider />

          {/* <StyledListItem button fullWidth>
            <ULanguagePicker
              htmlColor="#FFFFFF"
              viewer={viewer}
              t={t}
              open={open}
            />
          </StyledListItem> */}

          <ListItem button href="/" component="a">
            <ListItemIconStyle>
              <img
                style={{ width: '18px', height: '18px' }}
                src="/icon/switch-platform.svg"
              />
            </ListItemIconStyle>
            <LinkText
              style={{ color: '#FFFFFF' }}
              primary={t('switchPlatform')}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>

          {viewer?.isSuperAdmin && (
            <ListItemLink
              button
              to="/settings"
              onClick={() => handleClickMenuItem('settings')}
              selected={selectedMenuItem == 'settings'}
            >
              <ListItemIconStyle>
                <SettingsIcon
                  style={{ width: '18px', height: '18px' }}
                  htmlColor="#FFFFFF"
                />
              </ListItemIconStyle>
              <LinkText
                style={{ color: '#FFFFFF' }}
                primary={t('settings')}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItemLink>
          )}
        </MenuList>
      </LeftMenu>
    </div>
  );
};

export default compose(
  translate(),
  createFragmentContainerHOC({
    viewer: graphql`
      fragment LeftNavigation_viewer on User {
        ...LanguagePicker_viewer
        id
        isAdmin
        isSuperAdmin
        isBroker
        isInvestor
        isSeller
      }
    `,
  }),
)(leftNavigation);
