/**
 * @flow
 * @relayHash a289eab80195d170ab0a69f6edc39143
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignupInput = {|
  name?: ?string,
  company?: ?string,
  email?: ?string,
  phone?: ?string,
  clientMutationId?: ?string,
|};
export type requestAccessFormMutationVariables = {|
  input: SignupInput
|};
export type requestAccessFormMutationResponse = {|
  +signup: ?{|
    +clientMutationId: ?string
  |}
|};
export type requestAccessFormMutation = {|
  variables: requestAccessFormMutationVariables,
  response: requestAccessFormMutationResponse,
|};
*/


/*
mutation requestAccessFormMutation(
  $input: SignupInput!
) {
  signup(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SignupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "requestAccessFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "requestAccessFormMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "requestAccessFormMutation",
    "id": null,
    "text": "mutation requestAccessFormMutation(\n  $input: SignupInput!\n) {\n  signup(input: $input) {\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '612c1609fdc22a46896c8f64d31e3698';
module.exports = node;
