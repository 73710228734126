import React from 'react';

const ArrowDownIcon = ({ width = 14, height = 8, viewBox = '0 0 14 8' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2424_5299" fill="white">
        <path d="M2.08049 0.355353L6.79423 5.0691L11.508 0.355353C11.9818 -0.118451 12.7472 -0.118451 13.221 0.355353C13.6948 0.829157 13.6948 1.59453 13.221 2.06834L7.64465 7.64465C7.17084 8.11845 6.40547 8.11845 5.93166 7.64465L0.355353 2.06834C-0.118451 1.59453 -0.118451 0.829157 0.355353 0.355353C0.829157 -0.106302 1.60668 -0.118451 2.08049 0.355353Z" />
      </mask>
      <path
        d="M2.08049 0.355353L6.79423 5.0691L11.508 0.355353C11.9818 -0.118451 12.7472 -0.118451 13.221 0.355353C13.6948 0.829157 13.6948 1.59453 13.221 2.06834L7.64465 7.64465C7.17084 8.11845 6.40547 8.11845 5.93166 7.64465L0.355353 2.06834C-0.118451 1.59453 -0.118451 0.829157 0.355353 0.355353C0.829157 -0.106302 1.60668 -0.118451 2.08049 0.355353Z"
        fill="#333E48"
      />
      <path
        d="M2.08049 0.355353L4.90891 -2.47307L2.08049 0.355353ZM6.79423 5.0691L3.9658 7.89752L6.79423 10.726L9.62266 7.89752L6.79423 5.0691ZM11.508 0.355353L8.67955 -2.47307V-2.47307L11.508 0.355353ZM13.221 2.06834L10.3925 -0.76009V-0.76009L13.221 2.06834ZM7.64465 7.64465L10.4731 10.4731L7.64465 7.64465ZM5.93166 7.64465L8.76009 4.81622L5.93166 7.64465ZM0.355353 2.06834L-2.47307 4.89676L0.355353 2.06834ZM0.355353 0.355353L-2.43611 -2.50957L-2.45471 -2.49144L-2.47307 -2.47307L0.355353 0.355353ZM-0.747941 3.18378L3.9658 7.89752L9.62266 2.24067L4.90891 -2.47307L-0.747941 3.18378ZM9.62266 7.89752L14.3364 3.18378L8.67955 -2.47307L3.9658 2.24067L9.62266 7.89752ZM14.3364 3.18378C13.2481 4.27207 11.4808 4.27207 10.3925 3.18378L16.0494 -2.47307C14.0135 -4.50898 10.7154 -4.50898 8.67955 -2.47307L14.3364 3.18378ZM10.3925 3.18378C9.30424 2.09549 9.30424 0.328203 10.3925 -0.76009L16.0494 4.89676C18.0853 2.86086 18.0853 -0.437173 16.0494 -2.47307L10.3925 3.18378ZM10.3925 -0.76009L4.81622 4.81622L10.4731 10.4731L16.0494 4.89676L10.3925 -0.76009ZM4.81622 4.81622C5.90451 3.72793 7.6718 3.72793 8.76009 4.81622L3.10324 10.4731C5.13914 12.509 8.43717 12.509 10.4731 10.4731L4.81622 4.81622ZM8.76009 4.81622L3.18378 -0.76009L-2.47307 4.89676L3.10324 10.4731L8.76009 4.81622ZM3.18378 -0.76009C4.27207 0.328203 4.27207 2.09549 3.18378 3.18378L-2.47307 -2.47307C-4.50898 -0.437173 -4.50898 2.86086 -2.47307 4.89676L3.18378 -0.76009ZM3.14681 3.22027C2.11413 4.22647 0.385811 4.31753 -0.747941 3.18378L4.90891 -2.47307C2.82755 -4.55443 -0.455816 -4.43908 -2.43611 -2.50957L3.14681 3.22027Z"
        fill="#96A4B4"
        mask="url(#path-1-inside-1_2424_5299)"
      />
    </svg>
  );
};

export default ArrowDownIcon;
