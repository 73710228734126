/* @flow */

import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  defaultProps,
  withHandlers,
  withStateHandlers,
  branch,
  withPropsOnChange,
  type HOC,
} from 'recompose';
import { Paper, IconButton, Divider } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ClickOutside from 'react-click-outside';

import { uniMaterial } from '../../controls/UniStyled';
import InfiniteGrid, {
  headerRowClass,
  cellClass,
  rowClass,
} from '../../controls/InfiniteGrid';

export const UInput = styled(Input)``;

export const UInputToolbar = styled(Input)`
  width: 367px;
  height: 48px;
  background: #edf5fe;
  border-radius: 93px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #96a4b4;
`;

export const UPaper = styled(uniMaterial(Paper))`
  position: absolute;
  display: flex;
  max-height: 80vh;
`;
const UClickOutside = uniMaterial(ClickOutside);

const SearchGroups = styled(InfiniteGrid)`
  .${headerRowClass} {
    border: 0;
    background: transparent;
  }

  .${rowClass} {
    display: flex;
    align-items: stretch;
    border: 0;
    background: transparent;
  }

  .${cellClass} {
    margin: 0;
    display: flex;
    border: 0;
    background: transparent;
  }
`;

const SearchContainer = styled.div`
  height: 44px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SearchContainerToolbar = styled.div`
  margin-top: 13px;
  padding: 8px 24px;
  gap: 12px;
  width: 367px;
  height: 48px;
  /* background: #edf5fe; */
  border-radius: 93px;
  position: relative;
  display: flex;
`;

const InputContainer = styled(Paper)`
  transition: opacity 0.2s ease;
  padding: 6px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  opacity: ${props => (props['data-active'] ? '1' : '0.6')};
`;

const InputContainerAbsolute = styled(InputContainer)`
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  box-shadow: none;
  background: none;
`;

const LeftIconContainer = styled(InputAdornment).attrs({ position: 'start' })`
  margin-right: 16px;
  margin-left: 8px;
`;

const LeftIconContainerToolbar = styled(InputAdornment).attrs({
  position: 'start',
})`
  margin-right: 0px;
  margin-left: 12px;
`;

const FlexibleVert = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HeightAnimatable = styled.div`
  transition: ${props => props.theme.transitions.create('height')};
  height: ${props => (props.height > 0 ? Math.min(props.height, 500) : 0)}px;
  will-change: height;
`;

const searchToolbar = ({
  open,
  options,
  inputValue,
  handleInputValueChange,
  handleClickOutside,
  handleFocus,
  registerTable,
  columns,
  placeholder,
  rowHeight,
  totalHeight,
  handleRowMouseOver,
  handleRowMouseOut,
  handleKeyDown,
  focusedIndex,
  setInputRef,
  handleMouseUp,
  handleDragStart,
  className,
  label,
  onInputChange,
}) => (
  <SearchContainerToolbar>
    <UClickOutside onClickOutside={handleClickOutside}>
      <div
        className={className}
        tabIndex={-1}
        // tabindex={0}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        // return focus on mouse up
        onMouseUp={handleMouseUp}
        // prevent drag to not lost focus
        onDragStart={handleDragStart}
      >
        <InputContainerAbsolute
          data-active={open || !!inputValue}
          elevation={open ? 8 : 2}
        >
          <UInputToolbar
            inputRef={setInputRef}
            disableUnderline
            autoComplete="off"
            id="search"
            placeholder={placeholder}
            label={label}
            value={inputValue}
            onChange={handleInputValueChange}
            style={{ marginBottom: open && !!options.length ? 6 : 0 }}
            startAdornment={
              <LeftIconContainerToolbar>
                <IconButton disabled>
                  <SearchIcon style={{ color: '#3455D1' }} />
                </IconButton>
              </LeftIconContainerToolbar>
            }
            endAdornment={
              <InputAdornment position="end" style={{ color: '#96A4B4' }}>
                {!!inputValue && (
                  <IconButton onClick={() => onInputChange('')}>
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
          <HeightAnimatable height={open ? totalHeight : 0}>
            {open && !!options.length && (
              <>
                <Divider />
                <SearchGroups
                  headerHeight={0}
                  rowHeight={rowHeight}
                  registerTable={registerTable}
                  values={options}
                  totalCount={options.length}
                  columns={columns}
                  onRowMouseOver={handleRowMouseOver}
                  onRowMouseOut={handleRowMouseOut}
                  scrollToIndex={focusedIndex}
                />
              </>
            )}
          </HeightAnimatable>
        </InputContainerAbsolute>
      </div>
    </UClickOutside>
  </SearchContainerToolbar>
);

const searchStandalone = ({
  open,
  options,
  inputValue,
  handleInputValueChange,
  handleFocus,
  registerTable,
  columns,
  placeholder,
  rowHeight,
  handleRowMouseOver,
  handleRowMouseOut,
  handleKeyDown,
  focusedIndex,
  setInputRef,
  handleMouseUp,
  handleDragStart,
  className,
  label,
  onInputChange,
}) => (
  <SearchContainer>
    <FlexibleVert
      className={className}
      tabIndex={-1}
      // tabindex={0}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      // return focus on mouse up
      onMouseUp={handleMouseUp}
      // prevent drag to not lost focus
      onDragStart={handleDragStart}
    >
      <InputContainer data-active={open || !!inputValue} elevation={2}>
        <UInput
          autoFocus
          inputRef={setInputRef}
          disableUnderline
          autoComplete="off"
          id="search"
          placeholder={placeholder}
          label={label}
          value={inputValue}
          onChange={handleInputValueChange}
          style={{ marginBottom: open && !!options.length ? 6 : 0 }}
          startAdornment={
            <LeftIconContainer>
              <IconButton disabled>
                <SearchIcon />
              </IconButton>
            </LeftIconContainer>
          }
          endAdornment={
            <InputAdornment position="end">
              {!!inputValue && (
                <IconButton onClick={() => onInputChange('')}>
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
          // onFocus={handleFocus}
          // onKeyDown={handleKeyDown}
        />
      </InputContainer>
      {open && !!options.length && (
        <FlexibleVert>
          <Divider />
          <SearchGroups
            headerHeight={0}
            rowHeight={rowHeight}
            registerTable={registerTable}
            values={options}
            totalCount={options.length}
            columns={columns}
            onRowMouseOver={handleRowMouseOver}
            onRowMouseOut={handleRowMouseOut}
            scrollToIndex={focusedIndex}
          />
        </FlexibleVert>
      )}
    </FlexibleVert>
  </SearchContainer>
);

type VirtualTable = {
  scrollToPosition: (x: number) => void,
};

type Options =
  | {
      type: 'header',
      key: string,
      totalCount: number,
      showCount: number,
    }
  | {
      type: 'row',
      key: string,
      data: {},
    };

type SearchProps = {
  open?: boolean,
  onClose?: () => void,
  onOpen?: () => void,
  placeholder?: string,
  label?: string,
  value: ?{
    [id: string]: ?{
      results: Array<{}>,
      totalCount: number,
    },
  },
  keys: Array<{
    id: string,
    enabled?: boolean,
    rowHeight?: number,
  }>,
  renderHeader: (
    {
      key: string,
      totalCount: number,
      showCount: number,
    },
    inputValue: string,
  ) => React.Node,
  renderRow: (
    any,
    inputValue: string,
    focused: boolean,
    onClose: () => void,
  ) => React.Node,
  onRowClick?: (opt: any) => void,

  inputValue: string,
  onInputChange: (str: string) => void,
  disableUnderline?: boolean,
  className?: string,
  disablePaper?: boolean,
};

const searchEnhancer: HOC<*, SearchProps> = compose(
  defaultProps({
    headerHeight: 48,
    rowHeight: 30,
  }),
  branch(
    ({ onOpen }) => !onOpen,
    withStateHandlers(
      { open: false },
      {
        onOpen: () => () => ({
          open: true,
        }),
        onClose: () => () => ({
          open: false,
        }),
      },
    ),
  ),
  withStateHandlers(
    { focusedIndex: -1 },
    {
      handleRowMouseOver: () => ({ index }: { index?: number }) => ({
        focusedIndex: index,
      }),
      handleRowMouseOut: () => () => ({
        focusedIndex: undefined,
      }),
    },
  ),
  withHandlers({
    handleClose: ({ onClose }) => () => {
      // force blur on close
      if (window.document.activeElement && window.document.activeElement.blur) {
        window.document.activeElement.blur();
      }

      onClose();
    },
  }),
  withPropsOnChange(
    [
      'value',
      'keys',
      'renderHeader',
      'renderRow',
      'headerHeight',
      'rowHeight',
      'inputValue',
    ],
    ({ value, keys, headerHeight, rowHeight, inputValue }) => {
      const options: Array<Options> = [];
      if (!value) {
        return {
          options: [],
          columns: [],
          rowHeight: 30,
          totalHeight: 0,
        };
      }

      keys.forEach((key, i) => {
        if (!value || !value[key.id]) return;
        const { results, totalCount } = value[key.id];
        options.push({
          type: 'header',
          key: key.id,
          data: key,
          totalCount,
          showCount: results.length,
          isFirst: i === 0,
        });
        results.filter(Boolean).forEach(data => {
          options.push({ type: 'row', key: key.id, data });
        });
        if (totalCount > results.length) {
          options.push({
            type: 'row',
            key: 'showMore',
            data: key,
            totalCount,
            showCount: results.length,
          });
        }
      });

      const key2height = keys.reduce(
        (r, key) => ({
          ...r,
          [key.id]: key.rowHeight || rowHeight,
        }),
        {},
      );

      const rowHeightFn = ({ index }) => {
        if (options[index].type === 'header') {
          return headerHeight;
        }
        return key2height[options[index].key]({
          data: options[index].data,
          inputValue,
        });
      };

      let totalHeight = 0;
      for (let index = 0; index !== options.length; ++index) {
        totalHeight += rowHeightFn({ index });
      }

      return {
        options,
        rowHeight: rowHeightFn,
        totalHeight,
      };
    },
  ),
  withHandlers(() => {
    let ref_: ?VirtualTable = null;
    let inputRef_: ?HTMLInputElement = null;
    let lastKeyTime_ = 0;
    return {
      registerTable: () => (ref: ?VirtualTable) => {
        ref_ = ref;
      },
      setInputRef: () => (ref: ?HTMLInputElement) => {
        inputRef_ = ref;
      },
      handleClickOutside: ({ handleClose, open }) => () => {
        if (open) {
          if (ref_) ref_.scrollToPosition(0);
          handleClose();
        }
      },
      handleFocus: ({ onOpen }) => () => {
        onOpen();
      },
      handleMouseUp: () => () => {
        if (inputRef_) {
          inputRef_.focus();
        }
      },
      handleDragStart: () => (e: SyntheticEvent<EventTarget>) => {
        e.preventDefault();
      },
      handleInputValueChange: ({ onInputChange }) => (
        e: SyntheticInputEvent<HTMLInputElement>,
      ) => {
        onInputChange(e.target.value);
      },
      handleKeyDown: ({
        onRowClick,
        handleClose,
        focusedIndex,
        handleRowMouseOver,
        handleRowMouseOut,
        options,
      }) => (e: SyntheticKeyboardEvent<EventTarget>) => {
        switch (e.keyCode) {
          // tab, enter
          case 9:
          case 13:
            // blur
            /*
            if (
              window.document.activeElement &&
              window.document.activeElement.blur
            ) {
              window.document.activeElement.blur();
            }
            */
            if (
              focusedIndex &&
              focusedIndex > 0 &&
              focusedIndex < options.length
            ) {
              if (onRowClick && options[focusedIndex].type === 'row') {
                onRowClick(options[focusedIndex]);
              }
            }

            e.preventDefault();
            break;
          // up
          case 38: {
            lastKeyTime_ = new Date().getTime();

            const currentIndex = !focusedIndex ? options.length : focusedIndex;

            let prevIndex;
            for (
              prevIndex = currentIndex - 1;
              prevIndex < options.length && prevIndex >= 0;
              prevIndex--
            ) {
              if (options[prevIndex].type === 'row') break;
            }
            if (prevIndex < 0) {
              handleRowMouseOver({
                index: 0,
              });
            } else {
              handleRowMouseOver({ index: prevIndex });
            }
            // prevent to not move input cursor
            e.preventDefault();
            break;
          }
          // down
          case 40: {
            lastKeyTime_ = new Date().getTime();

            const currentIndex = !focusedIndex ? 0 : focusedIndex;
            // find next index
            let nextIndex;
            for (
              nextIndex = currentIndex + 1;
              nextIndex < options.length && nextIndex >= 0;
              nextIndex++
            ) {
              if (options[nextIndex].type === 'row') break;
            }

            if (nextIndex >= options.length) {
              handleRowMouseOver({
                index: 0,
              });
            } else {
              handleRowMouseOver({
                index: nextIndex,
              });
            }
            // prevent to not move input cursor
            e.preventDefault();
            break;
          }
          // escape
          case 27:
            handleClose();
            handleRowMouseOut();
            break;
          default:
        }
      },

      handleRowMouseOver: ({ handleRowMouseOver }) => ({
        index,
      }: {
        index: number,
      }) => {
        // prevent mouse hover immediatelly after keyboard up down events
        if (new Date().getTime() - lastKeyTime_ > 500) {
          handleRowMouseOver({ index });
        }
      },

      handleRowMouseOut: ({ handleRowMouseOut }) => () => {
        // prevent mouse hover immediatelly after keyboard up down events
        if (new Date().getTime() - lastKeyTime_ > 500) {
          handleRowMouseOut();
        }
      },

      getFocusedIndex: ({ focusedIndex }) => () => focusedIndex,
    };
  }),
  withPropsOnChange(
    [
      'renderHeader',
      'renderRow',
      'inputValue',
      'getFocusedIndex',
      'handleClose',
    ],
    ({ renderHeader, renderRow, inputValue, getFocusedIndex, handleClose }) => {
      const columns = [
        {
          dataKey: 'rowData',
          sortable: false,
          title: 'Image',
          format: (
            _,
            __,
            { rowData, rowIndex }: { rowData: Options, rowIndex: number },
          ) => {
            if (rowData.type === 'header') {
              return renderHeader(rowData, inputValue);
            }

            if (rowData.type === 'row') {
              const focusedIndex = getFocusedIndex();
              const focused = rowIndex === focusedIndex;
              return renderRow(rowData, inputValue, focused, handleClose);
            }

            return null;
          },
        },
      ];

      return {
        columns,
      };
    },
  ),
);

export const SearchStandalone = searchEnhancer(searchStandalone);
export const SearchToolbar = searchEnhancer(searchToolbar);
