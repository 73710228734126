export default ({ street, streetNr, postcode, city, country }) => {
  if (!(street || city || country)) return '';

  return (street || '') +
    (streetNr ? ` ${streetNr}` : '') +
    (postcode || city ? ', ' : '') +
    (postcode || '') +
    (postcode && city ? ` ${city}` : '') +
    (!postcode && city ? city : '') +
    ((postcode || city) && country ? `, ${country}` : '') +
    !(postcode || city) && country
    ? country
    : '';
};
