import React from 'react';
import styled from 'styled-components';

export const CircleWrapper = styled.div`
  width: 16px;
  height: 16px;
  background: #BDBCFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 8px;
    text-align: center;
    color: #FFFFFF;
  }
`;


const CircleNameIcon = (props) => {
  const { name = '' } = props;
  const tmpItems = name.split(' ');
  let shortName = '';
  if (tmpItems.length > 1) {
    shortName = tmpItems[0].substr(0, 1) + tmpItems[1].substr(0, 1);
  }
  else if (tmpItems.length === 1) {
    shortName = tmpItems[0].substr(0, 2);
  }
  shortName = shortName.toUpperCase();
  return (
    <>
      {shortName && (<CircleWrapper>
        <div>
          {shortName}
        </div>
      </CircleWrapper>
      )}
    </>
  );
};

export default CircleNameIcon;
