const initialState = {
  count: 0,
};

export default (state = initialState, action) => {
  if (action.type === 'INC_LOADING') {
    return { ...state, count: state.count + 1 };
  }

  if (action.type === 'DEC_LOADING') {
    return { ...state, count: state.count - 1 };
  }

  return state;
};
