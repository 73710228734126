// @flow

export default (fn: () => void) => (...args: any) =>
  new Promise<any>((r, rj) =>
    fn(...args, (data, errs) => {
      if (errs) {
        rj(errs);
      } else {
        r(data);
      }
    }),
  );
