import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import Tooltip from '@material-ui/core/Tooltip';
import { Div, uniDiv } from '../../controls/UniStyled';
import { useTranslation } from 'react-i18next';

const UTooltip = uniDiv(Tooltip);
const StyledSingleTitle = styled.div`
  height: 48px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #333e48;
  display: flex;
  align-items: center;
  padding-top: ${props => (props.isDealList ? '11px' : '13px')};
  text-overflow: ellipsis;
  display: block;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledReturnLink = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3455d1;
  position: absolute;
  top: 13px;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const FULL_HEADER_ROUTE_TITLE_CONFIG = {
  '/': 'Dashboard',
  '/organisations': 'Organisations',
  '/individuals': 'Users',
  '/settings': 'Settings',
  search: 'Search',
};

const ArrowBackIcon = () => (
  <svg
    width="7"
    height="13"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70711 10.7929C7.09763 11.1834 7.09763 11.8166 6.70711 12.2071C6.31658 12.5976 5.68342 12.5976 5.29289 12.2071L6.70711 10.7929ZM5.29289 0.792893C5.68342 0.402369 6.31658 0.402369 6.70711 0.792893C7.09763 1.18342 7.09763 1.81658 6.70711 2.20711L5.29289 0.792893ZM5.29289 12.2071L1 7.91421L2.41421 6.5L6.70711 10.7929L5.29289 12.2071ZM1 5.08579L5.29289 0.792893L6.70711 2.20711L2.41421 6.5L1 5.08579ZM1 7.91421C0.218953 7.13317 0.218951 5.86684 1 5.08579L2.41421 6.5L2.41421 6.5L1 7.91421Z"
      fill="#3455D1"
    />
  </svg>
);

const HeaderTitleBox = ({ isFullLayoutHeader, t }) => {
  const [currentRoute, setCurrentRoute] = useState('');
  const [itemId, setItemId] = useState('');

  const location = useLocation();
  const history = useHistory();
  const [loadOrgData, { data: organisationData }] = useLazyQuery(
    gql`
      query OrganisationQuery($id: ID!) {
        organisation: node(id: $id) {
          ...OrganisationDetailsOrgs_organisation
        }
      }
      fragment OrganisationDetailsOrgs_organisation on Organisation {
        name
      }
    `,
  );

  const [loadUserData, { data: userData }] = useLazyQuery(
    gql`
      query UserQuery($id: ID!) {
        user: node(id: $id) {
          ...UserDetails_user
        }
      }
      fragment UserDetails_user on User {
        fullName
      }
    `,
  );

  const [loadDealData, { data: dealData }] = useLazyQuery(
    gql`
      query DealQuery($id: ID!) {
        deal: node(id: $id) {
          ...DealDetailsOrgs_deal
        }
      }
      fragment DealDetailsOrgs_deal on Deal {
        name
      }
    `,
  );

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/deals/' || pathname === '/deals') {
      setCurrentRoute('deal-list');
    } else {
      const [_, routeName, id] = pathname.split('/');
      setCurrentRoute(routeName);
      setItemId(id);

      if (id) {
        switch (routeName) {
          case 'organisations':
            loadOrgData({
              variables: {
                id,
              },
            });
            break;
          case 'deals':
            loadDealData({
              variables: {
                id,
              },
            });
            break;
          case 'individuals':
            loadUserData({
              variables: {
                id,
              },
            });
            break;
          default:
            break;
        }
      }
    }
  }, [location.pathname]);

  const onGoBack = () => {
    history.push(`/${currentRoute}`);
  };

  const renderTitle = (title, isDealList) => (
    <UTooltip placement="bottom" title={title}>
      <StyledSingleTitle isDealList={isDealList}>{title}</StyledSingleTitle>
    </UTooltip>
  );

  const titleTranslationMapping = {
    '/': t('dashboard:title'),
    '/organisations': t('organisations:title'),
    '/individuals': t('users:title'),
    '/settings': t('settings:title'),
    '/deals': t('deals:title'),
  };

  return (
    <>
      {isFullLayoutHeader && location.pathname.indexOf('individuals') == -1 && (
        <StyledSingleTitle>
          {titleTranslationMapping[
           location.pathname
          ] || FULL_HEADER_ROUTE_TITLE_CONFIG[location.pathname]}
        </StyledSingleTitle>
      )}
      {isFullLayoutHeader && location.pathname.indexOf('/search') != -1 && (
        <StyledSingleTitle>Search</StyledSingleTitle>
      )}
      {isFullLayoutHeader && location.pathname.indexOf('individuals') > -1 && (
        <>
          {itemId ? (
            <>
              <StyledReturnLink style={{ marginTop: '6px' }} onClick={onGoBack}>
                <ArrowBackIcon />
                {t('returnToUsers')}
              </StyledReturnLink>
              <UTooltip placement="bottom" title={userData?.user.fullName}>
                <StyledSingleTitle isDealList style={{ marginTop: '15px' }}>
                  {userData?.user.fullName}
                </StyledSingleTitle>
              </UTooltip>
            </>
          ) : (
            <StyledSingleTitle>
              {titleTranslationMapping[location.pathname]}
            </StyledSingleTitle>
          )}
        </>
      )}
      {!isFullLayoutHeader && currentRoute === 'deal-list' && (
        <StyledSingleTitle isDealList>{t('deals:title')}</StyledSingleTitle>
      )}
      {!isFullLayoutHeader && currentRoute !== 'deal-list' && (
        <>
          {currentRoute === 'organisations' && (
            <>
              <StyledReturnLink onClick={onGoBack}>
                <ArrowBackIcon />
                {t('returnToOrganisations')}
              </StyledReturnLink>
              <UTooltip
                placement="bottom"
                title={organisationData?.organisation.name}
              >
                <StyledSingleTitle isDealList>
                  {organisationData?.organisation.name}
                </StyledSingleTitle>
              </UTooltip>
            </>
          )}
          {currentRoute === 'deals' && (
            <>
              <StyledReturnLink onClick={onGoBack}>
                <ArrowBackIcon />
                {t('returnToDeals')}
              </StyledReturnLink>
              <UTooltip placement="bottom" title={dealData?.deal.name}>
                <StyledSingleTitle isDealList>
                  {dealData?.deal.name}
                </StyledSingleTitle>
              </UTooltip>
            </>
          )}
          {currentRoute === 'settings' && (
            <StyledSingleTitle style={{ paddingTop: 0 }}>
              {t('settings:title')}
            </StyledSingleTitle>
          )}
        </>
      )}
    </>
  );
};

export default HeaderTitleBox;
