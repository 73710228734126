/**
 * @flow
 * @relayHash 64b8f4466ce0c0f5d0687ef33a6f4cc5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDealPipelineStageInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type dealPipelineStagesDeleteDealPipelineStageMutationVariables = {|
  input: DeleteDealPipelineStageInput
|};
export type dealPipelineStagesDeleteDealPipelineStageMutationResponse = {|
  +deleteDealPipelineStage: ?{|
    +deletedNodeId: string
  |}
|};
export type dealPipelineStagesDeleteDealPipelineStageMutation = {|
  variables: dealPipelineStagesDeleteDealPipelineStageMutationVariables,
  response: dealPipelineStagesDeleteDealPipelineStageMutationResponse,
|};
*/


/*
mutation dealPipelineStagesDeleteDealPipelineStageMutation(
  $input: DeleteDealPipelineStageInput!
) {
  deleteDealPipelineStage(input: $input) {
    deletedNodeId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteDealPipelineStageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteDealPipelineStage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDealPipelineStagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedNodeId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealPipelineStagesDeleteDealPipelineStageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dealPipelineStagesDeleteDealPipelineStageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "dealPipelineStagesDeleteDealPipelineStageMutation",
    "id": null,
    "text": "mutation dealPipelineStagesDeleteDealPipelineStageMutation(\n  $input: DeleteDealPipelineStageInput!\n) {\n  deleteDealPipelineStage(input: $input) {\n    deletedNodeId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c872874a1749d9768e018ff3373da47';
module.exports = node;
