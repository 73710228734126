/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type USER_LANGUAGE = "de" | "en" | "fr" | "it" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type LanguagePicker_viewer$ref: FragmentReference;
declare export opaque type LanguagePicker_viewer$fragmentType: LanguagePicker_viewer$ref;
export type LanguagePicker_viewer = {|
  +id: string,
  +language: ?USER_LANGUAGE,
  +$refType: LanguagePicker_viewer$ref,
|};
export type LanguagePicker_viewer$data = LanguagePicker_viewer;
export type LanguagePicker_viewer$key = {
  +$data?: LanguagePicker_viewer$data,
  +$fragmentRefs: LanguagePicker_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "LanguagePicker_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "language",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '43106cd0f4a451bade2ae91e22ffc141';
module.exports = node;
