import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext } from 'recompose';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import { graphql } from 'react-relay';
import { withFormik } from 'formik';
import ChipInput from 'material-ui-chip-input';
import * as yup from 'yup';
import { withTranslation as translate } from 'react-i18next';

import { updateSettings } from '../mutations/settings';
import { withQuery } from '../../../../utils/relay';
import parseErrorsLegacy from '../../utils/parseErrorsLegacy';

const Root = styled.div`
  padding: 24px;
`;

const BaseSettingsTab = ({
  handleSubmit,
  values,
  setFieldValue,
  setFieldTouched,
  isValid,
  dirty,
  t,
  errors,
}) => (
  <Root>
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <ChipInput
            label={t('adminEmails')}
            defaultValue={values.adminEmails}
            margin="dense"
            fullWidth
            type="email"
            error={!isValid}
            helperText={!isValid ? errors.adminEmails : ''}
            onChange={chips => {
              setFieldValue('adminEmails', chips);
              setFieldTouched('adminEmails', true);
            }}
          />
        </CardContent>
        <CardActions>
          <Button color="primary" type="submit" disabled={!dirty}>
            {t('button.submit')}
          </Button>
        </CardActions>
      </Card>
    </form>
  </Root>
);

const enhancer = compose(
  translate('settings'),
  withQuery(
    graphql`
      query BaseSettingsTabQuery {
        settings {
          adminEmails
        }
      }
    `,
    {
      renderLoading: true,
    },
  ),
  getContext({
    api: PropTypes.object.isRequired,
  }),
  withFormik({
    isInitialValid: true,
    mapPropsToValues: ({ data: { settings } }) => settings,
    handleSubmit: async (values, { props: { api }, resetForm, setErrors }) => {
      updateSettings(api, values)
        .then(updatedSetting => {
          resetForm();
        })
        .catch(errs => {
          const errors = parseErrorsLegacy(errs, values);
          setErrors(errors);
        });
    },
    validationSchema: yup.object().shape({
      adminEmails: yup
        .array()
        .of(yup.string().email())
        .required(),
    }),
  }),
);

export default enhancer(BaseSettingsTab);
