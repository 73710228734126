/**
 * @flow
 * @relayHash f562ef2d46df7e3e2b0ab9d0107ff9f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dealsFiltersExpectedPriceQueryVariables = {||};
export type dealsFiltersExpectedPriceQueryResponse = {|
  +minDeal: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +expectedSalePrice: ?number,
      |}
    |}>
  |},
  +maxDeal: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +expectedSalePrice: ?number,
      |}
    |}>
  |},
|};
export type dealsFiltersExpectedPriceQuery = {|
  variables: dealsFiltersExpectedPriceQueryVariables,
  response: dealsFiltersExpectedPriceQueryResponse,
|};
*/


/*
query dealsFiltersExpectedPriceQuery {
  minDeal: deals(first: 1, sortBy: "expectedSalePrice", sortDirection: ASC) {
    edges {
      node {
        id
        expectedSalePrice
      }
    }
  }
  maxDeal: deals(first: 1, sortBy: "expectedSalePrice", sortDirection: DESC) {
    edges {
      node {
        id
        expectedSalePrice
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": "expectedSalePrice"
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "DealEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Deal",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "expectedSalePrice",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": "minDeal",
    "name": "deals",
    "storageKey": "deals(first:1,sortBy:\"expectedSalePrice\",sortDirection:\"ASC\")",
    "args": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "sortDirection",
        "value": "ASC"
      }
    ],
    "concreteType": "DealConnection",
    "plural": false,
    "selections": (v2/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "maxDeal",
    "name": "deals",
    "storageKey": "deals(first:1,sortBy:\"expectedSalePrice\",sortDirection:\"DESC\")",
    "args": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "sortDirection",
        "value": "DESC"
      }
    ],
    "concreteType": "DealConnection",
    "plural": false,
    "selections": (v2/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealsFiltersExpectedPriceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dealsFiltersExpectedPriceQuery",
    "argumentDefinitions": [],
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "dealsFiltersExpectedPriceQuery",
    "id": null,
    "text": "query dealsFiltersExpectedPriceQuery {\n  minDeal: deals(first: 1, sortBy: \"expectedSalePrice\", sortDirection: ASC) {\n    edges {\n      node {\n        id\n        expectedSalePrice\n      }\n    }\n  }\n  maxDeal: deals(first: 1, sortBy: \"expectedSalePrice\", sortDirection: DESC) {\n    edges {\n      node {\n        id\n        expectedSalePrice\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1fdbcf49991ae1456ece075b7c8bf5d';
module.exports = node;
