const initialState = [];

export default (state = initialState, action) => {
  if (action.type === 'ADD_ERROR') {
    return [...state, action.payload];
  }

  if (action.type === 'REMOVE_ERROR') {
    return state.filter(e => e.id !== action.payload.id);
  }

  return state;
};
