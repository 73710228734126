import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createDealPipelineStage = makeCreateDictionaryMutation(
  graphql`
    mutation dealPipelineStagesCreateDealPipelineStageMutation(
      $input: CreateDealPipelineStageInput!
    ) {
      createDealPipelineStage(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createDealPipelineStage',
  'dealPipelineStages',
);

export const updateDealPipelineStage = makeUpdateDictionaryMutation(
  graphql`
    mutation dealPipelineStagesUpdateDealPipelineStageMutation(
      $input: UpdateDealPipelineStageInput!
    ) {
      updateDealPipelineStage(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateDealPipelineStage',
  'dealPipelineStages',
);

export const deleteDealPipelineStage = makeDeleteDictionaryMutation(
  graphql`
    mutation dealPipelineStagesDeleteDealPipelineStageMutation(
      $input: DeleteDealPipelineStageInput!
    ) {
      deleteDealPipelineStage(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteDealPipelineStage',
  'dealPipelineStages',
);
