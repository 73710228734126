// @flow

import * as React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { graphql, useFragment } from '../../controls/relay';
import type {
  contactDetails_contact,
  contactDetails_contact$key,
} from './__generated__/contactDetails_contact.graphql';

type Props = {
  variant: 'vertical' | 'horizontal',
  contact: contactDetails_contact$key,
};

export const ContactDetails = ({ variant, contact }: Props) => {
  const user: contactDetails_contact = useFragment<contactDetails_contact>(
    graphql`
      fragment contactDetails_contact on User {
        ...UserAvatar_user
        id
        fullName
        jobTitle
        mobilePhone
        officePhone
        email
        primaryImage {
          url
        }
      }
    `,
    contact,
  );

  return (
    <Box
      display="flex"
      css={[
        variant === 'horizontal' &&
          `
      align-items: center
      > div:first-child {
        margin-right: 24px;
      }
    `,
        variant === 'vertical' &&
          `
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
    `,
      ]}
    >
      <Box>
        <img
          src={
            user.primaryImage?.url ??
            'https://storage.googleapis.com/cre.realadvisor.com/user_placeholder.gif'
          }
          css={`
            border-radius: 120px;
            height: 120px;
            width: 120px;
            object-fit: cover;
          `}
        />
      </Box>
      <Box>
        <Typography variant="h6">{user.fullName}</Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          {user.jobTitle}
        </Typography>
        <Typography variant="body1">
          <Link href={`tel:${user.mobilePhone ?? ''}`}>
            <Typography color="textPrimary">{user.mobilePhone}</Typography>
          </Link>
        </Typography>
        <Typography variant="body1">
          <Link href={`mailto:${user.email}`}>
            <Typography color="textPrimary">{user.email}</Typography>
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
