/**
 * @flow
 * @relayHash e3898c111e4108bac9308916706b2b73
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteOrganisationTypeInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type organisationTypesDeleteOrganisationTypeMutationVariables = {|
  input: DeleteOrganisationTypeInput
|};
export type organisationTypesDeleteOrganisationTypeMutationResponse = {|
  +deleteOrganisationType: ?{|
    +deletedNodeId: string
  |}
|};
export type organisationTypesDeleteOrganisationTypeMutation = {|
  variables: organisationTypesDeleteOrganisationTypeMutationVariables,
  response: organisationTypesDeleteOrganisationTypeMutationResponse,
|};
*/


/*
mutation organisationTypesDeleteOrganisationTypeMutation(
  $input: DeleteOrganisationTypeInput!
) {
  deleteOrganisationType(input: $input) {
    deletedNodeId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteOrganisationTypeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteOrganisationType",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteOrganisationTypePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedNodeId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "organisationTypesDeleteOrganisationTypeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "organisationTypesDeleteOrganisationTypeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "organisationTypesDeleteOrganisationTypeMutation",
    "id": null,
    "text": "mutation organisationTypesDeleteOrganisationTypeMutation(\n  $input: DeleteOrganisationTypeInput!\n) {\n  deleteOrganisationType(input: $input) {\n    deletedNodeId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc86894ae8dad9506d08fe242169d60c';
module.exports = node;
