// @flow

import * as relayHooks from 'relay-hooks';

export { graphql } from 'relay-hooks';

export type $FragmentRef<T> = {
  +$fragmentRefs: $PropertyType<T, '$refType'>,
};

export type $FragmentRefArray<T> = $TupleMap<T, <T>(T) => $FragmentRef<T>>;

type DataFromEnum = {
  NETWORK_ONLY: any,
  STORE_THEN_NETWORK: any,
  STORE_OR_NETWORK: any,
  STORE_ONLY: any,
};
type DataFrom = $Keys<DataFromEnum>;

type $UseQueryProps<Query> = {
  dataFrom?: DataFrom,
  query: any,
  variables?: $ElementType<Query, 'variables'>,
};

type $RenderQueryProps<Query> = {
  error: ?Error,
  props: ?$ElementType<Query, 'response'>,
  retry: () => void,
  cached?: boolean,
};

type QueryType = {|
  variables: any,
  response: any,
|};
export const useQuery = <Query: QueryType>(
  props: $UseQueryProps<Query>,
): $RenderQueryProps<Query> => relayHooks.useQuery(props);

type $UseMutationProps<Query> = {
  dataFrom?: DataFrom,
  query: any,
  variables?: $ElementType<Query, 'variables'>,
};

type $RenderMutationProps<Mutation> = [
  (
    input: $ElementType<$ElementType<Mutation, 'variables'>, 'input'>,
    options?: any,
  ) => Promise<$ElementType<Mutation, 'response'>>,
  {
    error: ?Error,
    loading: boolean,
    data: $ElementType<Mutation, 'response'>,
  },
];

export const useMutation = <Mutation: any>(
  mutation: any,
  options?: {|
    onCompleted: (data: $ElementType<Mutation, 'response'>) => void,
  |},
): $RenderMutationProps<Mutation> => {
  const [mutate, props] = relayHooks.useMutation(mutation, options);

  return [
    (input, options) => mutate({ variables: { input }, ...options }),
    props,
  ];
};

export const useFragment = <Fragment: any>(
  taggedNode: any,
  fragmentRef: any,
): Fragment => relayHooks.useFragment(taggedNode, fragmentRef);
