import React from 'react';
import styled, { css } from 'styled-components';
import { Typography, IconButton } from '@material-ui/core';
import FlexBox from 'components/FlexBox';

export const UploadFileWrapper = styled(FlexBox)`
  ${({ theme: { spacing } }) => css`
    background-color: #edf5fe;
    padding: ${spacing(1.5)}px ${spacing(3)}px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: ${spacing(2.5)}px;

    && svg {
      color: #3455d1;
    }
  `}
`;

export const FileName = styled(Typography)`
  ${({ theme: { typography } }) => css`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

    color: #3455d1;
  `}
`;

export const RemoveButton = styled(IconButton)`
  ${() => css`
    padding: 0;
  `}
`;
