/* eslint-disable no-underscore-dangle  */
import React from 'react';
import idx from 'idx';
import { compose, withHandlers, withProps, defaultProps } from 'recompose';
import styled from 'styled-components';
import { AutoSizer, InfiniteLoader, Table, Column } from 'react-virtualized';
import { colors, TableSortLabel } from '@material-ui/core';
import {
  requestAnimationTimeout,
  cancelAnimationTimeout,
} from '../utils/requestAnimationTimeout';
import infiniteGridRowRenderer from './infiniteGridRowRenderer';

export const headerRowClass = 'il_headerRowClass';
export const rowClass = 'il_rowClass';
export const rowClassSelected = 'il_rowClassSelected';
export const cellClass = 'il_cellClass';
export const headerCellClass = 'il_headerCellClass';

const StyledTableSortLabel = styled(TableSortLabel)`
  margin-right: -8px;
  &:focus {
    color: rgba(0, 0, 0, 0.54);
    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

const StyledTable = styled(Table)`
  & .ReactVirtualized__Grid {
    outline: none;
  }

  & .${headerRowClass} {
    display: flex;
    flex-direction: row;
    background-color: ${colors.grey[200]};
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid #e9e9e9;
    font-size: 13px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    align-items: center;
    outline: none;
  }

  & .${rowClass} {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    outline: none;
    background-color: #ffffff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
    &.${rowClassSelected} {
      background-color: #e8f5e9;
    }
    cursor: pointer;
  }

  & .${headerCellClass} {
    padding: 2px 8px;
  }
`;

const infiniteGrid = ({
  getRowClassName,
  rowRenderer,
  onRowClick,
  cellRenderer,
  headerRenderer,
  headerHeight,
  rowHeight,
  loadMoreRows,
  totalCount,
  isRowLoaded,
  columns,
  rowGetter,
  threshold,
  minimumBatchSize,
  values,
  sort,
  onSortChanged,
  registerTable,
  cellDataGetter,
  setRef,
  allowFullBatch,
  className,
  onRowMouseOver,
  onRowMouseOut,
  scrollToIndex,
  minWidth = 0,
  // refresh params
  // focusedIndex,
}) => (
  <AutoSizer ref={setRef} style={{ minWidth }}>
    {({ height, width }) =>
      height > 0 &&
      width > 0 && (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={totalCount}
          minimumBatchSize={minimumBatchSize}
          threshold={threshold}
        >
          {({ onRowsRendered, registerChild }) => (
            <StyledTable
              className={className}
              innerRef={r => {
                registerChild(r);
                registerTable(r);
              }}
              onRowsRendered={onRowsRendered}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              height={height}
              width={width > minWidth ? width : minWidth}
              rowCount={allowFullBatch ? totalCount : values.length}
              rowGetter={rowGetter}
              rowRenderer={rowRenderer}
              rowClassName={getRowClassName}
              onRowClick={onRowClick}
              sortBy={sort && sort.sortBy}
              sortDirection={sort && sort.sortDirection}
              sort={onSortChanged}
              onRowMouseOver={onRowMouseOver}
              onRowMouseOut={onRowMouseOut}
              scrollToIndex={scrollToIndex}
              // to refresh on values change
              values={values}
              // focusedIndex={focusedIndex}
            >
              {columns.map((col, columnIndex) => (
                <Column
                  key={columnIndex}
                  headerRenderer={headerRenderer}
                  columnData={{ columnIndex }}
                  // use non default data getter so we can fake data for not existent rows
                  cellDataGetter={cellDataGetter}
                  cellRenderer={cellRenderer}
                  className={cellClass}
                  headerClassName={headerCellClass}
                  disableSort={!col.sortable || !sort}
                  {...col}
                  dataKey={col.sortKey || col.dataKey}
                />
              ))}
            </StyledTable>
          )}
        </InfiniteLoader>
      )
    }
  </AutoSizer>
);

const enhacer = compose(
  defaultProps({
    threshold: 60,
    minimumBatchSize: 40,
    headerHeight: 60,
    rowHeight: 50,
    cellRenderer: undefined, // ({ cellData /* , dataKey */ }) => cellData,
    headerRenderer: undefined,
    sort: undefined, // {sortBy,sortDirection}
    columnDefaults: {
      flexGrow: 1,
      minWidth: 60,
      sortable: false,
      width: 0,
    },
    columns: [],
    values: [],
    useNode: true,
    onRowClick: () => {},
    sortState: {
      sortBy: undefined,
      sortDirection: undefined,
    },
    // allow to load all the data if user drag scroll bar at the and
    allowFullBatch: true,
  }),
  withProps(({ columnDefaults, columns }) => {
    const columnsList = columns.map(col => ({ ...columnDefaults, ...col }));

    return {
      columns: columnsList,
    };
  }),
  withHandlers(() => {
    let ref_;
    let timeoutHandle_;

    const loadMoreRows_ = ({ values, loadMore, stopIndex }) => {
      const loadCount = Math.max(1 + stopIndex - values.length, 1);

      return new Promise((r, rj) => {
        if (timeoutHandle_) {
          cancelAnimationTimeout(timeoutHandle_);
        }

        return loadMore(loadCount, e => (e ? rj(e) : r()));
      }).catch(({ isLoading }) => {
        const DEBOUNCE_TIME = 100;
        if (isLoading) {
          if (timeoutHandle_) {
            cancelAnimationTimeout(timeoutHandle_);
          }
          timeoutHandle_ = requestAnimationTimeout(
            () => loadMoreRows_({ values, loadMore, stopIndex }),
            DEBOUNCE_TIME,
          );
        }
      });
    };

    return {
      setRef: () => v => {
        if (v === null) {
          // clear queue on unmount
          if (timeoutHandle_) {
            cancelAnimationTimeout(timeoutHandle_);
          }
        }
      },
      registerTable: ({ registerTable }) => ref => {
        ref_ = ref;
        if (registerTable) {
          registerTable(ref);
        }
      },
      rowGetter: ({ values }) => ({ index }) => values && values[index],
      isRowLoaded: ({ values }) => ({ index }) =>
        values && index < values.length,
      getRowClassName: ({ selectedRowIndex }) => ({ index }) =>
        index < 0
          ? headerRowClass
          : selectedRowIndex === index
          ? `${rowClass} ${rowClassSelected}`
          : rowClass,
      rowRenderer: ({
        onRowMouseDown,
        rowTabIndex,
        selectedRowIndex,
      }) => props =>
        infiniteGridRowRenderer({
          ...props,
          onRowMouseDown,
          rowTabIndex,
          selectedRowIndex,
        }),
      cellDataGetter: () => () => {},
      // p && p.rowData ? p.rowData[p.dataKey] : undefined,

      cellRenderer: ({ cellRenderer, columns, useNode }) => data => {
        const { format, dataKey } = columns[data.columnIndex];

        const cellData = useNode
          ? data.rowData && data.rowData.node && data.rowData.node[dataKey]
          : data.rowData && data.rowData[dataKey];
        // Parent cellRenderer if exists
        if (cellRenderer) {
          return cellRenderer({ ...data, cellData });
        }

        return format
          ? format(
              cellData,
              useNode
                ? idx(data, _ => _.rowData.node)
                : idx(data, _ => _.rowData),
              data,
            )
          : cellData || '';
      },
      headerRenderer: ({ headerRenderer, columns, sort }) => data => {
        if (headerRenderer) {
          return headerRenderer(data);
        }

        const {
          title,
          sortable,
          formatHeader,
          dataKey,
          align = 'left',
          sortKey,
        } = columns[data.columnData.columnIndex];

        const isSorted = sort && (sortKey || dataKey) === sort.sortBy;

        const titleText = title !== undefined ? title : dataKey;

        return (
          <div
            css={`
              display: flex;
              justify-content: ${align === 'right'
                ? 'flex-end'
                : align === 'center'
                ? 'center'
                : 'flex-start'};
              text-align: ${align};
            `}
          >
            {sortable ? (
              <StyledTableSortLabel
                active={isSorted}
                direction={
                  (isSorted &&
                    sort.sortDirection &&
                    sort.sortDirection.toLowerCase()) ||
                  undefined
                }
              >
                {formatHeader ? formatHeader(titleText) : titleText}
              </StyledTableSortLabel>
            ) : formatHeader ? (
              formatHeader(titleText)
            ) : (
              titleText
            )}
          </div>
        );
      },
      loadMoreRows: ({ values, loadMore }) => ({ stopIndex }) => {
        const res = loadMoreRows_({ values, loadMore, stopIndex });

        return res;
      },
      onSortChanged: ({ onSortChanged, sort }) => newSort => {
        if (!onSortChanged) return;

        if (
          sort.sortBy === newSort.sortBy &&
          newSort.sortDirection === 'ASC' &&
          sort.sortDirection === 'DESC'
        ) {
          onSortChanged({ sortBy: undefined, sortDirection: undefined });
        } else {
          onSortChanged(newSort);
        }

        if (ref_) {
          ref_.scrollToPosition(0);
        }
      },
    };
  }),
);

export default enhacer(infiniteGrid);
