/* @flow */
import * as React from 'react';
import styled from 'styled-components';

export const Div = styled.div`
  ${props =>
    Object.keys(props)
      .filter(
        k =>
          k !== 'children' &&
          k !== 'theme' &&
          typeof props[k] !== 'function' &&
          typeof props[k] !== 'object',
      )
      .map(k => `${k}:${props[k]};`)
      .join('\n')};
`;

export const Div2x = styled.div`
  && {
    ${props =>
      Object.keys(props)
        .filter(
          k =>
            k !== 'children' &&
            k !== 'theme' &&
            typeof props[k] !== 'function' &&
            typeof props[k] !== 'object',
        )
        .map(k => `${k}:${props[k]};`)
        .join('\n')};
  }
`;

export const Material = styled.div`
  && {
    ${props =>
      Object.keys(props)
        .filter(k => k !== 'children' && k !== 'theme')
        .filter(k => k.startsWith('data-'))
        .map(k => `${k.substr(5)}:${props[k]};`)
        .join('\n')};
  }
`;

export const Material2x = styled.div`
  &&& {
    ${props =>
      Object.keys(props)
        .filter(k => k !== 'children' && k !== 'theme')
        .filter(k => k.startsWith('data-'))
        .map(k => `${k.substr(5)}:${props[k]};`)
        .join('\n')};
  }
`;

export const uniDiv = (comp: any) => Div.withComponent(comp);
export const uniDiv2x = (comp: any) => Div2x.withComponent(comp);
export const Span = uniDiv('span');
// more specialized version to work with material-ui as jss place it's
// style tags at the end
//
// !!! IT'S USELESS SINCE JSS STYLES NOW INSERTS BEFORE styled-components !!!
export const uniMaterial = <A>(comp: React.ComponentType<A>) =>
  Material.withComponent(comp);

// to increase specificity (sometimes needed)
export const uniMaterial2x = <A>(comp: React.ComponentType<A>) =>
  Material2x.withComponent(comp);
