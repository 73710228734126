import { wrapDisplayName } from 'recompose';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
import { CircularProgress, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { get } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export default (
  query,
  {
    key = 'data',
    renderLoading = false,
    renderError = true,
    emptyPath = null,
    emptyError = 'Nothing found',
    variables = {},
    cacheConfig = { cacheFirst: true },
  },
) => ComposedComponent =>
  class WithMutation extends Component {
    static displayName = wrapDisplayName(ComposedComponent, 'withQuery');

    static contextTypes = {
      api: PropTypes.object.isRequired,
    };

    render() {
      return (
        <QueryRenderer
          cacheConfig={cacheConfig}
          query={query}
          environment={this.context.api.environment}
          variables={
            typeof variables === 'function' ? variables(this.props) : variables
          }
          render={({ props, error }) => {
            const loading = !props && !error;

            if (loading && renderLoading) {
              return (
                <Wrapper>
                  <CircularProgress />
                </Wrapper>
              );
            }

            if (error && renderError) {
              return <Wrapper>{error}</Wrapper>;
            }

            if (
              emptyPath &&
              (!get(props, emptyPath) || !get(props, emptyPath).length)
            ) {
              return (
                <Wrapper>
                  <Typography color="textSecondary">{emptyError}</Typography>
                </Wrapper>
              );
            }

            const obj = {
              [key]: {
                loading,
                error,
                ...props,
              },
            };

            return <ComposedComponent {...this.props} {...obj} />;
          }}
        />
      );
    }
  };
