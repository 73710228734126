/**
 * @flow
 * @relayHash f3bf95843ba47ee14e497a96eab854ae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserAvatar_user$ref = any;
export type USER_ROLE_ENUM = "BROKER" | "INVESTOR" | "SELLER" | "SUPERVISOR" | "%future added value";
export type UserFilters = {|
  organisations?: ?$ReadOnlyArray<?string>,
  cities?: ?$ReadOnlyArray<?string>,
  jobTitles?: ?$ReadOnlyArray<?string>,
  languages?: ?$ReadOnlyArray<?string>,
  isActive?: ?boolean,
  isAdmin?: ?boolean,
  isDealBrokers?: ?boolean,
  dealBrokersCreatedUserId?: ?string,
  isPrimaryContactOfOrgSeller?: ?boolean,
  isActivityCreator?: ?boolean,
  activityDealId?: ?string,
  isSeller?: ?boolean,
  isDealSellerAndCollSeller?: ?boolean,
  roles?: ?$ReadOnlyArray<?USER_ROLE_ENUM>,
|};
export type userInputQueryVariables = {|
  search?: ?string,
  filters?: ?UserFilters,
  dealBrokerFilters?: ?UserFilters,
|};
export type userInputQueryResponse = {|
  +users: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
        +email: string,
        +jobTitle: ?string,
        +isBroker: ?boolean,
        +isAdmin: boolean,
        +organisationId: ?string,
        +mobilePhone: ?string,
        +officePhone: ?string,
        +$fragmentRefs: UserAvatar_user$ref,
      |}
    |}>
  |},
  +dealBrokers: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
        +email: string,
        +jobTitle: ?string,
        +isBroker: ?boolean,
        +isAdmin: boolean,
        +organisationId: ?string,
        +mobilePhone: ?string,
        +officePhone: ?string,
        +$fragmentRefs: UserAvatar_user$ref,
      |}
    |}>
  |},
|};
export type userInputQuery = {|
  variables: userInputQueryVariables,
  response: userInputQueryResponse,
|};
*/


/*
query userInputQuery(
  $search: String
  $filters: UserFilters
  $dealBrokerFilters: UserFilters
) {
  users(search: $search, filters: $filters, first: 100, asRealUser: true) {
    edges {
      node {
        id
        fullName
        email
        jobTitle
        isBroker
        isAdmin
        organisationId
        mobilePhone
        officePhone
        ...UserAvatar_user
      }
    }
  }
  dealBrokers: users(search: $search, filters: $dealBrokerFilters, first: 100, asRealUser: false) {
    edges {
      node {
        id
        fullName
        email
        jobTitle
        isBroker
        isAdmin
        organisationId
        mobilePhone
        officePhone
        ...UserAvatar_user
      }
    }
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "UserFilters",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dealBrokerFilters",
    "type": "UserFilters",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = [
  {
    "kind": "Literal",
    "name": "asRealUser",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobTitle",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isBroker",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdmin",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "organisationId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mobilePhone",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "officePhone",
  "args": null,
  "storageKey": null
},
v13 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "UserEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "UserAvatar_user",
            "args": null
          }
        ]
      }
    ]
  }
],
v14 = [
  {
    "kind": "Literal",
    "name": "asRealUser",
    "value": false
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "dealBrokerFilters"
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v15 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "UserEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "primaryImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Image",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userInputQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": (v13/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "dealBrokers",
        "name": "users",
        "storageKey": null,
        "args": (v14/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": (v13/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userInputQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": (v15/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "dealBrokers",
        "name": "users",
        "storageKey": null,
        "args": (v14/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": (v15/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userInputQuery",
    "id": null,
    "text": "query userInputQuery(\n  $search: String\n  $filters: UserFilters\n  $dealBrokerFilters: UserFilters\n) {\n  users(search: $search, filters: $filters, first: 100, asRealUser: true) {\n    edges {\n      node {\n        id\n        fullName\n        email\n        jobTitle\n        isBroker\n        isAdmin\n        organisationId\n        mobilePhone\n        officePhone\n        ...UserAvatar_user\n      }\n    }\n  }\n  dealBrokers: users(search: $search, filters: $dealBrokerFilters, first: 100, asRealUser: false) {\n    edges {\n      node {\n        id\n        fullName\n        email\n        jobTitle\n        isBroker\n        isAdmin\n        organisationId\n        mobilePhone\n        officePhone\n        ...UserAvatar_user\n      }\n    }\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ebf90c08eb392b8f69bdce1990178a11';
module.exports = node;
