import idx from 'idx';

export const isDisabledSaveOrganisation = (isNewOrganisation, currentUser, organisation) => {
  // for frontend user, do something later
  if (!currentUser) {
    return true;
  }
  if (idx(currentUser, _ => _.isSuperAdmin)) {
    return false;
  }
  if (isNewOrganisation) {
    // for new item
    if (idx(currentUser, _ => _.isBroker)) {
      return false;
    }
    return true;
  }
  // for edit item
  const currentUserId = idx(currentUser, _ => _.id);
  if (currentUserId === idx(organisation, _ => _.owner.id)) {
    return false;
  }
  const userOrganisation = idx(currentUser, _ => _.organisation);
  const organisationId = idx(organisation, _ => _.id);
  const userOrganisationId = idx(userOrganisation, _ => _.id);
  // is seller admin or investor admin can edit, too
  if ((idx(currentUser, _ => _.isInvestor) || idx(currentUser, _ => _.isSeller)) && idx(currentUser, _ => _.isAdmin) 
    && userOrganisation.id === organisationId) {
    return false;
  }
  
  // super admin create for broker user
  if (userOrganisation && userOrganisationId === organisationId
    && idx(currentUser, _ => _.isBroker) && idx(currentUser, _ => _.isAdmin) ) {
    return false;
  }
  return true;
}

export const isDisabledIsSellerOrganisation = (isNewOrganisation, currentUser, organisation) => {
  if (!currentUser) {
    return true;
  }
  if (idx(currentUser, _ => _.isSuperAdmin)) {
    return false;
  }
  if (isNewOrganisation) {
    // for new item
    if (idx(currentUser, _ => _.isBroker)) {
      return false;
    }
    return true;
  }
  
  // for edit item
  if (idx(currentUser, _ => _.id) === idx(organisation, _ => _.owner.id)) {
    return false;
  }
  const userOrganisation = idx(currentUser, _ => _.organisation);
  const organisationId = idx(organisation, _ => _.id);
  const userOrganisationId = idx(userOrganisation, _ => _.id);
  if (userOrganisation && (userOrganisationId === organisationId || userOrganisationId === idx(organisation, _ => _.owner.organisationId)) 
    && idx(currentUser, _ => _.isBroker) && idx(currentUser, _ => _.isAdmin) ) {
    return false;
  }
  return true;
}

export const isDisabledDeleteOrganisation = (isNewOrganisation, currentUser, organisation) => {
  // for frontend user, do something later
  if (!currentUser) {
    return true;
  }
  if (isNewOrganisation) {
    return true;
  }
  // for edit item
  if (idx(organisation, _ => _.deals.totalCount) > 0 || (idx(organisation, _ => _.users) || []).length > 0) {
    return true;
  }
  if (idx(currentUser, _ => _.isSuperAdmin)) {
    return false;
  }
  if (idx(currentUser, _ => _.isAdmin) && idx(currentUser, _ => _.id) === idx(organisation, _ => _.owner.id)) {
    return false;
  }
  
  return true;
}