/**
 * @flow
 * @relayHash beaa3e9054104823720c524d90053b8a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteUserInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeleteProfileDialogDeleteUserMutationVariables = {|
  input: DeleteUserInput
|};
export type DeleteProfileDialogDeleteUserMutationResponse = {|
  +deleteUser: ?{|
    +deletedUserId: string
  |}
|};
export type DeleteProfileDialogDeleteUserMutation = {|
  variables: DeleteProfileDialogDeleteUserMutationVariables,
  response: DeleteProfileDialogDeleteUserMutationResponse,
|};
*/


/*
mutation DeleteProfileDialogDeleteUserMutation(
  $input: DeleteUserInput!
) {
  deleteUser(input: $input) {
    deletedUserId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedUserId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteProfileDialogDeleteUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteProfileDialogDeleteUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteProfileDialogDeleteUserMutation",
    "id": null,
    "text": "mutation DeleteProfileDialogDeleteUserMutation(\n  $input: DeleteUserInput!\n) {\n  deleteUser(input: $input) {\n    deletedUserId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5f4572eb3acc4e9728d235b3bba8f51';
module.exports = node;
