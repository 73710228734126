/**
 * @flow
 * @relayHash 671699f70f5227d529005cfbae620403
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeleteProfileDialog_user$ref = any;
type OrganisationInvestorProfileForm_organisation$ref = any;
type OrganisationInvestorProfileForm_regions$ref = any;
type OrganisationInvestorProfileForm_useTypes$ref = any;
type UserAvatar_user$ref = any;
type UserProfileForm_user$ref = any;
export type profileQueryVariables = {||};
export type profileQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +organisation: ?{|
      +name: ?string,
      +users: $ReadOnlyArray<{|
        +id: string,
        +fullName: ?string,
        +jobTitle: ?string,
        +$fragmentRefs: UserAvatar_user$ref,
      |}>,
      +$fragmentRefs: OrganisationInvestorProfileForm_organisation$ref,
    |},
    +$fragmentRefs: UserProfileForm_user$ref & DeleteProfileDialog_user$ref,
  |},
  +useTypes: ?$ReadOnlyArray<?{|
    +$fragmentRefs: OrganisationInvestorProfileForm_useTypes$ref
  |}>,
  +regions: ?$ReadOnlyArray<?{|
    +$fragmentRefs: OrganisationInvestorProfileForm_regions$ref
  |}>,
|};
export type profileQuery = {|
  variables: profileQueryVariables,
  response: profileQueryResponse,
|};
*/


/*
query profileQuery {
  viewer {
    id
    ...UserProfileForm_user
    ...DeleteProfileDialog_user
    organisation {
      name
      users {
        id
        fullName
        jobTitle
        ...UserAvatar_user
      }
      ...OrganisationInvestorProfileForm_organisation
      id
    }
  }
  useTypes {
    ...OrganisationInvestorProfileForm_useTypes
    id
  }
  regions {
    ...OrganisationInvestorProfileForm_regions
    id
  }
}

fragment UserProfileForm_user on User {
  id
  firstName
  lastName
  fullName
  isSubscribed
}

fragment DeleteProfileDialog_user on User {
  id
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}

fragment OrganisationInvestorProfileForm_organisation on Organisation {
  id
  minAssetSize
  maxAssetSize
  assetsUnderManagement
  lexKollerCompliant
  useTypeIds
  regionIds
  purchaseTypeIds
  core
  corePlus
  valueAdd
  opportunistic
  onlyIndirect
  owner {
    id
    fullName
    isBroker
    organisationId
  }
}

fragment OrganisationInvestorProfileForm_useTypes on UseType {
  id
  label
}

fragment OrganisationInvestorProfileForm_regions on Region {
  id
  label
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobTitle",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "label",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "profileQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organisation",
            "storageKey": null,
            "args": null,
            "concreteType": "Organisation",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "users",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "UserAvatar_user",
                    "args": null
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "OrganisationInvestorProfileForm_organisation",
                "args": null
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "UserProfileForm_user",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "DeleteProfileDialog_user",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "useTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "UseType",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrganisationInvestorProfileForm_useTypes",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "regions",
        "storageKey": null,
        "args": null,
        "concreteType": "Region",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrganisationInvestorProfileForm_regions",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "profileQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isSubscribed",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organisation",
            "storageKey": null,
            "args": null,
            "concreteType": "Organisation",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "users",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "primaryImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": null,
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ]
                  }
                ]
              },
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "minAssetSize",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "maxAssetSize",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "assetsUnderManagement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lexKollerCompliant",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "useTypeIds",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "regionIds",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "purchaseTypeIds",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "core",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "corePlus",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "valueAdd",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "opportunistic",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "onlyIndirect",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "owner",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isBroker",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "organisationId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "useTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "UseType",
        "plural": true,
        "selections": (v6/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "regions",
        "storageKey": null,
        "args": null,
        "concreteType": "Region",
        "plural": true,
        "selections": (v6/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "profileQuery",
    "id": null,
    "text": "query profileQuery {\n  viewer {\n    id\n    ...UserProfileForm_user\n    ...DeleteProfileDialog_user\n    organisation {\n      name\n      users {\n        id\n        fullName\n        jobTitle\n        ...UserAvatar_user\n      }\n      ...OrganisationInvestorProfileForm_organisation\n      id\n    }\n  }\n  useTypes {\n    ...OrganisationInvestorProfileForm_useTypes\n    id\n  }\n  regions {\n    ...OrganisationInvestorProfileForm_regions\n    id\n  }\n}\n\nfragment UserProfileForm_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  isSubscribed\n}\n\nfragment DeleteProfileDialog_user on User {\n  id\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n\nfragment OrganisationInvestorProfileForm_organisation on Organisation {\n  id\n  minAssetSize\n  maxAssetSize\n  assetsUnderManagement\n  lexKollerCompliant\n  useTypeIds\n  regionIds\n  purchaseTypeIds\n  core\n  corePlus\n  valueAdd\n  opportunistic\n  onlyIndirect\n  owner {\n    id\n    fullName\n    isBroker\n    organisationId\n  }\n}\n\nfragment OrganisationInvestorProfileForm_useTypes on UseType {\n  id\n  label\n}\n\nfragment OrganisationInvestorProfileForm_regions on Region {\n  id\n  label\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57ec320e53b1edf5808b70bb88041a51';
module.exports = node;
