// @flow
import * as React from 'react';
import { route } from 'navi';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Fade,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { graphql, useQuery } from 'relay-hooks';
import { useTranslation } from 'react-i18next';
import useTimeout from 'use-timeout';

import { DealsList } from '../deals-list';
import { RequestAccessForm } from '../request-access-form';
import { cloudinaryUrl } from '../../utils/cloudinaryUrl';
import { LoginForm } from '../login-form';
import { ContactDetails } from '../components/contact-details';
import { useScrollTo } from '../../utils';

const bgUrl = '/images/zurich_optimized1920.jpg';
const screenWidth =
  Math.ceil(window.screen.availWidth * window.devicePixelRatio * 1.5) || 1000;

const RequestAccessHero = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [scrollToForm, scrollToFormRef] = useScrollTo({ offset: 200 });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [formState, setFormState] = React.useState<'login' | 'signin'>('login');

  return (
    <>
      <div
        css={`
          background-image: linear-gradient(
              120deg,
              rgba(0, 0, 0, 0.58) 0%,
              rgba(196, 196, 196, 0) 100%
            ),
            url(${cloudinaryUrl(
              { w: screenWidth, fl_progressive: true },
              bgUrl,
            )});
          background-size: cover;
          background-position: center center;
          min-height: 70vh;
          display: flex;
        `}
      >
        <Container maxWidth="lg">
          <Box mt={{ xs: 6, sm: 8, md: 16 }} mb={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} md={6}>
                <div ref={scrollToFormRef}>
                  {formState === 'login' && (
                    <LoginForm
                      onSignin={() => {
                        setFormState('signin');
                        scrollToForm();
                      }}
                    />
                  )}
                  {formState === 'signin' && (
                    <RequestAccessForm
                      visible
                      onReset={() => {
                        setFormState('login');
                        scrollToForm();
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Container maxWidth="lg">
        <Box my={6}>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6}>
              <Box textAlign={{ xs: 'center', sm: 'initial' }}>
                <Typography variant="h6" paragraph={true}>
                  {t('aboutRealadvisor')}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  css={`
                    white-space: pre-line;
                  `}
                >
                  {t('aboutRealadvisorParagaph1')}
                </Typography>
                <Typography
                  variant="body1"
                  css={`
                    white-space: pre-line;
                  `}
                >
                  {t('aboutRealadvisorParagaph2')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

const PageSpinner = () => {
  const [tooLong, setTooLong] = React.useState(false);

  useTimeout(() => setTooLong(true), 1500);

  return (
    <Fade in={tooLong}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
        <Box mt={2}>
          <Typography variant="caption">
            Loading took more time than expected. Please wait...
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

const PageHome = () => {
  const { props, error } = useQuery({
    query: graphql`
      query homeQuery {
        viewer {
          id
        }
      }
    `,
  });

  if (props) {
    if (props?.viewer) {
      return <DealsList />;
    }
    return <RequestAccessHero />;
  }

  return (
    <Box
      flexGrow={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {error ?? <PageSpinner />}
    </Box>
  );
};

export default route({
  title: 'RealAdvisor Deals platform',
  view: <PageHome />,
});
