// @flow

import { createActions, handleActions, combineActions } from 'redux-actions';

export type ConfirmOptions = {
  message?: string,
  okText?: string,
  cancelText?: string,
  onOk?: () => void,
  onCancel?: () => void,
};

const defaultState: { confirm: ConfirmOptions } = {
  confirm: {
    open: false,
    message: 'Are you sure?',
    okText: 'Confirm',
    cancelText: 'Cancel',
    onOk: () => {},
    onCancel: () => {},
  },
};

export const dialogsActions = createActions({
  CONFIRM: {
    SHOW: options => ({ ...defaultState.confirm, ...options, open: true }),
    HIDE: () => ({ open: false }),
  },
});

const reducer = handleActions(
  {
    [combineActions(dialogsActions.confirm.show, dialogsActions.confirm.hide)](
      state,
      { payload },
    ) {
      return {
        ...state,
        confirm: { ...state.confirm, ...payload },
      };
    },
  },
  defaultState,
);

export default reducer;
