/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserAvatar_organisation$ref = any;
type UserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type GlobalSearchFrags_root$ref: FragmentReference;
declare export opaque type GlobalSearchFrags_root$fragmentType: GlobalSearchFrags_root$ref;
export type GlobalSearchFrags_root = {|
  +globalSearch: ?{|
    +users: ?{|
      +totalCount: ?number,
      +results: ?$ReadOnlyArray<?{|
        +id: string,
        +email: string,
        +firstName: ?string,
        +lastName: ?string,
        +primaryImage: ?{|
          +url: string
        |},
        +$fragmentRefs: UserAvatar_user$ref,
      |}>,
    |},
    +organisations: ?{|
      +totalCount: ?number,
      +results: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +street: ?string,
        +city: ?string,
        +primaryImage: ?{|
          +url: string
        |},
        +$fragmentRefs: UserAvatar_organisation$ref,
      |}>,
    |},
    +activities: ?{|
      +totalCount: ?number,
      +results: ?$ReadOnlyArray<?{|
        +id: string,
        +note: ?string,
        +subject: ?string,
        +parent: ?{|
          +__typename: string,
          +id: string,
          +deal?: ?{|
            +id: string,
            +name: ?string,
          |},
          +organisation?: ?{|
            +id: string,
            +name: ?string,
            +$fragmentRefs: UserAvatar_organisation$ref,
          |},
          +name?: ?string,
          +image?: ?{|
            +url: string
          |},
          +fullName?: ?string,
          +$fragmentRefs: UserAvatar_user$ref & UserAvatar_organisation$ref,
        |},
        +user: ?{|
          +firstName: ?string,
          +lastName: ?string,
        |},
        +creator: ?{|
          +firstName: ?string,
          +lastName: ?string,
        |},
        +deal: ?{|
          +id: string,
          +name: ?string,
          +title: ?string,
          +titleEn: ?string,
          +titleDe: ?string,
          +titleFr: ?string,
        |},
        +organisation: ?{|
          +name: ?string
        |},
      |}>,
    |},
    +deals: ?{|
      +totalCount: ?number,
      +results: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +titleEn: ?string,
        +titleDe: ?string,
        +titleFr: ?string,
        +image: ?{|
          +url: string
        |},
      |}>,
    |},
  |},
  +$refType: GlobalSearchFrags_root$ref,
|};
export type GlobalSearchFrags_root$data = GlobalSearchFrags_root;
export type GlobalSearchFrags_root$key = {
  +$data?: GlobalSearchFrags_root$data,
  +$fragmentRefs: GlobalSearchFrags_root$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "primaryImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": (v4/*: any*/)
},
v6 = {
  "kind": "FragmentSpread",
  "name": "UserAvatar_user",
  "args": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "FragmentSpread",
  "name": "UserAvatar_organisation",
  "args": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "image",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": (v4/*: any*/)
},
v10 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "alias": "titleEn",
  "name": "title",
  "args": [
    {
      "kind": "Literal",
      "name": "lang",
      "value": "en"
    }
  ],
  "storageKey": "title(lang:\"en\")"
},
v12 = {
  "kind": "ScalarField",
  "alias": "titleDe",
  "name": "title",
  "args": [
    {
      "kind": "Literal",
      "name": "lang",
      "value": "de"
    }
  ],
  "storageKey": "title(lang:\"de\")"
},
v13 = {
  "kind": "ScalarField",
  "alias": "titleFr",
  "name": "title",
  "args": [
    {
      "kind": "Literal",
      "name": "lang",
      "value": "fr"
    }
  ],
  "storageKey": "title(lang:\"fr\")"
};
return {
  "kind": "Fragment",
  "name": "GlobalSearchFrags_root",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "search",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "limit",
      "type": "Int"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "globalSearch",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "limit"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "GlobalSearch",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "users",
          "storageKey": null,
          "args": null,
          "concreteType": "GlobalSearchUser",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "results",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organisations",
          "storageKey": null,
          "args": null,
          "concreteType": "GlobalSearchOrganisation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "results",
              "storageKey": null,
              "args": null,
              "concreteType": "Organisation",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v7/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "street",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "city",
                  "args": null,
                  "storageKey": null
                },
                (v5/*: any*/),
                (v8/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "activities",
          "storageKey": null,
          "args": null,
          "concreteType": "GlobalSearchActivity",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "results",
              "storageKey": null,
              "args": null,
              "concreteType": "Activity",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "note",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "subject",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "parent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": null,
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "__typename",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "type": "Lead",
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "deal",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Deal",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v7/*: any*/)
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "organisation",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Organisation",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/)
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "Deal",
                      "selections": [
                        (v7/*: any*/),
                        (v9/*: any*/)
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "User",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "fullName",
                          "args": null,
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "Organisation",
                      "selections": [
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": (v10/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "creator",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": (v10/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "deal",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Deal",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v7/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "title",
                      "args": null,
                      "storageKey": null
                    },
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "organisation",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Organisation",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "deals",
          "storageKey": null,
          "args": null,
          "concreteType": "GlobalSearchDeal",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "results",
              "storageKey": null,
              "args": null,
              "concreteType": "Deal",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v7/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v9/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b7d910ab02ca4072ecf0277a7fd5690';
module.exports = node;
