import React, { useState } from 'react';
import FlexBox from 'components/FlexBox';
import FileDropZone from 'components/FileDropZone';
import { StyledButton, StyledCancelButton } from '../Style';

import UploadedFileBox from '../../UploadedFileBox';
import { useTranslation } from 'react-i18next';

const UploadDocumentForm = ({
  // description,
  files = [],
  hideModal,
  onSubmit,
  multiple = true,
}) => {
  const [selectedFiles, setSelectedFile] = useState([]);
  const { t } = useTranslation();

  // useEffect(() => {
  //   setSelectedFile(files);
  // }, files);
  const onCancel = () => {
    hideModal();
  };

  const onDeleteFile = id => {
    const _selectedFiles = selectedFiles.filter(v => v.id !== id);
    setSelectedFile(_selectedFiles);
  };

  const onDrop = file => {
    const newFile = file.map(v => ({
      type: { type: 'NEW' },
      file: v,
      filename: v.name,
      id: v.name,
    }));
    setSelectedFile(multiple ? [...selectedFiles, ...newFile] : newFile);
  };

  const onSubmitForm = () => {
    onSubmit(selectedFiles);
  };

  return (
    <FlexBox flexDirection="column" gap={24}>
      {/*<Description>{description}</Description>*/}
      <FlexBox gap={12} flexDirection="column" alignItems="center">
        <FileDropZone multiple={multiple} onDrop={onDrop} />
        {selectedFiles &&
          selectedFiles.length > 0 &&
          selectedFiles.map(({ filename, id }, index) => (
            <UploadedFileBox
              name={filename}
              key={index}
              onDelete={() => {
                onDeleteFile(id);
              }}
            />
          ))}
      </FlexBox>
      <FlexBox justifyContent="flex-end">
        <StyledCancelButton variant="text" onClick={onCancel}>
          <span className="text">{t('button.cancel')}</span>
        </StyledCancelButton>
        <StyledButton
          variant="contained"
          onClick={onSubmitForm}
          disabled={selectedFiles.length == 0}
        >
          {t('button.submit')}
        </StyledButton>
      </FlexBox>
    </FlexBox>
  );
};

export default UploadDocumentForm;
