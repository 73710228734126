/* @flow */
/* eslint-disable no-continue */
import * as React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import type { HOC } from 'recompose';

// It's better to not make bold, but change color
// to not break layout on highliting
export const BoldSpan = styled.span`
  color: #000;
  font-weight: 600;
`;

const highlighter = ({ chunks, className }) => (
  <span className={className}>
    {chunks.map((chunk, index) =>
      index % 2 === 0 ? (
        <span key={index}>{chunk}</span>
      ) : (
        <BoldSpan key={index}>{chunk}</BoldSpan>
      ),
    )}
  </span>
);

type Props = {
  text: ?string,
  search: string,
  className?: string,
  partial?: boolean,
};

export const normalizeSearchString = (search: string) => {
  // replace spaces with [\s\S]+ to allow multiline search
  let newSearch = search;
  // replace "bad" symbols

  newSearch = newSearch.replace(
    // eslint-disable-next-line no-useless-escape
    /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
    '\\$&',
  );

  const diacritics = [
    'aàáảãạăằắẳẵặâầấẩẫậäåāąAÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬÄÅĀĄ',
    'cçćčCÇĆČ',
    'dđďDĐĎ',
    'eèéẻẽẹêềếểễệëěēęEÈÉẺẼẸÊỀẾỂỄỆËĚĒĘ',
    'iìíỉĩịîïīIÌÍỈĨỊÎÏĪ',
    'lłLŁ',
    'nñňńNÑŇŃ',
    'oòóỏõọôồốổỗộơởỡớờợöøōOÒÓỎÕỌÔỒỐỔỖỘƠỞỠỚỜỢÖØŌ',
    'rřRŘ',
    'sšśșşSŠŚȘŞ',
    'tťțţTŤȚŢ',
    'uùúủũụưừứửữựûüůūUÙÚỦŨỤƯỪỨỬỮỰÛÜŮŪ',
    'yýỳỷỹỵÿYÝỲỶỸỴŸ',
    'zžżźZŽŻŹ',
  ];

  // make "diacritics" search
  newSearch = newSearch
    .split('')
    .map(c => {
      const dLine = diacritics.find(dl => dl.indexOf(c) > -1);
      if (!dLine) return c;
      return `[${dLine}]`;
    })
    .join('');

  // replace multiple spaces
  newSearch = newSearch.replace(/\s+/gi, ' ');

  return newSearch;
};

const enhanceHighlighter: HOC<*, Props> = compose(
  withPropsOnChange(
    ['text', 'search', 'partial'],
    ({ search, text, partial }) => {
      if (!text) {
        return { chunks: [''] };
      }

      const newSearch = normalizeSearchString(search);

      // move every word into match
      const regExps = newSearch
        .split(' ')
        .map(txtRe => new RegExp(txtRe, 'gmi'));

      let lastIndex = 0;
      const highlightIdx = [];

      for (let i = 0; i !== regExps.length; ++i) {
        const currRe = regExps[i];
        currRe.lastIndex = lastIndex;
        // lastIndex
        const match = currRe.exec(text);

        if (!match) {
          if (!partial) {
            return { chunks: [text] };
          }
          continue;
        }

        lastIndex = match.index + match[0].length;
        highlightIdx.push([match.index, match.index + match[0].length]);
      }

      const chunks: Array<string> = [];
      lastIndex = 0;
      for (let i = 0; i !== highlightIdx.length; ++i) {
        chunks.push(text.substring(lastIndex, highlightIdx[i][0]));
        chunks.push(text.substring(highlightIdx[i][0], highlightIdx[i][1]));
        lastIndex = highlightIdx[i][1];
      }
      chunks.push(text.substr(lastIndex));

      return { chunks };
    },
  ),
);

export const Highlighter = enhanceHighlighter(highlighter);
