// @flow

import * as React from 'react';
import {
  type Handle,
  requestAnimationTimeout,
  cancelAnimationTimeout,
} from '../utils/animation-timeout.js';

export const useDebouncedHandler = <T: (...$ReadOnlyArray<empty>) => void>(
  time: number,
  handler: T,
): T => {
  const timeoutHandle = React.useRef<Handle | null>(null);
  const handlerRef = React.useRef(handler);

  React.useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  React.useEffect(() => {
    return () => {
      cancelAnimationTimeout(timeoutHandle.current);
    };
  }, []);

  const [runDebouncedHandler] = React.useState(() => (...args) => {
    cancelAnimationTimeout(timeoutHandle.current);
    timeoutHandle.current = requestAnimationTimeout(time, () => {
      handlerRef.current(...args);
    });
  });

  return (runDebouncedHandler: any);
};

export const DebouncedHandler = <T: (...$ReadOnlyArray<empty>) => void>({
  time,
  handler,
  children,
}: {|
  time: number,
  handler: T,
  children: T => React.Node,
|}): React.Node => {
  const newHandler = useDebouncedHandler(time, handler);
  return children(newHandler);
};
