import React from 'react';
import PropTypes from 'prop-types';

export default class TitleProvider extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    component: PropTypes.element,
  };

  static contextTypes = {
    title: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    title: PropTypes.object,
  };

  getChildContext() {
    return {
      title: {
        ...this.context.title,
        currentIndex: this.context.title.currentIndex + 1,
      },
    };
  }

  titleObj = null;

  constructor(props, context) {
    super(props);
    this.updateTitle(props, context);
  }

  componentWillReceiveProps(nextProps, context) {
    if (this.props !== nextProps) this.updateTitle(nextProps, context);
  }

  componentWillUnmount() {
    this.context.title.removeTitle(this.titleObj);
  }

  updateTitle = (props, context) => {
    if (process.env.NODE_ENV !== 'production') {
      return null;
    }

    const text = props.text || props.component;
    const component = props.component || props.text;
    if (!this.titleObj) {
      this.titleObj = {
        text,
        component,
        index: context.title.currentIndex + 1,
      };
      context.title.addTitle(this.titleObj);
    } else if (
      this.titleObj.text !== text ||
      this.titleObj.component !== component
    ) {
      this.titleObj.component = component;
      this.titleObj.text = text;
      context.title.updateTitle(this.titleObj);
    }
  };

  render() {
    return this.props.children || null;
  }
}
