// @flow

import React, { Fragment } from 'react';
import { isObject } from 'lodash';
import { graphql } from 'react-relay';
import {
  branch,
  compose,
  renderNothing,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import styled from 'styled-components';
import {
  Divider,
  IconButton,
  Checkbox,
  Avatar,
  Typography,
  MenuItem,
  SvgIcon,
  Button,
  Chip,
  Paper,
  ListItemAvatar,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import {
  Note as NoteIcon,
  Email as EmailIcon,
  Visibility as VisibilityIcon,
  Star as StarIcon,
  Share as ShareIcon,
  Assignment as AssignmentIcon,
  OpenInNew as OpenInNewIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import Icon from '@mdi/react';
import { mdiStarOff } from '@mdi/js';
import { Link } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import idx from 'idx';
import { withTranslation as translate } from 'react-i18next';

import { createFragmentContainerHOC } from '../../../../utils/relay';
import TimeAgo from '../../../../components/TimeAgo/TimeAgo';
import ButtonMenu from '../../../../components/ButtonMenu';
import { formatDate } from '../../../../utils';
import { withConfirmDialog } from '../../../../components/Dialogs';
import ActivityForm from '../ActivityForm';
import TextLink from '../../controls/TextLink';
import getFileIcon from '../../utils/getFileIcon';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const UserLink = ({ user }) => (
  <TextLink to={`/individuals/${user && user.id}`}>
    <strong>{user && user.fullName}</strong>
  </TextLink>
);

const OrganisationLink = ({ organisation }) => (
  <TextLink to={`/organisation/${organisation.id}`}>
    <strong>{organisation.name}</strong>
  </TextLink>
);

const ChunkEmail = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  height: 150px;
`;

const EmailBody = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 8px;
  flex: 1;
  &::after {
    z-index: 1;
    content: '';
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 10px;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    position: absolute;
  }
`;

const VerticalDivider = styled.div`
  align-self: stretch;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  margin: 8px 24px;
`;

const FileLabel = styled(Paper).attrs({ elevation: 1 })`
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const types = {
  NOTE: {
    icon: <NoteIcon />,
    title: ({ creator }, t) => (
      <span>
        <UserLink user={creator} /> <span>{t('wroteNote')}</span>
      </span>
    ),
  },
  DEAL_INTEREST: {
    icon: ({ leadData }) =>
      !leadData || leadData.isInterested ? (
        <StarIcon />
      ) : (
        <Icon path={mdiStarOff} size={24} />
      ),
    title: ({ creator, leadData }, t) => (
      <span>
        <UserLink user={creator} />{' '}
        {!leadData || leadData.isInterested
          ? t('interestedDeal')
          : t('notInterestedDeal')}
      </span>
    ),
    body: ({ leadData, subject }) =>
      leadData &&
      (leadData.refusalReasonText || leadData.refusalReasons.length) ? ( // return null to hide empty body wrapper
        <>
          <div style={{ marginBottom: '1rem' }}>
            {leadData.refusalReasons.map(({ id, label }) => (
              <Chip style={{ marginRight: 8 }} key={id} label={label} />
            ))}
          </div>
          {leadData.refusalReasonText}
        </>
      ) : (
        subject
      ),
  },
  DEAL_VIEW: {
    icon: <VisibilityIcon />,
    title: ({ creator }, t) => (
      <span>
        <UserLink user={creator} /> {t('viewedDeal')}
      </span>
    ),
  },
  DOCUMENT_VIEW: {
    icon: <VisibilityIcon />,
    title: ({ creator }, t) => (
      <>
        <UserLink user={creator} /> {t('viewedDocument')}
      </>
    ),
    body: ({ file }) =>
      !!file && (
        <a href={file.url} target="_blank">
          <FileLabel>
            <SvgIcon>{getFileIcon(file.mimetype)}</SvgIcon> {file.filename}
          </FileLabel>
        </a>
      ),
  },
  DEAL_INVITED: {
    icon: <ShareIcon />,
    body: ({ organisation }, t) => (
      <span>
        <OrganisationLink organisation={organisation} /> {t('invitedToDeal')}
      </span>
    ),
  },
  EMAIL: {
    icon: <EmailIcon />,
    title: ({ creator }) => <UserLink user={creator} />,
    body: ({ user, email, subject, deal }, t) => (
      <span>
        {t('emailSentTo')} <UserLink user={user} />
        {!!subject && (
          <Fragment>
            <Divider style={{ marginTop: 16 }} />
            <ChunkEmail>
              <EmailBody>{subject}</EmailBody>
              {!!email && (
                <Fragment>
                  <VerticalDivider />
                  <Button
                    component={Link}
                    to={`/deals/${deal.id}/emails/${email.id}`}
                    color="primary"
                  >
                    {t('showEmail')}
                  </Button>
                </Fragment>
              )}
            </ChunkEmail>
          </Fragment>
        )}
      </span>
    ),
  },
  TASK: {
    icon: <AssignmentIcon />,
    body: ({ subject }) => <span>{subject}</span>,
    title: ({ creator, assignee }, t, viewer) => (
      <span>
        <UserLink user={creator} /> {` `}
        {!!assignee && (
          <Fragment>
            {t('assignedNewTask')} {` `}
            {assignee.id !== viewer.id ? (
              <UserLink user={assignee} />
            ) : (
              t('you')
            )}
          </Fragment>
        )}
      </span>
    ),
  },
  LONG_LIST_EDIT: {
    icon: <SettingsIcon />,
    title: ({ creator }, t) => (
      <span>
        <UserLink user={creator} /> {` `}
        {t('editedLongList')}
      </span>
    ),
  },
  CALL: {
    icon: <SettingsIcon />,
    title: ({ creator }, t) => <span>CALL CONTENT</span>,
  },
  VISIT: {
    icon: <SettingsIcon />,
    title: ({ creator }, t) => <span>VISIT CONTENT</span>,
  },
};

const Root = styled(Card)`
  margin-bottom: ${({ theme, elevation }) =>
    theme.spacing(1) * (elevation <= 1 ? 2 : 4)}px;
  transition: ${({ theme }) =>
    theme.transitions.create(['box-shadow', 'position', 'margin-bottom'])};
  position: ${({ elevation }) => (elevation <= 1 ? 'static' : 'relative')};
`;

const UListItem = styled(ListItem)`
  padding-bottom: 6px;
  padding-top: 6px;
`;

const UCheckbox = styled(Checkbox)`
  ${props => props.checked && `color: ${green[500]}`};
`;

const CardActionWide = styled(CardContent)`
  padding-top: 0;
  padding-bottom: 0;
  &:last-child {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 8px;
  }
`;

const ChunkTask = ({ activity, handleDoneClick, t }) => (
  <CardActionWide>
    <Divider />
    <List>
      <UListItem button onClick={handleDoneClick} style={{ paddingLeft: 16 }}>
        <ListItemAvatar>
          <UCheckbox disableRipple checked={activity.isDone} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={activity.isDone ? t('taskDone') : t('taskNotDone')}
          secondary={
            <Typography color="textSecondary" component="div">
              {activity.isDone ? (
                <TimeAgo>{activity.doneAt}</TimeAgo>
              ) : (
                `Due at ${formatDate(activity.dueAt)}`
              )}
            </Typography>
          }
        />
      </UListItem>
    </List>
  </CardActionWide>
);

export const getParentData = (parent: any) => {
  if (!parent || !isObject(parent)) {
    return {};
  }
  switch (parent.__typename) {
    case 'User':
      return {
        image: '',
        primary: parent.fullName,
        secondary: 'Individual',
        link: `/individuals/${parent.id}`,
      };
    case 'Deal':
      return {
        image: <Avatar src={idx(parent, _ => _.image.url)} />,
        primary: parent.name,
        secondary: 'Deal',
        link: `/deals/${parent.id}`,
      };
    case 'Organisation':
      return {
        image: '',
        primary: parent.name,
        secondary: 'Organisation',
        link: `/organisations/${parent.id}`,
      };
    case 'Lead':
      return {
        image: '',
        primary: idx(parent, _ => _.organisation.name),
        secondary: 'Lead',
        link: `/deals/${parent.deal.id}/longlist/${parent.id}`,
      };
    default:
      return {};
  }
};

const ChunkParent = ({ activity }) => {
  const { primary, secondary, image, link } = getParentData(activity.parent);
  return (
    <CardActionWide>
      <Divider />
      <List>
        <ListItem
          component={Link}
          to={`${link}#activity-${activity.id}`}
          button
        >
          {image}
          <ListItemText primary={primary} secondary={secondary} />
          <ListItemSecondaryAction>
            <IconButton disabled>
              <OpenInNewIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </CardActionWide>
  );
};

const Body = styled(CardContent)`
  word-break: break-word;
  white-space: pre-line;
`;

const EDITABLE_TYPES = ['TASK', 'NOTE'];

const Activity = ({
  activity,
  handleDeleteClick,
  handleEditClick,
  handleDoneClick,
  hasParentLink,
  root: { viewer },
  root,
  isEditing,
  handleEditCancel,
  t,
  disabledDelete,
  disabledEdit,
}) => {
  const body = types[activity.type]?.body
    ? types[activity.type].body(activity, t)
    : activity.note || activity.subject;
  const avatar =
    typeof types[activity.type]?.icon === 'function'
      ? types[activity.type].icon(activity)
      : types[activity.type]?.icon;
  const title =
    !!types[activity.type]?.title &&
    types[activity.type].title(activity, t, viewer);

  return (
    <Root id={`activity-${activity.id}`} elevation={isEditing ? 16 : 1}>
      {isEditing ? (
        <ActivityForm
          activity={activity}
          root={root}
          onCancel={handleEditCancel}
        />
      ) : (
        <Fragment>
          <CardHeader
            avatar={
              <Avatar>
                <SvgIcon>{avatar}</SvgIcon>
              </Avatar>
            }
            action={
              ((EDITABLE_TYPES.includes(activity.type) && !disabledEdit) ||
                !disabledDelete) && (
                <ButtonMenu>
                  {EDITABLE_TYPES.includes(activity.type) && !disabledEdit && (
                    <MenuItem onClick={handleEditClick}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      Edit
                    </MenuItem>
                  )}
                  {!disabledDelete && (
                    <MenuItem onClick={handleDeleteClick}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  )}
                </ButtonMenu>
              )
            }
            title={title}
            subheader={<TimeAgo>{activity.updatedAt}</TimeAgo>}
          />

          {!!body && (
            <Body>
              <Typography component="div">{body}</Typography>
            </Body>
          )}

          {hasParentLink && <ChunkParent activity={activity} />}
          {activity.type === 'TASK' && (
            <ChunkTask
              activity={activity}
              handleDoneClick={handleDoneClick}
              t={t}
            />
          )}
        </Fragment>
      )}
    </Root>
  );
};

const activityEnhancer = compose(
  translate('activities'),
  createFragmentContainerHOC({
    root: graphql`
      fragment ActivityCard_root on Query {
        viewer {
          id
        }
        ...ActivityForm_root
      }
    `,
    activity: graphql`
      fragment ActivityCard_activity on Activity {
        ...ActivityForm_activity
        id
        isDone
        isSuccess
        updatedAt
        createdAt
        dueAt
        doneAt
        note
        subject
        deal {
          id
        }
        leadData {
          id
          isInterested
          refusalReasonText
          refusalReasons {
            id
            label
          }
        }
        parent {
          __typename
          id
          ... on Lead {
            deal {
              id
              name
            }
            organisation {
              id
              name
            }
          }
          ... on Deal {
            name
            image {
              url
            }
          }
          ... on User {
            fullName
          }
          ... on Organisation {
            name
          }
        }
        creator {
          id
          fullName
        }
        type
        user {
          id
          fullName
        }
        assignee {
          id
          fullName
        }
        email {
          id
        }
        file {
          id
          filename
          size
          mimetype
          url
        }
      }
    `,
  }),
  branch(props => props.activity == null, renderNothing),
  withStateHandlers(
    {
      isEditing: false,
    },
    {
      handleEditClick: () => () => ({ isEditing: true }),
      handleEditCancel: () => () => ({ isEditing: false }),
    },
  ),
  withConfirmDialog(),
  withHandlers({
    handleDeleteClick: ({ activity, onDelete, showConfirm, t }) => () => {
      showConfirm({
        onOk: () => onDelete(activity),
        okText: t('button.delete'),
      });
    },
    handleDoneClick: ({
      activity,
      onUpdate,
      showConfirm,
      t,
      disabledEdit,
    }) => () => {
      if (disabledEdit) {
        return false;
      }
      const isDone = !activity.isDone;
      showConfirm({
        message: isDone ? t('sureDoneTask') : t('sureUndoneTask'),
        onOk: () => onUpdate({ id: activity.id, isDone }),
        okText: t('button.change'),
      });
    },
  }),
);

export default activityEnhancer(Activity);
