import { getRequest, createOperationDescriptor } from 'relay-runtime';

// preload data if needed
export default (environment, query, variables, cacheConfig) => {
  const operation = createOperationDescriptor(getRequest(query), variables);

  environment
    .execute({ operation, cacheConfig: { ...cacheConfig, preload: true } })
    .subscribe({});
};
