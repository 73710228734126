import styled from 'styled-components';

export const StyledDeleteButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #DD3944;
  float: right;
  cursor: pointer;
`;

export const StyledFirstForSaveCancelButs = styled.div`
  position: fixed;
  bottom: 32px;
  right: 35%;
  z-index: 999;
`;

export const StyledSecondForSaveCancelButs = styled.div`
  background: #EDF5FE;
  padding: 12px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(40, 99, 166, 0.15);
`;

export const ReliabilityOptionIcons = {
  high: `<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="7.5" cy="8" r="7.5" fill="#69B34C"/>
</svg>`,
  medium: `<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="7.5" cy="8" r="7.5" fill="#FAB733"/>
</svg>`,
  low: `<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="7.5" cy="8" r="7.5" fill="#FF4E11"/>
</svg>`,
}

export const ConfidenceRow = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  & > * {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @-moz-document url-prefix() {
    svg {
      width: 15.3px;
    }
  }
`;