import React, { forwardRef, memo } from 'react';
import { useTheme } from '@material-ui/core';
import {
  UploadFileWrapper,
  FileName,
  RemoveButton,
} from './UploadedFileBox.styles';
import FlexBox from 'components/FlexBox';
import FileAttachmentIcon from '../../Icons/FileAttachmentIcon';
import CloseIcon from 'components/Icons/CloseIcon';

const UploadFileBox = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ onDelete, name }, _ref) => {
    const theme = useTheme();

    return (
      <UploadFileWrapper>
        <FlexBox alignItems="center" gap={theme.spacing(1)}>
          <FileAttachmentIcon />
          <FileName noWrap>{name}</FileName>
        </FlexBox>
        <FlexBox>
          <RemoveButton onClick={onDelete}>
            <CloseIcon />
          </RemoveButton>
        </FlexBox>
      </UploadFileWrapper>
    );
  },
);

export default memo(UploadFileBox);
