/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActivityForm_root$ref: FragmentReference;
declare export opaque type ActivityForm_root$fragmentType: ActivityForm_root$ref;
export type ActivityForm_root = {|
  +viewer: ?{|
    +organisation: ?{|
      +users: $ReadOnlyArray<{|
        +id: string,
        +fullName: ?string,
      |}>
    |}
  |},
  +$refType: ActivityForm_root$ref,
|};
export type ActivityForm_root$data = ActivityForm_root;
export type ActivityForm_root$key = {
  +$data?: ActivityForm_root$data,
  +$fragmentRefs: ActivityForm_root$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ActivityForm_root",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "viewer",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organisation",
          "storageKey": null,
          "args": null,
          "concreteType": "Organisation",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "users",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fullName",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '31702e66e56513b31f533763fdc2a9c1';
module.exports = node;
