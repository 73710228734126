/**
 * @flow
 * @relayHash 03e8a95960e6b8e73901911e6b95af8b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dealsFiltersSignatureDateQueryVariables = {||};
export type dealsFiltersSignatureDateQueryResponse = {|
  +signatureDates: ?$ReadOnlyArray<?{|
    +id: string,
    +year: ?string,
  |}>
|};
export type dealsFiltersSignatureDateQuery = {|
  variables: dealsFiltersSignatureDateQueryVariables,
  response: dealsFiltersSignatureDateQueryResponse,
|};
*/


/*
query dealsFiltersSignatureDateQuery {
  signatureDates {
    id
    year
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signatureDates",
    "storageKey": null,
    "args": null,
    "concreteType": "SignatureDate",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "year",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealsFiltersSignatureDateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dealsFiltersSignatureDateQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "dealsFiltersSignatureDateQuery",
    "id": null,
    "text": "query dealsFiltersSignatureDateQuery {\n  signatureDates {\n    id\n    year\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ecc1ef9b03171819060b4b7503aaf39';
module.exports = node;
