import React from 'react';

const CloseIcon = ({
  width = 16,
  height = 16,
  color = '#3455D1',
  viewBox = '0 0 16 16',
  onClick = () => {},
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.60059"
        width="20.3641"
        height="2.26268"
        rx="1.13134"
        transform="rotate(45 1.60059 0)"
        fill={color}
      />
      <rect
        x="0.000488281"
        y="14.4004"
        width="20.3641"
        height="2.26268"
        rx="1.13134"
        transform="rotate(-45 0.000488281 14.4004)"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
