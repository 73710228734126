import React from 'react';

const AttachmentIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          width="30"
          height="30"
          rx="15"
          fill="#96A4B4"
          shape-rendering="crispEdges"
        />
        <path
          transform="translate(-8, -6)"
          d="M18.5 13.5C17.675 13.5 17.0075 14.175 17.0075 15L17 27C17 27.825 17.6675 28.5 18.4925 28.5H27.5C28.325 28.5 29 27.825 29 27V18.6225C29 18.225 28.8425 17.8425 28.5575 17.565L24.935 13.9425C24.6575 13.6575 24.275 13.5 23.8775 13.5H18.5ZM23.75 18V14.625L27.875 18.75H24.5C24.0875 18.75 23.75 18.4125 23.75 18Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4545_14413"
          x="0"
          y="0"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.588235 0 0 0 0 0.643137 0 0 0 0 0.705882 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4545_14413"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4545_14413"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default AttachmentIcon;
