/* @flow */
import * as React from 'react';
import type { HOC } from 'recompose';

function refetchConnectionOnPropsChange<Enhanced: { relay: any }, ConnFilter>(
  shouldRefetch: (props: Enhanced, prevProps: Enhanced) => boolean,
  mapPropsToFragmentVars: (props: Enhanced) => ConnFilter,
  totalCount: number,
): HOC<{ ...$Exact<Enhanced>, connectionFilterKeys: ConnFilter }, Enhanced> {
  return Base =>
    class RefetchConnectionOnPropsChange extends React.Component<Enhanced> {
      render() {
        return (
          <Base
            {...this.props}
            connectionFilterKeys={mapPropsToFragmentVars(this.props)}
          />
        );
      }

      componentDidUpdate(prevProps) {
        if (shouldRefetch(this.props, prevProps)) {
          // $ExpectError
          this.props.relay.refetchConnection(
            totalCount,
            null,
            mapPropsToFragmentVars(this.props),
          );
        }
      }
    };
}

export default refetchConnectionOnPropsChange;
