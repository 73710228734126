import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import { graphql } from 'react-relay';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import {
  compose,
  defaultProps,
  getContext,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import styled, { css, injectGlobal } from 'styled-components';
import { createFragmentContainerHOC } from '../../../../utils/relay';
import DictionaryItem from './DictionaryItem';

const CardTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  padding: 12px 0;
  font-weight: 500;
`;

const CreateButton = styled(Button)`
  margin: 12px 0;
`;

const AddIconStyled = styled(AddIcon)`
  margin-right: 8px;
`;

const Root = styled.div`
  padding: 8px;
  flex-grow: 2;
  ${props =>
    props['data-fixed'] &&
    /* make it scrollable */ css`
      padding: 36px;
      height: calc(100vh - 64px - 48px); /* 100vh - AppBar - Tabs */
      overflow-y: auto;
    `};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const SortableDictionaryItem = sortableElement(DictionaryItem);

export const dealStates = [
  { value: 'CALL_SELLER', label: 'CALL_SELLER' },
  { value: 'MEETING_WITH_SELLER', label: 'MEETING_WITH_SELLER' },
  { value: 'INVITE_SELLER_TO_CRE', label: 'INVITE_SELLER_TO_CRE' },
  { value: 'MANDATE_ANALYSIS', label: 'MANDATE_ANALYSIS' },
  { value: 'MANDATE_SIGNATURE', label: 'MANDATE_SIGNATURE' },
  { value: 'TEASER_SALE_MEMO', label: 'TEASER_SALE_MEMO' },
  {
    value: 'TEASER_SALE_MEMO_VALIDATION',
    label: 'TEASER_SALE_MEMO_VALIDATION',
  },
  { value: 'TENDERING_PREPARATION', label: 'TENDERING_PREPARATION' },
  { value: 'NDA_FIRST_CIRCLE', label: 'NDA_FIRST_CIRCLE' },
  { value: 'NDA_SECOND_CIRCLE', label: 'NDA_SECOND_CIRCLE' },
  { value: 'NDA_THIRD_CIRCLE', label: 'NDA_THIRD_CIRCLE' },
  { value: 'NBO_PHASE', label: 'NBO_PHASE' },
  { value: 'NBO_SELECTION', label: 'NBO_SELECTION' },
  { value: 'BO_PHASE', label: 'BO_PHASE' },
  { value: 'BO_SELECTION', label: 'BO_SELECTION' },
  { value: 'CLOSING', label: 'CLOSING' },
  { value: 'CLOSING_VALIDATION', label: 'CLOSING_VALIDATION' },
  { value: 'SOLD', label: 'SOLD' },
];

export const pipelineStageGroups = [
  {
    value: 'SELECTED_NOTIFIED',
    label: 'Selected & Notified',
  },
  {
    value: 'INTERESTED',
    label: 'Interested',
  },
  {
    value: 'NDA_SIGNED',
    label: 'NDA Signed',
  },
  {
    value: 'NBO_SUBMITTED',
    label: 'NBO Submitted',
  },
  {
    value: 'VISIT',
    label: 'Visit',
  },
  {
    value: 'DUE_DILIGENCE',
    label: 'Due diligence',
  },
  {
    value: 'BO_SUBMITTED',
    label: 'BO Submitted',
  },
  {
    value: 'CLOSING',
    label: 'Closing',
  },
  {
    value: 'NOT_SELECTED',
    label: 'Not Selected',
  },
  {
    value: 'NOT_INTERESTED',
    label: 'Not Interested',
  },
];

export const fileTypesGroups = [
  {
    value: 'DEAL',
    label: 'Deal documents',
  },
  {
    value: 'ORGANISATION',
    label: 'Organisation documents',
  },
  {
    value: 'SELLER',
    label: 'Seller documents',
  },
];

export const dealStatusGroups = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'CLOSED',
    label: 'Closed',
  },
  {
    value: 'UNPUBLISHED',
    label: 'Unpublished',
  },
  {
    value: 'LOST',
    label: 'Lost',
  },
];

const DictionaryList = ({
  deleteMutation,
  newItemGroup,
  setNewItemGroup,
  handleSubmit,
  isWide,
  groups,
  typename,
  t,
}) => (
  <Root data-fixed={isWide}>
    {groups.map(({ value, label, items }) => (
      <React.Fragment key={value}>
        <CardTitle>{label}</CardTitle>
        {items.map(item => (
          <SortableDictionaryItem
            key={item.id}
            handleSubmit={handleSubmit}
            deleteMutation={items.length > 1 && deleteMutation}
            item={item}
            index={item.position}
            sortIndex={item.position}
            collection={value}
            typename={typename}
            group={value}
          />
        ))}
        {value === newItemGroup ? (
          <DictionaryItem
            handleSubmit={handleSubmit}
            onCancel={() => setNewItemGroup(null)}
            item={null}
            typename={typename}
            isNew
            group={value}
          />
        ) : (
          <ButtonWrapper>
            <CreateButton
              color="primary"
              onClick={() => setNewItemGroup(value)}
            >
              <AddIconStyled />
              {t('addElement')}
            </CreateButton>
          </ButtonWrapper>
        )}
      </React.Fragment>
    ))}
  </Root>
);

const enhancer = compose(
  translate('settings'),
  createFragmentContainerHOC({
    items: graphql`
      fragment DictionaryList_items on Dictionary @relay(plural: true) {
        id
        position
        ... on PipelineStage {
          group
        }
        ... on DealStatus {
          group
        }
        ... on FileType {
          group
          allowMultiple
        }
        ... on DealPipelineStage {
          dealState {
            CALL_SELLER
            MEETING_WITH_SELLER
            INVITE_SELLER_TO_CRE
            MANDATE_ANALYSIS
            MANDATE_SIGNATURE
            TEASER_SALE_MEMO
            TEASER_SALE_MEMO_VALIDATION
            TENDERING_PREPARATION
            NDA_FIRST_CIRCLE
            NDA_SECOND_CIRCLE
            NDA_THIRD_CIRCLE
            NBO_PHASE
            NBO_SELECTION
            BO_PHASE
            BO_SELECTION
            CLOSING
            CLOSING_VALIDATION
            SOLD
          }
        }
        ...DictionaryItem_item
      }
    `,
  }),
  withState('newItemGroup', 'setNewItemGroup', null),
  getContext({
    api: PropTypes.object.isRequired,
  }),
  withHandlers({
    handleSubmit: ({
      api,
      newItemGroup,
      setNewItemGroup,
      createMutation,
      updateMutation,
    }) => async values => {
      const isNew = !values.id;
      const input = Object.assign({}, values);

      if (isNew && newItemGroup && newItemGroup !== 'default')
        input.group = newItemGroup;

      if (input.adminOnlyAccess)
        input.adminOnlyAccess = input.adminOnlyAccess === 'true';

      await (isNew ? createMutation : updateMutation)(api, input);
      if (isNew) {
        setNewItemGroup(null);
      }
    },
    onSortEnd: ({ api, updateMutation, items }) => async ({
      oldIndex,
      newIndex,
    }) => {
      if (oldIndex === newIndex) return;

      const { id } = items[oldIndex];

      await updateMutation(api, {
        id,
        position: newIndex,
        _oldPosition: oldIndex,
      });
    },
  }),
  withProps(({ items, typename, title }) => {
    let groups;
    switch (typename) {
      // we group items by type if it's provided by node
      case 'PipelineStage': // PipelineStage has type field
        groups = pipelineStageGroups.map(list => ({ items: [], ...list }));
        items.forEach(item => {
          groups[
            groups.findIndex(({ value }) => value === item.group)
          ].items.push(item);
        });
        break;
      case 'FileType':
        groups = fileTypesGroups.map(list => ({ items: [], ...list }));
        items.forEach(item => {
          groups[
            groups.findIndex(({ value }) => value === item.group)
          ].items.push(item);
        });
        break;
      case 'DealStatus':
        groups = dealStatusGroups.map(list => ({ items: [], ...list }));
        items.forEach(item => {
          groups[
            groups.findIndex(({ value }) => value === item.group)
          ].items.push(item);
        });
        break;
      default:
        // if node type has no group, we make only one group with hardcoded value=default;
        // we value for 1) trigger new item form 2) react-sortable-hoc collection 3) as type, that will be attached to the new node
        groups = [
          {
            value: 'default',
            label: title,
            items,
          },
        ];
    }
    return {
      groups,
    };
  }),
  defaultProps({
    // sortableContainer props
    useDragHandle: true,
    helperClass: 'no-transition-duration-hack',
    transitionDuration: 200,
  }),
  sortableContainer,
);

injectGlobal`
  .no-transition-duration-hack {
      transition-duration: 0s;
  }
`;

export default enhancer(DictionaryList);
