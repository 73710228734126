import { graphql } from 'react-relay';
import promiseify from '../../../../utils/promiseify';

const mapActivityObj = obj => obj;

export const updateActivity = promiseify(
  (api, { type, ...input }, onCompleted) => {
    api.commitMutation({
      mutation: graphql`
        mutation activitiesUpdateMutation($input: UpdateActivityInput!) {
          updateActivity(input: $input) {
            activity {
              id
              type
              createdAt
              updatedAt
              dueAt
              doneAt
              parent {
                id
                __typename
              }
              note
              subject
              isDone
              isSuccess
              assignee {
                id
                firstName
                lastName
              }
              creator {
                id
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables: {
        input: mapActivityObj(input),
      },
      onError: err => {
        onCompleted(undefined, [err]);
      },
      onCompleted,
    });
  },
);

export const createActivity = promiseify(
  (api, { connectionKey, ...input }, onCompleted = () => false) => {
    api.commitMutation({
      mutation: graphql`
        mutation activitiesCreateMutation($input: CreateActivityInput!) {
          createActivity(input: $input) {
            activityEdge {
              node {
                id
                type
                createdAt
                updatedAt
                dueAt
                doneAt
                parent {
                  id
                  __typename
                }
                note
                subject
                isDone
                isSuccess
                assignee {
                  id
                  firstName
                  lastName
                }
                creator {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,
      variables: {
        input: mapActivityObj(input),
      },
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: input.parentId,
          connectionInfo: [
            {
              key: connectionKey,
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'activityEdge',
        },
      ],
      onError: err => {
        onCompleted(undefined, [err]);
      },
      onCompleted,
    });
  },
);

export const deleteActivity = promiseify(
  (api, { connectionKey, parentId, ...input }, onCompleted = () => false) => {
    api.commitMutation({
      mutation: graphql`
        mutation activitiesDeleteMutation($input: DeleteActivityInput!) {
          deleteActivity(input: $input) {
            deletedActivityId
          }
        }
      `,
      variables: {
        input: {
          id: input.id,
        },
      },
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: parentId || 'client:root',
          connectionKeys: [
            {
              key: connectionKey,
            },
          ],
          pathToConnection: ['node', connectionKey.split('_')[1]],
          deletedIDFieldName: 'deletedActivityId',
        },
        {
          type: 'NODE_DELETE',
          deletedIDFieldName: 'deletedActivityId',
        },
      ],
      onError: err => {
        onCompleted(undefined, [err]);
      },
      onCompleted,
    });
  },
);
