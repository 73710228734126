import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createPipelineStage = makeCreateDictionaryMutation(
  graphql`
    mutation pipelineStagesCreatePipelineStageMutation(
      $input: CreatePipelineStageInput!
    ) {
      createPipelineStage(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createPipelineStage',
  'pipelineStages',
);

export const updatePipelineStage = makeUpdateDictionaryMutation(
  graphql`
    mutation pipelineStagesUpdatePipelineStageMutation(
      $input: UpdatePipelineStageInput!
    ) {
      updatePipelineStage(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updatePipelineStage',
  'pipelineStages',
);

export const deletePipelineStage = makeDeleteDictionaryMutation(
  graphql`
    mutation pipelineStagesDeletePipelineStageMutation(
      $input: DeletePipelineStageInput!
    ) {
      deletePipelineStage(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deletePipelineStage',
  'pipelineStages',
);
