/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type userInput_user$ref: FragmentReference;
declare export opaque type userInput_user$fragmentType: userInput_user$ref;
export type userInput_user = {|
  +id: string,
  +fullName: ?string,
  +email: string,
  +jobTitle: ?string,
  +isBroker: ?boolean,
  +isAdmin: boolean,
  +organisationId: ?string,
  +mobilePhone: ?string,
  +officePhone: ?string,
  +$fragmentRefs: UserAvatar_user$ref,
  +$refType: userInput_user$ref,
|};
export type userInput_user$data = userInput_user;
export type userInput_user$key = {
  +$data?: userInput_user$data,
  +$fragmentRefs: userInput_user$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "userInput_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobTitle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isBroker",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "organisationId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mobilePhone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "officePhone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserAvatar_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8788d264cbe728dc9ac746b9dc6f8eee';
module.exports = node;
