import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createFileType = makeCreateDictionaryMutation(
  graphql`
    mutation fileTypesCreateFileTypeMutation($input: CreateFileTypeInput!) {
      createFileType(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createFileType',
  'fileTypes',
);

export const updateFileType = makeUpdateDictionaryMutation(
  graphql`
    mutation fileTypesUpdateFileTypeMutation($input: UpdateFileTypeInput!) {
      updateFileType(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateFileType',
  'fileTypes',
);

export const deleteFileType = makeDeleteDictionaryMutation(
  graphql`
    mutation fileTypesDeleteFileTypeMutation($input: DeleteFileTypeInput!) {
      deleteFileType(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteFileType',
  'fileTypes',
);
