import * as React from 'react';
import idx from 'idx';
import {
  compose,
  defaultProps,
  getContext,
  onlyUpdateForKeys,
  withProps,
} from 'recompose';
import { QueryRenderer, graphql } from 'react-relay';
import GlobalSearchFrags from './GlobalSearchFrags';
import { TitleProvider } from '../../admin/controls/Title';

const globalSearchRenderer = ({ api, query, match, search, isPage }) => (
  <QueryRenderer
    environment={api.environment}
    query={query}
    variables={{
      limit: isPage ? 100000 : 8,
      search,
    }}
    render={({ error, props }) => {
      if (error) return <div>{error.message}</div>;

      // provide null so internal routes can be loaded even with some defaults
      return (
        <React.Fragment>
          {match ? <TitleProvider text="Search" /> : null}
          <GlobalSearchFrags
            root={props || null}
            search={search}
            isPage={isPage}
          />
        </React.Fragment>
      );
    }}
  />
);

const query = graphql`
  query GlobalSearchQuery($limit: Int, $search: String) {
    ...GlobalSearchFrags_root
  }
`;

export default compose(
  defaultProps({
    query,
  }),
  getContext({
    api: () => {},
  }),
  withProps(({ match }) => ({
    search: idx(match, _ => _.params.search),
  })),
  onlyUpdateForKeys(['search']),
)(globalSearchRenderer);
