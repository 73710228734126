/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserAvatar_organisation$ref: FragmentReference;
declare export opaque type UserAvatar_organisation$fragmentType: UserAvatar_organisation$ref;
export type UserAvatar_organisation = {|
  +id: string,
  +name: ?string,
  +primaryImage: ?{|
    +url: string
  |},
  +$refType: UserAvatar_organisation$ref,
|};
export type UserAvatar_organisation$data = UserAvatar_organisation;
export type UserAvatar_organisation$key = {
  +$data?: UserAvatar_organisation$data,
  +$fragmentRefs: UserAvatar_organisation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserAvatar_organisation",
  "type": "Organisation",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "primaryImage",
      "storageKey": null,
      "args": null,
      "concreteType": "Image",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '99e4bd8394822a1c7770bbbab7a13347';
module.exports = node;
