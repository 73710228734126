import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledControlLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  /* RealAdvisor Black */

  color: #333e48;
`;

export const SelectNextActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 200px;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 93px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff !important;
  background-color: #3455d1;
  .text {
    color: #3455d1 !important;
  }
  text-align: center;
  &:disabled {
    color: #fff;
  }
`;
export const StyledCancelButton = styled(Button)`
  border-radius: 93px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  .text {
    color: #3455d1 !important;
  }
  text-align: center;
`;
