/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserProfileForm_user$ref: FragmentReference;
declare export opaque type UserProfileForm_user$fragmentType: UserProfileForm_user$ref;
export type UserProfileForm_user = {|
  +id: string,
  +firstName: ?string,
  +lastName: ?string,
  +fullName: ?string,
  +isSubscribed: boolean,
  +$refType: UserProfileForm_user$ref,
|};
export type UserProfileForm_user$data = UserProfileForm_user;
export type UserProfileForm_user$key = {
  +$data?: UserProfileForm_user$data,
  +$fragmentRefs: UserProfileForm_user$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserProfileForm_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSubscribed",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd2b0485f2644d87c041420b0f8fd61b7';
module.exports = node;
