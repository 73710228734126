/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';

const SaveIndicator = styled(LinearProgress).attrs({
  color: 'secondary',
})`
  && {
    height: 2px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10000;
  }
`;

const adminAppBarLoading = ({ count }) =>
  count > 0 ? <SaveIndicator /> : null;

export default compose(connect(state => state.loading))(adminAppBarLoading);
