// @flow

import * as React from 'react';
import isDeepEqual from 'dequal';

type FormProps = {|
  className?: string,
  onSubmit: () => void,
  children: React.Node,
  css?: any, // workaround for emotion
|};

export const Form = ({ className, onSubmit, children }: FormProps) => {
  return (
    <form
      className={className}
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        onSubmit();
      }}
    >
      {/* hack to allow to trigger submit from inputs */}
      <button hidden={true} />
      {children}
    </form>
  );
};

type FormikErrors<Values> = $Shape<$ObjMap<Values, <T>(a: T) => ?string>>;
type FormikTouched<Values> = $Shape<$ObjMap<Values, <T>(a: T) => ?boolean>>;

type State<Values> = {|
  values: Values,
  errors: FormikErrors<Values>,
  responseErrors: FormikErrors<Values>,
  touched: FormikTouched<Values>,
|};

type Action<Values> =
  | {| type: 'setValues', values: $Shape<Values> | (Values => $Shape<Values>) |}
  | {| type: 'setTouched', touched: FormikTouched<Values> |}
  | {| type: 'setResponseErrors', responseErrors: FormikErrors<Values> |}
  | {| type: 'submit' |}
  | {| type: 'reset' |};

type FormikParams<Values> = {|
  initialValues: Values,
  validate: Values => FormikErrors<Values>, // TODO | Promise<FormikErrors<Values>>,
  areValuesEqual?: (Values, Values) => boolean,
  onSubmit: Values => void,
  onInvalidSubmit?: (values: Values, errors: $ReadOnlyArray<string>) => void,
|};

export type FormikHook<Values> = {|
  values: Values,
  errors: FormikErrors<Values>,
  changed: boolean,
  valid: boolean,
  setValues: ($Shape<Values> | (Values => $Shape<Values>)) => void,
  setTouched: (FormikTouched<Values>) => void,
  setResponseErrors: (FormikErrors<Values>) => void,
  submitForm: () => void,
  resetForm: () => void,
  touched: FormikErrors<Values>,
|};

const filterObject = (values, filter: string => boolean) => {
  return Object.keys(values).reduce((acc, key) => {
    if (filter(key)) {
      acc[key] = values[key];
    }
    return acc;
  }, {});
};

const fillObject = (values, fillingValue) => {
  return Object.keys(values).reduce((acc, key) => {
    acc[key] = fillingValue;
    return acc;
  }, {});
};

const mergeNonNullableValues = (a, b) => {
  const result = {};
  const assignNonNullableEntry = ([key, value]) => {
    if (value != null) {
      result[key] = value;
    }
  };
  Object.entries(a).forEach(assignNonNullableEntry);
  Object.entries(b).forEach(assignNonNullableEntry);
  return result;
};

const areAllObjectValuesNullable = values =>
  !Object.values(values).some(value => value != null);

const getNonNullableValues = values =>
  Object.values(values)
    .filter(value => value != null)
    .map(String);

export const useFormik = <T: { +[string]: mixed }>({
  initialValues,
  validate,
  areValuesEqual = isDeepEqual,
  onSubmit,
  onInvalidSubmit,
}: FormikParams<T>): FormikHook<T> => {
  const reducer = (state, action) => {
    if (action.type === 'setValues') {
      return {
        values: {
          ...state.values,
          ...(typeof action.values === 'function'
            ? action.values(state.values)
            : action.values),
        },
        touched: { ...state.touched, ...fillObject(action.values, false) },
        errors: state.errors,
        responseErrors: {
          ...state.responseErrors,
          ...fillObject(action.values, null),
        },
      };
    }
    if (action.type === 'setTouched') {
      return {
        values: state.values,
        touched: { ...state.touched, ...action.touched },
        errors: validate(state.values),
        responseErrors: state.responseErrors,
      };
    }
    if (action.type === 'submit') {
      const errors = validate(state.values);
      return {
        values: state.values,
        touched: { ...state.touched, ...fillObject(errors, true) },
        errors,
        responseErrors: state.responseErrors,
      };
    }
    if (action.type === 'reset') {
      return {
        values: initialValues,
        touched: {},
        errors: {},
        responseErrors: {},
      };
    }
    if (action.type === 'setResponseErrors') {
      return {
        values: state.values,
        touched: state.touched,
        errors: state.errors,
        responseErrors: action.responseErrors,
      };
    }
    throw Error('Invalid action');
  };

  const [state, dispatch] = React.useReducer<State<T>, Action<T>>(reducer, {
    values: initialValues,
    errors: {},
    responseErrors: {},
    touched: {},
  });

  const changed = areValuesEqual(initialValues, state.values) === false;

  const touchedErrors = React.useMemo(
    () =>
      filterObject(
        state.errors,
        key => state.errors[key] != null && state.touched[key] === true,
      ),
    [state.errors, state.touched],
  );

  const responseErrors = state.responseErrors;

  const mergedErrors = React.useMemo(
    () => mergeNonNullableValues(touchedErrors, responseErrors),
    [touchedErrors, responseErrors],
  );

  const valid = React.useMemo(
    () =>
      areAllObjectValuesNullable(touchedErrors) &&
      areAllObjectValuesNullable(responseErrors),
    [touchedErrors, responseErrors],
  );

  const setValuesWithTouchedReset = newValues =>
    dispatch({ type: 'setValues', values: newValues });

  const setTouchedWithValidation = newTouched =>
    dispatch({ type: 'setTouched', touched: newTouched });

  const setResponseErrors = newResponseErrors =>
    dispatch({ type: 'setResponseErrors', responseErrors: newResponseErrors });

  const submitForm = () => {
    dispatch({ type: 'submit' });
    const newErrors = validate(state.values);
    if (areAllObjectValuesNullable(newErrors)) {
      onSubmit(state.values);
    } else if (onInvalidSubmit) {
      onInvalidSubmit(state.values, getNonNullableValues(newErrors));
    }
  };

  const resetForm = () => {
    dispatch({ type: 'reset' });
  };

  return {
    values: state.values,
    errors: mergedErrors,
    changed,
    valid,
    setValues: setValuesWithTouchedReset,
    setTouched: setTouchedWithValidation,
    setResponseErrors,
    submitForm,
    resetForm,
    touched: state.touched,
  };
};
