import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createDealProgressStage = makeCreateDictionaryMutation(
  graphql`
    mutation dealProgressStagesCreateDealProgressStageMutation(
      $input: CreateDealProgressStageInput!
    ) {
      createDealProgressStage(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createDealProgressStage',
  'dealProgressStages',
);

export const updateDealProgressStage = makeUpdateDictionaryMutation(
  graphql`
    mutation dealProgressStagesUpdateDealProgressStageMutation(
      $input: UpdateDealProgressStageInput!
    ) {
      updateDealProgressStage(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateDealProgressStage',
  'dealProgressStages',
);

export const deleteDealProgressStage = makeDeleteDictionaryMutation(
  graphql`
    mutation dealProgressStagesDeleteDealProgressStageMutation(
      $input: DeleteDealProgressStageInput!
    ) {
      deleteDealProgressStage(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteDealProgressStage',
  'dealProgressStages',
);
