import React, { memo } from 'react';
import { Box, useTheme } from '@material-ui/core';
import FlexBox from '../FlexBox';
import { OffDragSubtitle, BrowserLink } from './FileDropZone.styles';
import UploadCloudIcon from '../Icons/UploadCloudIcon';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const DragBody = ({
  isDragActive = false,
  activeLabel = 'Drop documents here',
  nonActiveLabel = i18next.t('uploadDocumentsHere'),
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      gap={theme.spacing(1)}
    >
      <FlexBox
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={theme.spacing(1)}
      >
        <UploadCloudIcon />
        <OffDragSubtitle>
          {!isDragActive ? nonActiveLabel : activeLabel}
        </OffDragSubtitle>
      </FlexBox>
      <Box>
        {!isDragActive && <BrowserLink>{t('browseFiles')}</BrowserLink>}
      </Box>
    </FlexBox>
  );
};

export default memo(DragBody);
