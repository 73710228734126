/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type userMenu_viewer$ref: FragmentReference;
declare export opaque type userMenu_viewer$fragmentType: userMenu_viewer$ref;
export type userMenu_viewer = {|
  +id: string,
  +fullName: ?string,
  +isLoggedInAs: boolean,
  +isAdmin: boolean,
  +isSuperAdmin: boolean,
  +realUser: ?{|
    +id: string,
    +fullName: ?string,
    +$fragmentRefs: UserAvatar_user$ref,
  |},
  +$fragmentRefs: UserAvatar_user$ref,
  +$refType: userMenu_viewer$ref,
|};
export type userMenu_viewer$data = userMenu_viewer;
export type userMenu_viewer$key = {
  +$data?: userMenu_viewer$data,
  +$fragmentRefs: userMenu_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "FragmentSpread",
  "name": "UserAvatar_user",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "userMenu_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isLoggedInAs",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSuperAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "realUser",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    },
    (v2/*: any*/)
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9208181f79d96e89678cd08e78bcffdc';
module.exports = node;
