/* @flow */

import options from './options';
import detectLanguage from './detectLanguage';

const languages = options.whitelist.filter(x => x !== 'en'); // ['de', 'fr']
const langRegExp = new RegExp(
  `^(${languages.map(x => `/${x}`).join('|')}|)(/.*?|)$`,
);

/**
 * If the currently selected language differs from an auto-detected one,
 * return a redirect URL path, otherwise return null.
 */
function detectRedirect(
  pathname: string,
  viewer: ?{ language: ?string },
  cookieLanguage: ?string,
  acceptLanguage: ?string,
) {
  const currentLanguage =
    (langRegExp.exec(pathname) || [])[1].substr(1) || options.fallbackLng;
  const detectedLanguage = detectLanguage(
    viewer,
    cookieLanguage,
    acceptLanguage,
  );

  if (detectedLanguage !== currentLanguage) {
    const [, , path] = langRegExp.exec(pathname) || [];
    return detectedLanguage === options.fallbackLng
      ? path || '/'
      : `/${detectedLanguage}${path === '/' ? '' : path}`;
  }

  return null;
}

export default detectRedirect;
