import React from 'react';
import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { blue, grey, teal } from '@material-ui/core/colors';
import { ThemeProvider } from 'styled-components';

export const frontTheme = createMuiTheme({
  palette: {
    primary: {
      light: blue.A200,
      main: `#2F50D9`,
      dark: blue.A700,
    },
    secondary: { light: grey[300], main: grey[500], dark: grey[700] },
  },
  typography: {
    h6: {},
    subtitle1: {},
    subtitle2: {},
    button: {
      textTransform: 'initial',
    },
  },
  shape: {
    borderRadius: 6,
  },
});

export const adminTheme = createMuiTheme({
  palette: {
    primary: {
      light: blue.A200,
      main: '#3455D1',
      dark: blue[900],
    },
    secondary: { light: teal[300], main: teal[500], dark: teal[700] },
    info: {
      main: '#3455D1',
    },
  },
  props: {
    MuiFormControl: {
      variant: 'filled',
      fullWidth: true,
    },
  },
  typography: {
    button: {
      textTransform: 'initial',
    },
  },
  shape: {
    borderRadius: 6,
  },
});

export default ({ children, theme }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);
