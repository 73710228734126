// @flow

import { Environment, RecordSource, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  cacheMiddleware,
  errorMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern';
import Cookies from 'js-cookie';

import options from './i18n/options';
import reduxStore from './redux/store';

const { fallbackLng, whitelist } = options;

const getLanguage = () => {
  const locationLang = window.location.pathname.split('/')[1];
  if (whitelist.includes(locationLang)) {
    return locationLang;
  }

  return fallbackLng;
};

type Options = {|
  baseUrl: string,
|};

export const createRelayEnvironment = ({ baseUrl }: Options) => {
  const network = new RelayNetworkLayer([
    urlMiddleware({ url: () => `${baseUrl}/graphql` }),
    cacheMiddleware({
      size: 100, // max 100 requests
      ttl: 5 * 60 * 1000,
      clearOnMutation: true,
    }),
    // $FlowFixMe
    next => async req => {
      req.fetchOpts.headers['Accept-Language'] =
        Cookies.get('lng') ?? fallbackLng;
      // req.fetchOpts.headers['Accept-Language'] = getLanguage();
      req.fetchOpts.credentials = 'include';

      let result;
      try {
        reduxStore.dispatch({ type: 'INC_LOADING' });

        result = await next(req);

        if (result?.errors?.length > 0) {
          const anonymousError = result.errors.find(
            error => error.message === 'Anonymous access denied',
          );
          if (anonymousError) {
            return (window.location.href = '/');
          }
        }
      } catch (err) {
        const errorText = JSON.parse(JSON.stringify(err));

        result = JSON.parse(err?.res?.text || `{'errors': '${errorText}'}`) || {
          errors: [
            {
              message: errorText,
              locations: [],
            },
          ],
        };
      } finally {
        reduxStore.dispatch({ type: 'DEC_LOADING' });
      }
      return result;
    },
    process.env.NODE_ENV !== 'production' ? errorMiddleware() : null,
  ]);
  const store = new Store(new RecordSource());

  const environment = new Environment({
    network,
    store,
  });

  return environment;
};
