import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createNotInterestedReason = makeCreateDictionaryMutation(
  graphql`
    mutation notInterestedReasonsCreateNotInterestedReasonMutation(
      $input: CreateNotInterestedReasonInput!
    ) {
      createNotInterestedReason(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createNotInterestedReason',
  'notInterestedReasons',
);

export const updateNotInterestedReason = makeUpdateDictionaryMutation(
  graphql`
    mutation notInterestedReasonsUpdateNotInterestedReasonMutation(
      $input: UpdateNotInterestedReasonInput!
    ) {
      updateNotInterestedReason(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateNotInterestedReason',
  'notInterestedReasons',
);

export const deleteNotInterestedReason = makeDeleteDictionaryMutation(
  graphql`
    mutation notInterestedReasonsDeleteNotInterestedReasonMutation(
      $input: DeleteNotInterestedReasonInput!
    ) {
      deleteNotInterestedReason(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteNotInterestedReason',
  'notInterestedReasons',
);
