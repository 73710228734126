import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AttachmentIcon from '../../../../components/Icons/AttachmentIcon';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useFeedback from '../../../../hooks/useFeedback';
import UploadDocumentForm from '../../Deals/Deal/DealActivities/components/ActionForms/UploadDocumentForm';
import DocumentLargeIcon from '../../Deals/Deal/DealActivities/Icons/DocumentLargeIcon';
import {
  DELETE_FILE,
  GENERATE_SIGNED_URL,
} from '../../Deals/Deal/DealActivities/utils/query';
import EditIcon from '../../../../components/Icons/EditIcon';
import DownloadIcon from '../../../../components/Icons/DownloadIcon';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const downloadFilesAsZip = (files, fileName, onProgress) => {
  let count = 0;
  const zip = new JSZip();
  let totalProgress = 0;
  files.forEach(function(file) {
    const xhttp = new XMLHttpRequest();
    xhttp.open('GET', file.url);
    xhttp.responseType = 'blob';
    xhttp.setRequestHeader('Content-type', file.mimetype);
    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        zip.file(file.fileName, xhttp.response);
        count++;
        if (count == files.length) {
          zip.generateAsync({ type: 'blob' }).then(function(content) {
            saveAs(content, fileName);
          });
        }
      }
    };
    xhttp.onprogress = function(e) {
      if (e.lengthComputable) {
        const fileProgress = (e.loaded / e.total) * 100 * (1 / files.length);
        totalProgress =
          totalProgress + fileProgress > 100
            ? 100
            : totalProgress + fileProgress;
        onProgress && onProgress(totalProgress);
      }
    };
    xhttp.send();
  });
};

export const DownloadProgress = ({ value, ...props }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.2)',
        zIndex: 300000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          borderRadius: '1rem',
          background: 'white',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          variant="determinate"
          color="primary"
          {...props}
          value={value}
          size={80}
        ></CircularProgress>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1rem',
          }}
        >
          {`${Math.round(Number(value))}%`}
        </Box>
      </Box>
    </Box>
  );
};

const CardTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  padding: 12px 0;
  font-weight: 500;
`;

const Root = styled.div`
  padding: 36px;
  flex-grow: 2;
  ${props =>
    props['data-fixed'] &&
    /* make it scrollable */ css`
      padding: 36px;
      height: calc(100vh - 64px - 48px); /* 100vh - AppBar - Tabs */
      overflow-y: auto;
    `};

  .wrapper {
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    border-radius: 8px;
  }
`;

const StyledDocumentRow = styled(Box)`
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: ${props => !props.isLast && '1px solid #e0e0e0'};
  border-top-left-radius: ${props => props.isFirst && '8px'};
  border-top-right-radius: ${props => props.isFirst && '8px'};
  border-bottom-left-radius: ${props => props.isLast && '8px'};
  border-bottom-right-radius: ${props => props.isLast && '8px'};

  .left {
    svg {
      font-size: 16px;
      fill: rgb(51, 62, 72);
    }

    .name {
      line-height: 18px;
      color: rgb(51, 62, 72);
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.00714em;
    }

    display: flex;
    align-items: center;
    gap: 12px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //gap: 8px;

    button {
      min-width: auto;
    }
  }

  .add-document-btn {
    color: #3455d1;
    border: #3455d1 1px solid;
    border-radius: 100px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const getTemplateList = gql`
  query getFileTemplates {
    fileTemplates {
      id
      label
      labelEn: label(lang: "en")
      labelDe: label(lang: "de")
      labelFr: label(lang: "fr")
      type
    }
  }
`;

export const getTemplateFiles = gql`
  query fileIncludeTemplates($filters: FileIncludeTemplateFilters) {
    fileIncludeTemplates(filters: $filters) {
      id
      filename
      url
      mimetype
      template {
        id
        label
      }
    }
  }
`;

export const createTemplate = gql`
  mutation createFileTemplate($input: CreateFileInput!) {
    createFile(input: $input) {
      clientMutationId
      file {
        id
        url
        template {
          id
          label
        }
      }
    }
  }
`;

const DocumentRow = ({ name, id, type, isLast, isFirst }) => {
  const [
    generateSignedUrl,
    { loading: loadingGenerateSignedUrl },
  ] = useMutation(GENERATE_SIGNED_URL);
  const [createFile, { loading: loadingCreateFile }] = useMutation(
    createTemplate,
  );
  const [deleteFileMutation, { loading: loadingDeleteFile }] = useMutation(
    DELETE_FILE,
  );
  const [isUploading, setIsUploading] = useState(false);
  const { data, loading: loadingFetchFile, error, refetch } = useQuery(
    getTemplateFiles,
    {
      variables: {
        filters: {
          fileTemplateType_in: [type],
        },
      },
    },
  );
  const { showModal, hideModal } = useFeedback();
  const [showProgress, setShowProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const { t } = useTranslation();

  const { fileIncludeTemplates = [] } = data || {};

  const [uploadedFile] = fileIncludeTemplates;

  const isLoading =
    loadingFetchFile ||
    loadingDeleteFile ||
    loadingCreateFile ||
    isUploading ||
    loadingGenerateSignedUrl;

  const onDownload = () => {
    if (!uploadedFile) return;

    downloadFilesAsZip(
      [
        {
          ...uploadedFile,
          url: uploadedFile.url.split('?')[0],
          fileName: uploadedFile.filename,
        },
      ],
      uploadedFile?.filename?.split('.')[0]
        ? `${name} - ${uploadedFile?.filename?.split('.')[0]}`
        : `${name} template`,
      percent => {
        if (!percent && percent !== 0) {
          return;
        }
        setProgressValue(percent);
        if (percent < 100) {
          setShowProgress(true);
        } else {
          setTimeout(() => {
            setShowProgress(false);
            setProgressValue(0);
          }, 500);
        }
      },
    );
  };

  const uploadFile = async f => {
    if (fileIncludeTemplates.length > 0) {
      const result = await Promise.all(
        fileIncludeTemplates.map(file =>
          deleteFileMutation({
            variables: {
              input: { id: file.id },
            },
          }),
        ),
      );
    }

    await generateSignedUrl({
      variables: {
        name: f.file.name,
        type: f.file.type,
        bucket: 'files',
      },
      onCompleted(res) {
        const signedUrl = res?.generateSignedUrl?.url;
        setIsUploading(true);
        fetch(signedUrl, {
          method: 'PUT',
          body: f.file,
          headers: {
            'Content-Type': f.file.type,
          },
        }).then(
          ({ status, url }) => {
            if (status !== 200) throw new Error('Upload file error');
            createFile({
              variables: {
                input: {
                  url: url,
                  filename: f.file.name,
                  size: f.file.size,
                  mimetype: f.file.type,
                  templateId: id,
                },
              },
              onCompleted: async () => {
                refetch && (await refetch());
                setIsUploading(false);
              },
              onError: () => {},
            });
          },
          error => {},
        );
      },
      onError: _err => {},
    });
  };

  const onSubmitDialog = files => {
    files.forEach(f => {
      uploadFile(f);
    });
    hideModal();
  };

  const onShowUploadModal = () => {
    showModal(
      <UploadDocumentForm
        hideModal={hideModal}
        multiple={false}
        onSubmit={onSubmitDialog}
      />,
      {
        contentWidth: 650,
        contentMinHeight: 0,
        title: name,
        hasCloseButton: false,
        titleIcon: DocumentLargeIcon,
      },
    );
  };

  return (
    <StyledDocumentRow isFirst={isFirst} isLast={isLast}>
      {showProgress && <DownloadProgress value={progressValue} />}
      <Box className="left">
        <DragHandleIcon />
        <Box>
          <AttachmentIcon className="drag-icon" />
        </Box>
        <Box className="name">{name}</Box>
      </Box>
      <Box className="right">
        {fileIncludeTemplates.length === 0 && !isLoading && (
          <Button className="add-document-btn" onClick={onShowUploadModal}>
            <AddIcon color="primary" />
            {t('settings:addTemplate')}
          </Button>
        )}
        {fileIncludeTemplates.length > 0 && uploadedFile && !isLoading && (
          <>
            <Box>{uploadedFile.filename || ''}</Box>
            <Button onClick={onShowUploadModal} style={{ cursor: 'pointer' }}>
              <EditIcon />
            </Button>
            <Button style={{ cursor: 'pointer' }} onClick={onDownload}>
              <DownloadIcon />
            </Button>
          </>
        )}
        {isLoading && <CircularProgress size={20} />}
      </Box>
    </StyledDocumentRow>
  );
};

const TemplatesUpload = ({ isWide }) => {
  const { data, loading, error } = useQuery(getTemplateList);
  const { t, i18n } = useTranslation();

  if (loading || error) return null;

  return (
    <Root data-fixed={isWide}>
      <CardTitle>{t('settings:fileTemplates')}</CardTitle>
      <Box className="wrapper">
        {data &&
          data.fileTemplates &&
          data.fileTemplates.length > 0 &&
          data.fileTemplates.map((document, index) => (
            <DocumentRow
              isLast={index === data.fileTemplates.length - 1}
              isFirst={index === 0}
              key={document.id}
              name={
                document[
                  `label${i18n.language.charAt(0).toUpperCase() +
                    i18n.language.slice(1)}`
                ]
              }
              {...document}
            />
          ))}
      </Box>
    </Root>
  );
};

export default TemplatesUpload;
