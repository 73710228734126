import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createRegion = makeCreateDictionaryMutation(
  graphql`
    mutation regionsCreateRegionMutation($input: CreateRegionInput!) {
      createRegion(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createRegion',
  'regions',
);

export const updateRegion = makeUpdateDictionaryMutation(
  graphql`
    mutation regionsUpdateRegionMutation($input: UpdateRegionInput!) {
      updateRegion(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateRegion',
  'regions',
);

export const deleteRegion = makeDeleteDictionaryMutation(
  graphql`
    mutation regionsDeleteRegionMutation($input: DeleteRegionInput!) {
      deleteRegion(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteRegion',
  'regions',
);
