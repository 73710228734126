/**
 * @flow
 * @relayHash ab061af220fff739c80451c3a4029461
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserProfileForm_user$ref = any;
export type UpdateUserInput = {|
  id: string,
  organisationId?: ?string,
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  language?: ?string,
  gender?: ?string,
  jobTitle?: ?string,
  mobilePhone?: ?string,
  officePhone?: ?string,
  street?: ?string,
  streetNr?: ?string,
  postcode?: ?string,
  city?: ?string,
  country?: ?string,
  isSubscribed?: ?boolean,
  isActive?: ?boolean,
  isAdmin?: ?boolean,
  proofRead?: ?boolean,
  proofReadDate?: ?any,
  isSuperAdmin?: ?boolean,
  primaryImageId?: ?string,
  linkedinUrl?: ?string,
  ownerId?: ?string,
  isInvestor?: ?boolean,
  isSeller?: ?boolean,
  isBroker?: ?boolean,
  isSupervisor?: ?boolean,
  latitude?: ?number,
  longitude?: ?number,
  address?: ?string,
  clientMutationId?: ?string,
|};
export type UserProfileFormUpdateUserMutationVariables = {|
  input: UpdateUserInput
|};
export type UserProfileFormUpdateUserMutationResponse = {|
  +updateUser: ?{|
    +user: ?{|
      +$fragmentRefs: UserProfileForm_user$ref
    |}
  |}
|};
export type UserProfileFormUpdateUserMutation = {|
  variables: UserProfileFormUpdateUserMutationVariables,
  response: UserProfileFormUpdateUserMutationResponse,
|};
*/


/*
mutation UserProfileFormUpdateUserMutation(
  $input: UpdateUserInput!
) {
  updateUser(input: $input) {
    user {
      ...UserProfileForm_user
      id
    }
  }
}

fragment UserProfileForm_user on User {
  id
  firstName
  lastName
  fullName
  isSubscribed
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserProfileFormUpdateUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "UserProfileForm_user",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserProfileFormUpdateUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isSubscribed",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UserProfileFormUpdateUserMutation",
    "id": null,
    "text": "mutation UserProfileFormUpdateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      ...UserProfileForm_user\n      id\n    }\n  }\n}\n\nfragment UserProfileForm_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  isSubscribed\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82f73bafc018ffa600c7ae06cace7800';
module.exports = node;
