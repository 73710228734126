import React from 'react';
import styled from 'styled-components';
import {
  AppBar,
  TextField,
  FormControl,
  InputAdornment,
  Box,
} from '@material-ui/core';
import SearchIcon from '../../../../../components/Icons/SearchIcon';
import CircleNameIcon from '../../../../../components/Icons/CircleNameIcon';
import InputNumber from '../../../controls/InputNumber';
// import {
//   PercentInput,
// } from '../../../controls/percent-input';
import InputNumberCustom from '../../../controls/InputNumberCustom';
import ClearIcon from '@material-ui/icons/Clear';
import { get } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

const maxContentWidth = 1214;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100vh - 64px);
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  background: #fbfcfe;
  * {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`;

export const PageHeader = styled.div`
  /* box-shadow: 0px 3px 10px rgba(40, 99, 166, 0.15); */
  padding: 0;
  background: #ffffff;
  .innerContent {
    max-width: ${maxContentWidth}px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const PageTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #333e48;
  padding: 0;
  margin: 4px 0 0;
`;

export const ReturnToDeals = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3455d1;
  display: flex;
  margin-top: 12px;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const MainContentWrapper = styled.div`
  max-width: ${maxContentWidth}px;
  width: 100%;
  margin: 32px auto;
  display: flex;
  gap: 0px;
  align-items: flex-start;
  .mainLeftContent {
    max-width: 838px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(40, 99, 166, 0.15);
    border-radius: 20px;
    padding: 24px;
  }
  .mainRightContent {
    max-width: 374px;
    width: 100%;
    border-radius: 20px;
    .dealMap {
      max-width: 100%;
      margin-left: 20px;
      margin-top: -6px;
      margin-bottom: 6px;
    }
    .mainRightInnerContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 0 32px;
      gap: 24px;
      .googleMapWrapper {
        height: 150px;
        width: 100%;
        & > div > div {
          border-radius: 20px;
          filter: drop-shadow(0 8px 20px rgba(40, 99, 166, 0.08));
        }
      }
      .sellerInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        gap: 16px;
        width: 100%;
        width: 344px;
        height: 321.33px;
        background: #edf5fe;
        border-radius: 20px;
        .sellerInfoTitleBlock {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 12px;
          width: 100%;
          height: 37.33px;
          padding-bottom: 16px;
          border-bottom: 2px solid #d6dfea;
          img {
            width: 21.33px;
            height: 21.33px;
          }
          h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #333e48;
          }
        }
        .phoneBlock {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          width: 100%;
          height: 16px;
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-decoration-line: underline;
            color: #3455d1;
            word-break: break-all;
          }
          .phoneBlockSubGroup1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
          }
          .copy {
            cursor: pointer;
          }
        }
      }
      .appraisal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 16px;
        width: 100%;
        height: 232px;
        background: #edf5fe;
        border-radius: 20px;
        .appraisalTitleBlockImage {
          width: 13.33px;
          height: 24px;
        }
        .appraisalTitleBlock {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 12px;
          width: 100%;
          height: 40px;
          padding-bottom: 16px;
          border-bottom: 2px solid #d6dfea;
          h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #333e48;
          }
        }
        .viewChabotAnswers {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 6px;
          height: 16px;
          span {
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #3455d1;
          }
          img {
            width: 8px;
          }
        }
        .appraisalSliderBlock {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          width: 100%;
          height: 48px;
          .appraisalSliderGuide {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 100%;
            height: 32px;
            > div {
              display: flex;
              flex-direction: column;
              padding: 0px;
              width: 50%;
              height: 32px;
              span {
                font-style: normal;
                font-size: 14px;
                line-height: 16px;
                &:first-child {
                  font-weight: 700;
                  color: #333e48;
                }
                &:last-child {
                  font-weight: 400;
                  color: #96a4b4;
                }
              }
            }
            .appraisalSliderGuideFirstCol {
              align-items: flex-start;
            }
            .appraisalSliderGuideSecondCol {
              align-items: flex-end;
            }
          }
          .MuiSlider-root {
            height: 8px;
            padding: 0;
            * {
              border-radius: inherit !important;
            }
          }
          .MuiSlider-thumb {
            background: #3455d1;
            padding: 0;
            width: 2px;
            margin-right: -6px;
            margin-left: 0;
            border-radius: inherit;
            .&MuiSlider-active {
              box-shadow: none;
            }
          }
          .MuiSlider-track {
            border-radius: inherit;
            background: none;
            height: 0;
          }
          .MuiSlider-rail {
            border-top: 2px dashed #3455d1;
            opacity: 1;
            height: 0;
            background: none;
            border-radius: inherit;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .mainLeftContent {
      flex-basis: 70%;
      width: auto;
    }
    .mainRightContent {
      width: auto;
      flex-basis: 30%;
    }
  }
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    .mainLeftContent {
      flex: 100%;
      width: 100%;
    }
    .mainRightContent {
      width: 100%;
      flex-basis: 100%;
      margin-top: 30px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      .mainRightInnerContent {
        padding-left: 0;
      }
    }
  }
`;

export const TabsAppBar = styled(AppBar).attrs({
  position: 'static',
  component: 'nav',
})`
  z-index: 2;
  background: #ffffff;
  margin-top: 4px;
  box-shadow: none;
  position: fixed;
  box-shadow: 0px 3px 10px rgb(40 99 166 / 15%);
  padding: 0px 64px;
  .MuiTabs-root {
    min-height: auto;
  }
  .MuiButtonBase-root {
    padding: 0;
    margin: 0 48px 0 0;
    min-width: auto;
    max-width: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333e48;
    height: auto;
    min-height: auto;
    letter-spacing: normal;
    padding-bottom: 12px;
    span {
      padding: 0;
      margin: 0;
    }
  }
  .MuiTabs-indicator {
    padding: 10px 5px;
    border-radius: 5px;
    margin-left: 0;
    height: 0;
    bottom: -16px;
    background: #3455d1;
  }
`;

export const RadiusTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  min-width: 453px;
  height: 43px;
  padding-bottom: 12px;
  border-bottom: 2px solid #edf5fe;
  .tabTitle {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    height: 31px;
    background: #ffffff;
    border-radius: 36px;
    div {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #3455d1;
    }
    &.validatedErrorTab {
      background: red;
      div {
        color: #ffffff;
      }
    }
    &.selectedTab {
      background: #3455d1;
      border-radius: 36px;
      div {
        color: #ffffff;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
`;

const roundedInputTextRoot = `
  height: 48px;
  background: #FBFCFE;
  border: 1px solid #D6DFEA;
  border-radius: 10px;
  padding: 7px 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const roundedInputTextLabelRoot = `
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #333E48;
  opacity: 0.5;
  transform: translate(12px, 20px) scale(1);
  position: static;
  transform: none;
  &.MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: none;
    color: #333E48;
  }
  padding-left: 0;
`;

const roundedInputTextFilledInputRoot = `
  .MuiFilledInput-input {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333E48;
    height: auto;
    text-align: left;
  }
`;

export const TextFieldWrapper = styled(TextField)`
  ${roundedInputTextRoot}
  label {
    ${roundedInputTextLabelRoot}
  }
  .MuiInputBase-root,
  .MuiFilledInput-root {
    background: none;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  ${roundedInputTextFilledInputRoot}
  &.multipleLines {
    height: 102px;
    .MuiFilledInput-multiline {
      padding: 0;
      min-height: 72px;
      .MuiFilledInput-input {
        height: 72px !important;
      }
    }
  }
`;

export const TextFieldNumberWrapper = styled(InputNumber)`
  ${roundedInputTextRoot}
  label {
    ${roundedInputTextLabelRoot}
    &.MuiInputLabel-shrink {
      padding-top: 0;
    }
    &.Mui-focused {
      padding-left: 0;
      opacity: 0.5 !important;
    }
  }
  .MuiInputBase-root,
  .MuiFilledInput-root {
    background: none;
    text-align: left;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  ${roundedInputTextFilledInputRoot}
`;

export const InputNumberCustomWrapper = styled(InputNumberCustom)`
  ${roundedInputTextRoot}
  label {
    ${roundedInputTextLabelRoot}
  }
  .MuiInputBase-root,
  .MuiFilledInput-root {
    background: none;
    text-align: left;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  ${roundedInputTextFilledInputRoot}
  .MuiFilledInput-input:read-only {
    color: #96a4b4;
    cursor: default;
  }
`;

export const TextFieldAddressSearchWrapper = styled(TextField)`
  ${roundedInputTextRoot}
  label {
    ${roundedInputTextLabelRoot}
  }
  .MuiInputBase-root,
  .MuiFilledInput-root {
    background: none;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  ${roundedInputTextFilledInputRoot}
  .MuiFilledInput-adornedEnd {
    padding-right: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-top: -10px;
  }
`;

export const Mt32 = styled.div`
  height: 32px;
  width: 100%;
`;
export const Mt12 = styled.div`
  height: 12px;
  width: 100%;
`;

export const RowInput2Cols = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  min-height: 48px;
  > * {
    flex: 50%;
  }
  &.verticalCenter {
    align-items: center;
  }
  .w100p {
    width: 100%;
  }
  &.h24px {
    height: 24px;
  }
`;

export const RowInput1Of2Cols = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 48px;
  > * {
    width: calc(50% - 6px);
  }
`;

const FormControlSelectWrapperRoot = `
  display: flex;
  flex-direction: column;
  padding: 7px 16px;
  gap: 2px;
  width: 100%;
  height: 48px;
  background: #FBFCFE;
  border: 1px solid #D6DFEA;
  border-radius: 75px;
`;
const FormControlSelectWrapperInput = `
  .MuiSelect-root, .MuiFilledInput-input, .MuiFilledInput-input {
    padding-right: 40px;
  }
  .MuiInputBase-root, .MuiFilledInput-root, .MuiFilledInput-input {
    background: none !important;
  }
  .MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  .MuiFilledInput-input {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333E48;
    height: auto;
  }
`;
export const FormControlSelectWrapper = styled(FormControl)`
  ${FormControlSelectWrapperRoot}
  .MuiSvgIcon-root {
    width: auto;
    height: auto;
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #333e48;
    opacity: 0.5;
    transform: translate(12px, 20px) scale(1);
    position: static;
    transform: none;
    &.MuiInputLabel-filled.MuiInputLabel-shrink,
    &.MuiFormLabel-root.Mui-focused {
      transform: none;
      color: #333e48;
    }
  }
  ${FormControlSelectWrapperInput}
  .MuiSelect-icon, .MuiSelect-iconFille {
    right: 0;
  }
`;

export const FormControlSelectNoLabelWrapper = styled(FormControl)`
  ${FormControlSelectWrapperRoot}
  .MuiSvgIcon-root {
    width: auto;
    height: auto;
  }
  label {
    display: none;
  }
  ${FormControlSelectWrapperInput}
  .MuiSelect-icon, .MuiSelect-iconFille {
    right: 0;
  }
  .MuiInputBase-root {
    padding-top: 7px;
  }
  .MuiSelect-icon {
    top: calc(50% - 2px);
  }
`;

export const DealManagerTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333e48;
  padding-bottom: 8px;
  border-bottom: 2px solid #edf5fe;
  height: 27px;
`;

export const getSuffixSearchForInput = (
  startValue = null,
  callBackClear = false,
  options = {},
) => {
  const hasStartValue = startValue && startValue.toString();
  return {
    startAdornment: hasStartValue && (
      <InputAdornment position="start">
        <CircleNameIcon name={startValue.toString()} />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        {!hasStartValue || get(options, 'alwayShowSearchIcon', true) ? (
          <div
            style={{ display: 'block' }}
            onClick={e => {
              const inputEle = e.target
                .closest('.MuiInputBase-root')
                .getElementsByTagName('input')[0];
              inputEle.click();
            }}
          >
            <SearchIcon />
          </div>
        ) : (
          <ClearIcon
            className="clearUserSvgWrapper"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (callBackClear !== false) {
                callBackClear();
              }
            }}
          />
        )}
      </InputAdornment>
    ),
  };
};

export const UserInputSeachIconWrapper = styled.div`
  width: 100%;
  ${roundedInputTextRoot};
  .MuiFormControl-root {
    padding: 0;
  }
  label {
    ${roundedInputTextLabelRoot}
  }
  .MuiSelect-root,
  .MuiFilledInput-input,
  .MuiFilledInput-input {
    padding-right: 40px;
  }
  .MuiInputBase-root,
  .MuiFilledInput-root,
  .MuiFilledInput-input {
    background: none !important;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  .MuiFilledInput-input {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333e48;
    height: auto;
  }
  .MuiSelect-icon,
  .MuiSelect-iconFille {
    right: 0;
  }
  .MuiFilledInput-adornedEnd {
    padding-right: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.54);
    svg {
      margin-top: -3px;
      &.clearUserSvgWrapper {
        margin-top: -11px;
      }
    }
  }
  .MuiInputAdornment-positionStart {
    margin-right: 4px;
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
  }
`;

export const UserMultiInputSeachIconWrapper = styled.div`
  width: 100%;
  ${roundedInputTextRoot};
  height: auto;
  min-height: 48px;
  .MuiFormControl-root {
    padding: 0;
  }
  label {
    ${roundedInputTextLabelRoot}
  }
  .MuiSelect-root,
  .MuiFilledInput-input,
  .MuiFilledInput-input {
    padding-right: 40px;
  }
  .MuiInputBase-root,
  .MuiFilledInput-root,
  .MuiFilledInput-input {
    background: none !important;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  .MuiFilledInput-input {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333e48;
    height: auto;
  }
  .MuiSelect-icon,
  .MuiSelect-iconFille {
    right: 0;
  }
  .MuiFilledInput-adornedEnd {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    svg {
      margin-top: -3px;
    }
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
    .MuiAutocomplete-input {
    padding: 0;
  }
  .MuiAutocomplete-popupIndicator {
    margin-top: -10px;
  }
  .MuiAutocomplete-popupIndicatorOpen {
    transform: none;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .MuiIconButton-root:hover {
    background: none;
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
    .MuiAutocomplete-endAdornment {
    right: 0;
  }
  .MuiAutocomplete-tag,
  .MuiChip-root .MuiChip-avatar {
    height: 16px;
    margin-left: 0;
  }
  .MuiChip-root .MuiChip-avatar {
    width: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 8px;
    text-align: center;
    color: #ffffff !important;
    background-color: #bdbcff !important;
  }
  .MuiChip-label {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333e48;
    height: auto;
    margin-left: 9px;
  }
  .MuiChip-deleteIcon {
    width: 16px;
    height: 16px;
    margin-right: 0;
    margin-left: 5px;
  }
  .MuiFilledInput-adornedEnd svg {
    margin-top: 0;
  }
  .MuiChip-outlined {
    border: 1px solid #d6dfea;
    background-color: transparent;
  }
`;

export const UserInputNoLabelSeachIconWrapper = styled.div`
  ${roundedInputTextRoot};
  .MuiFormControl-root {
    padding: 8px 0 8px;
  }
  label {
    display: none;
  }
  .MuiSelect-root,
  .MuiFilledInput-input,
  .MuiFilledInput-input {
    padding-right: 40px;
  }
  .MuiInputBase-root,
  .MuiFilledInput-root,
  .MuiFilledInput-input {
    background: none !important;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  .MuiFilledInput-input {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #333e48;
    height: auto;
  }
  .MuiSelect-icon,
  .MuiSelect-iconFille {
    right: 0;
  }
  .MuiFilledInput-adornedEnd {
    padding-right: 0;
    padding-left: 0;
    svg {
      margin-top: 4px;
    }
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
  }
`;

export const SaveCancelButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
  height: 32px;
  filter: drop-shadow(0px 5px 20px rgba(40, 99, 166, 0.15));
  border-radius: 45px;
`;

export const SaveChangesButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 6px;
  height: 32px;
  background: #3455d1;
  border-radius: 93px;
  cursor: pointer;
  div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
`;

export const CancelButton = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #3455d1;
`;

export const UserTypeAutocompleteWrapper = styled(Autocomplete)`
  width: 100%;
  .MuiFormControl-root {
    ${FormControlSelectWrapperRoot}
  }
  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
  .MuiAutocomplete-popupIndicator {
    .MuiSvgIcon-root {
      width: 14px;
      height: 8px;
    }
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #333e48;
    opacity: 0.5;
    transform: translate(12px, 20px) scale(1);
    position: static;
    transform: none;
    &.MuiInputLabel-filled.MuiInputLabel-shrink,
    &.MuiFormLabel-root.Mui-focused {
      transform: none;
      color: #333e48;
    }
  }
  ${FormControlSelectWrapperInput}
  .MuiSelect-icon, .MuiSelect-iconFille {
    right: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 0;
    padding-left: 0;
    .MuiAutocomplete-input {
      padding: 0;
    }
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 22px);
    right: 0;
    .MuiAutocomplete-popupIndicator,
    .MuiAutocomplete-clearIndicator {
      background: none;
    }
    .MuiAutocomplete-clearIndicator {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
    .MuiAutocomplete-endAdornment {
    right: 0;
  }
`;

export const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const FormControlSwitchLabelWrapper = styled(FormControlLabel)`
  width: auto;
  margin-left: 0;
  transform: translateX(-9px);
  .MuiSwitch-thumb {
    width: 18px;
    height: 18px;
    background: #ffffff;
    box-shadow: none;
  }
  .MuiSwitch-root {
    width: 48px;
    height: 24px;
    margin: 0 8px;
  }
  .MuiSwitch-switchBase {
    padding: 0;
    top: 3px;
    left: 3px;
  }
  .MuiSwitch-track {
    width: 48px;
    height: 24px;
    background: #d6dfea;
    border-radius: 100px;
    border: 0;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    transform: translateX(25px);
  }
  .MuiFormControlLabel-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333e48;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background: #3455d1;
  }
`;

export const KeyboardTimePickerWrapper = styled(KeyboardTimePicker)`
  ${roundedInputTextRoot}
  .MuiIconButton-root {
    padding: 0;
    background: none;
  }
  .MuiSvgIcon-root {
    width: 20px;
    height: 22px;
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #333e48;
    opacity: 0.5;
    transform: translate(12px, 20px) scale(1);
    position: static;
    transform: none;
    &.MuiInputLabel-filled.MuiInputLabel-shrink,
    &.MuiFormLabel-root.Mui-focused {
      transform: none;
      color: #333e48;
    }
  }
  ${FormControlSelectWrapperInput}
  .MuiSelect-icon, .MuiSelect-iconFille {
    right: 0;
  }
  .MuiInputBase-root {
    padding-right: 0;
  }
  .MuiInputAdornment-positionEnd {
    align-content: flex-end;
    justify-content: end;
    margin-top: -13px;
    margin-right: 2px;
  }
  .MuiFormHelperText-root.Mui-error {
    display: none !important;
  }
`;

export const KeyboardDatePickerWrapper = styled(KeyboardDatePicker)`
  ${roundedInputTextRoot}
  .MuiIconButton-root {
    padding: 0;
    background: none;
  }
  .MuiSvgIcon-root {
    width: 20px;
    height: 22px;
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #333e48;
    opacity: 0.5;
    transform: translate(12px, 20px) scale(1);
    position: static;
    transform: none;
    &.MuiInputLabel-filled.MuiInputLabel-shrink,
    &.MuiFormLabel-root.Mui-focused {
      transform: none;
      color: #333e48;
    }
  }
  ${FormControlSelectWrapperInput}
  .MuiSelect-icon, .MuiSelect-iconFille {
    right: 0;
  }
  .MuiInputBase-root {
    padding-right: 0;
  }
  .MuiInputAdornment-positionEnd {
    align-content: flex-end;
    justify-content: end;
    margin-top: -13px;
    margin-right: 2px;
  }
  .MuiFormHelperText-root.Mui-error {
    display: none !important;
  }
`;

export const RichTextWrapper = styled.div`
  ${roundedInputTextRoot}
  height: 174px;
  label {
    ${roundedInputTextLabelRoot}
  }
  .jodit-container,
  .jodit-wysiwyg,
  .jodit-container:not(.jodit_inline) .jodit-workplace {
    max-height: 135px !important;
    min-height: 135px !important;
  }
  .jodit-container:not(.jodit_inline),
  .jodit-toolbar__box:not(:empty) {
    border: 0;
  }
  .jodit-container {
    margin-top: 5px;
    .jodit-workplace {
      max-height: 100px !important;
      min-height: auto !important;
      .jodit-wysiwyg {
        max-height: 95px !important;
        min-height: auto !important;
        padding: 8px 0;
        ul,
        p {
          margin: 0;
          padding: 0 20px;
        }
        p {
          padding: 0;
        }
      }
    }
    .jodit-add-new-line {
      display: none;
    }
  }
  .jodit-status-bar {
    display: none;
  }
  .jodit-toolbar-editor-collection {
    .jodit-toolbar-button {
      display: none;
      &[ref='ul'] {
        display: inline-block;
      }
    }
  }
  .jodit-ui-separator {
    display: none;
  }
`;

export const CommissionSplitsWrapper = styled(Box)`
  border: 1px solid #d6dfea;
  border-radius: 20px;
  padding: 12px;

  .title {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333e48;
    text-align: center;
  }
`;
