// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
} from '@material-ui/core';
import IconSearch from '@material-ui/icons/Search';

import type {
  Field,
  UrlSearchParamsHook,
} from '../../../hooks/url-search-params';
import {
  makeField,
  makeUrlSearchParamsHook,
} from '../../../hooks/url-search-params';
import { Filter, FilterV2, FilterV3 } from '../../../controls/filter';
import { Box, Flex } from 'react-system';
import { graphql, useQuery } from '../../../controls/relay';
// import { useDebouncedHandler } from '../../../hooks/debounce';
import { UserAvatar } from '../../../components/UserAvatar';
import { useDebouncedHandler } from '../../../hooks/debounce';
import { tableNumberFormat } from '../utils/formatNumber';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';

import type {
  DealFilters,
  DEAL_CONFIDENCE_TYPE_ENUM,
} from './__generated__/DealsQuery.graphql';
import type { dealsFiltersUsersQuery } from './__generated__/dealsFiltersUsersQuery.graphql';
import type { dealsFiltersOrganisationsQuery } from './__generated__/dealsFiltersOrganisationsQuery.graphql';
import type { dealsFiltersDealStatusQuery } from './__generated__/dealsFiltersDealStatusQuery.graphql';
import type { dealsFiltersDealPipelineStagesQuery } from './__generated__/dealsFiltersDealPipelineStagesQuery.graphql';
import type { dealsFiltersRegionsQuery } from './__generated__/dealsFiltersRegionsQuery.graphql';
import type { dealsFiltersUseTypesQuery } from './__generated__/dealsFiltersUseTypesQuery.graphql';
import type { dealsFiltersExpectedPriceQuery } from './__generated__/dealsFiltersExpectedPriceQuery.graphql';
import type { dealsFiltersCommissionQuery } from './__generated__/dealsFiltersCommissionQuery.graphql';
import { InputNumberCustomWrapper } from '../Deals/Deal/styles/DealDetails';
import {
  ConfidenceRow,
  ReliabilityOptionIcons,
} from '../controls/StyledCommon';
import { get, toString } from 'lodash';
import DateTimePickerIcon from 'components/Icons/DatePickerIcon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from 'components/Icons/CloseIcon';

const reliabilityOptionIcons = ReliabilityOptionIcons;

const StyledCheckbox = styled(Checkbox)`
  padding: 3px 8px 3px 0px;
  .MuiSvgIcon-root {
    fill: ${props => (props.checked ? `#3455D1` : `#d6dfea`)};
  }
`;

const StyledFilterItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333e48;
  display: flex;
  gap: 8px;
  align-items: center;
  .circle-img {
    border-radius: 16px;
    width: 16px;
    height: 16px;
    font-size: 7px;
    font-weight: 500;
  }
`;

const StyledSearchControl = styled.div`
  position: relative;
  padding-bottom: 12px;
  margin: 0px 12px;
  border-bottom: 2px solid #edf5fe;
  input {
    background: #fbfcfe;
    border: 1px solid #d6dfea;
    border-radius: 10px;
    width: 176px;
    height: 32px;
    padding: 0px 8px 0px 30px;
    &:focus {
      outline: none;
    }
  }
  .search-icon {
    position: absolute;
    top: 7px;
    width: 17px;
    left: 9px;
    fill: #96a4b4;
  }
`;

const deal_confidence_type_of_string = (string): DEAL_CONFIDENCE_TYPE_ENUM => {
  switch (string) {
    case 'high':
    case 'medium':
    case 'low':
      return string;
    default:
      throw new Error(`unsupported DEAL_CONFIDENCE_TYPE_ENUM: ${string}`);
  }
};

const makeNullableListField = name => {
  return makeField<$ReadOnlyArray<string> | null>({
    get: params => {
      const list = params.getAllStrings(name);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings(name, value || []),
  });
};

const makeNullableNumberField = name => {
  return makeField<number | null>({
    get: params => params.getNumber(name),
    set: (params, value) => params.setNumber(name, value),
  });
};

const makeNullableStringField = name => {
  return makeField<string | null>({
    get: params => params.getString(name),
    set: (params, value) => params.setString(name, value),
  });
};

export const useDealsLeadsParams: UrlSearchParamsHook<{|
  sortBy: Field<string | null>,
  sortDirection: Field<'ASC' | 'DESC' | null>,

  brokerId_in: Field<$ReadOnlyArray<string> | null>,
  leaderBrokerId_in: Field<$ReadOnlyArray<string> | null>,
  sellerId_in: Field<$ReadOnlyArray<string> | null>,
  dealStatusId_in: Field<$ReadOnlyArray<string> | null>,
  confidence_in: Field<$ReadOnlyArray<DEAL_CONFIDENCE_TYPE_ENUM> | null>,
  dealPipelineStageId_in: Field<$ReadOnlyArray<string> | null>,
  regionId_in: Field<$ReadOnlyArray<string> | null>,
  useTypeId_in: Field<$ReadOnlyArray<string> | null>,
  expectedSalePrice_gte: Field<number | null>,
  expectedSalePrice_lte: Field<number | null>,
  potentialCommission_gte: Field<number | null>,
  potentialCommission_lte: Field<number | null>,
  signatureQuarter_in: Field<$ReadOnlyArray<string> | null>,
  commissionQuarter_in: Field<$ReadOnlyArray<string> | null>,
  estimateMonthOfSignature_in: Field<$ReadOnlyArray<string> | null>,
  estimateMonthOfPayment_in: Field<$ReadOnlyArray<string> | null>,
  signatureYear_in: Field<$ReadOnlyArray<string> | null>,
  cities: Field<$ReadOnlyArray<string> | null>,
|}> = makeUrlSearchParamsHook({
  sortBy: makeNullableStringField('sortBy'),
  sortDirection: makeField<'ASC' | 'DESC' | null>({
    get: params =>
      params.getString('sortDirection') === 'DESC' ? 'DESC' : 'ASC',
    set: (params, value) => params.setString('sortDirection', value),
  }),

  brokerId_in: makeNullableListField('brokerId_in'),
  leaderBrokerId_in: makeNullableListField('leaderBrokerId_in'),
  sellerId_in: makeNullableListField('sellerId_in'),
  dealStatusId_in: makeNullableListField('dealStatusId_in'),
  confidence_in: makeField<$ReadOnlyArray<DEAL_CONFIDENCE_TYPE_ENUM> | null>({
    get: params =>
      params
        .getAllStrings('confidence_in')
        .map(deal_confidence_type_of_string)
        .filter(Boolean),
    set: (params, value) => params.setAllStrings('confidence_in', value ?? []),
  }),
  dealPipelineStageId_in: makeNullableListField('dealPipelineStageId_in'),
  regionId_in: makeNullableListField('regionId_in'),
  useTypeId_in: makeNullableListField('useTypeId_in'),
  expectedSalePrice_gte: makeNullableNumberField('expectedSalePrice_gte'),
  expectedSalePrice_lte: makeNullableNumberField('expectedSalePrice_lte'),
  potentialCommission_gte: makeNullableNumberField('potentialCommission_gte'),
  potentialCommission_lte: makeNullableNumberField('potentialCommission_lte'),
  signatureQuarter_in: makeNullableListField('signatureQuarter_in'),
  signatureYear_in: makeNullableListField('signatureYear_in'),
  commissionQuarter_in: makeNullableListField('commissionQuarter_in'),
  estimateMonthOfSignature_in: makeNullableListField(
    'estimateMonthOfSignature_in',
  ),
  estimateMonthOfPayment_in: makeNullableListField('estimateMonthOfPayment_in'),
  cities: makeNullableListField('cities'),
});

type Params = $ElementType<$Call<typeof useDealsLeadsParams>, 0>;

export const paramsToFilters = (params: Params): DealFilters => ({
  brokerId_in: params.brokerId_in,
  leaderBrokerId_in: params.leaderBrokerId_in,
  sellerId_in: params.sellerId_in,
  dealStatusId_in: params.dealStatusId_in,
  confidence_in: params.confidence_in,
  dealPipelineStageId_in: params.dealPipelineStageId_in,
  regionId_in: params.regionId_in,
  useTypeId_in: params.useTypeId_in,
  expectedSalePrice_gte: params.expectedSalePrice_gte,
  expectedSalePrice_lte: params.expectedSalePrice_lte,
  potentialCommission_gte: params.potentialCommission_gte,
  potentialCommission_lte: params.potentialCommission_lte,
  signatureQuarter_in: params.signatureQuarter_in,
  commissionQuarter_in: params.commissionQuarter_in,
  estimateMonthOfSignature_in: params.estimateMonthOfSignature_in
    ? params.estimateMonthOfSignature_in.map(v => v)
    : params.estimateMonthOfSignature_in,
  signatureYear_in: params.signatureYear_in
    ? params.signatureYear_in.map(v => +v)
    : params.signatureYear_in,
  estimateMonthOfPayment_in: params.estimateMonthOfPayment_in
    ? params.estimateMonthOfPayment_in.map(v => v)
    : params.estimateMonthOfPayment_in,
  cities: params.cities,
});

const toggleFilterItem = (set, item) => {
  const newSet = new Set(set);
  if (newSet.has(item)) {
    newSet.delete(item);
  } else {
    newSet.add(item);
  }
  const list = Array.from(newSet);
  return list.length === 0 ? null : list;
};

const getLabel = (nodes, ids, idleLabel) => {
  if (ids.size === 0) {
    return idleLabel;
  }
  const firstId = Array.from(ids)[0];
  // $FlowFixMe
  const first = nodes.find(d => d.id === firstId);
  if (first && first.label != null) {
    return ids.size > 1 ? `${first.label} +${ids.size - 1}` : first.label;
  }
  return '...';
};

const getCitiesLabel = (nodes, cities, idleLabel) => {
  if (cities.size === 0) {
    return idleLabel;
  }
  const [firstCity] = Array.from(cities);
  // $FlowFixMe
  const first = nodes.find(d => d.name === firstCity);
  if (first && first.name != null) {
    return cities.size > 1 ? `${first.name} +${cities.size - 1}` : first.name;
  }
  return '...';
};
const getYearLabel = (nodes, years, idleLabel) => {
  if (years.size === 0) {
    return idleLabel;
  }
  const [firstYear] = Array.from(years);
  // $FlowFixMe
  const first = nodes.find(d => d.year === +firstYear);
  if (first) {
    return years.size > 1 ? `${first.year} +${years.size - 1}` : first.year;
  }
  return '...';
};

const NormalFilterContent = ({ nodes, selectedIds, onClick }) => (
  <Box
    width="200px"
    py={2}
    css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
  >
    {nodes.map(item => (
      <Box key={item.id} css={{ padding: '0px 20px' }}>
        <FormControlLabel
          label={item.label}
          control={
            <StyledCheckbox
              color="primary"
              checked={selectedIds.has(item.id)}
              onChange={() => onClick(item.id)}
            />
          }
        />
      </Box>
    ))}
  </Box>
);

const NormalFilterConfidenceContent = ({ nodes, selectedIds, onClick }) => (
  <Box
    width="200px"
    py={2}
    css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
  >
    {nodes.map(item => (
      <Box key={item.id} css={{ padding: '0px 20px' }}>
        <FormControlLabel
          label={
            <ConfidenceRow>
              <div
                dangerouslySetInnerHTML={{
                  __html: get(reliabilityOptionIcons, item.id),
                }}
              />
              <span>{item.label}</span>
            </ConfidenceRow>
          }
          control={
            <StyledCheckbox
              color="primary"
              checked={selectedIds.has(item.id)}
              onChange={() => onClick(item.id)}
            />
          }
        />
      </Box>
    ))}
  </Box>
);

const EnableSearchFilterContent = ({ nodes, selectedIds, onClick }) => {
  const [searchText, setSearchText] = React.useState('');
  return (
    <>
      <StyledSearchControl>
        <SearchIcon class="search-icon" />
        <input
          value={searchText}
          onChange={e => {
            setSearchText(e.target.value);
          }}
          placeholder="Search"
        />
      </StyledSearchControl>

      <Box
        width="200px"
        py={1}
        css={{
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
      >
        {nodes
          .filter(
            item =>
              // $FlowFixMe
              item.label?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1,
          )
          .map(item => (
            <Box
              key={item.id}
              css={{
                padding: '0px 0px 0px 20px',
              }}
            >
              <FormControlLabel
                label={item.label}
                control={
                  <StyledCheckbox
                    color="primary"
                    checked={selectedIds.has(item.id)}
                    onChange={() => onClick(item.id)}
                  />
                }
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

const EnableSearchFilterLocation = ({ nodes, selectedCities, onClick }) => {
  const [searchText, setSearchText] = React.useState('');
  const [t] = useTranslation();
  return (
    <>
      <StyledSearchControl>
        <SearchIcon class="search-icon" />
        <input
          value={searchText}
          onChange={e => {
            setSearchText(e.target.value);
          }}
          placeholder={t('searchSingle')}
        />
      </StyledSearchControl>

      <Box
        width="200px"
        py={1}
        css={{
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
      >
        {nodes
          .filter(
            item =>
              // $FlowFixMe
              item.name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1,
          )
          .map(item => (
            <Box
              key={item.id}
              css={{
                padding: '0px 0px 0px 20px',
              }}
            >
              <FormControlLabel
                label={item.name}
                control={
                  <StyledCheckbox
                    color="primary"
                    checked={selectedCities.has(item.name)}
                    onChange={() => onClick(item.name)}
                  />
                }
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

const EnableSearchFilterYear = ({ nodes, selectedYears, onClick }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState('');
  let selectedYearsArray = Array.from(selectedYears);
  selectedYearsArray = selectedYearsArray.map(v => +v);
  return (
    <>
      <StyledSearchControl>
        <SearchIcon class="search-icon" />
        <input
          value={searchText}
          onChange={e => {
            setSearchText(e.target.value);
          }}
          placeholder={t('searchSingle')}
        />
      </StyledSearchControl>

      <Box
        width="200px"
        py={1}
        css={{
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
      >
        {nodes
          .filter(
            item =>
              // $FlowFixMe
              item.year
                ?.toString()
                .toLowerCase()
                .indexOf(searchText?.toLowerCase()) > -1,
          )
          .map(item => (
            <Box
              key={item.id}
              css={{
                padding: '0px 0px 0px 20px',
              }}
            >
              <FormControlLabel
                label={item.year}
                control={
                  <StyledCheckbox
                    color="primary"
                    checked={selectedYearsArray.indexOf(item.year) > -1}
                    onChange={() => onClick(item.year)}
                  />
                }
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

const FilterConfidence = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const selectedIds = new Set(params.confidence_in);
  const toggle = id =>
    setParams({
      confidence_in: toggleFilterItem(selectedIds, id)?.map(
        deal_confidence_type_of_string,
      ),
    });

  const sources = [
    {
      id: 'high',
      label: t('confidenceHighSortOnlyText'),
    },
    {
      id: 'medium',
      label: t('confidenceMediumSortOnlyText'),
    },
    {
      id: 'low',
      label: t('confidenceLowSortOnlyText'),
    },
  ];

  return (
    <FilterV2
      label={getLabel(sources, selectedIds, t('confidence'))}
      dialogTitle={t('confidence')}
      empty={selectedIds.size === 0}
      onReset={() => setParams({ confidence_in: [] })}
    >
      <NormalFilterConfidenceContent
        nodes={sources}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterBroker = () => {
  const [t] = useTranslation();
  const [search, setSearch] = React.useState(null);
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersUsersQuery>({
    query: graphql`
      query dealsFiltersUsersQuery($search: String) {
        users(search: $search, first: 30) {
          edges {
            node {
              ...UserAvatar_user
              id
              fullName
              email
            }
          }
        }
        viewer {
          id
          fullName
        }
      }
    `,
    variables: {
      search,
    },
  });

  const users =
    query.props?.users?.edges?.filter(Boolean).map(edge => edge.node) ?? [];
  const usersForLabel = users;
  const viewer = query.props?.viewer;

  const selectedIds = new Set(params.brokerId_in);
  const toggle = id =>
    setParams({ brokerId_in: toggleFilterItem(selectedIds, id) });

  // fast hack to display preloaded user, it's always viewer
  if (
    viewer != null &&
    selectedIds.has(viewer.id) &&
    !users.some(user => user?.id === viewer.id)
  ) {
    usersForLabel.push(viewer);
  }

  return (
    <FilterV2
      label={getLabel(
        usersForLabel.map(user => ({ label: user?.fullName, id: user?.id })),
        selectedIds,
        t('broker'),
      )}
      dialogTitle={t('broker')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ brokerId_in: null });
        setSearch(null);
      }}
      searchEnabled={true}
    >
      <Box>
        {/* <Input
          value={search}
          onChange={evt => setSearch(evt.target.value)}
          placeholder={t('searchUsers')}
          fullWidth={true}
          type="search"
          disableUnderline={true}
          autoFocus={true}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        /> */}
        <StyledSearchControl>
          <SearchIcon class="search-icon" />
          <input
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            placeholder={t('searchSingle')}
          />
        </StyledSearchControl>
      </Box>
      <List
        css={`
          width: 200px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 80vh;
        `}
      >
        {query.props?.users ? (
          query.props.users.edges?.filter(Boolean).length ? (
            query.props?.users?.edges
              ?.filter(Boolean)
              .map(edge => edge.node)
              .filter(Boolean)
              .map(user => (
                <ListItem
                  button={true}
                  key={user.id}
                  onClick={() => toggle(user.id)}
                  css={{ paddingLeft: '12px', paddingRight: '12px' }}
                >
                  <ListItemIcon css={{ minWidth: '30px' }}>
                    <StyledCheckbox
                      color="primary"
                      checked={selectedIds.has(user.id)}
                      onChange={() => toggle(user.id)}
                    />
                  </ListItemIcon>
                  <ListItemIcon css={{ minWidth: '30px' }}>
                    <UserAvatar
                      className="circle-img"
                      user={user}
                      organisation={null}
                      css={{
                        borderRadius: '16px',
                        width: '16px',
                        height: '16px',
                        fontSize: '7px',
                        fontWeight: '500',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    css={`
                      overflow: hidden;
                      margin-right: 8px;
                    `}
                    primary={user.fullName}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))
          ) : (
            <Box px={3} py={2}>
              {t('nothingFound')}
            </Box>
          )
        ) : (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </List>
    </FilterV2>
  );
};

const FilterBrokerManager = () => {
  const [t] = useTranslation();
  const [search, setSearch] = React.useState(null);
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersUsersQuery>({
    query: graphql`
      query dealsFiltersUsersQuery_brokerManagerQuery($search: String) {
        users(search: $search, first: 30) {
          edges {
            node {
              ...UserAvatar_user
              id
              fullName
              email
            }
          }
        }
        viewer {
          id
          fullName
        }
      }
    `,
    variables: {
      search,
    },
  });

  const users =
    query.props?.users?.edges?.filter(Boolean).map(edge => edge.node) ?? [];
  const usersForLabel = users;
  const viewer = query.props?.viewer;

  const selectedIds = new Set(params.leaderBrokerId_in);
  const toggle = id =>
    setParams({ leaderBrokerId_in: toggleFilterItem(selectedIds, id) });

  // fast hack to display preloaded user, it's always viewer
  if (
    viewer != null &&
    selectedIds.has(viewer.id) &&
    !users.some(user => user?.id === viewer.id)
  ) {
    usersForLabel.push(viewer);
  }

  return (
    <FilterV2
      label={getLabel(
        usersForLabel.map(user => ({ label: user?.fullName, id: user?.id })),
        selectedIds,
        t('brokerManager'),
      )}
      dialogTitle={t('brokerManager')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ leaderBrokerId_in: null });
        setSearch(null);
      }}
      searchEnabled={true}
    >
      <Box>
        {/* <Input
          value={search}
          onChange={evt => setSearch(evt.target.value)}
          placeholder={t('searchUsers')}
          fullWidth={true}
          type="search"
          disableUnderline={true}
          autoFocus={true}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        /> */}
        <StyledSearchControl>
          <SearchIcon class="search-icon" />
          <input
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            placeholder={t('searchSingle')}
          />
        </StyledSearchControl>
      </Box>
      <List
        css={`
          width: 200px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 80vh;
        `}
      >
        {query.props?.users ? (
          query.props.users.edges?.filter(Boolean).length ? (
            query.props?.users?.edges
              ?.filter(Boolean)
              .map(edge => edge.node)
              .filter(Boolean)
              .map(user => (
                <ListItem
                  button={true}
                  key={user.id}
                  onClick={() => toggle(user.id)}
                  css={{ paddingLeft: '12px', paddingRight: '12px' }}
                >
                  <ListItemIcon css={{ minWidth: '30px' }}>
                    <StyledCheckbox
                      color="primary"
                      checked={selectedIds.has(user.id)}
                      onChange={() => toggle(user.id)}
                    />
                  </ListItemIcon>
                  <ListItemIcon css={{ minWidth: '30px' }}>
                    <UserAvatar
                      className="circle-img"
                      user={user}
                      organisation={null}
                      css={{
                        borderRadius: '16px',
                        width: '16px',
                        height: '16px',
                        fontSize: '7px',
                        fontWeight: '500',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    css={`
                      overflow: hidden;
                      margin-right: 8px;
                    `}
                    primary={user.fullName}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))
          ) : (
            <Box px={3} py={2}>
              {t('nothingFound')}
            </Box>
          )
        ) : (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </List>
    </FilterV2>
  );
};

const FilterSeller = () => {
  const [t] = useTranslation();
  const [search, setSearch] = React.useState(null);
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersOrganisationsQuery>({
    query: graphql`
      query dealsFiltersOrganisationsQuery($search: String) {
        organisations(search: $search, first: 30) {
          edges {
            node {
              ...UserAvatar_organisation
              id
              name
              emailAddress
            }
          }
        }
      }
    `,
    variables: {
      search,
    },
  });

  const organisations = query.props?.organisations?.edges ?? [];
  const selectedIds = new Set(params.sellerId_in);
  const toggle = id =>
    setParams({ sellerId_in: toggleFilterItem(selectedIds, id) });

  return (
    <FilterV2
      label={getLabel(
        organisations.map(edge => ({
          label: edge?.node?.name,
          id: edge?.node?.id,
        })),
        selectedIds,
        t('seller'),
      )}
      dialogTitle={t('seller')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ sellerId_in: null });
        setSearch(null);
      }}
    >
      <Box p={2}>
        <Input
          value={search}
          onChange={evt => setSearch(evt.target.value)}
          placeholder={t('searchOrganisations')}
          fullWidth={true}
          type="search"
          disableUnderline={true}
          autoFocus={true}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        />
      </Box>
      <Divider />
      <List
        css={`
          width: 300px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 80vh;
        `}
      >
        {query.props?.organisations ? (
          query.props.organisations.edges?.length ? (
            query.props.organisations.edges
              .filter(Boolean)
              .map(edge => edge.node)
              .filter(Boolean)
              .map(organisation => (
                <ListItem
                  button={true}
                  key={organisation.id}
                  onClick={() => toggle(organisation.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={selectedIds.has(organisation.id)}
                      onChange={() => toggle(organisation.id)}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <UserAvatar organisation={organisation} />
                  </ListItemIcon>
                  <ListItemText
                    css={`
                      overflow: hidden;
                      margin-right: 8px;
                    `}
                    primary={organisation.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={organisation.emailAddress}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))
          ) : (
            <Box px={3} py={2}>
              {t('nothingFound')}
            </Box>
          )
        ) : (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </List>
    </FilterV2>
  );
};

const FilterStatus = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersDealStatusQuery>({
    query: graphql`
      query dealsFiltersDealStatusQuery {
        dealStatus {
          id
          label
        }
      }
    `,
  });

  const dealStatuses = query.props?.dealStatus?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.dealStatusId_in);
  const toggle = id =>
    setParams({
      dealStatusId_in: toggleFilterItem(selectedIds, id),
    });

  return (
    <FilterV2
      label={getLabel(dealStatuses, selectedIds, t('status'))}
      dialogTitle={t('status')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ dealStatusId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealStatuses}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterPipelineStages = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersDealPipelineStagesQuery>({
    query: graphql`
      query dealsFiltersDealPipelineStagesQuery {
        dealPipelineStages {
          id
          label
        }
      }
    `,
  });

  const dealPipelineStages =
    query.props?.dealPipelineStages?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.dealPipelineStageId_in);
  const toggle = id =>
    setParams({
      dealPipelineStageId_in: toggleFilterItem(selectedIds, id),
    });

  return (
    <FilterV2
      label={getLabel(dealPipelineStages, selectedIds, t('stage'))}
      dialogTitle={t('stage')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ dealPipelineStageId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealPipelineStages}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterRegions = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersRegionsQuery>({
    query: graphql`
      query dealsFiltersRegionsQuery {
        regions {
          id
          label
        }
      }
    `,
  });

  const regions = query.props?.regions?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.regionId_in);
  const toggle = id =>
    setParams({ regionId_in: toggleFilterItem(selectedIds, id) });

  return (
    <FilterV2
      label={getLabel(regions, selectedIds, t('region'))}
      dialogTitle={t('region')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ regionId_in: null });
      }}
      searchEnabled={true}
    >
      <EnableSearchFilterContent
        nodes={regions}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterLocations = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<any>({
    query: graphql`
      query dealsFiltersLocationsQuery {
        cities(filter: DEALS) {
          id
          name
        }
      }
    `,
  });

  const locations = query.props?.cities?.filter(Boolean) ?? [];
  const selectedCities = new Set(params.cities || []);
  const toggle = city =>
    setParams({ cities: toggleFilterItem(selectedCities, city) });

  return (
    <FilterV2
      label={getCitiesLabel(locations, selectedCities, t('location'))}
      dialogTitle={t('location')}
      empty={selectedCities.size === 0}
      onReset={() => {
        setParams({ cities: null });
      }}
      searchEnabled={true}
    >
      <EnableSearchFilterLocation
        nodes={locations}
        selectedCities={selectedCities}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterSignatureYear = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<any>({
    query: graphql`
      query dealsFiltersSignatureDateQuery {
        signatureDates {
          id
          year
        }
      }
    `,
  });
  let years = query.props?.signatureDates?.filter(Boolean) ?? [];
  years = years.map(v => ({ ...v, year: +v.year }));
  let signatureYear_in = new Set(params.signatureYear_in || []);
  const toggle = year =>
    setParams({
      signatureYear_in: toggleFilterItem(signatureYear_in, '' + year),
    });

  return (
    <FilterV2
      label={getYearLabel(years, signatureYear_in, t('signatureYear'))}
      dialogTitle={t('signatureYear')}
      empty={signatureYear_in.size === 0}
      onReset={() => {
        setParams({ signatureYear_in: null });
      }}
      searchEnabled={true}
    >
      <EnableSearchFilterYear
        nodes={years}
        selectedYears={signatureYear_in}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterUseType = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersUseTypesQuery>({
    query: graphql`
      query dealsFiltersUseTypesQuery {
        useTypes {
          id
          label
        }
      }
    `,
  });

  const useTypes = query.props?.useTypes?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.useTypeId_in);
  const toggle = id =>
    setParams({ useTypeId_in: toggleFilterItem(selectedIds, id) });

  return (
    <FilterV2
      label={getLabel(useTypes, selectedIds, t('useType'))}
      dialogTitle={t('useType')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ useTypeId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={useTypes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterExpectedPrice = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersExpectedPriceQuery>({
    query: graphql`
      query dealsFiltersExpectedPriceQuery {
        minDeal: deals(
          first: 1
          sortBy: "expectedSalePrice"
          sortDirection: ASC
        ) {
          edges {
            node {
              id
              expectedSalePrice
            }
          }
        }
        maxDeal: deals(
          first: 1
          sortBy: "expectedSalePrice"
          sortDirection: DESC
        ) {
          edges {
            node {
              id
              expectedSalePrice
            }
          }
        }
      }
    `,
  });

  const min = query.props?.minDeal?.edges?.[0]?.node?.expectedSalePrice ?? 0;
  const max =
    query.props?.maxDeal?.edges?.[0]?.node?.expectedSalePrice ?? 100000000000;

  const [value, setValue] = React.useState([
    params.expectedSalePrice_gte,
    params.expectedSalePrice_lte,
  ]);
  const debouncedSetParams = useDebouncedHandler(300, setParams);

  const getLabel = ([min, max], def) => {
    if (min == null && max == null) {
      return def;
    }
    let str = '';
    if (min != null) {
      str += `${t('from')} ${tableNumberFormat(min)}`;
    }
    if (max != null) {
      str += ` ${t('to')} ${tableNumberFormat(max)}`;
    }

    return str;
  };

  return (
    <FilterV2
      label={getLabel(
        [params.expectedSalePrice_gte, params.expectedSalePrice_lte],
        t('expectedSalePrice'),
      )}
      dialogTitle={t('expectedSalePrice')}
      empty={
        params.expectedSalePrice_gte == null &&
        params.expectedSalePrice_lte == null
      }
      onReset={() => {
        setParams({ expectedSalePrice_gte: null, expectedSalePrice_lte: null });
        setValue([null, null]);
      }}
    >
      <div
        css={`
          width: 300px;
        `}
      >
        <Box p={3}>
          <Flex justifyContent="space-between">
            <Typography variant="body2">
              {value[0]?.toLocaleString()}
            </Typography>
            <Typography variant="body2">
              {value[1]?.toLocaleString()}
            </Typography>
          </Flex>
          <Slider
            min={0}
            max={max}
            value={[value[0] ?? 0, value[1] ?? max]}
            step={10000}
            onChange={(evt, newValue) => {
              setValue(newValue);
              debouncedSetParams({
                expectedSalePrice_gte: newValue[0],
                expectedSalePrice_lte: newValue[1],
              });
            }}
          />
          <Flex justifyContent="space-between" css={{ gap: '12px' }}>
            <InputNumberCustomWrapper
              label={t('from')}
              value={value[0] ?? 0}
              onChange={e => {
                if (+e.target.value < 0 || +e.target.value > max) return;
                setValue([
                  +e.target.value,
                  +e.target.value > (value[1] ?? max)
                    ? +e.target.value
                    : value[1] ?? max,
                ]);
                debouncedSetParams({
                  expectedSalePrice_gte: +e.target.value,
                  expectedSalePrice_lte:
                    +e.target.value > (value[1] ?? max)
                      ? +e.target.value
                      : value[1] ?? max,
                });
              }}
              fullWidth={true}
              variant="filled"
              thousandSeparator="’"
              prefix={'CHF '}
            />
            <InputNumberCustomWrapper
              label={t('to')}
              value={value[1] ?? max}
              onChange={e => {
                if (+e.target.value < 0 || +e.target.value > max) return;

                setValue([
                  +e.target.value < (value[0] ?? 0)
                    ? +e.target.value
                    : value[0] ?? 0,
                  +e.target.value,
                ]);
                debouncedSetParams({
                  expectedSalePrice_gte:
                    +e.target.value < (value[0] ?? 0)
                      ? +e.target.value
                      : value[0] ?? 0,
                  expectedSalePrice_lte: +e.target.value,
                });
              }}
              fullWidth={true}
              variant="filled"
              thousandSeparator="’"
              prefix={'CHF '}
            />
          </Flex>
          {/* <Flex justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {min.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {max.toLocaleString()}
            </Typography>
          </Flex> */}
        </Box>
      </div>
    </FilterV2>
  );
};

const FilterCommission = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersCommissionQuery>({
    query: graphql`
      query dealsFiltersCommissionQuery {
        minDeal: deals(
          first: 1
          sortBy: "commissionCalculated"
          sortDirection: ASC
        ) {
          edges {
            node {
              id
              commissionCalculated
            }
          }
        }
        maxDeal: deals(
          first: 1
          sortBy: "commissionCalculated"
          sortDirection: DESC
        ) {
          edges {
            node {
              id
              commissionCalculated
            }
          }
        }
      }
    `,
  });

  const min =
    parseInt(
      query.props?.minDeal?.edges?.[0]?.node?.commissionCalculated,
      10,
    ) ?? 0;
  const max =
    parseInt(
      query.props?.maxDeal?.edges?.[0]?.node?.commissionCalculated,
      10,
    ) ?? 100;

  const [value, setValue] = React.useState([
    params.potentialCommission_gte,
    params.potentialCommission_lte,
  ]);
  const debouncedSetParams = useDebouncedHandler(300, setParams);

  const getLabel = ([min, max], def) => {
    if (min == null && max == null) {
      return def;
    }
    let str = '';
    if (min != null) {
      str += `${t('from')} ${min.toLocaleString()}`;
    }
    if (max != null) {
      str += ` ${t('to')} ${max.toLocaleString()}`;
    }

    return str;
  };

  return (
    <FilterV2
      label={getLabel(
        [params.potentialCommission_gte, params.potentialCommission_lte],
        t('potentialCommission'),
      )}
      dialogTitle={t('potentialCommission')}
      empty={
        params.potentialCommission_gte == null &&
        params.potentialCommission_lte == null
      }
      onReset={() => {
        setParams({
          potentialCommission_gte: null,
          potentialCommission_lte: null,
        });
        setValue([null, null]);
      }}
    >
      <div
        css={`
          width: 300px;
        `}
      >
        <Box p={3}>
          <Flex justifyContent="space-between">
            <Typography variant="body2">
              {value[0]?.toLocaleString()}
            </Typography>
            <Typography variant="body2">
              {value[1]?.toLocaleString()}
            </Typography>
          </Flex>
          <Slider
            min={0}
            max={max}
            value={[value[0] ?? 0, value[1] ?? max]}
            step={1}
            onChange={(evt, newValue) => {
              setValue(newValue);
              debouncedSetParams({
                potentialCommission_gte: newValue[0],
                potentialCommission_lte: newValue[1],
              });
            }}
          />
          <Flex justifyContent="space-between" css={{ gap: '12px' }}>
            <InputNumberCustomWrapper
              label={t('from')}
              value={value[0] ?? 0}
              onChange={e => {
                if (+e.target.value < 0 || +e.target.value > max) return;
                setValue([
                  +e.target.value,
                  +e.target.value > (value[1] ?? max)
                    ? +e.target.value
                    : value[1] ?? max,
                ]);
                debouncedSetParams({
                  potentialCommission_gte: +e.target.value,
                  potentialCommission_lte:
                    +e.target.value > (value[1] ?? max)
                      ? +e.target.value
                      : value[1] ?? max,
                });
              }}
              fullWidth={true}
              variant="filled"
              thousandSeparator="’"
              prefix={'CHF '}
            />
            <InputNumberCustomWrapper
              label={t('to')}
              value={value[1] ?? max}
              onChange={e => {
                if (+e.target.value < 0 || +e.target.value > max) return;

                setValue([
                  +e.target.value < (value[0] ?? 0)
                    ? +e.target.value
                    : value[0] ?? 0,
                  +e.target.value,
                ]);
                debouncedSetParams({
                  potentialCommission_gte:
                    +e.target.value < (value[0] ?? 0)
                      ? +e.target.value
                      : value[0] ?? 0,
                  potentialCommission_lte: +e.target.value,
                });
              }}
              fullWidth={true}
              variant="filled"
              thousandSeparator="’"
              prefix={'CHF '}
            />
          </Flex>
          {/* <Flex justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {min.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {max.toLocaleString()}
            </Typography>
          </Flex> */}
        </Box>
      </div>
    </FilterV2>
  );
};

const GetFilterQuarterOptions = () => {
  const [t] = useTranslation();
  return [
    {
      id: 't1',
      label: t('signatureT1'),
    },
    {
      id: 't2',
      label: t('signatureT2'),
    },
    {
      id: 't3',
      label: t('signatureT3'),
    },
    {
      id: 't4',
      label: t('signatureT4'),
    },
  ];
};

const FilterSignatureQuarter = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const selectedIds = new Set(params.signatureQuarter_in);
  const toggle = id =>
    setParams({
      signatureQuarter_in: toggleFilterItem(selectedIds, id),
    });
  const dealQuarters = GetFilterQuarterOptions();
  return (
    <FilterV2
      label={getLabel(dealQuarters, selectedIds, t('signatureQuarter'))}
      dialogTitle={t('signatureQuarter')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ signatureQuarter_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealQuarters}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const FilterCommissionQuarter = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const selectedIds = new Set(params.commissionQuarter_in);
  const toggle = id =>
    setParams({
      commissionQuarter_in: toggleFilterItem(selectedIds, id),
    });
  const dealQuarters = GetFilterQuarterOptions();
  return (
    <FilterV2
      label={getLabel(dealQuarters, selectedIds, t('commisionQuarter'))}
      dialogTitle={t('commisionQuarter')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ commissionQuarter_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealQuarters}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </FilterV2>
  );
};

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  .MuiFilledInput-root {
    background: none;
    background: #fbfcfe;
    border: 1px solid #d6dfea;
    border-radius: 10px;
    /* height: 32px; */
    margin-top: 12px;
  }

  .MuiFilledInput-input {
    border: none;
    background: none;
    font-size: 0.8125rem;
    padding: 2px 0px 2px 12px !important;
  }

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    display: none;
    border: none;
  }
`;

const StyledMonthWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  :hover {
    background: #edf5fe;
  }
  padding: 6px 12px;
  border-radius: 10px;
`;

const EnableSearchEstimatedMonthSignature = ({ setOpen, open }) => {
  const [params, setParams] = useDealsLeadsParams();
  const [selectedMonth, setSelectedMonth] = React.useState(null);
  const { t } = useTranslation();

  const selectedMonths = new Set(params.estimateMonthOfSignature_in || []);
  const selectedMonthsArray = Array.from(selectedMonths);

  const selectMonth = month => {
    const isIn = selectedMonths.has(month);

    if (!isIn) {
      setParams({
        estimateMonthOfSignature_in: toggleFilterItem(selectedMonths, month),
      });
    }
  };

  const removeMonth = month => {
    setParams({
      estimateMonthOfSignature_in: toggleFilterItem(selectedMonths, month),
    });
  };

  return (
    <>
      <StyledSearchControl>
        <StyledKeyboardDatePicker
          placeholder={t('selectMonth')}
          value={selectedMonth}
          onChange={value => setSelectedMonth(value)}
          disableToolbar
          autoOk
          variant="inline"
          format="yyyy.MM"
          inputVariant="filled"
          autoComplete="invalid"
          fullWidth
          id={'estimateMonthOfSignature'}
          views={['year', 'month']}
          keyboardIcon={
            <DateTimePickerIcon width="20" height="22" viewBox="0 0 20 22" />
          }
          open={open}
          // minDate={getDefaultMinDate()}
          onBlur={() => {
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onMonthChange={month => {
            const formattedMonth = format(new Date(month), 'yyyy-MM');
            selectMonth(formattedMonth);
            setOpen(false);
          }}
          inputProps={{
            readOnly: true,
          }}
        />
      </StyledSearchControl>

      {selectedMonthsArray.length > 0 && (
        <Box
          width="100%"
          py={1}
          css={{
            maxHeight: 'calc(100vh - 300px)',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <div
            style={{
              fontSize: '0.875rem',
              fontWeight: 600,
            }}
          >
            {t('selectedMonths')}
          </div>
          {selectedMonthsArray.map((month, idx) => (
            <StyledMonthWrapper key={idx}>
              {month}
              <CloseIcon
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => removeMonth(month)}
              />
            </StyledMonthWrapper>
          ))}
        </Box>
      )}
    </>
  );
};

const EnableSearchEstimatedMonthPayment = ({ setOpen, open }) => {
  const [params, setParams] = useDealsLeadsParams();
  const [selectedMonth, setSelectedMonth] = React.useState(null);
  const { t } = useTranslation();

  const selectedMonths = new Set(params.estimateMonthOfPayment_in || []);
  const selectedMonthsArray = Array.from(selectedMonths);

  const selectMonth = month => {
    const isIn = selectedMonths.has(month);

    if (!isIn) {
      setParams({
        estimateMonthOfPayment_in: toggleFilterItem(selectedMonths, month),
      });
    }
  };

  const removeMonth = month => {
    setParams({
      estimateMonthOfPayment_in: toggleFilterItem(selectedMonths, month),
    });
  };

  return (
    <>
      <StyledSearchControl>
        <StyledKeyboardDatePicker
          placeholder={t('selectMonth')}
          value={selectedMonth}
          onChange={value => setSelectedMonth(value)}
          disableToolbar
          autoOk
          variant="inline"
          format="yyyy.MM"
          inputVariant="filled"
          autoComplete="invalid"
          fullWidth
          id={'estimateMonthOfSignature'}
          views={['year', 'month']}
          keyboardIcon={
            <DateTimePickerIcon width="20" height="22" viewBox="0 0 20 22" />
          }
          open={open}
          // minDate={getDefaultMinDate()}
          onBlur={() => {
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onMonthChange={month => {
            const formattedMonth = format(new Date(month), 'yyyy-MM');
            selectMonth(formattedMonth);
            setOpen(false);
          }}
          inputProps={{
            readOnly: true,
          }}
        />
      </StyledSearchControl>

      {selectedMonthsArray.length > 0 && (
        <Box
          width="100%"
          py={1}
          css={{
            maxHeight: 'calc(100vh - 300px)',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <div
            style={{
              fontSize: '0.875rem',
              fontWeight: 600,
            }}
          >
            {t('selectedMonths')}
          </div>
          {selectedMonthsArray.map((month, idx) => (
            <StyledMonthWrapper key={idx}>
              {month}
              <CloseIcon
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => removeMonth(month)}
              />
            </StyledMonthWrapper>
          ))}
        </Box>
      )}
    </>
  );
};

const EstimateMonthOfSignatureFilter = () => {
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [params, setParams] = useDealsLeadsParams();
  const selectedMonths = new Set(params.estimateMonthOfSignature_in || []);
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const selectedMonthsArray = Array.from(selectedMonths);
  return (
    <FilterV3
      label={getLabel(
        selectedMonthsArray.map(v => ({ label: v, id: v })),
        selectedMonths,
        t('deals:estimatedMonthOfSignature'),
      )}
      dialogTitle={t('deals:estimatedMonthOfSignature')}
      setOpen={setOpen}
      open={open}
      onClose={() => {
        if (pickerOpen) {
          return;
        }
        setOpen(false);
      }}
      empty={selectedMonths.size === 0}
      onReset={() => {
        setParams({ estimateMonthOfSignature_in: null });
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <EnableSearchEstimatedMonthSignature
          open={pickerOpen}
          setOpen={setPickerOpen}
        />
      </MuiPickersUtilsProvider>
    </FilterV3>
  );
};

const EstimateMonthOfPaymentFilter = () => {
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [params, setParams] = useDealsLeadsParams();
  const selectedMonths = new Set(params.estimateMonthOfPayment_in || []);
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const selectedMonthsArray = Array.from(selectedMonths);
  return (
    <FilterV3
      label={getLabel(
        selectedMonthsArray.map(v => ({ label: v, id: v })),
        selectedMonths,
        t('deals:estimatedMonthOfPayment'),
      )}
      dialogTitle={t('deals:estimatedMonthOfPayment')}
      setOpen={setOpen}
      open={open}
      onClose={() => {
        if (pickerOpen) {
          return;
        }
        setOpen(false);
      }}
      empty={selectedMonths.size === 0}
      onReset={() => {
        setParams({ estimateMonthOfPayment_in: null });
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <EnableSearchEstimatedMonthPayment
          open={pickerOpen}
          setOpen={setPickerOpen}
        />
      </MuiPickersUtilsProvider>
    </FilterV3>
  );
};

export const DealsFilters = () => (
  <div
    css={`
      flex-shrink: 0;
      padding: 32px 1px 0px 40px;
    `}
  >
    <Flex
      style={{
        flexWrap: 'wrap',
      }}
    >
      <Box p={1}>
        <FilterLocations />
      </Box>
      <Box p={1}>
        <FilterBroker />
      </Box>
      <Box p={1}>
        <FilterBrokerManager />
      </Box>
      <Box p={1}>
        <FilterConfidence />
      </Box>
      <Box p={1}>
        <FilterUseType />
      </Box>
      <Box p={1}>
        <FilterStatus />
      </Box>
      <Box p={1}>
        <FilterPipelineStages />
      </Box>
      <Box p={1}>
        <FilterExpectedPrice />
      </Box>
      <Box p={1}>
        <FilterCommission />
      </Box>
      <Box p={1}>
        <FilterSignatureYear />
      </Box>
      <Box p={1}>
        <EstimateMonthOfSignatureFilter />
      </Box>
      <Box p={1}>
        <EstimateMonthOfPaymentFilter />
      </Box>
    </Flex>
  </div>
);
