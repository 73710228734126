/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { AppBar, Box, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Route, Switch } from 'react-router-dom';
import { GatewayDest } from 'react-gateway';
import SearchIcon from '@material-ui/icons/Search';
import withWidth from '@material-ui/core/withWidth';

import GlobalSearch from '../../routes/admin/GlobalSearch';
import { uniMaterial } from '../../routes/admin/controls/UniStyled';
import { HeaderTitleBox } from '../../routes/admin/controls/Title';
import { UserMenu } from '../user-menu';
import LanguagePicker from '../LanguagePicker';

const TopBar = styled(AppBar)`
  right: 0;
  width: unset;
  transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  left: ${p => (p['data-open'] ? p['data-max-w'] : 56)}px;
  box-shadow: none;
`;

const AnimIconButton = styled(IconButton)`
  overflow: hidden;
  transition: width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  width: ${p => (p['data-open'] ? 0 : 48)}px;
`;

const StToolBar = uniMaterial(Toolbar);

const StyledTollbar = styled(StToolBar)`
  /* width: 1214px; */
  margin: 0px 64px;
  justify-content: space-between;
  padding: 13px 0px 0px 0px;
  height: 100%;
`;

const StyledFakeDiv = styled.div`
  height: 26px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(40, 99, 166, 0.15);
  clip-path: inset(5px 0px -10px 0px);
  line-height: 26px;
  color: #fff;
`;

const StyledBox = styled(Box)`
  height: 48px;
  margin-top: 13px;
  padding-top: 13px;
  display: flex;
  align-items: center;
`;

const adminAppBar = ({
  open,
  onMenuClick,
  navMenuMinWidth,
  navMenuMaxWidth,
  width,
  viewer,
  isFullLayoutHeader,
  t,
}) => {
  return (
    <TopBar
      data-open={open}
      data-min-w={navMenuMinWidth}
      data-max-w={navMenuMaxWidth}
      position="fixed"
      color="#ffffff"
    >
      <StyledTollbar>
        <Box display="flex" flex={1} alignItems="center" mr={5} minWidth={0}>
          <HeaderTitleBox isFullLayoutHeader={isFullLayoutHeader} t={t} />
        </Box>
        <Switch>
          <Route path="/search/:search" component={() => null} />
          <Route render={() => width !== 'xs' && <GlobalSearch />} />
        </Switch>
        <StyledBox display="flex">
          <GatewayDest name="actions" />
          {width === 'xs' && (
            <Link to="/search">
              <IconButton color="inherit">
                <SearchIcon />
              </IconButton>
            </Link>
          )}
          {viewer != null && (
            <Box ml={4}>
              <UserMenu
                viewer={viewer}
                refetch={() => window.location.reload()}
              />
            </Box>
          )}
          <Box ml={2.5}>
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.00001 22C10.1 22 10.99 21.11 10.99 20.01H7.01001C7.01001 21.11 7.90001 22 9.00001 22ZM17.29 17.29L16 16V10C16 6.65 13.64 3.85 10.5 3.17V2C10.5 1.17 9.83001 0.5 9.00001 0.5C8.17001 0.5 7.50001 1.17 7.50001 2V3.17C4.36001 3.85 2.00001 6.65 2.00001 10V16L0.710006 17.29C0.0800057 17.92 0.520006 19 1.41001 19H16.58C17.48 19 17.92 17.92 17.29 17.29ZM10 14.5C10 15.0523 9.55229 15.5 9.00001 15.5C8.44772 15.5 8.00001 15.0523 8.00001 14.5C8.00001 13.9477 8.44772 13.5 9.00001 13.5C9.55229 13.5 10 13.9477 10 14.5ZM10 11.5C10 12.05 9.55001 12.5 9.00001 12.5C8.45001 12.5 8.00001 12.05 8.00001 11.5V8C8.00001 7.45 8.45001 7 9.00001 7C9.55001 7 10 7.45 10 8V11.5Z"
                fill="#DD3944"
              />
            </svg>
          </Box>
          {viewer != null && (
            <Box ml={2.5}>
              <LanguagePicker t={t} viewer={viewer} style={{ fontSize: 20 }} />
            </Box>
          )}
        </StyledBox>
      </StyledTollbar>
      {isFullLayoutHeader && <StyledFakeDiv>&nbsp;</StyledFakeDiv>}
    </TopBar>
  );
};

export default compose(withWidth())(adminAppBar);
