import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createUseType = makeCreateDictionaryMutation(
  graphql`
    mutation useTypesCreateUseTypeMutation($input: CreateUseTypeInput!) {
      createUseType(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createUseType',
  'useTypes',
);

export const updateUseType = makeUpdateDictionaryMutation(
  graphql`
    mutation useTypesUpdateUseTypeMutation($input: UpdateUseTypeInput!) {
      updateUseType(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateUseType',
  'useTypes',
);

export const deleteUseType = makeDeleteDictionaryMutation(
  graphql`
    mutation useTypesDeleteUseTypeMutation($input: DeleteUseTypeInput!) {
      deleteUseType(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteUseType',
  'useTypes',
);
