import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DateTimePickerIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z" fill="#96A4B4"/>
    </SvgIcon>
  );
};

export default DateTimePickerIcon;
