import React, { createContext, useState, useMemo } from 'react';
import Modal from '../components/Modal';

export const FeedbackContext = createContext();

const FeedbackProvider = ({ children }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    component: null,
    onClose: () => {},
    contentWidth: 500,
    withPaper: true,
    title: '',
    titleIcon: null,
    hasCloseButton: true,
  });

  const showModal = (
    component,
    { onClose, withPaper, contentWidth, title, titleIcon, hasCloseButton } = {},
  ) => {
    setModal(
      Object.assign({}, modal, {
        isOpen: component && true,
        component,
        onClose: onClose ? onClose : () => {},
        contentWidth: contentWidth ? contentWidth : 500,
        withPaper: withPaper ? withPaper : true,
        title: title ? title : '',
        titleIcon: titleIcon ? titleIcon : null,
        hasCloseButton: hasCloseButton !== undefined ? hasCloseButton : true,
      }),
    );
  };

  const hideModal = bCallOnClose => {
    if (bCallOnClose && modal.onClose) {
      modal.onClose();
    }
    setModal(
      Object.assign({}, modal, {
        isOpen: false,
        component: null,
        onClose: () => {},
      }),
    );
  };

  const contextProviderValue = useMemo(() => ({ showModal, hideModal }), [
    showModal,
    hideModal,
  ]);

  return (
    <FeedbackContext.Provider value={contextProviderValue}>
      {children}
      <Modal
        open={modal.isOpen}
        onClose={() => {
          hideModal();
          modal?.onClose();
        }}
        contentWidth={modal.contentWidth}
        title={modal.title}
        titleIcon={modal.titleIcon}
        withPaper={modal.withPaper}
        hasCloseButton={modal.hasCloseButton}
      >
        {modal.component}
      </Modal>
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
