/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';

export const DropZoneWrapper = styled(({ _isDragActive, ...props }) => (
  <div {...props} />
))`
  ${({ theme: { spacing }, _isDragActive }) => css`
    width: 100%;
    height: ${spacing(17)}px;
    background-color: ${!_isDragActive ? '#EDF5FE' : '#e8ffea'};
    border-radius: ${spacing(2.5)}px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%233455D14D' stroke-width='2' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  `}
`;

export const OffDragSubtitle = styled(Typography)`
  ${() => css`
    //styleName: Instructions;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;

    color: #96a4b4;
  `}
`;

export const BrowserLink = styled(Typography)`
  ${() => css`
    //styleName: Clickable link;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;

    color: #3455d1;
    cursor: pointer;
  `}
`;
