/**
 * @flow
 * @relayHash 7fedda97a0ce7da6e8f3b38a5bcb802e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganisationInvestorProfileForm_organisation$ref = any;
export type ORGANISATION_RELIABILITY_TYPE_ENUM = "high" | "low" | "medium" | "%future added value";
export type UpdateOrganisationInput = {|
  id: string,
  name?: ?string,
  organisationTypeId?: ?string,
  websiteUrl?: ?string,
  street?: ?string,
  streetNr?: ?string,
  postcode?: ?string,
  city?: ?string,
  country?: ?string,
  poBox?: ?string,
  minAssetSize?: ?number,
  maxAssetSize?: ?number,
  assetsUnderManagement?: ?number,
  lexKollerCompliant?: ?boolean,
  primaryContactUserId?: ?string,
  primaryImageId?: ?string,
  ownerId?: ?string,
  emailAddress?: ?string,
  phoneNumber?: ?string,
  core?: ?boolean,
  corePlus?: ?boolean,
  valueAdd?: ?boolean,
  opportunistic?: ?boolean,
  onlyIndirect?: ?boolean,
  isActive?: ?boolean,
  proofRead?: ?boolean,
  generalNdaSigned?: ?boolean,
  isInvestor?: ?boolean,
  isSeller?: ?boolean,
  isBroker?: ?boolean,
  isSupervisor?: ?boolean,
  address?: ?string,
  lat?: ?number,
  lng?: ?number,
  reliability?: ?ORGANISATION_RELIABILITY_TYPE_ENUM,
  regionIds?: ?$ReadOnlyArray<?string>,
  useTypeIds?: ?$ReadOnlyArray<?string>,
  purchaseTypeIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type OrganisationInvestorProfileFormUpdateOrganisationMutationVariables = {|
  input: UpdateOrganisationInput
|};
export type OrganisationInvestorProfileFormUpdateOrganisationMutationResponse = {|
  +updateOrganisation: ?{|
    +organisation: ?{|
      +id: string,
      +$fragmentRefs: OrganisationInvestorProfileForm_organisation$ref,
    |}
  |}
|};
export type OrganisationInvestorProfileFormUpdateOrganisationMutation = {|
  variables: OrganisationInvestorProfileFormUpdateOrganisationMutationVariables,
  response: OrganisationInvestorProfileFormUpdateOrganisationMutationResponse,
|};
*/


/*
mutation OrganisationInvestorProfileFormUpdateOrganisationMutation(
  $input: UpdateOrganisationInput!
) {
  updateOrganisation(input: $input) {
    organisation {
      id
      ...OrganisationInvestorProfileForm_organisation
    }
  }
}

fragment OrganisationInvestorProfileForm_organisation on Organisation {
  id
  minAssetSize
  maxAssetSize
  assetsUnderManagement
  lexKollerCompliant
  useTypeIds
  regionIds
  purchaseTypeIds
  core
  corePlus
  valueAdd
  opportunistic
  onlyIndirect
  owner {
    id
    fullName
    isBroker
    organisationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrganisationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrganisationInvestorProfileFormUpdateOrganisationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganisation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganisationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organisation",
            "storageKey": null,
            "args": null,
            "concreteType": "Organisation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "OrganisationInvestorProfileForm_organisation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrganisationInvestorProfileFormUpdateOrganisationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganisation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganisationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organisation",
            "storageKey": null,
            "args": null,
            "concreteType": "Organisation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "minAssetSize",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "maxAssetSize",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "assetsUnderManagement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lexKollerCompliant",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "useTypeIds",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "regionIds",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "purchaseTypeIds",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "core",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "corePlus",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "valueAdd",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "opportunistic",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "onlyIndirect",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "owner",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isBroker",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "organisationId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "OrganisationInvestorProfileFormUpdateOrganisationMutation",
    "id": null,
    "text": "mutation OrganisationInvestorProfileFormUpdateOrganisationMutation(\n  $input: UpdateOrganisationInput!\n) {\n  updateOrganisation(input: $input) {\n    organisation {\n      id\n      ...OrganisationInvestorProfileForm_organisation\n    }\n  }\n}\n\nfragment OrganisationInvestorProfileForm_organisation on Organisation {\n  id\n  minAssetSize\n  maxAssetSize\n  assetsUnderManagement\n  lexKollerCompliant\n  useTypeIds\n  regionIds\n  purchaseTypeIds\n  core\n  corePlus\n  valueAdd\n  opportunistic\n  onlyIndirect\n  owner {\n    id\n    fullName\n    isBroker\n    organisationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91631dad5330fd994e04f5e0ae9f9f61';
module.exports = node;
