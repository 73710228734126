/* eslint-disable no-underscore-dangle, no-plusplus, import/no-unresolved, import/extensions */
// This is a partial rewrite of this
// https://github.com/bvaughn/react-virtualized-select/blob/master/source/VirtualizedSelect/VirtualizedSelect.js
// what changed (a lot) but in short
// InfiniteLoader support
// material-ui input hardcoded

// TODO: prefiltering locally before new data loaded
// on a slow connection will provide a better UX if user types something

// TODO: if for some searchValue totalCount === options.length we can switch
// on local search only for every newSearchValue if searchValue.contains(newSearchValue)

/**
 * Usage example
 *  <VirtualLoadableSelect
 *    // --------------------------------------------------------------------------------
 *    // TextField props
 *    id="organisation"
 *    label="Organisation"
 *    value={values.organisation}
 *    onChange={handleChange}
 *    onBlur={handleBlur}
 *    error={Boolean(touched.organisation && errors.organisation)}
 *    helperText={touched.organisation && errors.organisation}
 *    // --------------------------------------------------------------------------------
 *    // react-select props
 *    // If your object shape is not { label, value } you need to provide
 *    // label and value keys for your shape i.e. for { id, name }
 *    labelKey={'name'}
 *    valueKey={'id'}
 *    // a list of options
 *    options={root.organisations.edges.map(({ node }) => node)}
 *    // --------------------------------------------------------------------------------
 *    // react-virtualized props
 *    // as react-virtualized used you need to provide optionHeight number or function
 *    optionHeight={50}
 *    // provide your custom renderer for option
 *    optionRenderer={(option) => myCustomOptionRenderer(option)}
 *    // --------------------------------------------------------------------------------
 *    // pagination and search support props
 *    // search string change - you need to refetch data
 *    onInputChange={onSearchChange}
 *    // relay loadMore, usually this function
 *    // (count, callback) => { if (relay.hasMore()) { relay.loadMore(count, callback);} }
 *    loadMore={loadMore}
 *    // total count of rows on the server
 *    totalCount={root.organisations.totalCount}
 *    // allow to load all the data if user drag scroll bar at the and (default true)
 *    // better ux as no scroll twitching on scrolling but can cause significant calls to api
 *    allowFullBatch
 *  />
 */

import React, { Component } from 'react';
import Select from 'react-select';

// Import directly to avoid Webpack bundling the parts of react-virtualized that we are not using
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  requestAnimationTimeout,
  cancelAnimationTimeout,
} from '../../utils/requestAnimationTimeout';

const StyledSelect = styled(Select)`
  position: relative;
  background-color: #fbfcfe;
  height: 48px;
  border: 1px solid #d6dfea;
  border-radius: 10px;
  font-size: 14px;
  &:after {
    content: ${props => !props.selectValue && `url('/icon/search.svg')`};
    position: absolute;
    top: 13px;
    right: 20px;
    width: 14px;
    height: 8px;
  }

  label {
    font-size: 14px;
  }
  .MuiInputBase-input {
    font-size: 14px;
    padding: 14px 16px 8px 16px;
  }
  .MuiInputBase-root :before {
    content: none !important;
  }
  .MuiInputBase-root :after {
    content: none !important;
  }
  .MuiFilledInput-root {
    background-color: unset !important;
  }
  .MuiFormLabel-root {
    font-size: 12px;
    padding-left: 5px;
    top: -3px;
  }
  & .Select-control {
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    /* height: 36px;*/
    outline: none;
    position: relative;
    width: 100%;
  }

  & .Select-clear-zone {
    cursor: pointer;
    /*
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    */
    width: 17px;
    position: absolute;
    /* top: 22px;*/
    bottom: 8px;
    right: 10px;
  }

  & .Select-clear {
    display: inline-block;
    font-size: 18px;
    line-height: 1px;
  }

  & .Select-value {
    bottom: 0;
    left: 12px;
    right: 12px;
    line-height: 34px;
    padding-left: 5px;
    padding-right: 10px;
    position: absolute;
    /* top: 15px; */
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .Select-placeholder {
    display: ${({ label, placeholder }) =>
      label === undefined && placeholder !== undefined ? 'block' : 'none'};
    bottom: 8px;
    left: 0;
    padding-left: 0;
    padding-right: 10px;
    position: absolute;
    right: 0;
    /* top: 20px; */
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* font-size: 18px; */
    color: rgba(0, 0, 0, 0.54);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  &.is-open .Select-placeholder {
    display: ${({ placeholder }) =>
      placeholder !== undefined ? 'block' : 'none'};
  }

  & .Select-menu-outer {
    background-color: white;
    box-shadow: ${({ theme }) => theme.shadows[2]};
    box-sizing: border-box;
    margin-top: ${({ label }) => (label === undefined ? '-4px' : '-1px')};
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9;
  }

  &.is-disabled {
    & .Select-value {
      color: #999;
    }
  }
`;

const alwaysTrue = () => true;

const inputRenderer = ({ ref, selectValue, focused, ...props }) => (
  <TextField
    {...props}
    inputRef={ref}
    fullWidth
    autoComplete="false"
    InputLabelProps={
      focused
        ? undefined
        : {
            shrink: Boolean(selectValue),
          }
    }
  />
);

export default class VirtualizedSelect extends Component {
  static defaultProps = {
    async: false,
    maxHeight: 200,
    optionHeight: 48,
    threshold: 40,
    minimumBatchSize: 40,
    labelKey: 'name',
    valueKey: 'id',
    // allow to load all the data if user drag scroll bar at the and
    allowFullBatch: true,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { focused: false };
    const totalCount =
      props.totalCount === undefined ? props.options.length : props.totalCount;

    this.fakeOptions = [...new Array(totalCount)].map((_, index) => ({
      [props.valueKey]: index,
      [props.labelKey]: index,
      fake: true,
    }));
    this._renderMenu = this._renderMenu.bind(this);
    this._setSelectRef = this._setSelectRef.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._optionRenderer = this._optionRenderer.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.totalCount !== this.props.totalCount ||
      (nextProps.totalCount === undefined &&
        this.props.options.length !== nextProps.options.length)
    ) {
      const totalCount =
        nextProps.totalCount === undefined
          ? nextProps.options.length
          : nextProps.totalCount;

      this.fakeOptions = [...new Array(totalCount)].map((_, index) => ({
        [this.props.valueKey]: index,
        [this.props.labelKey]: index,
        fake: true,
      }));
    }
  }

  componentWillUnmount() {
    if (this.timeoutHandle_) {
      cancelAnimationTimeout(this.timeoutHandle_);
    }
  }

  _onFocus(e) {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus({
        ...e,
        persist: () => {},
        target: {
          ...e.target,
          id: this.props.id,
          name: this.props.name,
        },
      });
    }
  }

  _onBlur(e) {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      // formik event support
      const evt = {
        ...e,
        persist: () => {},
        target: {
          ...e.target,
          id: this.props.id,
          name: this.props.name,
        },
      };

      // formik needs blur event after onChange
      // but react-select can run blur before
      Promise.resolve().then(() => this.props.onBlur(evt));
    }

    // Reset on blur as react-select resets search
    // but does not propagate an event about
    const { onInputChange } = this.props;

    if (onInputChange) {
      onInputChange('');
    }
  }

  /** See Select#focus (in react-select) */
  focus() {
    if (this._selectRef) {
      return this._selectRef.focus();
    }
    return undefined;
  }

  _optionRenderer({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    fakeOption,
    selectValue,
    style,
    // valueArray,
  }) {
    if (!option) return <div key={key} style={style} />;
    const { optionRenderer } = this.props;

    const events = option.disabled
      ? {}
      : {
          onClick: () => selectValue(option),
          onMouseEnter: () => focusOption(fakeOption),
        };

    return optionRenderer ? (
      optionRenderer({
        option,
        focused: fakeOption === focusedOption,
        key,
        style,
        events,
        selectValue,
        focusOption,
      })
    ) : (
      <div style={style} key={key}>
        <MenuItem
          selected={fakeOption === focusedOption}
          // title={option.title}
          {...events}
        >
          {option[labelKey]}
        </MenuItem>
      </div>
    );
  }

  // See https://github.com/JedWatson/react-select/#effeciently-rendering-large-lists-with-windowing
  _renderMenu({
    focusedOption,
    focusOption,
    labelKey,
    onSelect,
    options,
    // selectValue,
    valueArray,
  }) {
    const {
      listProps,
      allowFullBatch,
      minimumBatchSize,
      threshold,
      valueKey,
    } = this.props;

    const height = this._calculateListHeight({ options });
    const totalCount =
      this.props.totalCount === undefined
        ? this.props.options.length
        : this.props.totalCount;

    const optionRenderer = this._optionRenderer;
    // react-select 1.0.0-rc2 passes duplicate `onSelect` and `selectValue` props to `menuRenderer`
    // The `Creatable` HOC only overrides `onSelect` which breaks an edge-case
    // In order to support creating items via clicking on the placeholder option,
    // We need to ensure that the specified `onSelect` handle is the one we use.
    // See issue #33

    const realOptions = this.props.options;
    const focusedOptionIndex = options.indexOf(focusedOption);
    // console.log('focusedOption', focusedOption, focusedOptionIndex);

    function wrappedRowRenderer({ index, key, style }) {
      const fakeOption = options[index];
      const option = realOptions[fakeOption[valueKey]];

      return optionRenderer({
        focusedOption,
        focusedOptionIndex,
        focusOption,
        key,
        labelKey,
        onSelect,
        option,
        fakeOption,
        optionIndex: index,
        options: realOptions,
        selectValue: onSelect,
        style,
        valueArray,
      });
    }

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <InfiniteLoader
            isRowLoaded={this._isRowLoaded}
            loadMoreRows={this._loadMoreRows}
            rowCount={totalCount}
            minimumBatchSize={minimumBatchSize}
            threshold={threshold}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                onRowsRendered={onRowsRendered}
                className="VirtualSelectGrid"
                height={height}
                ref={r => {
                  registerChild(r);
                  this._listRef = r;
                }}
                rowCount={allowFullBatch ? options.length : realOptions.length}
                rowHeight={({ index }) =>
                  this._getOptionHeight({
                    option: options[index],
                  })
                }
                rowRenderer={wrappedRowRenderer}
                scrollToIndex={focusedOptionIndex}
                width={width}
                {...listProps}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }

  _isRowLoaded = ({ index }) => {
    const { options } = this.props;
    return options && index < options.length;
  };

  _loadMoreRows = ({ stopIndex }) => {
    const { options, loadMore } = this.props;
    const loadCount = Math.max(1 + stopIndex - options.length, 1);

    return (
      new Promise((r, rj) => {
        if (this.timeoutHandle_) {
          cancelAnimationTimeout(this.timeoutHandle_);
        }

        loadMore(loadCount, e => (e ? rj(e) : r()));
      })
        //  uncomment to see resolved loadCounts
        // .then(r => console.log(loadCount) || r)
        .catch(({ isLoading }) => {
          const DEBOUNCE_TIME = 100;
          if (isLoading) {
            if (this.timeoutHandle_) {
              cancelAnimationTimeout(this.timeoutHandle_);
            }

            this.timeoutHandle_ = requestAnimationTimeout(
              () => this._loadMoreRows({ stopIndex }),
              DEBOUNCE_TIME,
            );
          }
        })
    );
  };

  _calculateListHeight({ options }) {
    const { maxHeight } = this.props;

    let height = 0;

    for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
      const option = options[optionIndex];

      height += this._getOptionHeight({ option });

      if (height > maxHeight) {
        return maxHeight;
      }
    }

    return height;
  }

  _getOptionHeight({ option }) {
    const { optionHeight } = this.props;

    return optionHeight instanceof Function
      ? optionHeight({ option })
      : optionHeight;
  }

  _setSelectRef(ref) {
    this._selectRef = ref;
  }

  _onInputChange = value => {
    const { onInputChange } = this.props;

    if (this._listRef) {
      this._listRef.scrollToPosition(0);
    }

    if (onInputChange) {
      onInputChange(value);
    }
  };

  _onChange = option => {
    // to support keyboard selection
    // as it provides a fake option
    // for onClick events good value is already provided
    // see onClick: () => selectValue(option),
    const newValue =
      option && option.fake === true
        ? this.props.options[option[this.props.valueKey]]
        : option;

    if (this.props.onChange) {
      // emulate react synthetic event so withFormik can work with as is
      this.props.onChange({
        persist: () => {},
        target: {
          id: this.props.id,
          name: this.props.name,
          value: newValue,
        },
      });
    }
  };

  filterOption = (opt, filter) => {
    const { labelKey, valueKey, options } = this.props;
    const realOption = options[opt[valueKey]];

    return (realOption[labelKey] ?? '')
      .toLowerCase()
      .startsWith(filter.toLowerCase());
  };

  render() {
    return (
      <StyledSelect
        // className={styleSelect.toString()}
        openOnFocus
        tabSelectsValue={false}
        autoBlur
        {...this.props}
        options={this.fakeOptions}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        ref={this._setSelectRef}
        menuRenderer={this._renderMenu}
        menuStyle={{ overflow: 'hidden' }}
        inputRenderer={inputRenderer}
        onInputChange={this._onInputChange}
        onChange={this._onChange}
        // disable local filtering for controls with totalCount
        filterOption={
          this.props.totalCount === undefined ? this.filterOption : alwaysTrue
        }
        inputProps={{
          disabled: this.props.disabled,
          label: this.props.label,
          selectValue: this.props.value,
          focused: this.state.focused,
          error: this.props.error,
          helperText: this.props.helperText,
          variant: this.props.variant,
        }}
        selectValue={this.props.value}
      />
    );
  }
}
