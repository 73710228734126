import React from 'react';
import {
  mdiFilePdf,
  mdiFileImage,
  mdiFileExcel,
  mdiFileWord,
  mdiFile,
} from '@mdi/js';

const getPath = mime => {
  if (mime === 'application/pdf') return mdiFilePdf;
  else if (mime.includes('image')) return mdiFileImage;
  else if (mime.includes('ms-excel') || mime.includes('spreadsheetml'))
    return mdiFileExcel;
  else if (mime.includes('msword')) return mdiFileWord;
  return mdiFile;
};

export default mime => <path d={getPath(mime)} />;
