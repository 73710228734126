import React from 'react';
import PropTypes from 'prop-types';

export default class BaseTitleProvider extends React.Component {
  static childContextTypes = {
    title: PropTypes.object,
  };

  getChildContext() {
    return {
      title: {
        objects: this.state.objects,
        addTitle: this.addTitle,
        removeTitle: this.removeTitle,
        updateTitle: this.updateTitle,
        currentIndex: 0,
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      objects: [{ text: props.baseTitle, component: props.baseTitle }],
    };
  }

  addTitle = titleObj => {
    this.setState(({ objects }) => ({ objects: [...objects, titleObj] }));
  };

  removeTitle = titleObj => {
    this.setState(({ objects }) => ({
      objects: objects.filter(obj => obj !== titleObj),
    }));
  };

  updateTitle = () => {
    // we passed and updated titleObj by ref before, so we don't need to do something here
    this.setState(({ objects }) => ({ objects }));
  };

  render() {
    return this.props.children;
  }
}
