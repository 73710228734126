/* eslint-disable import/no-extraneous-dependencies */

import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import React, { useEffect, useState } from 'react';
import { GatewayProvider } from 'react-gateway';
import { withTranslation as translate } from 'react-i18next';
import { graphql } from 'react-relay';
import { compose, defaultProps, withStateHandlers } from 'recompose';
import { injectGlobal } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { BaseTitleProvider } from '../../routes/admin/controls/Title';
import { Div, uniMaterial } from '../../routes/admin/controls/UniStyled';
import { createFragmentContainerHOC } from '../../utils/relay';
import AdminAppBar, { AdminAppBarLoading } from '../AdminAppBar';
import LeftNavigation from '../LeftNavigation';

/* eslint-disable */
injectGlobal`
  body, html {
    -webkit-font-smoothing: antialiased;
    color: #222;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  a {
    color: inherit;
     text-decoration: none;
  }

`;
/* eslint-enable */

const FakeAppBar = uniMaterial(AppBar);

const FULL_HEADER_ROUTE_CONFIG = ['/', '/organisations', '/individuals'];

const layout = ({
  isLeftNavigationOpen,
  toggleLeftNavigation,
  closeLeftNavigation,
  children,
  navMenuMinWidth,
  navMenuMaxWidth,
  onBackButton,
  backButtonText,
  viewer,
  t,
}) => {
  const [isFullLayoutHeader, setFullLayoutHeader] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname;
    setFullLayoutHeader(
      FULL_HEADER_ROUTE_CONFIG.indexOf(pathname) >= 0 ||
        pathname.indexOf('individuals') >= 0 ||
        pathname.indexOf('search') >= 0,
    );
  }, [location]);

  return (
    <GatewayProvider>
      <BaseTitleProvider baseTitle={t('baseTitle')}>
        <Div
          display="flex"
          background="linear-gradient(180deg, #FBFCFE 76.54%, rgba(251, 252, 254, 0) 100%)"
        >
          <AdminAppBarLoading />
          {/* The div above if only to fix IE 11 flex bugs */}
          <Div
            min-height="100vh"
            display="flex"
            overflow-y="hidden"
            overflow-x={isLeftNavigationOpen ? 'auto' : 'hidden'}
            flex="1"
          >
            <LeftNavigation
              open={isLeftNavigationOpen}
              onClose={closeLeftNavigation}
              position="fixed"
              navMenuMinWidth={navMenuMinWidth}
              navMenuMaxWidth={navMenuMaxWidth}
              viewer={viewer}
              onMenuClick={toggleLeftNavigation}
            />

            <Div
              flex="1"
              display="flex"
              flex-direction="column"
              max-width="100vw"
            >
              <AdminAppBar
                open={isLeftNavigationOpen}
                navMenuMinWidth={navMenuMinWidth}
                navMenuMaxWidth={navMenuMaxWidth}
                onMenuClick={toggleLeftNavigation}
                onBackButton={onBackButton}
                backButtonText={backButtonText}
                viewer={viewer}
                isFullLayoutHeader={isFullLayoutHeader}
                t={t}
              />
              {/* use hidden copy of original element to not implement media queries
                as even media will change Fake will preserve the same sizes
                as real element
            */}
              <FakeAppBar
                position="static"
                data-visibility="hidden"
                style={{ height: isFullLayoutHeader ? '100px' : '56px' }}
              >
                <ToolBar />
              </FakeAppBar>

              {children}
            </Div>
          </Div>
        </Div>
      </BaseTitleProvider>
    </GatewayProvider>
  );
};

export default compose(
  translate('admin'),
  defaultProps({
    navMenuMinWidth: 56,
    navMenuMaxWidth: 170,
  }),
  withStateHandlers(
    { isLeftNavigationOpen: false },
    {
      toggleLeftNavigation: ({ isLeftNavigationOpen }) => () => ({
        isLeftNavigationOpen: !isLeftNavigationOpen,
      }),
      closeLeftNavigation: () => () => ({
        isLeftNavigationOpen: false,
      }),
    },
  ),
  createFragmentContainerHOC({
    viewer: graphql`
      fragment Layoutv2_viewer on User {
        ...LeftNavigation_viewer
        ...userMenu_viewer
        id
      }
    `,
  }),
)(layout);
