// @flow

import * as React from 'react';
import { useTheme } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

import {
  SelectPopover,
  SelectPopoverV2,
  SelectPopoverV3,
} from './select-popover.js';
import { Box, Flex } from 'react-system';

type FilterDialogProps = {|
  bg?: string,
  title: React.Node,
  onClose: (SyntheticEvent<EventTarget>) => void,
  children: React.Node,
  searchEnabled?: boolean,
|};

const FilterDialog = (props: FilterDialogProps): React.Node => {
  const { typography, palette } = useTheme();

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        css={{
          background: props.bg ?? palette.primary.light,
          color: '#fff',
        }}
      >
        <Box py={2} px={3} css={typography.h6}>
          {props.title}
        </Box>
        <Box
          p={1}
          mr={2}
          css={{ lineHeight: 0, cursor: 'pointer' }}
          onClick={props.onClose}
        >
          <IconClose />
        </Box>
      </Flex>
      {props.children}
    </>
  );
};
type Props = {|
  empty?: boolean,
  label: string,
  dialogTitle: React.Node,
  onReset?: () => void,
  children: React.Node,
|};

export const Filter = (props: Props): React.Node => {
  const [open, setOpen] = React.useState(false);

  return (
    <SelectPopover
      label={props.label}
      empty={props.empty}
      open={open}
      onOpenChange={setOpen}
      onClear={props.onReset}
    >
      <FilterDialog title={props.dialogTitle} onClose={() => setOpen(false)}>
        {props.children}
      </FilterDialog>
    </SelectPopover>
  );
};

const FilterDialogV2 = (props: FilterDialogProps): React.Node => {
  const { typography, palette } = useTheme();

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        css={{
          background: '#FFFFFF',
          color: '#333E48',
          margin: '0px 12px',
          borderBottom: `${!props.searchEnabled ? '2px solid #EDF5FE' : ''}`,
        }}
      >
        <Box
          css={{
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '16px',
            padding: '12px 0px',
          }}
        >
          {props.title}
        </Box>
        <Box css={{ lineHeight: 0, cursor: 'pointer' }} onClick={props.onClose}>
          <IconClose
            css={{
              color: '#3455D1',
            }}
          />
        </Box>
      </Flex>
      {props.children}
    </>
  );
};

export const FilterV2 = (props: any) => {
  const [open, setOpen] = React.useState(false);

  return (
    <SelectPopoverV2
      chipWidth={props.chipWidth}
      label={props.label}
      empty={props.empty}
      open={open}
      onOpenChange={setOpen}
      onClear={props.onReset}
    >
      <FilterDialogV2
        searchEnabled={props.searchEnabled}
        title={props.dialogTitle}
        onClose={() => setOpen(false)}
      >
        {props.children}
      </FilterDialogV2>
    </SelectPopoverV2>
  );
};

export const FilterV3 = (props: any) => {
  return (
    <SelectPopoverV3
      chipWidth={props.chipWidth}
      label={props.label}
      empty={props.empty}
      open={props.open}
      onOpenChange={props.setOpen}
      onClear={props.onReset}
      onClose={props.onClose}
    >
      <FilterDialogV2
        searchEnabled={props.searchEnabled}
        title={props.dialogTitle}
        onClose={() => props.setOpen(false)}
      >
        {props.children}
      </FilterDialogV2>
    </SelectPopoverV3>
  );
};
