/* @flow */
/* eslint-disable no-unused-vars */
import { createFragmentContainer } from 'react-relay';
import type { HOC } from 'recompose';

function createFragmentContainerHOC<Enhaced, FragmentProps>(
  query: any,
  nullFragment: ?FragmentProps,
): HOC<{ ...$Exact<Enhaced>, ...$Exact<FragmentProps> }, Enhaced> {
  return base => createFragmentContainer(base, query);
}

export default createFragmentContainerHOC;
