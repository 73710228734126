import { Box, Typography, IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const ModalContent = styled(Box)`
  ${({ theme: { spacing }, width, withPaper = true }) => css`
    width: ${width}px;
    padding: ${spacing(3)}px;
    width: ${width}px;
    min-height: 0px;
    padding: ${spacing(3)}px;
    box-shadow: ${withPaper
      ? '0px 3px 10px 0px rgba(40, 99, 166, 0.15)'
      : 'none'};
    border-radius: ${spacing(2.5)}px;
    background-color: ${withPaper ? ' #fff' : 'transparent'};
  `}
`;

export const ModalTitleWrapper = styled(Box)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #edf5fe;
    padding-bottom: ${spacing(2)}px;
    margin-bottom: ${spacing(1.5)}px;
  `}
`;

export const TitleBox = styled(Box)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    align-items: center;
    gap: ${spacing(2)}px;
  `}
`;

export const ModalTitle = styled(Typography)`
  ${({ theme: { spacing, typography } }) => css`
    ${{ ...typography.body1 }}
    font-size: ${spacing(2.25)}px;
    line-height: ${spacing(2.6)}px;
    color: #333e48;
    font-weight: 600;
  `}
`;

export const StyledIconButton = styled(IconButton)`
  ${() => css`
    padding: 0;
  `}
`;
