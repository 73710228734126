import React, { memo, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropZoneWrapper } from './FileDropZone.styles';
import DragBody from './DragBody';

const FileDropZone = forwardRef(
  ({ className, activeLabel, nonActiveLabel, ...props }, ref) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      ...props,
    });

    return (
      <DropZoneWrapper
        className={className}
        _isDragActive={isDragActive}
        {...getRootProps()}
        ref={ref}
      >
        <input {...getInputProps()} />
        <DragBody
          activeLabel={activeLabel}
          nonActiveLabel={nonActiveLabel}
          isDragActive={isDragActive}
        />
      </DropZoneWrapper>
    );
  },
);

export default memo(FileDropZone);
