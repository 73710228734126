import { graphql } from 'react-relay';
import {
  makeCreateDictionaryMutation,
  makeUpdateDictionaryMutation,
  makeDeleteDictionaryMutation,
} from './dictionaries';

export const createDealStatus = makeCreateDictionaryMutation(
  graphql`
    mutation dealStatusesCreateDealStatusMutation(
      $input: CreateDealStatusInput!
    ) {
      createDealStatus(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'createDealStatus',
  'dealStatus',
);

export const updateDealStatus = makeUpdateDictionaryMutation(
  graphql`
    mutation dealStatusesUpdateDealStatusMutation(
      $input: UpdateDealStatusInput!
    ) {
      updateDealStatus(input: $input) {
        node {
          ...DictionaryItem_item
        }
      }
    }
  `,
  'updateDealStatus',
  'dealStatus',
);

export const deleteDealStatus = makeDeleteDictionaryMutation(
  graphql`
    mutation dealStatusesDeleteDealStatusMutation(
      $input: DeleteDealStatusInput!
    ) {
      deleteDealStatus(input: $input) {
        deletedNodeId
      }
    }
  `,
  'deleteDealStatus',
  'dealStatus',
);
