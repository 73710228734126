import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import {
  compose,
  getContext,
  toClass,
  withHandlers,
  withState,
  withPropsOnChange,
  withProps,
} from 'recompose';
import { Formik } from 'formik';
import {
  Checkbox,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Collapse,
  Typography,
  Box,
  Select,
  MenuItem,
} from '@material-ui/core';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { sortableHandle } from 'react-sortable-hoc';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withTranslation as translate } from 'react-i18next';

import { withBooleanState } from '../../../../utils';
import { createFragmentContainerHOC } from '../../../../utils/relay';
import { withConfirmDialog } from '../../../../components/Dialogs';
import Button from '../../../../components/ButtonProgress';
import { dealStates, pipelineStageGroups } from './DictionaryList';

const Actions = styled(ExpansionPanelActions)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const DragHandleRoot = styled.span`
  display: inline-block;
  cursor: row-resize;
`;

const DragHandle = sortableHandle(() => (
  <DragHandleRoot>
    <DragHandleIcon />
  </DragHandleRoot>
));

// react-sortable-hoc transition conflicts with material-ui ExpansionPanel transition
const RootPanel = styled(ExpansionPanel)`
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const CheckboxesWrapper = styled.div`
  padding-left: 36px;
`;

const fileTypes = [
  {
    id: 'TOMBSTONE',
    name: 'TOMBSTONE',
  },
  {
    id: 'PHOTO',
    name: 'PHOTO',
  },
  {
    id: 'DOCUMENT',
    name: 'DOCUMENT',
  },
  {
    id: 'GENERAL_NDA',
    name: 'GENERAL_NDA',
  },
  {
    id: 'NDA',
    name: 'NDA',
  },
  {
    id: 'SIGNED_NDA',
    name: 'SIGNED_NDA',
  },
  {
    id: 'TEASER',
    name: 'TEASER',
  },
  {
    id: 'BO_SUBMITTED',
    name: 'BO_SUBMITTED',
  },
  {
    id: 'NBO_SUBMITTED',
    name: 'NBO_SUBMITTED',
  },
  {
    id: 'SALES_MEMO',
    name: 'SALES_MEMO',
  },
  {
    id: 'BUILDING_PLAN',
    name: 'BUILDING_PLAN',
  },
  {
    id: 'COMMERCIAL_REGISTER',
    name: 'COMMERCIAL_REGISTER',
  },
  {
    id: 'RENTAL_STATUS',
    name: 'RENTAL_STATUS',
  },
  {
    id: 'LFAIE',
    name: 'LFAIE',
  },
  {
    id: 'POWER_OF_ATTORNY',
    name: 'POWER_OF_ATTORNY',
  },
  {
    id: 'POA',
    name: 'POA',
  },
  {
    id: 'SELLING_ACT',
    name: 'SELLING_ACT',
  },
];

const DictionaryItem = ({
  onSubmit,
  expanded,
  item,
  handleDelete,
  bindInputRef,
  onCancel,
  isNew,
  typename,
  deleteMutation,
  group,
  t,
}) => {
  const fields =
    typename === 'FileType' && group === 'DEAL'
      ? {
          type: isNew ? '' : item.type,
          // RadioGroup works on top of native radio, so we have to use string here
          adminOnlyAccess: String(isNew ? true : item.adminOnlyAccess),
          pipelineStageAccess: isNew
            ? {
                SELECTED_NOTIFIED: false,
                INTERESTED: false,
                NDA_SIGNED: false,
                NBO_SUBMITTED: false,
                VISIT: false,
                DUE_DILIGENCE: false,
                BO_SUBMITTED: false,
                CLOSING: false,
              }
            : item.pipelineStageAccess,
        }
      : (typename === 'FileType' && group === 'SELLER') ? { allowMultiple: item.allowMultiple } : {};
  const dealStateFields =
    typename === 'DealPipelineStage'
      ? {
          dealState: isNew
            ? {
                CALL_SELLER: false,
                MEETING_WITH_SELLER: false,
                INVITE_SELLER_TO_CRE: false,
                MANDATE_ANALYSIS: false,
                MANDATE_SIGNATURE: false,
                TEASER_SALE_MEMO: false,
                TEASER_SALE_MEMO_VALIDATION: false,
                TENDERING_PREPARATION: false,
                NDA_FIRST_CIRCLE: false,
                NDA_SECOND_CIRCLE: false,
                NDA_THIRD_CIRCLE: false,
                NBO_PHASE: false,
                NBO_SELECTION: false,
                BO_PHASE: false,
                BO_SELECTION: false,
                CLOSING: false,
                CLOSING_VALIDATION: false,
                SOLD: false,
              }
            : item.dealState,
        }
      : {};
  return (
    <Formik
      initialValues={{
        labelEn: item.labelEn || '',
        labelFr: item.labelFr || '',
        labelDe: item.labelDe || '',
        id: item.id,
        ...fields,
        ...dealStateFields,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, handleChange, handleBlur }) => (
        <RootPanel
          expanded={expanded.value}
          component="form"
          onSubmit={handleSubmit}
          onChange={(e, value) => {
            // miu and html form both triggers onChange
            if (typeof value !== 'undefined' && !isNew) {
              setTimeout(() => {
                expanded.toggle();
              }, 0);
            }
          }}
        >
          <ExpansionPanelSummary
            expandIcon={!isNew ? <ExpandMoreIcon /> : null}
          >
            {!isNew && (
              <Box pr={2}>
                <DragHandle />
              </Box>
            )}
            <Typography>
              {item.label || values.labelEn || values.labelFr || values.labelDe}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={24}>
              <Grid item xs={12} lg={6}>
                <TextField
                  margin="dense"
                  fullWidth
                  label={t('english')}
                  name="labelEn"
                  id="amount"
                  value={values.labelEn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputRef={bindInputRef}
                  required
                  autoFocus={isNew}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  label={t('french')}
                  name="labelFr"
                  id="amount"
                  value={values.labelFr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  label={t('german')}
                  name="labelDe"
                  id="amount"
                  value={values.labelDe}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {typename === 'FileType' && group === 'DEAL' && (
                  <FormControl
                    component="fieldset"
                    style={{ margin: '10px 0' }}
                  >
                    <FormLabel component="legend">{t('type')}</FormLabel>
                    <Select
                      margin="dense"
                      fullWidth
                      label={t('type')}
                      name="type"
                      id="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={null}>--</MenuItem>
                      {fileTypes.map(ft => (
                        <MenuItem value={ft.id}>{ft.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {typename === 'DealPipelineStage' && (
                  <FormControl
                    component="fieldset"
                    style={{ margin: '10px 0' }}
                  >
                    <CheckboxesWrapper>
                      {dealStates.map(({ value, label }) => (
                        <FormControlLabel
                          key={value}
                          control={
                            <Checkbox
                              name={`dealState.${value}`}
                              checked={values.dealState[value]}
                              onChange={handleChange}
                              defaultValue={false}
                            />
                          }
                          label={label}
                        />
                      ))}
                      <FormHelperText>{t('dealState')}</FormHelperText>
                    </CheckboxesWrapper>
                  </FormControl>
                )}
              </Grid>

              {typename === 'FileType' && group === 'DEAL' && (
                <Grid item xs={12} lg={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{t('fileAccess')}</FormLabel>
                    <RadioGroup
                      name="adminOnlyAccess"
                      value={values.adminOnlyAccess}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={t('fileAccessAdminsOnly')}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={t('fileAccessAdminsAndUsers')}
                      />
                    </RadioGroup>
                    <Collapse in={values.adminOnlyAccess === 'false'}>
                      <CheckboxesWrapper>
                        {pipelineStageGroups.map(({ value, label }) => (
                          <FormControlLabel
                            key={value}
                            control={
                              <Checkbox
                                name={`pipelineStageAccess.${value}`}
                                checked={values.pipelineStageAccess[value]}
                                onChange={handleChange}
                              />
                            }
                            label={label}
                          />
                        ))}
                        <FormHelperText>
                          {t('selectPipelineStagees')}
                        </FormHelperText>
                      </CheckboxesWrapper>
                    </Collapse>
                  </FormControl>
                </Grid>
              )}
              {typename === 'FileType' && group === 'SELLER' && (
                <Grid item xs={12} lg={6}>
                  <FormControl component="fieldset">
                    {/*<FormLabel component="legend">{t('fileAllowMultiple')}</FormLabel>*/}
                    <FormControlLabel
                      key={'allowMultiple'}
                      control={
                        <Checkbox
                          name={`allowMultiple`}
                          checked={values.allowMultiple}
                          onChange={handleChange}
                        />
                      }
                      label={t('fileAllowMultiple')}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
          <Divider />
          <Actions>
            <div>
              <Button
                size="small"
                onClick={isNew ? onCancel : expanded.setFalse}
              >
                {t('button.cancel')}
              </Button>
              <Button size="small" color="primary" type="submit">
                {isNew ? t('button.create') : t('button.submit')}
              </Button>
            </div>
            {!!deleteMutation && (
              <Button size="small" onClick={handleDelete}>
                {t('button.delete')}
              </Button>
            )}
            {!deleteMutation && !isNew && (
              <Tooltip title={t('cantDeleteLastDictionary')}>
                <div style={{ cursor: 'not-allowed' }}>
                  <Button size="small" disabled>
                    {t('button.delete')}
                  </Button>
                </div>
              </Tooltip>
            )}
          </Actions>
        </RootPanel>
      )}
    </Formik>
  );
};

const enhancer = compose(
  translate('settings'),
  createFragmentContainerHOC({
    item: graphql`
      fragment DictionaryItem_item on Dictionary {
        __typename
        id
        label
        position
        labelEn: label(lang: "en")
        labelFr: label(lang: "fr")
        labelDe: label(lang: "de")
        ... on PipelineStage {
          group
        }
        ... on DealStatus {
          group
        }
        ... on FileType {
          group
          type
          adminOnlyAccess
          pipelineStageAccess {
            SELECTED_NOTIFIED
            INTERESTED
            NDA_SIGNED
            NBO_SUBMITTED
            VISIT
            DUE_DILIGENCE
            BO_SUBMITTED
            CLOSING
          }
          allowMultiple
        }
        ... on DealPipelineStage {
          dealState {
            CALL_SELLER
            MEETING_WITH_SELLER
            INVITE_SELLER_TO_CRE
            MANDATE_ANALYSIS
            MANDATE_SIGNATURE
            TEASER_SALE_MEMO
            TEASER_SALE_MEMO_VALIDATION
            TENDERING_PREPARATION
            NDA_FIRST_CIRCLE
            NDA_SECOND_CIRCLE
            NDA_THIRD_CIRCLE
            NBO_PHASE
            NBO_SELECTION
            BO_PHASE
            BO_SELECTION
            CLOSING
            CLOSING_VALIDATION
            SOLD
          }
        }
      }
    `,
  }),
  getContext({
    api: PropTypes.object.isRequired,
  }),
  withBooleanState('expanded', ({ isNew }) => !!isNew), // make default expanded if it is new form
  withState('inputRef', 'setInputRef'),
  withConfirmDialog(),
  withProps(({ item }) => ({ item: item || {} })),
  withHandlers({
    bindInputRef: ({ setInputRef }) => ref => {
      setInputRef(ref);
    },
    handleDelete: ({
      deleteMutation,
      api,
      expanded,
      showConfirm,
      item: { id },
      t,
    }) => async () => {
      showConfirm({
        okText: t('button.delete'),
        onOk: async () => {
          expanded.setFalse();
          try {
            await deleteMutation(api, { id });
          } catch (err) {
            console.error(err);
            expanded.setTrue();
          }
        },
      });
    },
    onSubmit: ({ expanded, handleSubmit }) => async (...args) => {
      expanded.setFalse();
      try {
        await handleSubmit(...args);
      } catch (err) {
        console.error(err);
        expanded.setTrue();
      }
    },
  }),
  withPropsOnChange(
    (oldProps, nextProps) =>
      nextProps.expanded.value && !oldProps.expanded.value,
    props => {
      if (props.inputRef) {
        setTimeout(() => {
          // wait for panel expanding
          props.inputRef.focus();
        }, 200);
      }
    },
  ),
  toClass,
);

export default enhancer(DictionaryItem);
