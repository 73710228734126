/**
 * @flow
 * @relayHash fdbc6dcd8683ca1dca430b7caf9248dd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ACTIVITY_DATE_TIME_PERIOD = "ONE_MONTH" | "ONE_WEEK" | "THREE_MONTH" | "TOMORROW" | "TWO_DAY" | "%future added value";
export type ACTIVITY_IMPORTANT_LEVEL = "HIGH" | "MEDIUM" | "SMALL" | "%future added value";
export type ACTIVITY_STATUS = "CANCELLED" | "COMPLETED" | "MISSED" | "NOT_REACHED" | "PLAN" | "REACHED" | "SUCCESS" | "UPLOADED" | "VALIDATED" | "%future added value";
export type ACTIVITY_TYPE = "CALL" | "DEAL_INTEREST" | "DEAL_INVITED" | "DEAL_VIEW" | "DOCUMENT_VIEW" | "EMAIL" | "LONG_LIST_EDIT" | "NOTE" | "TASK" | "UPLOAD" | "USER_DELETED" | "VISIT" | "%future added value";
export type CreateActivityInput = {|
  parentId: string,
  assigneeId?: ?string,
  note?: ?string,
  subject?: ?string,
  isSuccess?: ?boolean,
  isDone?: ?boolean,
  dueAt?: ?any,
  date?: ?any,
  time?: ?any,
  dateTimePeriod?: ?ACTIVITY_DATE_TIME_PERIOD,
  importanceLevel?: ?ACTIVITY_IMPORTANT_LEVEL,
  status?: ?ACTIVITY_STATUS,
  location?: ?string,
  isFileValidated?: ?boolean,
  lat?: ?number,
  lng?: ?number,
  fileId?: ?string,
  dealPipelineStageId?: ?string,
  type: ACTIVITY_TYPE,
  completedDescription?: ?string,
  dealOwnerId?: ?string,
  dealLeaderBrokerId?: ?string,
  clientMutationId?: ?string,
|};
export type activitiesCreateMutationVariables = {|
  input: CreateActivityInput
|};
export type activitiesCreateMutationResponse = {|
  +createActivity: ?{|
    +activityEdge: ?{|
      +node: ?{|
        +id: string,
        +type: ACTIVITY_TYPE,
        +createdAt: ?any,
        +updatedAt: ?any,
        +dueAt: ?any,
        +doneAt: ?any,
        +parent: ?{|
          +id: string,
          +__typename: string,
        |},
        +note: ?string,
        +subject: ?string,
        +isDone: ?boolean,
        +isSuccess: ?boolean,
        +assignee: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |},
        +creator: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |},
      |}
    |}
  |}
|};
export type activitiesCreateMutation = {|
  variables: activitiesCreateMutationVariables,
  response: activitiesCreateMutationResponse,
|};
*/


/*
mutation activitiesCreateMutation(
  $input: CreateActivityInput!
) {
  createActivity(input: $input) {
    activityEdge {
      node {
        id
        type
        createdAt
        updatedAt
        dueAt
        doneAt
        parent {
          id
          __typename
        }
        note
        subject
        isDone
        isSuccess
        assignee {
          id
          firstName
          lastName
        }
        creator {
          id
          firstName
          lastName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateActivityInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createActivity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateActivityPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "activityEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "ActivityEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Activity",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dueAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "doneAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "parent",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "note",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subject",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isDone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isSuccess",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "assignee",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": (v2/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "creator",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": (v2/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "activitiesCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "activitiesCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "activitiesCreateMutation",
    "id": null,
    "text": "mutation activitiesCreateMutation(\n  $input: CreateActivityInput!\n) {\n  createActivity(input: $input) {\n    activityEdge {\n      node {\n        id\n        type\n        createdAt\n        updatedAt\n        dueAt\n        doneAt\n        parent {\n          id\n          __typename\n        }\n        note\n        subject\n        isDone\n        isSuccess\n        assignee {\n          id\n          firstName\n          lastName\n        }\n        creator {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f5ee6e1d3433992d1d5a90e9d8b281a';
module.exports = node;
