import { combineReducers } from 'redux';
import loading from './loading';
import errors from './errors';
import dialogs from './dialogs';

export default combineReducers({
  loading,
  errors,
  dialogs,
});
