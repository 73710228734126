import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { graphql } from 'react-relay';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  ListItemText,
  ListItem,
  List,
  ListItemIcon,
  colors,
} from '@material-ui/core';
import { withTranslation as translate } from 'react-i18next';
import styled from 'styled-components';
import WarningIcon from '@material-ui/icons/Warning';
import { withSafeTimeout } from '@hocs/safe-timers';

import { createFragmentContainerHOC, withMutation } from '../../utils/relay';
import Button from '../../components/ButtonProgress';
import { withBooleanState } from '../../utils';

const DeleteButton = styled(Button)`
  background-color: ${colors.red[500]};
  color: ${({ theme }) => theme.palette.getContrastText(colors.red[500])};
  :hover {
    background-color: ${colors.red[700]};
  }
`;

const RedIcon = styled(ListItemIcon)`
  color: ${colors.red[800]};
`;

const RedList = styled(List)`
  background-color: ${colors.red[50]};
`;

const DeleteProfileDialog = ({
  t,
  deleteUser,
  open,
  onClose,
  canContinue,
  user,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{t('deleteProfile.title')}</DialogTitle>
    <RedList>
      <ListItem>
        <RedIcon>
          <WarningIcon />
        </RedIcon>
        <ListItemText primary={t('deleteProfile.itWillRemoveData')} />
      </ListItem>
      <ListItem>
        <RedIcon>
          <WarningIcon />
        </RedIcon>
        <ListItemText primary={t('deleteProfile.itCannotBeUndone')} />
      </ListItem>
    </RedList>
    <DialogActions>
      <Button onClick={onClose}>{t('button.cancel')}</Button>
      <DeleteButton
        variant="contained"
        color="primary"
        disabled={!canContinue.value}
        loading={deleteUser.loading}
        onClick={() => {
          deleteUser.mutate({ id: user.id }).then(() => {
            document.location = '/';
          });
        }}
      >
        {t('button.delete')}
      </DeleteButton>
    </DialogActions>
  </Dialog>
);

export const enhance = compose(
  translate('front'),
  createFragmentContainerHOC({
    user: graphql`
      fragment DeleteProfileDialog_user on User {
        id
      }
    `,
  }),
  withBooleanState('canContinue'),
  withSafeTimeout,
  withPropsOnChange(['open'], ({ open, setSafeTimeout, canContinue }) => {
    if (open) {
      setSafeTimeout(() => {
        canContinue.setTrue();
      }, 4000);
    } else {
      canContinue.setFalse();
    }
  }),
  withMutation(
    graphql`
      mutation DeleteProfileDialogDeleteUserMutation($input: DeleteUserInput!) {
        deleteUser(input: $input) {
          deletedUserId
        }
      }
    `,
    'deleteUser',
  ),
);

export default enhance(DeleteProfileDialog);
