/**
 * @flow
 * @relayHash 6b9860d452dc5872fe5aaf01e918d7c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type userMenu_viewer$ref = any;
export type LoginAsUserInput = {|
  userId: string,
  clientMutationId?: ?string,
|};
export type userMenuLoginAsMutationVariables = {|
  input: LoginAsUserInput
|};
export type userMenuLoginAsMutationResponse = {|
  +loginAsUser: ?{|
    +result: boolean,
    +viewer: {|
      +$fragmentRefs: userMenu_viewer$ref
    |},
  |}
|};
export type userMenuLoginAsMutation = {|
  variables: userMenuLoginAsMutationVariables,
  response: userMenuLoginAsMutationResponse,
|};
*/


/*
mutation userMenuLoginAsMutation(
  $input: LoginAsUserInput!
) {
  loginAsUser(input: $input) {
    result
    viewer {
      ...userMenu_viewer
      id
    }
  }
}

fragment userMenu_viewer on User {
  ...UserAvatar_user
  id
  fullName
  isLoggedInAs
  isAdmin
  isSuperAdmin
  realUser {
    ...UserAvatar_user
    id
    fullName
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "LoginAsUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "result",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "primaryImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userMenuLoginAsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "loginAsUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginAsUserPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "userMenu_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userMenuLoginAsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "loginAsUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginAsUserPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isLoggedInAs",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isAdmin",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isSuperAdmin",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "realUser",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "userMenuLoginAsMutation",
    "id": null,
    "text": "mutation userMenuLoginAsMutation(\n  $input: LoginAsUserInput!\n) {\n  loginAsUser(input: $input) {\n    result\n    viewer {\n      ...userMenu_viewer\n      id\n    }\n  }\n}\n\nfragment userMenu_viewer on User {\n  ...UserAvatar_user\n  id\n  fullName\n  isLoggedInAs\n  isAdmin\n  isSuperAdmin\n  realUser {\n    ...UserAvatar_user\n    id\n    fullName\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '663d143e987ff021a2325c0fcdc5fa01';
module.exports = node;
