/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ACTIVITY_TYPE = "CALL" | "DEAL_INTEREST" | "DEAL_INVITED" | "DEAL_VIEW" | "DOCUMENT_VIEW" | "EMAIL" | "LONG_LIST_EDIT" | "NOTE" | "TASK" | "UPLOAD" | "USER_DELETED" | "VISIT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActivityForm_activity$ref: FragmentReference;
declare export opaque type ActivityForm_activity$fragmentType: ActivityForm_activity$ref;
export type ActivityForm_activity = {|
  +id: string,
  +note: ?string,
  +subject: ?string,
  +assignee: ?{|
    +id: string
  |},
  +dueAt: ?any,
  +isDone: ?boolean,
  +type: ACTIVITY_TYPE,
  +$refType: ActivityForm_activity$ref,
|};
export type ActivityForm_activity$data = ActivityForm_activity;
export type ActivityForm_activity$key = {
  +$data?: ActivityForm_activity$data,
  +$fragmentRefs: ActivityForm_activity$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ActivityForm_activity",
  "type": "Activity",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "note",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignee",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9dd5d2e2ce847c682ebbcdfa2d43735';
module.exports = node;
