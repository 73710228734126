import React from 'react';

const FileAttachmentIcon = () => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.90812 4.18063V11.8741C6.90812 13.3939 5.79555 14.7464 4.28303 14.8918C2.5451 15.0591 1.09076 13.6993 1.09076 11.9977V3.00988C1.09076 2.05729 1.7743 1.19196 2.71962 1.09742C3.81037 0.988348 4.72661 1.83914 4.72661 2.90808V10.5434C4.72661 10.9433 4.39938 11.2705 3.99944 11.2705C3.59949 11.2705 3.27227 10.9433 3.27227 10.5434V4.18063C3.27227 3.88249 3.02503 3.63525 2.72689 3.63525C2.42875 3.63525 2.18151 3.88249 2.18151 4.18063V10.4416C2.18151 11.3942 2.86505 12.2595 3.81037 12.354C4.90113 12.4631 5.81736 11.6123 5.81736 10.5434V3.0317C5.81736 1.51191 4.70479 0.159374 3.19228 0.0139395C1.46161 -0.15331 0 1.2065 0 2.90808V11.8305C0 13.9174 1.52706 15.7863 3.60677 15.9826C5.99916 16.2008 7.99887 14.3392 7.99887 11.9977V4.18063C7.99887 3.88249 7.75164 3.63525 7.4535 3.63525C7.15536 3.63525 6.90812 3.88249 6.90812 4.18063Z"
        fill="#3455D1"
      />
    </svg>
  );
};

export default FileAttachmentIcon;
