/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrganisationInvestorProfileForm_regions$ref: FragmentReference;
declare export opaque type OrganisationInvestorProfileForm_regions$fragmentType: OrganisationInvestorProfileForm_regions$ref;
export type OrganisationInvestorProfileForm_regions = $ReadOnlyArray<{|
  +id: string,
  +label: ?string,
  +$refType: OrganisationInvestorProfileForm_regions$ref,
|}>;
export type OrganisationInvestorProfileForm_regions$data = OrganisationInvestorProfileForm_regions;
export type OrganisationInvestorProfileForm_regions$key = $ReadOnlyArray<{
  +$data?: OrganisationInvestorProfileForm_regions$data,
  +$fragmentRefs: OrganisationInvestorProfileForm_regions$ref,
}>;
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrganisationInvestorProfileForm_regions",
  "type": "Region",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f657d71e7ebc09250566ffa09c4b7ff2';
module.exports = node;
