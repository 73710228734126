import React from 'react';
import { compose, pure, getContext, withProps, withHandlers } from 'recompose';
import { PropTypes } from 'prop-types';
import idx from 'idx';
import { graphql } from 'react-relay';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { withFormik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, Checkbox, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withTranslation as translate } from 'react-i18next';

import parseErrorsLegacy from '../../routes/admin/utils/parseErrorsLegacy';
import {
  uniMaterial,
  uniMaterial2x,
} from '../../routes/admin/controls/UniStyled';
import InputNumber from '../../routes/admin/controls/InputNumberNew';
import {
  createFragmentContainerHOC,
  withMutation,
} from '../../utils/relay/index';
import mapKeys from '../../routes/admin/utils/mapKeys';
import hasIntersection from '../../routes/admin/utils/hasIntersection';
import Button from '../ButtonProgress';
import { isDisabledSaveOrganisation } from '../../utils/checkOrganisationPermission';
import VirtualLoadableSelect from '../../routes/admin/controls/VirtualLoadableSelectHiddenValue';

const Item = styled(uniMaterial2x(Grid)).attrs({
  // TextField error breaks height otherwise
  // 'data-height': '66px',
  item: true,
})``;

const LItem = styled(Grid).attrs({ item: true })`
  height: 40px;
`;

const Container = styled(uniMaterial(Grid)).attrs({
  container: true,
})``;

const SaveIndicator = styled(LinearProgress)`
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

const ErrorText = styled(uniMaterial(FormHelperText)).attrs({ error: true })`
  padding-left: 8px;
  padding-bottom: 8px;
  word-wrap: break-word;
`;

const LocalCheckBox = pure(
  withHandlers({
    onClick: ({ setFieldValue, setFieldTouched, id, value, values }) => () => {
      //
      setFieldValue(
        id,
        values.map(u => ({
          ...u,
          value: u.id === value.id ? !u.value : u.value,
        })),
      );
      setFieldTouched(id);
    },
  })(({ onClick, value, id, label }) => (
    <FormControlLabel
      control={
        <StyledCheckbox checked={value.value} onClick={onClick} id={id} />
      }
      label={label}
    />
  )),
);

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  fieldset {
    border: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiButtonBase-root {
    transform: none !important ;
  }
  label.Mui-focused {
    top: 12px;
  }
  label.MuiInputLabel-shrink {
    top: 12px;
  }
  input {
    padding-top: 13px;
  }
`;

const StyledItemHalfLine = styled(Item)`
  padding: 0px 6px 0px 12px;
`;
const StyledItemFullLine = styled(Item)`
  padding: 0px 12px !important;
`;

const StyledFirstSection = styled.div`
  display: flex;
  padding: 12px;
  gap: 12px;
  width: 100%;
`;

const StyledSectionHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #333e48;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 8px;
  border-bottom: 2px solid #edf5fe;
  margin-bottom: 18px;
  margin-left: 12px;
  width: 100%;
  margin-right: 12px;
  margin-top: 20px;
`;
const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  .MuiSvgIcon-root {
    fill: ${props => (props.checked ? `#3455D1` : `#d6dfea`)};
  }
  padding-right: 8px;
`;
const StyledFormLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 8px;
  border: 1px solid #d6dfea;
  border-radius: 5px;
  color: #333e48;
  span {
    display: flex;
    height: 14px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 93px;
`;

const SelectAll = styled.div`
  position: absolute;
  right: 16px;
  top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3455d1;
  cursor: pointer;
`;

const Region = styled.div`
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6dfea;
  border-radius: 75px;
  margin-top: 6px;
  padding: 7px 16px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333e48;
  }
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .icon {
    font-size: 16px;
    cursor: pointer;
  }
`;

const StyledHeader = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #333e48;
`;

export const organisationDetailsInvestorProfileForm = ({
  values,
  setValues,
  errors,
  handleChange,
  setFieldTouched,
  handleSubmit,
  setFieldValue,
  handleReset,
  isSubmitting,
  dirty,
  touched,
  isNewOrganisation,
  router,
  t,
  organisation,
  currentUser,
  onUpdateData,
}) => {
  const onSelectAll = type => {
    setValues({
      ...values,
      [type]: values[type].map(v => ({
        ...v,
        value: true,
      })),
    });
  };

  const onSelectAllStrategies = () => {
    setValues({
      ...values,
      core: true,
      corePlus: true,
      valueAdd: true,
      opportunistic: true,
    });
  };

  const onSelectAllOthers = () => {
    setValues({
      ...values,
      onlyIndirect: true,
      lexKollerCompliant: true,
    });
  };

  const handleChangeRegion = event => {
    const selectedId = event.target.value.id;
    let { regions } = values;
    regions = regions.map(v => {
      if (v.id === selectedId) {
        return {
          ...v,
          value: !v.value,
        };
      }
      return v;
    });
    setValues({
      ...values,
      regions: regions,
    });
  };

  const onDeleteRegion = id => {
    let { regions } = values;
    regions = regions.map(v => {
      if (v.id === id) {
        return {
          ...v,
          value: false,
        };
      }
      return v;
    });
    setValues({
      ...values,
      regions: regions,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <SaveIndicator />}
      <Container spacing={2}>
        <Grid item sm={12}>
          <StyledHeader>Deal preferences</StyledHeader>
        </Grid>

        <Grid xs={12}>
          <Grid container>
            <StyledSectionHeader>Budget</StyledSectionHeader>
            <StyledItemFullLine xs={6} data-height="60px">
              <InputNumber
                id="minAssetSize"
                type="tel"
                label={t('minAssetSize')}
                value={values.minAssetSize || ''}
                onChange={handleChange}
                error={Boolean(touched.minAssetSize && errors.minAssetSize)}
                helperText={touched.minAssetSize && errors.minAssetSize}
                fullWidth
              />
            </StyledItemFullLine>
            <StyledItemFullLine xs={6} data-height="60px">
              <InputNumber
                id="maxAssetSize"
                type="tel"
                label={t('maxAssetSize')}
                value={values.maxAssetSize || ''}
                onChange={handleChange}
                error={Boolean(touched.maxAssetSize && errors.maxAssetSize)}
                helperText={touched.maxAssetSize && errors.maxAssetSize}
                fullWidth
              />
            </StyledItemFullLine>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid container style={{ position: 'relative' }}>
            <SelectAll
              onClick={() => {
                onSelectAll('useTypes');
              }}
            >
              Select all
            </SelectAll>
            <StyledSectionHeader>Property types</StyledSectionHeader>
            <Grid container spacing={2} style={{ padding: '0px 20px' }}>
              {values.useTypes.map(useType => (
                <LItem key={useType.id}>
                  <LocalCheckBox
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={useType}
                    values={values.useTypes}
                    id="useTypes"
                    label={useType.label}
                  />
                </LItem>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Grid container>
            <StyledSectionHeader>{t('regions')}</StyledSectionHeader>
            <Grid container spacing={2} style={{ padding: '0px 20px' }}>
              <Item sm={12} xs={12} style={{ padding: '0px' }}>
                <VirtualLoadableSelect
                  id="regions"
                  onChange={handleChangeRegion}
                  options={values.regions}
                  labelKey="label"
                  valueKey="id"
                  variant="filled"
                />
              </Item>
              <Item sm={12} xs={12} style={{ padding: '0px' }}>
                {values.regions
                  .filter(v => v.value)
                  .map(r => (
                    <Region>
                      <span>{r.label}</span>
                      <Icon
                        className="icon"
                        onClick={() => onDeleteRegion(r.id)}
                      >
                        <img src="/icon/delete.svg" />
                      </Icon>
                    </Region>
                  ))}
              </Item>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Grid container style={{ position: 'relative' }}>
            <SelectAll
              onClick={() => {
                onSelectAll('purchaseTypes');
              }}
            >
              Select all
            </SelectAll>
            <StyledSectionHeader>{t('purchaseType')}</StyledSectionHeader>
            <Grid container spacing={2} style={{ padding: '0px 20px' }}>
              {values.purchaseTypes?.map(purchaseType => (
                <LItem key={purchaseType.id}>
                  <LocalCheckBox
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={purchaseType}
                    values={values.purchaseTypes}
                    id="purchaseTypes"
                    label={purchaseType.label}
                  />
                </LItem>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Grid container style={{ position: 'relative' }}>
            <SelectAll onClick={onSelectAllStrategies}>Select all</SelectAll>
            <StyledSectionHeader>{t('strategies')}</StyledSectionHeader>
            <Grid container spacing={2} style={{ padding: '0px 20px' }}>
              <LItem>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.core}
                      onClick={() => {
                        setFieldValue('core', !values.core);
                        setFieldTouched('core');
                      }}
                      id="core"
                    />
                  }
                  label={t('strategy.core')}
                />
              </LItem>
              <LItem>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.corePlus}
                      onClick={() => {
                        setFieldValue('corePlus', !values.corePlus);
                        setFieldTouched('corePlus');
                      }}
                      id="corePlus"
                    />
                  }
                  label={t('strategy.corePlus')}
                />
              </LItem>
              <LItem>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.valueAdd}
                      onClick={() => {
                        setFieldValue('valueAdd', !values.valueAdd);
                        setFieldTouched('valueAdd');
                      }}
                      id="valueAdd"
                    />
                  }
                  label={t('strategy.valueAdd')}
                />
              </LItem>
              <LItem>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.opportunistic}
                      onClick={() => {
                        setFieldValue('opportunistic', !values.opportunistic);
                        setFieldTouched('opportunistic');
                      }}
                      id="opportunistic"
                    />
                  }
                  label={t('strategy.opportunistic')}
                />
              </LItem>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Grid container style={{ position: 'relative' }}>
            <SelectAll onClick={onSelectAllOthers}>Select all</SelectAll>
            <StyledSectionHeader>{t('other')}</StyledSectionHeader>
            <Grid container spacing={2} style={{ padding: '0px 20px' }}>
              <Item data-height="40px">
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.lexKollerCompliant}
                      onClick={() => {
                        setFieldValue(
                          'lexKollerCompliant',
                          !values.lexKollerCompliant,
                        );
                        setFieldTouched('lexKollerCompliant');
                      }}
                      id="lexKollerCompliant"
                    />
                  }
                  label={t('lexKollerCompliant')}
                />
              </Item>
              <Item data-height="40px">
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.onlyIndirect}
                      onClick={() => {
                        setFieldValue('onlyIndirect', !values.onlyIndirect);
                        setFieldTouched('onlyIndirect');
                      }}
                      id="onlyIndirect"
                    />
                  }
                  label={t('onlyIndirectInvestments')}
                />
              </Item>
            </Grid>
          </Grid>
        </Grid>

        {/*  Router is available only on /admin  */}
        {!!router && (
          <Prompt
            when={!(!dirty || hasIntersection(errors, touched))}
            message={t('detailsNotSaved')}
          />
        )}

        <Grid item sm={12} xs={12}>
          <Container
            data-padding-right="16px"
            spacing={3}
            justify="flex-end"
            alignItems="center"
          >
            {(errors.$others || hasIntersection(errors, touched)) && (
              <Grid item sm={isNewOrganisation ? 12 : 8}>
                <ErrorText data-text-align="right">
                  {errors.$others || t('formHasErrors')}
                </ErrorText>
              </Grid>
            )}
            <Grid item sm={isNewOrganisation ? 12 : 4}>
              <Container
                data-height="70px"
                justify="flex-end"
                alignItems="center"
                data-flex-wrap="nowrap"
              >
                <StyledButton
                  onClick={handleReset}
                  disabled={
                    !dirty ||
                    isSubmitting ||
                    isDisabledSaveOrganisation(
                      isNewOrganisation,
                      currentUser,
                      organisation,
                    )
                  }
                >
                  {t('button.cancel')}
                </StyledButton>
                <StyledButton
                  color={
                    hasIntersection(errors, touched) ? 'accent' : 'primary'
                  }
                  type="submit"
                  variant="contained"
                  disabled={
                    !dirty ||
                    isSubmitting ||
                    hasIntersection(errors, touched) ||
                    isDisabledSaveOrganisation(
                      isNewOrganisation,
                      currentUser,
                      organisation,
                    )
                  }
                  loading={isSubmitting}
                >
                  Save changes
                </StyledButton>
              </Container>
            </Grid>
          </Container>
        </Grid>
      </Container>
    </form>
  );
};

const fragments = {
  organisation: graphql`
    fragment OrganisationInvestorProfileForm_organisation on Organisation {
      id
      minAssetSize
      maxAssetSize
      assetsUnderManagement
      lexKollerCompliant
      useTypeIds
      regionIds
      purchaseTypeIds
      core
      corePlus
      valueAdd
      opportunistic
      onlyIndirect
      owner {
        id
        fullName
        isBroker
        organisationId
      }
    }
  `,
  useTypes: graphql`
    fragment OrganisationInvestorProfileForm_useTypes on UseType
      @relay(plural: true) {
      id
      label
    }
  `,
  regions: graphql`
    fragment OrganisationInvestorProfileForm_regions on Region
      @relay(plural: true) {
      id
      label
    }
  `,
  purchaseTypes: graphql`
    fragment OrganisationInvestorProfileForm_purchaseTypes on PurchaseType
      @relay(plural: true) {
      id
      label
    }
  `,
};

export const organisationDetailsInvestorProfileFormHOC = compose(
  translate('organisations'),
  createFragmentContainerHOC(fragments),
  getContext({
    api: PropTypes.shape({
      commitMutation: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.object,
  }),
  withMutation(
    graphql`
      mutation OrganisationInvestorProfileFormUpdateOrganisationMutation(
        $input: UpdateOrganisationInput!
      ) {
        updateOrganisation(input: $input) {
          organisation {
            id
            ...OrganisationInvestorProfileForm_organisation
          }
        }
      }
    `,
    'updateOrganisation',
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({
      organisation: {
        core,
        corePlus,
        valueAdd,
        opportunistic,
        ...organisation
      },
      useTypes,
      regions,
      purchaseTypes,
    }) => ({
      minAssetSize: organisation.minAssetSize,
      maxAssetSize: organisation.maxAssetSize,
      lexKollerCompliant: !!organisation.lexKollerCompliant,
      onlyIndirect: !!organisation.onlyIndirect,
      assetsUnderManagement: organisation.assetsUnderManagement,
      core,
      corePlus,
      valueAdd,
      opportunistic,
      useTypes: useTypes.map(({ id, label }) => ({
        id,
        label,
        value: organisation.useTypeIds.indexOf(id) > -1,
      })),
      regions: regions.map(({ id, label }) => ({
        id,
        label,
        value: organisation.regionIds.indexOf(id) > -1,
      })),
      purchaseTypes: purchaseTypes
        ? purchaseTypes.map(({ id, label }) => ({
            id,
            label,
            value: organisation.purchaseTypeIds.indexOf(id) > -1,
          }))
        : [],
    }),
    validate: () => {},
    handleSubmit: async (
      values,
      {
        props: { organisation, updateOrganisation, onUpdateData },
        setErrors,
        resetForm,
        setSubmitting,
      },
    ) => {
      const { useTypes, regions, purchaseTypes, ...rest } = values;
      const input = {
        ...rest,
        id: organisation.id,
        regionIds: regions
          ? regions.filter(({ value }) => value).map(({ id }) => id)
          : undefined,
        useTypeIds: useTypes
          ? useTypes.filter(({ value }) => value).map(({ id }) => id)
          : undefined,
        purchaseTypeIds: purchaseTypes
          ? purchaseTypes.filter(({ value }) => value).map(({ id }) => id)
          : undefined,
      };
      try {
        await updateOrganisation.mutate(input);
        setSubmitting(false);
        resetForm();
        if (onUpdateData) onUpdateData();
      } catch (errs) {
        console.error(
          'Error while trying to update organisation investor profile',
          errs,
        );
        setSubmitting(false);
        const errors = parseErrorsLegacy(errs, values);
        console.error(
          'Parsed Error while trying to update organisation investor profile',
          errors,
          errs,
        );

        setErrors(errors);
      }
    },
  }),
  withProps(({ errors, organisation }) => ({
    isNewOrganisation: !organisation,
    // YUP provide errors for subproperties like { 'language.code': 'blabla' }
    // so to not complicate render transform such errors into {language: 'blabla'}
    errors: mapKeys(key => key.split('.')[0], errors),
  })),
  withHandlers({
    // https://github.com/jaredpalmer/formik/issues/114 will be fixed soon
    handleChange: ({ handleChange, handleBlur, touched }) => e => {
      handleChange(e);

      if (!touched[e.target.id]) {
        Promise.resolve().then(() => handleBlur(e));
      }
    },
  }),
);

export default organisationDetailsInvestorProfileFormHOC(
  organisationDetailsInvestorProfileForm,
);
