// @flow

import React from 'react';
import { graphql } from 'react-relay';
import {
  Card,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Button,
  colors,
  ListItemAvatar,
  Container,
  Box,
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { route } from 'navi';
import { useQuery } from 'relay-hooks';

import OrganisationInvestorProfileForm from '../../components/OrganisationInvestorProfileForm';
import UserProfileForm from '../components/UserProfileForm';
import DeleteProfileDialog from '../components/DeleteProfileDialog';
import UserAvatar from '../../components/UserAvatar';
import { withAuthentication } from '../../utils/withAuthentication';

const FormCard = styled(Card)`
  margin-bottom: 24px;
  padding: 24px;
`;

const DeleteButton = styled(Button).attrs({ variant: 'outlined' })`
  color: ${colors.red[500]};
`;

const DeleteContainer = styled.div`
  padding: 36px;
  text-align: center;
`;

const query = graphql`
  query profileQuery {
    viewer {
      id
      ...UserProfileForm_user
      ...DeleteProfileDialog_user
      organisation {
        name
        users {
          id
          fullName
          jobTitle
          ...UserAvatar_user
        }
        ...OrganisationInvestorProfileForm_organisation
      }
    }

    useTypes {
      ...OrganisationInvestorProfileForm_useTypes
    }
    regions {
      ...OrganisationInvestorProfileForm_regions
    }
  }
`;

const ProfilePage = () => {
  const { t } = useTranslation('front');
  const { props } = useQuery({
    query,
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  if (!props?.viewer) return null;

  const { viewer, useTypes, regions } = props;

  return (
    <Box mt={4}>
      <Container>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <UserProfileForm user={viewer} />
            <FormCard id="investor">
              {viewer && viewer.organisation && (
                <OrganisationInvestorProfileForm
                  organisation={viewer.organisation}
                  useTypes={useTypes}
                  regions={regions}
                />
              )}
            </FormCard>
            <FormCard id="team">
              <Typography variant="h6">{t('myTeam')}</Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="textSecondary"
              >
                {viewer.organisation.name}
              </Typography>
              <List dense>
                {viewer.organisation.users.map(member => (
                  <ListItem key={member.id} disableGutters>
                    <ListItemAvatar>
                      <UserAvatar user={member} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={member.fullName}
                      secondary={member.jobTitle}
                    />
                  </ListItem>
                ))}
              </List>
            </FormCard>
            <DeleteProfileDialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              user={viewer}
            />
            <DeleteContainer>
              <DeleteButton onClick={() => setDeleteDialogOpen(true)}>
                {t('deleteProfile.openDialogButton')}
              </DeleteButton>
            </DeleteContainer>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withAuthentication({})(
  route({
    title: 'Profile page',
    view: <ProfilePage />,
  }),
);
