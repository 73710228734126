import { graphql } from 'react-relay';
import promiseify from '../../../../utils/promiseify';
import getRootFieldName from '../../../../utils/relay/getRootFieldName';

export const updateSettings = promiseify((api, input, onCompleted) => {
  const mutation = graphql`
    mutation settingsUpdateSettingsMutation($input: UpdateSettingsInput!) {
      updateSettings(input: $input) {
        settings {
          adminEmails
        }
      }
    }
  `;

  api.commitMutation({
    mutation,
    variables: {
      input,
    },
    onError(err) {
      onCompleted(undefined, err.res.errors);
    },
    onCompleted(data, errs) {
      if (errs) {
        onCompleted(data, errs);
      } else {
        const rootField = getRootFieldName(api.environment, mutation);
        onCompleted(data[rootField], errs);
      }
    },
  });
});
