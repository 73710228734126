import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { compose, getContext, withPropsOnChange } from 'recompose';
import { graphql, QueryRenderer } from 'react-relay';
import PropTypes from 'prop-types';

import Layoutv2 from '../../components/Layoutv2';
import Organisations from './Organisations';
import Users from './Users';
import Deals from './Deals';
import Settings from './Settings';
import GlobalSearch from './GlobalSearch';
import { Div } from './controls/UniStyled';
import Tracker from './Tracker';
import Dashboard from './Dashboard';
import Dialogs from '../../components/Dialogs';
import { detectLanguage, withChangeLanguage } from '../../i18n';
import { withTranslation } from 'react-i18next';

const trackingData = {
  userAgent: navigator.userAgent || 'unknown',
  language:
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    'unknown',
  devicePixelRatio: window.devicePixelRatio || 'unknown',
  screen: [window.screen.width, window.screen.height],
  cpuClass: navigator.cpuClass || 'unknown',
  platform: navigator.platform || 'unknown',
};

const app = ({ viewer = null }) => (
  <Layoutv2 viewer={viewer}>
    <CssBaseline />
    <Dialogs />
    <Tracker event="AdminPage" data={trackingData} />
    <Switch>
      <Route
        path="/organisations"
        children={(props) => {
          return (
            <Organisations rootViewer={viewer} {...props}/>
          );
        }}
      />
      <Route path="/individuals" component={Users} />
      {/* <Route path="/deals" component={Deals} /> */}
      {!viewer?.isInvestor && (
        <Route
          path="/deals"
          children={(props) => {
            return (
              <Deals rootViewer={viewer} {...props}/>
            );
          }}
        />
      )}
      { viewer && viewer.isSuperAdmin && (<Route path="/settings" component={Settings} />)}
      <Route
        path="/search/:search?"
        component={p => <GlobalSearch isPage {...p} />}
      />

      <Route exact path="/" component={Dashboard} />
      <Route
        render={() => (
          <Div
            flex="1"
            display="flex"
            justify-content="center"
            align-items="center"
            flex-direction="column"
            color="#999"
          >
            <Div font-size="156px">404</Div>
            <Div>PAGE NOT FOUND</Div>
          </Div>
        )}
      />
    </Switch>
  </Layoutv2>
);

const App = compose(
  withTranslation('admin'),
  withPropsOnChange(['viewer'], ({ i18n, viewer }) => {
    i18n.changeLanguage(detectLanguage(viewer));
    if (viewer && !(viewer.isAdmin || viewer.isSuperAdmin)) {
      window.location = '/';
    }
  }),
)(app);

const query = graphql`
  query adminQuery {
    viewer {
      id
      language
      isAdmin
      isBroker
      isSeller
      isInvestor
      isSuperAdmin
      ...Layoutv2_viewer
      organisation {
        id
        isInvestor
        isSeller
        isBroker
      }
    }
  }
`;

export default getContext({
  api: PropTypes.object.isRequired,
})(({ api, ...rest }) => (
  <QueryRenderer
    environment={api.environment}
    query={query}
    render={({ props }) => <App {...props} {...rest} />}
  />
));
