// @flow

import { connect, useDispatch } from 'react-redux';
import {
  dialogsActions,
  type ConfirmOptions,
} from '../../redux/reducers/dialogs';

export default () =>
  connect(
    null,
    dispatch => ({
      hideConfirm: () => dispatch(dialogsActions.confirm.hide()),
      showConfirm: options => dispatch(dialogsActions.confirm.show(options)),
    }),
  );

export const useConfirmDialog = () => {
  const dispatch = useDispatch();

  return {
    hideConfirm: () => dispatch(dialogsActions.confirm.hide()),
    showConfirm: (options: ConfirmOptions) =>
    {
      return dispatch(dialogsActions.confirm.show(options));
    },
  };
};
