/**
 * @flow
 * @relayHash 74b48ee3a489754b3ef6e5f112bf8906
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LanguagePicker_viewer$ref = any;
type UserAvatar_user$ref = any;
type userMenu_viewer$ref = any;
export type frontendLayoutQueryVariables = {||};
export type frontendLayoutQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +fullName: ?string,
    +isActive: boolean,
    +isAdmin: boolean,
    +isSuperAdmin: boolean,
    +isLoggedInAs: boolean,
    +realUser: ?{|
      +id: string,
      +fullName: ?string,
      +$fragmentRefs: UserAvatar_user$ref,
    |},
    +$fragmentRefs: UserAvatar_user$ref & LanguagePicker_viewer$ref & userMenu_viewer$ref,
  |}
|};
export type frontendLayoutQuery = {|
  variables: frontendLayoutQueryVariables,
  response: frontendLayoutQueryResponse,
|};
*/


/*
query frontendLayoutQuery {
  viewer {
    id
    firstName
    lastName
    fullName
    isActive
    isAdmin
    isSuperAdmin
    isLoggedInAs
    realUser {
      ...UserAvatar_user
      id
      fullName
    }
    ...UserAvatar_user
    ...LanguagePicker_viewer
    ...userMenu_viewer
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}

fragment LanguagePicker_viewer on User {
  id
  language
}

fragment userMenu_viewer on User {
  ...UserAvatar_user
  id
  fullName
  isLoggedInAs
  isAdmin
  isSuperAdmin
  realUser {
    ...UserAvatar_user
    id
    fullName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isActive",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdmin",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperAdmin",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isLoggedInAs",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "FragmentSpread",
  "name": "UserAvatar_user",
  "args": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "primaryImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "frontendLayoutQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "realUser",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/)
            ]
          },
          (v8/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "LanguagePicker_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "userMenu_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "frontendLayoutQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "realUser",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v9/*: any*/)
            ]
          },
          (v9/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "language",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "frontendLayoutQuery",
    "id": null,
    "text": "query frontendLayoutQuery {\n  viewer {\n    id\n    firstName\n    lastName\n    fullName\n    isActive\n    isAdmin\n    isSuperAdmin\n    isLoggedInAs\n    realUser {\n      ...UserAvatar_user\n      id\n      fullName\n    }\n    ...UserAvatar_user\n    ...LanguagePicker_viewer\n    ...userMenu_viewer\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n\nfragment LanguagePicker_viewer on User {\n  id\n  language\n}\n\nfragment userMenu_viewer on User {\n  ...UserAvatar_user\n  id\n  fullName\n  isLoggedInAs\n  isAdmin\n  isSuperAdmin\n  realUser {\n    ...UserAvatar_user\n    id\n    fullName\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f71aaf8b7bbd69c35fc5920a22145600';
module.exports = node;
