/**
 * @flow
 * @relayHash a8849af47754e3888ed154bb65e36742
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type USER_LANGUAGE = "de" | "en" | "fr" | "it" | "%future added value";
export type frontAppQueryVariables = {||};
export type frontAppQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +fullName: ?string,
    +language: ?USER_LANGUAGE,
    +isAdmin: boolean,
    +isSuperAdmin: boolean,
  |}
|};
export type frontAppQuery = {|
  variables: frontAppQueryVariables,
  response: frontAppQueryResponse,
|};
*/


/*
query frontAppQuery {
  viewer {
    id
    fullName
    language
    isAdmin
    isSuperAdmin
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fullName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "language",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isAdmin",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSuperAdmin",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "frontAppQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "frontAppQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "frontAppQuery",
    "id": null,
    "text": "query frontAppQuery {\n  viewer {\n    id\n    fullName\n    language\n    isAdmin\n    isSuperAdmin\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eef1a8931784ec6e2938b85507160d26';
module.exports = node;
