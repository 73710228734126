/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ActivityForm_activity$ref = any;
export type ACTIVITY_TYPE = "CALL" | "DEAL_INTEREST" | "DEAL_INVITED" | "DEAL_VIEW" | "DOCUMENT_VIEW" | "EMAIL" | "LONG_LIST_EDIT" | "NOTE" | "TASK" | "UPLOAD" | "USER_DELETED" | "VISIT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActivityCard_activity$ref: FragmentReference;
declare export opaque type ActivityCard_activity$fragmentType: ActivityCard_activity$ref;
export type ActivityCard_activity = {|
  +id: string,
  +isDone: ?boolean,
  +isSuccess: ?boolean,
  +updatedAt: ?any,
  +createdAt: ?any,
  +dueAt: ?any,
  +doneAt: ?any,
  +note: ?string,
  +subject: ?string,
  +deal: ?{|
    +id: string
  |},
  +leadData: ?{|
    +id: string,
    +isInterested: ?boolean,
    +refusalReasonText: ?string,
    +refusalReasons: $ReadOnlyArray<{|
      +id: string,
      +label: ?string,
    |}>,
  |},
  +parent: ?{|
    +__typename: string,
    +id: string,
    +deal?: ?{|
      +id: string,
      +name: ?string,
    |},
    +organisation?: ?{|
      +id: string,
      +name: ?string,
    |},
    +name?: ?string,
    +image?: ?{|
      +url: string
    |},
    +fullName?: ?string,
  |},
  +creator: ?{|
    +id: string,
    +fullName: ?string,
  |},
  +type: ACTIVITY_TYPE,
  +user: ?{|
    +id: string,
    +fullName: ?string,
  |},
  +assignee: ?{|
    +id: string,
    +fullName: ?string,
  |},
  +email: ?{|
    +id: string
  |},
  +file: ?{|
    +id: string,
    +filename: string,
    +size: ?number,
    +mimetype: ?string,
    +url: ?string,
  |},
  +$fragmentRefs: ActivityForm_activity$ref,
  +$refType: ActivityCard_activity$ref,
|};
export type ActivityCard_activity$data = ActivityCard_activity;
export type ActivityCard_activity$key = {
  +$data?: ActivityCard_activity$data,
  +$fragmentRefs: ActivityCard_activity$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v5/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "ActivityCard_activity",
  "type": "Activity",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSuccess",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "doneAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "note",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deal",
      "storageKey": null,
      "args": null,
      "concreteType": "Deal",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "leadData",
      "storageKey": null,
      "args": null,
      "concreteType": "Lead",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isInterested",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "refusalReasonText",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "refusalReasons",
          "storageKey": null,
          "args": null,
          "concreteType": "NotInterestedReason",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parent",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "type": "Lead",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "deal",
              "storageKey": null,
              "args": null,
              "concreteType": "Deal",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "organisation",
              "storageKey": null,
              "args": null,
              "concreteType": "Organisation",
              "plural": false,
              "selections": (v3/*: any*/)
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "Deal",
          "selections": [
            (v2/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "image",
              "storageKey": null,
              "args": null,
              "concreteType": "Image",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "User",
          "selections": [
            (v5/*: any*/)
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "Organisation",
          "selections": [
            (v2/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "creator",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignee",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "email",
      "storageKey": null,
      "args": null,
      "concreteType": "Email",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "file",
      "storageKey": null,
      "args": null,
      "concreteType": "File",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "size",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mimetype",
          "args": null,
          "storageKey": null
        },
        (v4/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ActivityForm_activity",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0eebf2a180ebe6c74fde04ffb3ce70a1';
module.exports = node;
