import React from 'react';
import {
  Select as MiuSelect,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Switch as MiuSwitch,
  Checkbox as MiuCheckbox,
  FormControlLabel,
  MenuItem,
  FormGroup,
} from '@material-ui/core';
import { withProps, withState, compose } from 'recompose';
import { format, parse } from 'date-fns';
import InputMask from 'react-input-mask';

// TODO: keep DRY

export const Input = ({
  field,
  form: { touched, errors },
  id,
  helperText,
  ...rest
}) => (
  <TextField
    id={field.name || id}
    {...field}
    error={touched[field.name] && !!errors[field.name]}
    helperText={
      touched[field.name] && !!errors[field.name]
        ? errors[field.name]
        : helperText
    }
    {...rest}
  />
);

const hasDatetimeSupport =
  window &&
  window.document &&
  (() => {
    const input = window.document.createElement('input');
    try {
      input.type = 'datetime-local';
      return input.type === 'datetime-local';
    } catch (e) {
      return false;
    }
  })();

const toDatetimeString = date =>
  format(date, hasDatetimeSupport ? "yyyy-MM-dd'T'HH:mm" : 'dd/MM/yyyy HH:mm');
const fromDatetimeString = str =>
  parse(
    str,
    hasDatetimeSupport ? "yyyy-MM-dd'T'HH:mm" : 'dd/MM/yyyy HH:mm',
    new Date(),
  );

const dateTimeHOC = compose(
  withState('inputValue', 'setInputValue', props =>
    props.field.value ? toDatetimeString(new Date(props.field.value)) : '',
  ),
  withProps(
    ({
      inputValue,
      setInputValue,
      form,
      field: { name, onChange },
      id,
      helperText,
      inputProps = {},
      InputProps = {},
      min,
      max,
    }) => ({
      onChange: event => {
        let eventValue = event.target.value;
        setInputValue(eventValue);
        if (eventValue) {
          try {
            eventValue = fromDatetimeString(eventValue).toISOString();
          } catch (e) {
            return;
          }
        }
        onChange({
          persist: () => {},
          target: {
            id,
            name,
            value: eventValue,
          },
        });
      },
      hasError: form.touched && !!form.errors[name],
      helperText:
        form.touched && !!form.errors[name] ? form.errors[name] : helperText,
      id: id || name,
      inputValue,
      inputProps: {
        ...(hasDatetimeSupport
          ? {
              min: min && toDatetimeString(new Date(min)),
              max: max && toDatetimeString(new Date(max)),
            }
          : {
              mask: '99/99/9999 99:99',
              placeholder: 'dd/mm/yyyy --:--',
              maskChar: '',
            }),
        ...inputProps,
      },
      InputProps: {
        inputComponent: hasDatetimeSupport ? undefined : InputMask,
        ...InputProps,
      },
    }),
  ),
);

export const DateTime = dateTimeHOC(
  ({
    hasError,
    helperText,
    min,
    max,
    InputLabelProps = {},
    inputValue,
    setInputValue,
    ...rest
  }) => (
    <TextField
      type="datetime-local"
      error={hasError}
      helperText={helperText}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      {...rest}
      value={inputValue}
    />
  ),
);

export const Switch = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  label,
  margin,
  fullWidth,
  ...rest
}) => (
  <FormGroup margin={margin} style={{ flex: fullWidth ? 1 : 'auto' }}>
    <FormControlLabel
      control={
        <MiuSwitch
          checked={value}
          onChange={(event, checked) => {
            setFieldTouched(name);
            setFieldValue(name, checked);
          }}
          {...rest}
        />
      }
      label={label}
    />
  </FormGroup>
);

export const Checkbox = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  label,
  margin,
  fullWidth,
  ...rest
}) => (
  <FormGroup margin={margin} style={{ flex: fullWidth ? 1 : 'auto' }}>
    <FormControlLabel
      control={
        <MiuCheckbox
          checked={value}
          onChange={(event, checked) => {
            setFieldTouched(name);
            setFieldValue(name, checked);
          }}
          {...rest}
        />
      }
      label={label}
    />
  </FormGroup>
);

export const Select = withProps(({ name, id, options, children, form }) => ({
  name,
  id: id || name,
  children: options
    ? options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))
    : children,
  hasError: form.touched && !!form.errors[name],
  helperText: form.touched && !!form.errors[name] ? form.errors[name] : null,
}))(
  ({
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    children,
    id,
    label,
    fullWidth,
    required,
    margin,
    helperText,
    hasError,
    variant,
    ...rest
  }) => (
    <FormControl
      margin={margin}
      error={hasError}
      fullWidth={fullWidth}
      required={required}
      variant={variant}
    >
      {!!label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <MiuSelect
        required={required}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
        onBlur={() => {
          setFieldTouched(name);
        }}
        inputProps={{ name, id }}
        {...rest}
      >
        {children}
      </MiuSelect>
      {hasError && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  ),
);
