/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DictionaryItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DictionaryList_items$ref: FragmentReference;
declare export opaque type DictionaryList_items$fragmentType: DictionaryList_items$ref;
export type DictionaryList_items = $ReadOnlyArray<{|
  +id: string,
  +position: ?number,
  +group?: ?string,
  +allowMultiple?: boolean,
  +dealState?: ?{|
    +CALL_SELLER: boolean,
    +MEETING_WITH_SELLER: boolean,
    +INVITE_SELLER_TO_CRE: boolean,
    +MANDATE_ANALYSIS: boolean,
    +MANDATE_SIGNATURE: boolean,
    +TEASER_SALE_MEMO: boolean,
    +TEASER_SALE_MEMO_VALIDATION: boolean,
    +TENDERING_PREPARATION: boolean,
    +NDA_FIRST_CIRCLE: boolean,
    +NDA_SECOND_CIRCLE: boolean,
    +NDA_THIRD_CIRCLE: boolean,
    +NBO_PHASE: boolean,
    +NBO_SELECTION: boolean,
    +BO_PHASE: boolean,
    +BO_SELECTION: boolean,
    +CLOSING: boolean,
    +CLOSING_VALIDATION: boolean,
    +SOLD: boolean,
  |},
  +$fragmentRefs: DictionaryItem_item$ref,
  +$refType: DictionaryList_items$ref,
|}>;
export type DictionaryList_items$data = DictionaryList_items;
export type DictionaryList_items$key = $ReadOnlyArray<{
  +$data?: DictionaryList_items$data,
  +$fragmentRefs: DictionaryList_items$ref,
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "DictionaryList_items",
  "type": "Dictionary",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "position",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "PipelineStage",
      "selections": (v1/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "DealStatus",
      "selections": (v1/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "FileType",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "allowMultiple",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "DealPipelineStage",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "dealState",
          "storageKey": null,
          "args": null,
          "concreteType": "DealPipelineStageDealState",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "CALL_SELLER",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "MEETING_WITH_SELLER",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "INVITE_SELLER_TO_CRE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "MANDATE_ANALYSIS",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "MANDATE_SIGNATURE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TEASER_SALE_MEMO",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TEASER_SALE_MEMO_VALIDATION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TENDERING_PREPARATION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_FIRST_CIRCLE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_SECOND_CIRCLE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_THIRD_CIRCLE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NBO_PHASE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NBO_SELECTION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "BO_PHASE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "BO_SELECTION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "CLOSING",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "CLOSING_VALIDATION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "SOLD",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "DictionaryItem_item",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ac4504dd8a41c343e9b1035b2eda10e';
module.exports = node;
