/* @flow */
/* eslint-disable no-unused-vars */
import { createRefetchContainer } from 'react-relay';
import type { HOC } from 'recompose';

function createRefetchContainerHOC<Enhaced, FragmentProps>(
  fragments: any,
  query: any,
  nullFragment: ?FragmentProps,
): HOC<{ ...$Exact<Enhaced>, ...$Exact<FragmentProps>, relay: any }, Enhaced> {
  return comp => createRefetchContainer(comp, fragments, query);
}

export default createRefetchContainerHOC;
