import { wrapDisplayName } from 'recompose';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

// TODO: possibility to use map props function instead of name, like in apollo
export default (mutation, name, options = {}) => ComposedComponent =>
  class WithMutation extends Component {
    static displayName = wrapDisplayName(ComposedComponent, 'withMutation');

    static contextTypes = {
      api: PropTypes.object.isRequired,
    };

    state = {
      loading: false,
      errors: null,
      showSnackbar: false,
    };

    mutate = input => {
      this.setState({ loading: true, errors: null });
      return new Promise((resolve, reject) => {
        this.context.api.commitMutation({
          ...options,
          mutation,
          variables: { input },
          onCompleted: (data, errors) => {
            if (errors) {
              console.error(errors);
              // TODO: parse errors
              this.setState({
                errors: errors.map(e => e.message),
                loading: false,
                showSnackbar: true,
              });
              return reject(errors);
            }
            this.setState({ loading: false });
            if (typeof options.onCompleted === 'function')
              options.onCompleted(data, errors);
            return resolve(data);
          },
          onError: error => {
            console.error(error);
            this.setState({
              loading: false,
              errors: [error.message],
              showSnackbar: true,
            });
            if (typeof options.onError === 'function') options.onError(error);
            reject([error]);
          },
        });
      });
    };

    hideSnackbar = () => {
      this.setState({
        showSnackbar: false,
      });
    };

    render() {
      const payload = {
        [name]: {
          mutate: this.mutate,
          loading: this.state.loading,
          errors: this.state.errors,
        },
      };

      const errorMessage = this.state.errors && this.state.errors[0].message;

      return (
        <ComposedComponent {...this.props} {...payload}>
          <Snackbar
            open={this.state.showSnackbar}
            autoHideDuration={5000}
            onClose={this.hideSnackbar}
            message={errorMessage}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          />
          {this.props.children}
        </ComposedComponent>
      );
    }
  };

export const withMutationPropType = PropTypes.shape({
  mutate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
});
