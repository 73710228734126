// @flow

import * as React from 'react';
import { useHistory, useLocation } from './router.js';

type UrlSearchParamsGetters = {|
  getString(name: string): string | null,
  getAllStrings(name: string): $ReadOnlyArray<string>,
  getNumber(name: string): number | null,
  getAllNumbers(name: string): $ReadOnlyArray<number>,
  getBoolean(name: string): boolean | null,
  getAllBooleans(name: string): $ReadOnlyArray<boolean>,
|};

type UrlSearchParamsSetters = {|
  toString(): string,
  setString(name: string, string | null): void,
  setAllStrings(name: string, $ReadOnlyArray<string>): void,
  setNumber(name: string, number | null): void,
  setAllNumbers(name: string, $ReadOnlyArray<number>): void,
  setBoolean(name: string, boolean | null): void,
  setAllBooleans(name: string, $ReadOnlyArray<boolean>): void,
|};

const getNonNull = <T>(item: T | null): $ReadOnlyArray<T> =>
  item == null ? [] : [item];

const string_of_number = (number: number): string => String(number);

const number_of_string = (string: string): number | null => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

const boolean_of_string = (string: string): boolean | null => {
  if (string === 'true') {
    return true;
  }
  if (string === 'false') {
    return false;
  }
  return null;
};

const string_of_boolean = (boolean: boolean) => (boolean ? 'true' : 'false');

const makeUrlSearchParamsGetters = (search: string): UrlSearchParamsGetters => {
  const params = new URLSearchParams(search);
  const get = (name: string): string | null => {
    const value = params.get(name);
    if (value == null) {
      return null;
    }
    return decodeURIComponent(value);
  };
  const getAll = (name: string): $ReadOnlyArray<string> => {
    return params.getAll(name).map(decodeURIComponent);
  };
  return {
    getString: get,
    getAllStrings: getAll,
    getNumber: name => {
      const value = get(name);
      if (value == null) {
        return null;
      }
      return number_of_string(value);
    },
    getAllNumbers: name => {
      return getAll(name)
        .map(number_of_string)
        .flatMap(getNonNull);
    },
    getBoolean: name => {
      const value = get(name);
      if (value == null) {
        return null;
      }
      return boolean_of_string(value);
    },
    getAllBooleans: name => {
      return getAll(name)
        .map(boolean_of_string)
        .flatMap(getNonNull);
    },
  };
};

const makeUrlSearchParamsSetters = (search: string): UrlSearchParamsSetters => {
  const params = new URLSearchParams(search);
  const set = (name: string, string: string | null) => {
    if (string == null) {
      params.delete(name);
    } else {
      params.set(name, encodeURIComponent(string));
    }
  };
  const setAll = (name: string, strings: $ReadOnlyArray<string>) => {
    params.delete(name);
    strings.forEach(string => {
      params.append(name, encodeURIComponent(string));
    });
  };
  return {
    toString: () => params.toString(),
    setString: set,
    setAllStrings: setAll,
    setNumber: (name, number) => {
      set(name, number == null ? null : string_of_number(number));
    },
    setAllNumbers: (name, numbers) => {
      setAll(name, numbers.map(string_of_number));
    },
    setBoolean: (name, boolean) => {
      set(name, boolean == null ? null : string_of_boolean(boolean));
    },
    setAllBooleans: (name, booleans) => {
      setAll(name, booleans.map(string_of_boolean));
    },
  };
};

type FieldDefinition<T> = {|
  get(params: UrlSearchParamsGetters): T,
  set(params: UrlSearchParamsSetters, value: T): void,
|};

export opaque type Field<T> = FieldDefinition<T>;

type ExtractObjectType<T> = $ObjMap<T, <V>(Field<V>) => V>;

type Hook<T: { ... }> = () => [T, ($Shape<T>) => void];

export type UrlSearchParamsHook<T> = Hook<ExtractObjectType<T>>;

export const makeField = <T>(definition: FieldDefinition<T>): Field<T> =>
  definition;

export const makeUrlSearchParamsHook = <T: { +[string]: Field<any>, ... }>(
  definition: T,
): UrlSearchParamsHook<T> => {
  const parse = search => {
    const params = makeUrlSearchParamsGetters(search);
    const result = {};
    Object.keys(definition).forEach(key => {
      result[key] = definition[key].get(params);
    });
    return result;
  };

  const serialize = (search, values) => {
    const params = makeUrlSearchParamsSetters(search);
    // iterate value to prevent not specified values overriding
    Object.keys(values).forEach(key => {
      definition[key].set(params, values[key]);
    });
    return `?${params.toString()}`;
  };

  /* eslint-disable react-hooks/rules-of-hooks */
  const hook = () => {
    const history = useHistory();
    const location = useLocation();
    const search = location.search;
    const value = React.useMemo(() => parse(search), [search]);
    const setValue = values => {
      history.replace({
        // take the latest location from history
        // to prevent overriding immediate replaces
        pathname: history.location.pathname,
        search: serialize(history.location.search, values),
      });
    };
    return [value, setValue];
  };
  /* eslint-enable react-hooks/rules-of-hooks */

  return hook;
};
