// @flow
import * as React from 'react';
import { Link } from 'react-navi';
import {
  Container,
  Typography,
  Box,
  Grid,
  Chip,
  CircularProgress,
  colors,
  SvgIcon,
} from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
} from '@material-ui/core';
import { compareDesc } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { mdiHomeCityOutline, mdiCrosshairsGps } from '@mdi/js';

import type { dealsListQuery } from './__generated__/dealsListQuery.graphql.js';
import { useQuery, graphql } from '../controls/relay';

type DealStatus = 'ACTIVE' | 'CLOSED';

const dealsByStatus = (status: DealStatus = 'ACTIVE') => ({ node: deal }) =>
  deal?.status?.group === status;

const dealsSortByClosingDate = ({ node: dealA }, { node: dealB }) =>
  compareDesc(
    dealA?.closingDate ?? Date.now(),
    dealB?.closingDate ?? Date.now(),
  );

const DealCard = ({ deal }) => {
  const [t] = useTranslation();
  const isClosed = deal?.status?.group === 'CLOSED';
  const showLabel = isClosed;

  const formatNumber = number =>
    number
      ? number.toLocaleString('de-CH', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          style: 'currency',
          currency: deal?.currencyType ?? 'CHF',
        })
      : '';

  // 350_123_456 => 350M, 123_456 => 0.12M
  const formatWithRound = number =>
    number
      ? `${formatNumber(Math.trunc((number / 1000000) * 100) / 100)}M`
      : '';

  return (
    <Card
      css={`
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
      `}
    >
      <CardActionArea
        component={Link}
        href={`/deal/${deal?.id ?? ''}`}
        disabled={isClosed}
      >
        {showLabel && (
          <Chip
            size="small"
            color="primary"
            css={`
              position: absolute;
              top: 12px;
              right: 12px;
              padding: 0 4px;
              background-color: ${isClosed
                ? colors.red['600']
                : colors.green['600']};
              z-index: 1;
            `}
            label={isClosed ? t('sold') : deal?.dealProgressStage?.label}
          />
        )}
        <CardMedia
          image={deal?.image?.url || ''}
          css={`
            height: 200px;
            background-color: #eee;
            background-size: cover;
            filter: ${isClosed ? 'grayscale(0.9)' : 'initial'};
          `}
        />

        <CardContent>
          <Typography variant="h6" noWrap>
            {deal?.title ?? t('untitled')}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            css={`
              display: flex;
              align-items: center;
              margin: 6px 0;
              opacity: ${deal?.useType?.label || deal?.dealType?.label ? 1 : 0};
            `}
          >
            <SvgIcon
              fontSize="inherit"
              css={`
                font-size: 18px;
                margin-right: 8px;
              `}
            >
              <path d={mdiHomeCityOutline} />
            </SvgIcon>
            {deal?.useType?.label} {deal?.dealType?.label}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            paragraph
            css={`
              display: flex;
              align-items: center;
              opacity: ${deal?.location ? 1 : 0};
            `}
          >
            <SvgIcon
              fontSize="inherit"
              css={`
                font-size: 18px;
                margin-right: 8px;
              `}
            >
              <path d={mdiCrosshairsGps} />
            </SvgIcon>
            {deal?.location}
          </Typography>
          {deal?.priceType === 'fixed' && (
            <Typography variant="h6">{formatWithRound(deal.price)}</Typography>
          )}
          {deal?.priceType === 'range' && (
            <Typography variant="h6">
              <Typography
                color="textSecondary"
                css={`
                  display: inline-block;
                `}
              >
                {t('from')}
              </Typography>{' '}
              {formatWithRound(deal.priceMin)}
            </Typography>
          )}
          {deal?.priceType === 'on_request' && (
            <Typography variant="h6" color="textSecondary">
              {t('onRequest')}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const DealsList = () => {
  const [t] = useTranslation();
  const { props } = useQuery<dealsListQuery>({
    query: graphql`
      query dealsListQuery {
        viewer {
          id
        }
        deals(sortDirection: DESC) {
          edges {
            node {
              id
              title
              description
              closingDate
              image {
                url
              }
              status {
                label
                group
              }
              year
              dealType {
                id
                label
              }
              useType {
                id
                label
              }
              regions {
                id
                label
              }
              dealProgressStage {
                id
                label
              }
              constructionPeriod
              expectedRent
              vacancyRate
              location
              type
              price
              priceMin
              priceMax
              currencyType
              priceType
            }
          }
        }
      }
    `,
    dataFrom: 'STORE_THEN_NETWORK',
  });

  if (!props) {
    return (
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box my={4}>
        <Container maxWidth="lg">
          <Box>
            <Box px={1}>
              <Typography
                variant="h5"
                paragraph={true}
                css={`
                  font-weight: 500;
                `}
              >
                {t('onTheMarket')}
              </Typography>
            </Box>
            <Grid container spacing={6} wrap="wrap">
              {(props.deals?.edges || [])
                .filter(Boolean)
                .filter(dealsByStatus('ACTIVE'))
                .map(({ node }) => (
                  <Grid item key={node?.id} xs={12} sm={6} md={4} lg={3}>
                    <DealCard deal={node} />
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box mt={{ xs: 4, sm: 8 }}>
            <Box px={1}>
              <Typography
                variant="h5"
                paragraph={true}
                css={`
                  font-weight: 500;
                `}
              >
                {t('pastInvestmentOpportunities')}
              </Typography>
            </Box>
            <Grid container spacing={6} wrap="wrap">
              {(props.deals?.edges || [])
                .filter(Boolean)
                .filter(dealsByStatus('CLOSED'))
                .sort(dealsSortByClosingDate)
                .map(({ node }) => (
                  <Grid item key={node?.id} xs={12} sm={6} md={4} lg={3}>
                    <DealCard deal={node} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
