/**
 * @flow
 * @relayHash badec95cf4bc4031b09ad404b433f00b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TrackerQueryVariables = {|
  event: string,
  data: string,
|};
export type TrackerQueryResponse = {|
  +tracker: ?number
|};
export type TrackerQuery = {|
  variables: TrackerQueryVariables,
  response: TrackerQueryResponse,
|};
*/


/*
query TrackerQuery(
  $event: String!
  $data: String!
) {
  tracker(event: $event, data: $data)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "event",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "tracker",
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      },
      {
        "kind": "Variable",
        "name": "event",
        "variableName": "event"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TrackerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TrackerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TrackerQuery",
    "id": null,
    "text": "query TrackerQuery(\n  $event: String!\n  $data: String!\n) {\n  tracker(event: $event, data: $data)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b66ded54f31b4d3ba70165630ed71386';
module.exports = node;
