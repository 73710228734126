// @flow

import * as React from 'react';
import { useFormik, Form } from '../realadvisor-packages/form';
import { useLocalStorage } from '@rehooks/local-storage';
import {
  Box,
  TextField,
  Typography,
  Paper,
  Link,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useMutation, graphql } from 'relay-hooks';
import parseErrors from '../routes/admin/utils/parseErrors';
import { green } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { ButtonProgress } from '../components/ButtonProgress';

const CustomTextField = props => (
  <Box my={1}>
    <TextField
      fullWidth
      variant="filled"
      css={`
        border-radius: 6px;
        background-color: #fff;
        overflow: hidden;
      `}
      {...props}
    />
  </Box>
);

type Props = {|
  visible: boolean,
  onReset: () => void,
|};

export const RequestAccessForm = ({ visible, onReset }: Props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [signupSent, setSignupSent] = useLocalStorage('signupSent', false);
  const form = useFormik({
    initialValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
    },
    validate: () => ({}),
    onSubmit: values => {
      mutate({ variables: { input: values } });
    },
  });
  const { submitForm, setValues, values, resetForm } = form;
  const [mutate, { loading, error }] = useMutation(
    graphql`
      mutation requestAccessFormMutation($input: SignupInput!) {
        signup(input: $input) {
          clientMutationId
        }
      }
    `,
    {
      onCompleted: ({ signup }) => {
        if (signup) {
          setSignupSent(true);
          resetForm();
        }
      },
    },
  );

  // focus the first field
  const firstInput = React.useRef();
  React.useEffect(() => {
    if (firstInput.current) firstInput.current.focus();
  }, [visible]);

  const { errors } = parseErrors(error ?? [], values);

  return (
    <Box
      css={`
        position: relative;
        pointer-events: ${loading ? 'none' : 'initial'};
      `}
    >
      <Box color="#fff" textAlign={{ xs: 'center', sm: 'initial' }}>
        <Typography variant={isMobile ? 'h6' : 'h4'} color="inherit">
          {t('investmentPlatformAccess')}
        </Typography>
        {!signupSent && (
          <Typography variant={'subtitle1'} color="inherit">
            {t('weWillContactYouOnceYourRequestIsApproved')}
          </Typography>
        )}
        <Box mt={2} />
        {signupSent && (
          <>
            <Paper
              css={`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: white;
                background-color: ${green.A700};
              `}
            >
              <Box p={3}>
                <Typography variant="body1" align="center">
                  {t('accessRequested')}
                </Typography>
              </Box>
            </Paper>
            <Box my={2}>
              <Typography variant={'body1'} color="inherit" paragraph>
                {t('weWillContactYouOnceYourRequestIsApproved')}
              </Typography>
              <Typography variant="body2">
                Thank you for your patience.{' '}
                <Link
                  color="inherit"
                  underline="always"
                  onClick={() => setSignupSent(false)}
                  css={`
                    cursor: pointer;
                  `}
                >
                  Send again
                </Link>
              </Typography>
            </Box>
          </>
        )}

        {!signupSent && (
          <Box maxWidth={350}>
            <Form onSubmit={submitForm}>
              <CustomTextField
                inputRef={firstInput}
                required
                label="Name"
                value={values.name}
                onChange={e => setValues({ name: e.target.value })}
                autoComplete="name"
                error={errors?.name}
                helperText={errors?.name}
              />
              <CustomTextField
                required
                label="Company"
                value={values.company}
                onChange={e => setValues({ company: e.target.value })}
                autoComplete="organization"
                error={errors?.company}
                helperText={errors?.company}
              />
              <CustomTextField
                required
                type="email"
                label="Email"
                value={values.email}
                onChange={e => setValues({ email: e.target.value })}
                autoComplete="email"
                error={errors?.email}
                helperText={errors?.email}
              />
              <CustomTextField
                required
                type="tel"
                label="Phone"
                value={values.phone}
                onChange={e => setValues({ phone: e.target.value })}
                autoComplete="tel"
                error={errors?.phone}
                helperText={errors?.phone}
              />
              <Box mt={2}>
                <ButtonProgress
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  loading={loading}
                >
                  Request access
                </ButtonProgress>
              </Box>
              <Box
                color="#fff"
                mt={1}
                textAlign={{ xs: 'center', sm: 'initial' }}
              >
                <Typography variant="body2">
                  {t('alreadyMember')}{' '}
                  <Link
                    onClick={onReset}
                    underline="always"
                    color="inherit"
                    css={`
                      cursor: pointer;
                    `}
                  >
                    {t('logIn')}
                  </Link>
                </Typography>
              </Box>
            </Form>
          </Box>
        )}
      </Box>
    </Box>
  );
};
