/**
 * @flow
 * @relayHash 38c148b2c869f74aa8853b60da35c0c2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dealsFiltersDealStatusQueryVariables = {||};
export type dealsFiltersDealStatusQueryResponse = {|
  +dealStatus: ?$ReadOnlyArray<?{|
    +id: string,
    +label: ?string,
  |}>
|};
export type dealsFiltersDealStatusQuery = {|
  variables: dealsFiltersDealStatusQueryVariables,
  response: dealsFiltersDealStatusQueryResponse,
|};
*/


/*
query dealsFiltersDealStatusQuery {
  dealStatus {
    id
    label
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "dealStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "DealStatus",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealsFiltersDealStatusQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dealsFiltersDealStatusQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "dealsFiltersDealStatusQuery",
    "id": null,
    "text": "query dealsFiltersDealStatusQuery {\n  dealStatus {\n    id\n    label\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ecf55c5e8da243f681977bcb710da128';
module.exports = node;
