/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrganisationInvestorProfileForm_organisation$ref: FragmentReference;
declare export opaque type OrganisationInvestorProfileForm_organisation$fragmentType: OrganisationInvestorProfileForm_organisation$ref;
export type OrganisationInvestorProfileForm_organisation = {|
  +id: string,
  +minAssetSize: ?number,
  +maxAssetSize: ?number,
  +assetsUnderManagement: ?number,
  +lexKollerCompliant: ?boolean,
  +useTypeIds: $ReadOnlyArray<string>,
  +regionIds: $ReadOnlyArray<string>,
  +purchaseTypeIds: $ReadOnlyArray<string>,
  +core: ?boolean,
  +corePlus: ?boolean,
  +valueAdd: ?boolean,
  +opportunistic: ?boolean,
  +onlyIndirect: ?boolean,
  +owner: ?{|
    +id: string,
    +fullName: ?string,
    +isBroker: ?boolean,
    +organisationId: ?string,
  |},
  +$refType: OrganisationInvestorProfileForm_organisation$ref,
|};
export type OrganisationInvestorProfileForm_organisation$data = OrganisationInvestorProfileForm_organisation;
export type OrganisationInvestorProfileForm_organisation$key = {
  +$data?: OrganisationInvestorProfileForm_organisation$data,
  +$fragmentRefs: OrganisationInvestorProfileForm_organisation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrganisationInvestorProfileForm_organisation",
  "type": "Organisation",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "minAssetSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "maxAssetSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "assetsUnderManagement",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lexKollerCompliant",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "useTypeIds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "regionIds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "purchaseTypeIds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "core",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "corePlus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "valueAdd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "opportunistic",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "onlyIndirect",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "owner",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isBroker",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "organisationId",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be28a17c19aacfe0763f9e2f64717797';
module.exports = node;
