import React from 'react';

const SearchIcon = (props) => {
  return (
    <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6665 18.6665H19.6132L19.2399 18.3065C20.8399 16.4399 21.6665 13.8932 21.2132 11.1865C20.5865 7.47986 17.4932 4.51986 13.7599 4.06652C8.11986 3.37319 3.37319 8.11986 4.06652 13.7599C4.51986 17.4932 7.47986 20.5865 11.1865 21.2132C13.8932 21.6665 16.4399 20.8399 18.3065 19.2399L18.6665 19.6132V20.6665L24.3332 26.3332C24.8799 26.8799 25.7732 26.8799 26.3199 26.3332C26.8665 25.7865 26.8665 24.8932 26.3199 24.3465L20.6665 18.6665ZM12.6665 18.6665C9.34652 18.6665 6.66652 15.9865 6.66652 12.6665C6.66652 9.34652 9.34652 6.66652 12.6665 6.66652C15.9865 6.66652 18.6665 9.34652 18.6665 12.6665C18.6665 15.9865 15.9865 18.6665 12.6665 18.6665Z" fill="#96A4B4"/>
    </svg>
  );
};

export default SearchIcon;
