// @flow
import React from 'react';

type EditIconProps = {
  color?: string,
  width?: string | number,
  height?: string | number,
};

const EditIcon = ({
  color = '#3455D1',
  width = 24,
  height = 24,
}: EditIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.058 3.59086L21.408 4.94086C22.198 5.72086 22.198 6.99086 21.408 7.77086L8.17805 21.0009H3.99805V16.8209L14.398 6.41086L17.228 3.59086C18.008 2.81086 19.278 2.81086 20.058 3.59086ZM5.99805 19.0009L7.40805 19.0609L17.228 9.23086L15.818 7.82086L5.99805 17.6409V19.0009Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
