import React from 'react';

// prettier-ignore
export const getCountries = () => import(/* webpackChunkName: 'countries' */ 'country-list/data.json');

export const getCountryName = (code, countries) => {
  if (!countries) return '';

  const country = countries.find(c => c.code === code);

  if (!country) return '';

  return country.name;
};

export const withCountries = Component =>
  class WithCountries extends React.Component {
    state = {
      countries: null,
    };

    componentWillMount() {
      getCountries().then(countries => {
        this.setState({ countries });
      });
    }

    render() {
      return <Component countries={this.state.countries} {...this.props} />;
    }
  };
