import * as React from 'react';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { graphql } from 'react-relay';
import createRefetchContainerHOC from '../../../utils/relay/createRefetchContainerHOC';
import GlobalSearchView from './GlobalSearchView';
import {
  requestAnimationTimeout,
  cancelAnimationTimeout,
} from '../utils/requestAnimationTimeout';

const fragment = {
  root: graphql`
    fragment GlobalSearchFrags_root on Query {
      globalSearch(search: $search, limit: $limit) {
        users {
          totalCount
          results {
            id
            email
            firstName
            lastName
            primaryImage {
              url
            }
            ...UserAvatar_user
          }
        }
        organisations {
          totalCount
          results {
            id
            name
            street
            city
            primaryImage {
              url
            }
            ...UserAvatar_organisation
          }
        }
        activities {
          totalCount
          results {
            id
            note
            subject
            parent {
              __typename
              id
              ... on Lead {
                deal {
                  id
                  name
                }
                organisation {
                  id
                  name
                  ...UserAvatar_organisation
                }
              }
              ... on Deal {
                name
                image {
                  url
                }
              }
              ... on User {
                fullName
                ...UserAvatar_user
              }
              ... on Organisation {
                name
                ...UserAvatar_organisation
              }
            }
            user {
              firstName
              lastName
            }
            creator {
              firstName
              lastName
            }
            deal {
              id
              name
              title
              titleEn: title(lang: "en")
              titleDe: title(lang: "de")
              titleFr: title(lang: "fr")
            }
            organisation {
              name
            }
          }
        }
        deals {
          totalCount
          results {
            id
            name
            titleEn: title(lang: "en")
            titleDe: title(lang: "de")
            titleFr: title(lang: "fr")
            image {
              url
            }
          }
        }
      }
    }
  `,
};

const globalSearchFragmets = ({
  root,
  inputValue,
  handleInputValueChange,
  setRef,
  isPage,
}) => (
  <GlobalSearchView
    ref={setRef}
    inputValue={inputValue}
    onInputChange={handleInputValueChange}
    value={root && root.globalSearch}
    isPage={isPage}
  />
);

const enhancer = compose(
  createRefetchContainerHOC(
    fragment,
    graphql`
      query GlobalSearchFragsQuery($limit: Int, $search: String) {
        ...GlobalSearchFrags_root
      }
    `,
  ),
  withStateHandlers(({ search }) => ({ inputValue: search || '' }), {
    handleInputValueChange: () => (inputValue: string) => ({
      inputValue,
    }),
  }),
  withHandlers(() => {
    let deferredSearch_;
    let isLoading_ = false;
    let disposable_;
    let timeoutHandle_;
    const cleanup = () => {
      if (disposable_) {
        disposable_.dispose();
        disposable_ = null;
      }
      if (timeoutHandle_) {
        cancelAnimationTimeout(timeoutHandle_);
      }
    };

    const refetchOptions = {
      force: false,
    };

    // Don't refetch until previous results completed
    const refetch = (relay, search) => {
      if (isLoading_) {
        deferredSearch_ = search;
        return;
      }
      isLoading_ = true;
      deferredSearch_ = undefined;

      disposable_ = relay.refetch(
        { search },
        null,
        err => {
          if (err) {
            // TODO: show error toasts
            console.error('err', err);
          }
          isLoading_ = false;
          if (deferredSearch_ !== undefined) {
            refetch(relay, deferredSearch_);
          }
        },
        refetchOptions,
      );
    };

    return {
      setRef: () => ref => {
        if (!ref) {
          cleanup();
        }
      },
      handleInputValueChange: ({
        relay,
        handleInputValueChange,
        inputValue,
        isPage,
      }) => search => {
        const re = /\s+/;
        // spaces must not cause additional queries
        if (
          (inputValue || '').replace(re, ' ').trim() !==
          (search || '').replace(re, ' ').trim()
        ) {
          const DEBOUNCE_INTERVAL = isPage ? 300 : 0;
          if (timeoutHandle_) {
            cancelAnimationTimeout(timeoutHandle_);
          }
          timeoutHandle_ = requestAnimationTimeout(
            () => refetch(relay, search),
            DEBOUNCE_INTERVAL,
          );
        }

        handleInputValueChange(search);
      },
    };
  }),
);

export default enhancer(globalSearchFragmets);
