// @flow

const config = {
  // Node.js app
  port: process.env.PORT || 3000,

  // API Gateway
  api: {
    // API URL to be used in the client-side code
    clientUrl: process.env.REACT_APP_API_SERVER_URL || '',
    // API URL to be used in the server-side code
    serverUrl: process.env.REACT_APP_API_SERVER_URL || 'http://localhost:8080',
  },

  // websiteUrl: process.env.WEBSITE_URL || 'http://localhost:3000',

  // Database
  // databaseUrl: process.env.DATABASE_URL || 'sqlite:database.sqlite',

  // Web analytics
  analytics: {
    // https://analytics.google.com/
    googleTrackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID, // UA-XXXXX-X
  },

  contactEmail: 'federico.copercini@realadvisor.com',
};

module.exports = config;
