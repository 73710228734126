/* eslint-disable react/no-danger */
import React from 'react';
import snarkdown from 'snarkdown';

class Snarkdown extends React.Component {
  componentWillUpdate(nextProps) {
    if (nextProps.source !== this.props.source) {
      this.md = snarkdown(nextProps.source);
    }
  }

  md = snarkdown(this.props.source);

  render() {
    const Container = this.props.container;

    return (
      <Container>
        <span
          dangerouslySetInnerHTML={{
            __html: this.md,
          }}
        />
      </Container>
    );
  }
}

Snarkdown.defaultProps = {
  container: 'div',
  options: { breaks: true },
};

export default Snarkdown;
