/**
 * @flow
 * @relayHash 4cf871a9691058ea68fe5db26599b71f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dealsFiltersDealPipelineStagesQueryVariables = {||};
export type dealsFiltersDealPipelineStagesQueryResponse = {|
  +dealPipelineStages: ?$ReadOnlyArray<?{|
    +id: string,
    +label: ?string,
  |}>
|};
export type dealsFiltersDealPipelineStagesQuery = {|
  variables: dealsFiltersDealPipelineStagesQueryVariables,
  response: dealsFiltersDealPipelineStagesQueryResponse,
|};
*/


/*
query dealsFiltersDealPipelineStagesQuery {
  dealPipelineStages {
    id
    label
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "dealPipelineStages",
    "storageKey": null,
    "args": null,
    "concreteType": "DealPipelineStage",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealsFiltersDealPipelineStagesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dealsFiltersDealPipelineStagesQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "dealsFiltersDealPipelineStagesQuery",
    "id": null,
    "text": "query dealsFiltersDealPipelineStagesQuery {\n  dealPipelineStages {\n    id\n    label\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76a65e06aef75ef55b63f5edf2b70281';
module.exports = node;
