/* @flow */
/* eslint-disable no-unused-vars */
import { createPaginationContainer } from 'react-relay';
import type { HOC } from 'recompose';

function createPaginationContainerHOC<Enhaced, FragmentProps>(
  fragments: any,
  connectionConfig: any,
  nullFragment: ?FragmentProps,
): HOC<{ ...$Exact<Enhaced>, ...$Exact<FragmentProps>, relay: any }, Enhaced> {
  return comp => createPaginationContainer(comp, fragments, connectionConfig);
}

export default createPaginationContainerHOC;
