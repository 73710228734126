// flow
/* eslint-disable no-restricted-globals */

import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withContext } from 'recompose';
import { Provider as ReduxProvider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import {
  commitLocalUpdate,
  commitMutation,
  Environment,
  fetchQuery,
} from 'relay-runtime';
import { RelayEnvironmentProvider } from 'relay-hooks';

import { AdminApp } from './admin/admin-app';
import i18n from './i18n/browser';
import i18nOptions from './i18n/options';
import { FrontApp } from './front/front-app';
import store from './redux/store';
import config from './config';
import { createRelayEnvironment } from './createRelayEnvironment';
import 'rc-time-picker/assets/index.css';
import countries from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import frCountries from 'i18n-iso-countries/langs/fr.json';
import deCountries from 'i18n-iso-countries/langs/de.json';

countries.registerLocale(enCountries);
countries.registerLocale(frCountries);
countries.registerLocale(deCountries);

const environment = createRelayEnvironment({
  baseUrl: config.api.clientUrl,
});

const context = {
  // Universal API client
  api: {
    environment,
    fetchQuery: fetchQuery.bind(undefined, environment),
    commitMutation: commitMutation.bind(undefined, environment),
    commitLocalUpdate: commitLocalUpdate.bind(undefined, environment),
  },
  i18n,
};

context.relay = {
  environment: context.api.environment,
  variables: {},
};

const lang = location.pathname.split('/')[1];
const adminBasename = `/${
  i18nOptions.whitelist.includes(lang) ? `${lang}/` : ''
}admin`;
const basename = `/${i18nOptions.whitelist.includes(lang) ? `${lang}/` : ''}`;
const mountNode = document.getElementById('app');
const isAdminApp = location.pathname.startsWith(adminBasename);
let App = isAdminApp ? AdminApp : FrontApp;

// legacy context
App = withContext(
  {
    api: PropTypes.shape({
      fetch: PropTypes.func.isRequired,
      fetchQuery: PropTypes.func.isRequired,
      commitMutation: PropTypes.func.isRequired,
      commitLocalUpdate: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.instanceOf(Environment).isRequired,
      variables: PropTypes.object.isRequired,
    }),
    i18n: PropTypes.object.isRequired,
    // history: PropTypes.object.isRequired,
    // route: PropTypes.object.isRequired,
    // router: PropTypes.object.isRequired, // workaroud to use react-router components e.g. Prompt
  },
  () => context,
)(App);

App = hot(App);

ReactDOM.render(
  <React.Suspense fallback={err => <div>{err}</div>}>
    <RelayEnvironmentProvider environment={environment}>
      <I18nextProvider i18n={i18n}>
        <ReduxProvider store={store}>
          <App basename={isAdminApp ? adminBasename : basename} />
        </ReduxProvider>
      </I18nextProvider>
    </RelayEnvironmentProvider>
  </React.Suspense>,
  mountNode,
);
