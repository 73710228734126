import { formatDistance } from 'date-fns';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { compose, lifecycle } from 'recompose';
import { withTranslation as translate } from 'react-i18next';
import { withSafeInterval } from '@hocs/safe-timers';
import de from 'date-fns/esm/locale/de';
import fr from 'date-fns/esm/locale/fr';

import { formatDate } from '../../utils';

export const getLocale = i18n => ({ de, fr }[i18n.language]);

const timeAgo = ({
  setSafeInterval,
  date,
  children,
  t,
  i18n,
  tReady,
  addSuffix = true,
  ...rest
}) => {
  const data = new Date(date || children);

  return (
    <Tooltip
      PopperProps={{ component: 'span' }}
      title={formatDate(data)}
      placement="top"
    >
      <time dateTime={data} {...rest}>
        {formatDistance(data, new Date(), {
          locale: getLocale(i18n),
          addSuffix,
        })}
      </time>
    </Tooltip>
  );
};

export default compose(
  withSafeInterval,
  translate(),
  lifecycle({
    componentDidMount() {
      this.props.setSafeInterval(() => this.forceUpdate(), 15 * 1000); // force rerender every 15 secs
    },
  }),
)(timeAgo);
