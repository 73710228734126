import * as React from "react";
import { defaultProps } from 'recompose';
import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';

class NumberFormatCustom extends React.Component {
  onChange = values => {
    const { props } = this;
    props.onChange({
      persist: () => {},
      target: {
        id: props.id,
        name: props.name,
        value: values.value ? values.value : null,
      },
    });
  };

  render() {
    const {
      props: { onChange: tmp, inputRef, ...props },
      onChange,
    } = this;

    return (
      <NumberFormat {...props} innerRef={inputRef} 
        isNumericString
        onValueChange={onChange} />
    );
  }
}


export default defaultProps({
  decimalScale: 0,
  thousandSeparator: "'",
  fixedDecimalScale: false,
  allowNegative: false,
  suffix: '',
  prefix: '',
  readOnly: false,
})(
  ({
    InputProps,
    onChange,
    decimalScale,
    fixedDecimalScale,
    thousandSeparator,
    allowNegative,
    suffix,
    prefix,
    readOnly,
    ...props
  }) => (
    <TextField
      {...props}
      inputProps={{
        thousandSeparator,
        decimalScale,
        fixedDecimalScale,
        allowNegative,
        suffix,
        prefix,
        readOnly: readOnly,
      }}
      InputProps={{
        ...InputProps,
        onChange,
        inputComponent: NumberFormatCustom
      }}
    />
  ),
);
