import React from 'react';
import {
  Modal as MuiModal,
  Fade,
  Backdrop,
  ModalProps as MuiModalProps,
  Box,
} from '@material-ui/core';
import {
  ModalContent,
  ModalTitleWrapper,
  ModalTitle,
  TitleBox,
  StyledIconButton,
} from './Modal.styles';

import CloseIcon from '../../components/Icons/CloseIcon';

const Modal = ({
  open = false,
  onClose = () => {},
  withPaper,
  contentWidth = 500,
  title = '',
  titleIcon: TitleIcon = null,
  hasCloseButton = true,
  children,
  ...props
}) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      {...props}
      BackdropComponent={Backdrop}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open}>
        <ModalContent width={Number(contentWidth)} withPaper={withPaper}>
          <Box display="flex" flexDirection="column">
            {(title || TitleIcon) && (
              <ModalTitleWrapper>
                <TitleBox>
                  {TitleIcon && <TitleIcon />}
                  {title && <ModalTitle>{title}</ModalTitle>}
                </TitleBox>
                {hasCloseButton && (
                  <StyledIconButton onClick={onClose}>
                    <CloseIcon />
                  </StyledIconButton>
                )}
              </ModalTitleWrapper>
            )}
            {children}
          </Box>
        </ModalContent>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
