/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LanguagePicker_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type LeftNavigation_viewer$ref: FragmentReference;
declare export opaque type LeftNavigation_viewer$fragmentType: LeftNavigation_viewer$ref;
export type LeftNavigation_viewer = {|
  +id: string,
  +isAdmin: boolean,
  +isSuperAdmin: boolean,
  +isBroker: ?boolean,
  +isInvestor: ?boolean,
  +isSeller: ?boolean,
  +$fragmentRefs: LanguagePicker_viewer$ref,
  +$refType: LeftNavigation_viewer$ref,
|};
export type LeftNavigation_viewer$data = LeftNavigation_viewer;
export type LeftNavigation_viewer$key = {
  +$data?: LeftNavigation_viewer$data,
  +$fragmentRefs: LeftNavigation_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "LeftNavigation_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSuperAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isBroker",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isInvestor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSeller",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LanguagePicker_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2fb86cc5189117d5838c3e1bf90452b7';
module.exports = node;
