import { Link } from 'react-router-dom';
import styled from 'styled-components';
import blue from '@material-ui/core/colors/blue';

export default styled(Link)`
  color: ${blue[600]};
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &:active {
    color: ${blue[800]};
    text-decoration: underline;
  }
`;
