/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type contactDetails_contact$ref: FragmentReference;
declare export opaque type contactDetails_contact$fragmentType: contactDetails_contact$ref;
export type contactDetails_contact = {|
  +id: string,
  +fullName: ?string,
  +jobTitle: ?string,
  +mobilePhone: ?string,
  +officePhone: ?string,
  +email: string,
  +primaryImage: ?{|
    +url: string
  |},
  +$fragmentRefs: UserAvatar_user$ref,
  +$refType: contactDetails_contact$ref,
|};
export type contactDetails_contact$data = contactDetails_contact;
export type contactDetails_contact$key = {
  +$data?: contactDetails_contact$data,
  +$fragmentRefs: contactDetails_contact$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "contactDetails_contact",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobTitle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mobilePhone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "officePhone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "primaryImage",
      "storageKey": null,
      "args": null,
      "concreteType": "Image",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "UserAvatar_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '40a87d212344dfdcf210d1cdf5525c87';
module.exports = node;
