// @flow

import { map, redirect } from 'navi';

type WithAuthenticationOptions = {|
  disableError?: boolean,
|};

export const withAuthentication = ({
  disableError = false,
}: WithAuthenticationOptions) => (matcher: any) => {
  return map((request, context) => {
    return context.viewer === null
      ? redirect({
          pathname: context.basename,
          query: {
            returnUrl: request.mountpath,
            ...(disableError !== true
              ? { error: 'ERROR_UNAUTHENTICATED' }
              : {}),
          },
        })
      : matcher;
  });
};
