export const formatThousands = num => {
  if (typeof num !== 'number') {
    return '-';
  }

  const locStr = num.toLocaleString('en-US');

  return locStr.replace(/,/g, "'");
};

export const tableNumberFormat = (num, placeholder = '-') => {
  if (typeof num !== 'number') {
    return placeholder;
  }

  let symbol = '';
  let res = num;

  if (num > 1000000) {
    res = num / 1000000;
    symbol = 'M';
  } else if (num > 1000) {
    res = num / 1000;
    symbol = 'K';
  }

  if (res < 100) {
    res = Math.round(res * 100) / 100;
  } else {
    res = Math.round(res);
  }

  return `${formatThousands(res)}${symbol}`;
};

export const currencyFormat = value => {
  let temp = parseInt(value)
    .toString()
    .split('');
  temp = temp.reverse();
  let result = [];
  for (let i = 0; i < temp.length; i++) {
    if (i > 0 && i % 3 === 0) {
      result.push('’');
    }
    result.push(temp[i]);
  }
  result = result.reverse();
  return `${result.join('')} CHF`;
};
