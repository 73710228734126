/* @flow */

import I18next from 'i18next';
import LocizeBackend from 'i18next-locize-backend';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import options from './options';

// without clone it has strange value CIMODE
export const whitelist = [...options.whitelist];
export const fallbackLng = options.fallbackLng;

const version = options.locizeBackend.version;
const versions = whitelist.reduce(
  (acc, lng) => ({ ...acc, [lng]: version }),
  {},
);

I18next.use(Backend);

I18next.init({
  ...options,
  backend: {
    projectId: options.locizeBackend.projectId,
    version: options.locizeBackend.version,
    referenceLng: options.locizeBackend.referenceLng,
    backends: [
      // we don't use locize anymore, so hotfix:
      HttpBackend,

      // TODO: remove that
      // Use cached locales only in production environment,
      // otherwise translators won't be able to see their
      // changes on https://test.realadvisor.ch
      ...(version === 'latest' ? [] : [LocalStorageBackend]),
      LocizeBackend,
    ],
    backendOptions: [
      { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      ...(version === 'latest' ? [] : [{ versions }]),
      options.locizeBackend,
    ],
  },
});

export default I18next;
