import { wrapDisplayName } from 'recompose';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default (name, initialValue = false) => ComposedComponent =>
  class WithBooleanState extends Component {
    static displayName = wrapDisplayName(ComposedComponent, 'withBooleanState');

    state = {
      value:
        typeof initialValue === 'function'
          ? initialValue(this.props)
          : initialValue,
    };

    setTrue = () => {
      this.setState({ value: true });
    };

    setFalse = () => {
      this.setState({ value: false });
    };

    toggle = value => {
      this.setState({
        value: typeof value === 'boolean' ? value : !this.state.value,
      });
    };

    render() {
      const payload = {
        [name]: {
          value: this.state.value,
          setTrue: this.setTrue,
          setFalse: this.setFalse,
          toggle: this.toggle,
        },
      };

      return <ComposedComponent {...this.props} {...payload} />;
    }
  };

export const withBooleanStatePropType = PropTypes.shape({
  value: PropTypes.bool.isRequired,
  setTrue: PropTypes.func.isRequired,
  setFalse: PropTypes.func.isRequired,
});
