import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const StyledNumberField = styled(TextField)`
  height: 48px !important;
  background-color: #fbfcfe;
  border: 1px solid #d6dfea;
  border-radius: 10px;
  label {
    font-size: 14px;
    color: #333e48;
    opacity: 0.5;
    padding-left: 16px;
    top: -14px;
  }
  label.Mui-focused {
    opacity: 1 !important;
    padding: 10px 0px 0px 16px;
    top: 0px;
  }
  .MuiInputLabel-shrink {
    padding-top: 10px;
    top: 0px;
  }
  .MuiInputBase-root :before {
    content: none !important;
  }
  .MuiInputBase-root :after {
    content: none !important;
  }
  .MuiFilledInput-root {
    background-color: unset !important;
  }
  /* .Mui-disabled {
    background-color: #ffffff !important;
  } */
  input {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
