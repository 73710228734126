import React from 'react';
import PropTypes from 'prop-types';
import Snarkdown from './Snarkdown';

const defaultOptions = {
  breaks: true,
};

const withLocales = namespace => WithLocales =>
  class extends React.Component {
    static contextTypes = {
      i18n: PropTypes.object.isRequired,
    };

    t = this.context.i18n
      ? this.context.i18n.getFixedT(null, namespace || 'common')
      : v => v;

    md = (raw, container, options) => (
      <Snarkdown
        container={container}
        options={options || defaultOptions}
        source={raw}
      />
    );

    render() {
      return (
        <WithLocales
          {...this.props}
          language={this.context.i18n ? this.context.i18n.language : 'en'}
          t={this.t}
          md={this.md}
        />
      );
    }
  };

export default withLocales;
