import promiseify from '../../../../utils/promiseify';
import getRootFieldName from '../../../../utils/relay/getRootFieldName';

const sortByPosition = (itemA, itemB) =>
  itemA.getValue('position') - itemB.getValue('position');

export const makeUpdateDictionaryMutation = (
  mutation,
  mutationName,
  rootPath,
) =>
  promiseify((api, { _oldPosition, ...input }, onCompleted) => {
    const sharedUpdater = (store, node) => {
      const nodes = store.getRoot().getLinkedRecords(rootPath);
      const proxy = store.getRoot();

      const currentPosition = _oldPosition;
      const nextPosition = node.getValue('position');
      if (currentPosition === nextPosition) return;

      nodes.forEach(item => {
        if (item.getDataID() === node.getDataID()) return;

        const itemPosition = item.getValue('position');

        if (
          // decrease position for prev items
          nextPosition > currentPosition &&
          itemPosition > currentPosition &&
          itemPosition <= nextPosition
        ) {
          item.setValue(itemPosition - 1, 'position');
        } else if (
          // increase position for next items
          nextPosition < currentPosition &&
          itemPosition >= nextPosition &&
          itemPosition < currentPosition
        ) {
          item.setValue(itemPosition + 1, 'position');
        }
      });

      proxy.setLinkedRecords([...nodes.sort(sortByPosition)], rootPath);
    };

    api.commitMutation({
      mutation,
      variables: {
        input,
      },
      onError(err) {
        onCompleted(undefined, [err]);
      },
      optimisticUpdater(store) {
        if (input.position !== undefined) {
          const node = store.get(input.id);
          node.setValue(input.position, 'position');
          sharedUpdater(store, node);
        }
      },
      updater(store) {
        const payload = store.getRootField(mutationName);
        if (!payload) return;
        if (input.position !== undefined) {
          const updatedNode = payload.getLinkedRecord('node');
          sharedUpdater(store, updatedNode);
        }
      },
      onCompleted(data, errs) {
        if (errs) {
          onCompleted(data, errs);
        } else {
          const rootField = getRootFieldName(api.environment, mutation);
          onCompleted(data[rootField], errs);
        }
      },
    });
  });

export const makeCreateDictionaryMutation = (
  mutation,
  mutationName,
  rootPath,
) =>
  promiseify((api, input, onCompleted) => {
    api.commitMutation({
      mutation,
      variables: {
        input,
      },
      updater(store) {
        const payload = store.getRootField(mutationName);
        if (!payload) return;
        const node = payload.getLinkedRecord('node');
        const nodes = store.getRoot().getLinkedRecords(rootPath);
        const proxy = store.getRoot();
        const newRecordPosition = node.getValue('position');
        nodes.forEach(item => {
          if (item.getValue('position') >= newRecordPosition) {
            item.setValue(item.getValue('position') + 1, 'position');
          }
        });
        proxy.setLinkedRecords([...nodes, node].sort(sortByPosition), rootPath);
      },
      onError(err) {
        onCompleted(undefined, [err]);
      },
      onCompleted(data, errs) {
        if (errs) {
          onCompleted(data, errs);
        } else {
          const rootField = getRootFieldName(api.environment, mutation);
          onCompleted(data[rootField], errs);
        }
      },
    });
  });

export const makeDeleteDictionaryMutation = (
  mutation,
  mutationName,
  rootPath,
) =>
  promiseify((api, input, onCompleted) => {
    let node;
    const sharedUpdater = store => {
      let nodes = store.getRoot().getLinkedRecords(rootPath);
      const proxy = store.getRoot();

      nodes = nodes
        .filter(item => item.getDataID() !== node.getDataID())
        .map(item => {
          if (item.getValue('position') > node.getValue('position')) {
            item.setValue(item.getValue('position') - 1, 'position');
          }

          return item;
        });

      proxy.setLinkedRecords(nodes, rootPath);
    };
    api.commitMutation({
      mutation,
      variables: {
        input,
      },
      optimisticUpdater(store) {
        node = store.get(input.id);
        sharedUpdater(store);
      },
      updater(store) {
        const payload = store.getRootField(mutationName);
        if (!payload) return;
        sharedUpdater(store);
      },
      onError(err) {
        onCompleted(undefined, [err]);
      },
      onCompleted(data, errs) {
        if (errs) {
          onCompleted(undefined, errs);
        } else {
          const rootField = getRootFieldName(api.environment, mutation);
          onCompleted(data[rootField], errs);
        }
      },
      configs: [
        {
          type: 'NODE_DELETE',
          deletedIDFieldName: 'deletedNodeId',
        },
      ],
    });
  });
