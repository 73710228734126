/**
 * @flow
 * @relayHash a7d749a73b2fd027527fec738b88074b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DictionaryItem_item$ref = any;
export type UpdateNotInterestedReasonInput = {|
  id: string,
  labelEn?: ?string,
  labelFr?: ?string,
  labelDe?: ?string,
  position?: ?number,
  clientMutationId?: ?string,
|};
export type notInterestedReasonsUpdateNotInterestedReasonMutationVariables = {|
  input: UpdateNotInterestedReasonInput
|};
export type notInterestedReasonsUpdateNotInterestedReasonMutationResponse = {|
  +updateNotInterestedReason: ?{|
    +node: ?{|
      +$fragmentRefs: DictionaryItem_item$ref
    |}
  |}
|};
export type notInterestedReasonsUpdateNotInterestedReasonMutation = {|
  variables: notInterestedReasonsUpdateNotInterestedReasonMutationVariables,
  response: notInterestedReasonsUpdateNotInterestedReasonMutationResponse,
|};
*/


/*
mutation notInterestedReasonsUpdateNotInterestedReasonMutation(
  $input: UpdateNotInterestedReasonInput!
) {
  updateNotInterestedReason(input: $input) {
    node {
      ...DictionaryItem_item
      id
    }
  }
}

fragment DictionaryItem_item on Dictionary {
  __typename
  id
  label
  position
  labelEn: label(lang: "en")
  labelFr: label(lang: "fr")
  labelDe: label(lang: "de")
  ... on PipelineStage {
    group
  }
  ... on DealStatus {
    group
  }
  ... on FileType {
    group
    type
    adminOnlyAccess
    pipelineStageAccess {
      SELECTED_NOTIFIED
      INTERESTED
      NDA_SIGNED
      NBO_SUBMITTED
      VISIT
      DUE_DILIGENCE
      BO_SUBMITTED
      CLOSING
    }
    allowMultiple
  }
  ... on DealPipelineStage {
    dealState {
      CALL_SELLER
      MEETING_WITH_SELLER
      INVITE_SELLER_TO_CRE
      MANDATE_ANALYSIS
      MANDATE_SIGNATURE
      TEASER_SALE_MEMO
      TEASER_SALE_MEMO_VALIDATION
      TENDERING_PREPARATION
      NDA_FIRST_CIRCLE
      NDA_SECOND_CIRCLE
      NDA_THIRD_CIRCLE
      NBO_PHASE
      NBO_SELECTION
      BO_PHASE
      BO_SELECTION
      CLOSING
      CLOSING_VALIDATION
      SOLD
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNotInterestedReasonInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "CLOSING",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "notInterestedReasonsUpdateNotInterestedReasonMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNotInterestedReason",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotInterestedReasonPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "NotInterestedReason",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "DictionaryItem_item",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "notInterestedReasonsUpdateNotInterestedReasonMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNotInterestedReason",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotInterestedReasonPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "NotInterestedReason",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "label",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "position",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": "labelEn",
                "name": "label",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "lang",
                    "value": "en"
                  }
                ],
                "storageKey": "label(lang:\"en\")"
              },
              {
                "kind": "ScalarField",
                "alias": "labelFr",
                "name": "label",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "lang",
                    "value": "fr"
                  }
                ],
                "storageKey": "label(lang:\"fr\")"
              },
              {
                "kind": "ScalarField",
                "alias": "labelDe",
                "name": "label",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "lang",
                    "value": "de"
                  }
                ],
                "storageKey": "label(lang:\"de\")"
              },
              {
                "kind": "InlineFragment",
                "type": "PipelineStage",
                "selections": (v3/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "DealStatus",
                "selections": (v3/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "FileType",
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "adminOnlyAccess",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pipelineStageAccess",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FileTypePipelineStageAccess",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "SELECTED_NOTIFIED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "INTERESTED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NDA_SIGNED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NBO_SUBMITTED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "VISIT",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "DUE_DILIGENCE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "BO_SUBMITTED",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "allowMultiple",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "InlineFragment",
                "type": "DealPipelineStage",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "dealState",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DealPipelineStageDealState",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "CALL_SELLER",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "MEETING_WITH_SELLER",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "INVITE_SELLER_TO_CRE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "MANDATE_ANALYSIS",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "MANDATE_SIGNATURE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "TEASER_SALE_MEMO",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "TEASER_SALE_MEMO_VALIDATION",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "TENDERING_PREPARATION",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NDA_FIRST_CIRCLE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NDA_SECOND_CIRCLE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NDA_THIRD_CIRCLE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NBO_PHASE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NBO_SELECTION",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "BO_PHASE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "BO_SELECTION",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "CLOSING_VALIDATION",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "SOLD",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "notInterestedReasonsUpdateNotInterestedReasonMutation",
    "id": null,
    "text": "mutation notInterestedReasonsUpdateNotInterestedReasonMutation(\n  $input: UpdateNotInterestedReasonInput!\n) {\n  updateNotInterestedReason(input: $input) {\n    node {\n      ...DictionaryItem_item\n      id\n    }\n  }\n}\n\nfragment DictionaryItem_item on Dictionary {\n  __typename\n  id\n  label\n  position\n  labelEn: label(lang: \"en\")\n  labelFr: label(lang: \"fr\")\n  labelDe: label(lang: \"de\")\n  ... on PipelineStage {\n    group\n  }\n  ... on DealStatus {\n    group\n  }\n  ... on FileType {\n    group\n    type\n    adminOnlyAccess\n    pipelineStageAccess {\n      SELECTED_NOTIFIED\n      INTERESTED\n      NDA_SIGNED\n      NBO_SUBMITTED\n      VISIT\n      DUE_DILIGENCE\n      BO_SUBMITTED\n      CLOSING\n    }\n    allowMultiple\n  }\n  ... on DealPipelineStage {\n    dealState {\n      CALL_SELLER\n      MEETING_WITH_SELLER\n      INVITE_SELLER_TO_CRE\n      MANDATE_ANALYSIS\n      MANDATE_SIGNATURE\n      TEASER_SALE_MEMO\n      TEASER_SALE_MEMO_VALIDATION\n      TENDERING_PREPARATION\n      NDA_FIRST_CIRCLE\n      NDA_SECOND_CIRCLE\n      NDA_THIRD_CIRCLE\n      NBO_PHASE\n      NBO_SELECTION\n      BO_PHASE\n      BO_SELECTION\n      CLOSING\n      CLOSING_VALIDATION\n      SOLD\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1fd64122e02c454423fe4e10eec84e9b';
module.exports = node;
