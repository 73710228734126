import React from 'react';
import { compose } from 'recompose';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';
import { Link, Route, withRouter } from 'react-router-dom';
import { withTranslation as translate } from 'react-i18next';

import DictionariesTab from './Dictionaries/DictionariesTab';
import BaseSettingsTab from './BaseSettings/BaseSettingsTab';
import TitleProvider from '../controls/Title/TitleProvider';

const TabsAppBar = styled(AppBar).attrs({
  position: 'static',
  component: 'nav',
})`
  z-index: 2;
  background: #ffffff;
  margin-top: 4px;
  box-shadow: 0px 3px 10px rgb(40 99 166 / 15%);
  padding-top: 12px;
  padding-left: 64px;
  .MuiTabs-root {
    min-height: auto;
  }
  .MuiButtonBase-root {
    padding: 0;
    margin: 0 48px 0 0;
    min-width: auto;
    max-width: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333e48;
    height: auto;
    min-height: auto;
    letter-spacing: normal;
    padding-bottom: 12px;
    span {
      padding: 0;
      margin: 0;
    }
  }
  .MuiTabs-indicator {
    padding: 10px 5px;
    border-radius: 5px;
    margin-left: 0;
    height: 0;
    bottom: -16px;
    background: #3455d1;
  }
  .MuiTab-wrapper {
    color: #333e48;
  }
`;

const TABS = {
  BASE_SETTINGS: 'base',
  DICTIONARIES: 'dictionaries',
};

// TODO: make it loadable
const SettingsPage = ({ match, t }) => (
  <TitleProvider text={t('settings')}>
    <Route
      path={`${match.url}/:tab?`}
      render={({ match: { params } }) => {
        const tabValue =
          params.tab && Object.values(TABS).includes(params.tab)
            ? params.tab
            : TABS.BASE_SETTINGS;
        return (
          <React.Fragment>
            <TabsAppBar>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabValue}
              >
                <Tab
                  label={t('baseSettings')}
                  value={TABS.BASE_SETTINGS}
                  component={Link}
                  to={`${match.url}/${TABS.BASE_SETTINGS}`}
                />
                <Tab
                  label={t('dictionaries')}
                  value={TABS.DICTIONARIES}
                  component={Link}
                  to={`${match.url}/${TABS.DICTIONARIES}`}
                />
              </Tabs>
            </TabsAppBar>

            {tabValue === TABS.BASE_SETTINGS && <BaseSettingsTab />}
            {tabValue === TABS.DICTIONARIES && <DictionariesTab />}
          </React.Fragment>
        );
      }}
    />
  </TitleProvider>
);

const enhancer = compose(withRouter, translate('settings'));

export default enhancer(SettingsPage);
