// @flow

import Cookies from 'js-cookie';
import options from './options';

const { fallbackLng, whitelist } = options;

type User = {
  language: ?string,
};

// Detect user's language (code) based on:
//  (1) user's profile > language, fetched by using "{ viewer { language } }" GraphQL query
//  (2) cookies > language (only in a browser environment)
//  (3) req.headers['accept-language'] on the server, or navigator.language on the client
//  (4) fallback to 'en'
export default function detectLanguage(
  user: ?User,
  cookieLanguage: ?string,
  acceptLanguage: ?string,
): string {
  let lang;
  if (user && user.language && whitelist.includes(user.language)) {
    return user.language;
    // TODO: replace with something like process.env.BROWSER if SSR
  } else if (window) {
    const locationLang = window.location.pathname.split('/')[1];
    if (whitelist.includes(locationLang)) {
      return locationLang;
    }

    lang = Cookies.get('lng');

    if (lang && whitelist.includes(lang)) {
      return lang;
    }

    if (
      (navigator.languages || []).some((x: string) => {
        lang = x.substr(0, 2);
        return whitelist.includes(lang);
      })
    ) {
      return lang;
    }

    // $FlowFixMe
    lang = (navigator.language || navigator.userLanguage || '').substr(0, 2);
    if (lang && whitelist.includes(lang)) {
      return lang;
    }
  } else if (cookieLanguage && whitelist.includes(cookieLanguage)) {
    return cookieLanguage;
  } else if (
    acceptLanguage &&
    acceptLanguage.split(';').some(x => {
      lang = x.substr(0, 2);
      return whitelist.includes(lang);
    }) &&
    lang
  ) {
    return lang;
  }

  return fallbackLng;
}
