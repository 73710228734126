/* eslint-disable react/jsx-no-duplicate-props, no-unused-vars */
import * as React from 'react';
import { defaultProps } from 'recompose';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { StyledNumberField } from '../../../components/UIComponents/NumberField';

const UNumberFormat = styled(NumberFormat)``;

class NumberFormatCustom extends React.Component {
  onChange = values => {
    const { props } = this;

    props.onChange({
      persist: () => {},
      target: {
        id: props.id,
        name: props.name,
        value: values.value ? parseInt(values.value) : null,
      },
    });
  };

  render() {
    const {
      props: { onChange: tmp, inputRef, ...props },
      onChange,
    } = this;

    return (
      <UNumberFormat {...props} innerRef={inputRef} onValueChange={onChange} />
    );
  }
}

export default defaultProps({
  decimalScale: 0,
  thousandSeparator: "'",
  fixedDecimalScale: false,
  allowNegative: false,
})(
  ({
    InputProps,
    onChange,
    decimalScale,
    fixedDecimalScale,
    thousandSeparator,
    allowNegative,
    ...props
  }) => (
    <TextField
      {...props}
      inputProps={{
        thousandSeparator,
        decimalScale,
        fixedDecimalScale,
        allowNegative,
      }}
      InputProps={{
        ...InputProps,
        onChange,
        inputComponent: NumberFormatCustom,
      }}
    />
  ),
);
