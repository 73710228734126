// @flow
import * as React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  Box,
  Fade,
  Slide,
  Paper,
  InputBase,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

import config from '../config';
import { FlatButton } from '../controls/flat-button';

const returnUrl = process.env.BROWSER ? `${config.api.clientUrl}/` : '/';

const BoxItem = styled(Box)`
  position: absolute;
  height: 100%;
  padding: 16px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const reducer = (prevState, updatedProperty) => ({
  ...prevState,
  // $FlowFixMe
  ...updatedProperty,
});

type Props = {|
  onSignin: () => void,
|};

export const LoginForm = ({ onSignin }: Props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasAuthError =
    window && window.location.href.includes('ERROR_UNAUTHENTICATED');
  const [state, setState] = React.useReducer(reducer, {
    email: '',
    errors: hasAuthError ? [t('errorUnauthenticated')] : [],
    submitting: false,
    submitted: false,
  });

  const refetchErrors = async () => {
    const resp = await fetch(`${config.api.clientUrl}/login/error`, {
      method: 'POST',
      credentials: 'include',
    });
    if (resp.ok) {
      const { errors } = (await resp.json()) || {};
      setState({
        submitting: false,
        errors: errors && errors.length ? errors : [],
      });
    } else {
      setState({
        submitting: false,
        errors: [t('authenticationFailed')],
      });
    }
  };

  React.useEffect(() => {
    refetchErrors();
  }, []);

  const isGmail = state.email.endsWith('@gmail.com');

  const handleSubmit = async event => {
    event.preventDefault();
    setState({
      submitting: true,
      errors: [],
    });
    const searchReturnUrl = new URL(window.location).searchParams.get(
      'returnUrl',
    );

    let resp = await fetch(`${config.api.clientUrl}/login/email`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: state.email,
        return: searchReturnUrl || returnUrl,
      }),
      credentials: 'include',
    });

    if (resp.ok) {
      setState({ submitted: true });
    } else {
      refetchErrors();
    }
  };

  return (
    <Grid>
      <Box color="#fff" textAlign={{ xs: 'center', sm: 'initial' }}>
        <Typography variant={isMobile ? 'h6' : 'h4'} color="inherit">
          {t('investmentPlatformLogin')}
        </Typography>
        <Typography
          variant={isMobile ? 'subtitle2' : 'subtitle1'}
          color="inherit"
          paragraph
        >
          {t('requestMagicLink')}
        </Typography>
      </Box>
      <Paper
        component={Box}
        css={`
          position: relative;
          overflow: hidden;
        `}
      >
        <Fade enter={false} in={!state.submitted}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              css={`
                min-height: 54px;
              `}
            >
              <Grid container item xs={12} sm={8}>
                <InputBase
                  id="email"
                  type="email"
                  placeholder={t('yourEmailAddress')}
                  value={state.email}
                  onChange={event =>
                    setState({ email: event.target.value, errors: [] })
                  }
                  autoFocus={!isMobile}
                  required={true}
                  variant="outlined"
                  readOnly={state.submitting}
                  fullWidth={true}
                  // error={state.errors[0] != null}
                  // helperText={state.errors[0] ?? ''}
                  css={`
                    padding: 12px 20px;
                  `}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={4}
                // hack to prevent 1px white strip
                css={`
                  > div {
                    margin-right: -1px;
                  }
                `}
              >
                <FlatButton
                  type="submit"
                  size="large"
                  loading={state.submitting}
                >
                  {t('logIn')}
                </FlatButton>
              </Grid>
            </Grid>
          </form>
        </Fade>
        <Slide direction="left" in={state.submitted}>
          <BoxItem
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            css={`
              color: white;
              background-color: ${green.A700};
            `}
          >
            <Typography variant="subtitle1">
              {t('authenticationLinkSent', { email: state.email })}
            </Typography>
          </BoxItem>
        </Slide>
      </Paper>
      {state.errors.length > 0 && (
        <Fade in mountOnEnter>
          <Box
            color={red[200]}
            mt={1}
            textAlign={{ xs: 'center', sm: 'initial' }}
          >
            {state.errors.map(error => (
              <Typography variant="body1" key={error}>
                {error}
              </Typography>
            ))}
          </Box>
        </Fade>
      )}
      {state.submitted && (
        <Fade in mountOnEnter>
          <Box color="#fff" mt={1} textAlign={{ xs: 'center', sm: 'initial' }}>
            <Typography variant="body1">
              {t('openYourEmailAndClickLogin')}{' '}
              {isGmail && (
                <Link
                  color="inherit"
                  underline="always"
                  size="small"
                  target="_blank"
                  href="https://mail.google.com/mail/u/0/"
                >
                  {t('openGmail')}
                </Link>
              )}
            </Typography>
            <Box mt={1} />
            <Typography variant="body2">
              {t('didNotReceiveEmail')}{' '}
              <Link
                color="inherit"
                underline="always"
                size="small"
                css={`
                  cursor: pointer;
                `}
                onClick={() => {
                  setState({
                    email: '',
                    errors: [],
                    submitting: false,
                    submitted: false,
                  });
                }}
              >
                {t('tryAgain')}
              </Link>
            </Typography>
          </Box>
        </Fade>
      )}
      <Box color="#fff" mt={1} textAlign={{ xs: 'center', sm: 'initial' }}>
        <Typography variant="body2">
          {t('notMemberYet')}{' '}
          <Link
            onClick={onSignin}
            underline="always"
            color="inherit"
            css={`
              cursor: pointer;
            `}
          >
            {t('requestAccess')}
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
};
