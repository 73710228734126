import React from 'react';
import { graphql } from 'react-relay';
import { compose } from 'recompose';
import { pick } from 'lodash';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Checkbox, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { withTranslation as translate } from 'react-i18next';

import Button from '../../components/ButtonProgress';
import { createFragmentContainerHOC, withMutation } from '../../utils/relay';

const UCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

const UCardActions = styled(CardActions)`
  justify-content: flex-end;
`;

const UserProfileForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  updateProfile,
  dirty,
  user,
  t,
}) => (
  <form onSubmit={handleSubmit}>
    <UCard>
      <CardContent>
        <Typography variant="h6">{t('userProfileTitle')}</Typography>
        <Typography variant="subtitle2" gutterBottom color="textSecondary">
          {user.fullName}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.isSubscribed}
              name="isSubscribed"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={t('isSubscribedLabel')}
        />
      </CardContent>
      <UCardActions>
        <Button
          type="submit"
          color="primary"
          loading={updateProfile.loading}
          disabled={!dirty}
        >
          {t('button.submit')}
        </Button>
      </UCardActions>
    </UCard>
  </form>
);

const userProfileFormEnhancer = compose(
  createFragmentContainerHOC({
    user: graphql`
      fragment UserProfileForm_user on User {
        id
        firstName
        lastName
        fullName
        isSubscribed
      }
    `,
  }),
  translate('front'),
  withMutation(
    graphql`
      mutation UserProfileFormUpdateUserMutation($input: UpdateUserInput!) {
        updateUser(input: $input) {
          user {
            ...UserProfileForm_user
          }
        }
      }
    `,
    'updateProfile',
  ),
  withFormik({
    mapPropsToValues: props => pick(props.user, ['isSubscribed']),
    handleSubmit: async (
      values,
      {
        props: {
          updateProfile,
          user: { id },
        },
        resetForm,
      },
    ) => {
      await updateProfile.mutate({ id, ...values });
      resetForm();
    },
  }),
);

export default userProfileFormEnhancer(UserProfileForm);
