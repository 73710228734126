// @flow

import * as React from 'react';
import { GatewayProvider } from 'react-gateway';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import store from '../redux/store';
import AppThemeProvider, { adminTheme } from '../AppThemeProvider';
import Admin from '../routes/admin';
import { RouterProvider } from '../hooks/router';
import { FeedbackProvider } from '../context';
import { FeedbackKanbanProvider } from '../routes/admin/controls/LeadsKanban/context';

import ApolloClientProvider from '../apollo-server/apollo-provider';

type Props = {|
  basename: string,
|};

export const AdminApp = ({ basename }: Props) => (
  <GatewayProvider>
    <ReduxProvider store={store}>
      <BrowserRouter basename={basename}>
        <RouterProvider>
          <AppThemeProvider theme={adminTheme}>
            <ApolloClientProvider>
              <SnackbarProvider>
                <FeedbackProvider>
                  <FeedbackKanbanProvider>
                    <Admin />
                  </FeedbackKanbanProvider>
                </FeedbackProvider>
              </SnackbarProvider>
            </ApolloClientProvider>
          </AppThemeProvider>
        </RouterProvider>
      </BrowserRouter>
    </ReduxProvider>
  </GatewayProvider>
);
