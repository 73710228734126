// @flow

import * as React from 'react';

export const useScrollTo = ({ offset = 0 }: { offset?: number }) => {
  const htmlElRef = React.useRef<React.ElementRef<'div'> | null>(null);
  const executeScroll = () => {
    if (htmlElRef.current) {
      window.scrollTo({
        left: 0,
        top: htmlElRef.current.offsetTop - offset,
        behavior: 'smooth',
      });
    }
  };

  return [executeScroll, htmlElRef];
};
