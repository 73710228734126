import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import withConfirmDialog from './withConfirmDialog';

// TODO: implement notifications using same principle

const ConfirmDialog = styled(Dialog)`
  z-index: 99999999;
`;

const ConfirmContent = styled(DialogContent)`
  min-width: 250px;
`;

const Dialogs = ({ confirm, handleCancel, handleOk }) => [
  <ConfirmDialog open={confirm.open} onClose={handleCancel} key="confirm">
    <ConfirmContent
      css={`
        margin-top: 16px;
      `}
    >
      <DialogContentText>{confirm.message}</DialogContentText>
    </ConfirmContent>
    <DialogActions>
      {confirm.cancelText != null && (
        <Button onClick={handleCancel} size="small">
          {confirm.cancelText}
        </Button>
      )}
      {confirm.okText != null && (
        <Button color="primary" onClick={handleOk} autoFocus size="small">
          {confirm.okText}
        </Button>
      )}
    </DialogActions>
  </ConfirmDialog>,
];

const dialogsHOC = compose(
  connect(state => ({ confirm: state.dialogs.confirm })),
  withConfirmDialog(),
  withHandlers({
    handleCancel: ({ hideConfirm, confirm }) => () => {
      hideConfirm();
      confirm.onCancel();
    },
    handleOk: ({ hideConfirm, confirm }) => () => {
      hideConfirm();
      confirm.onOk();
    },
  }),
);

export default dialogsHOC(Dialogs);
