import React from 'react';

const UploadCloudIcon = () => {
  return (
    <svg
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_6_11)">
        <path
          d="M25.8 8.05333C24.8933 3.45333 20.8533 0 16 0C12.1467 0 8.8 2.18667 7.13333 5.38667C3.12 5.81333 0 9.21333 0 13.3333C0 17.7467 3.58667 21.3333 8 21.3333H25.3333C29.0133 21.3333 32 18.3467 32 14.6667C32 11.1467 29.2667 8.29333 25.8 8.05333ZM18.6667 12V16.5833C18.6667 16.9975 18.3309 17.3333 17.9167 17.3333H14.0833C13.6691 17.3333 13.3333 16.9975 13.3333 16.5833V12H11.144C10.4758 12 10.1412 11.1921 10.6137 10.7197L15.5333 5.8C15.8 5.53333 16.2133 5.53333 16.48 5.8L21.3897 10.7202C21.8614 11.1929 21.5265 12 20.8588 12H18.6667Z"
          fill="#3455D1"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_6_11"
          x="0"
          y="0"
          width="33"
          height="24.3333"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_6_11"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default UploadCloudIcon
