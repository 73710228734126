// @flow

import * as React from 'react';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import { graphql } from 'react-relay';
import { withTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import { MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { getLangFlag } from '../../utils';
import { withChangeLanguage, languages } from '../../i18n';
import { createFragmentContainerHOC } from '../../utils/relay';
import DropdownButton from '../ButtonMenu';

const LangFlagIconRoot = styled.span`
  display: inline-block;
  position: relative;
  height: 24px;
  color: #fff;
  > img {
    position: absolute;
    height: 12px;
    width: 16px;
    bottom: 0;
    right: -4px;
  }
`;

const LangFlagIcon = ({ language }) => (
  <LangFlagIconRoot>
    <LanguageIcon style={{ color: '#333E48' }} color="#333E48" />
    {!!language && <img src={getLangFlag(language)} alt={language} />}
  </LangFlagIconRoot>
);

const Root = styled.div`
  display: inline-block;
  .MuiButtonBase-root {
    padding: 0;
  }
`;

const LinkText = styled(ListItemText)`
  .MuiTypography-noWrap {
    white-space: nowrap;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    overflow: unset;
    text-overflow: unset;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  /* padding: 12px 0 12px 17px; */
`;

const StyledDropdownButton = styled(DropdownButton)`
  width: 100%;

  .icon-button {
    width: 100%;
  }

  .MuiIconButton-label {
    display: block;
  }
  .MuiListItemText-root {
    flex: 0;
  }
`;

const languagePicker = ({
  viewer,
  changeLanguage,
  i18n,
  t,
  open,
  isViewerNotRequired = false,
  ...props
}) => {
  return (
    <Root {...props}>
      <StyledDropdownButton
        icon={
          <IconWrapper>
            <LangFlagIcon language={i18n?.language} />
            {open && (
              <LinkText
                style={{ color: '#FFFFFF' }}
                primary={t('language')}
                primaryTypographyProps={{ noWrap: true }}
              />
            )}
          </IconWrapper>
        }
      >
        {languages.map(({ value, label }) => (
          <MenuItem
            selected={viewer?.language === value}
            value={value}
            key={value}
            onClick={() => {
              if (isViewerNotRequired) {
                const pathnameWithoutLang = window.location.pathname.replace(
                  /\/(de|en|fr)/,
                  '',
                );
                window.location.href = `/${value === 'en' ? '' : value}${
                  !pathnameWithoutLang || pathnameWithoutLang === '/'
                    ? ''
                    : `/${pathnameWithoutLang}`
                }`;
                return;
              }
              changeLanguage(value, viewer);
            }}
          >
            <ListItemIcon>
              <img
                src={getLangFlag(value)}
                alt={value}
                style={{ width: 'auto', height: '1em' }}
              />
            </ListItemIcon>
            <ListItemText inset primary={label} />
          </MenuItem>
        ))}
      </StyledDropdownButton>
    </Root>
  );
};

export default compose(
  withTranslation('common'),
  withChangeLanguage(),
  createFragmentContainerHOC({
    viewer: graphql`
      fragment LanguagePicker_viewer on User {
        id
        language
      }
    `,
  }),
)(languagePicker);
