import {
  format,
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
  formatDistance,
  differenceInCalendarDays,
} from 'date-fns';

import { fr, enUS, de } from 'date-fns/locale';
import i18next from 'i18next';

const langLocaleObj = {
  en: enUS,
  fr: fr,
  de: de,
};

const frMonthShortNames = {
  janv: 'Jan',
  févr: 'Feb',
  mars: 'Mar',
  avr: 'Avr',
  mai: 'Mai',
  juin: 'Juin',
  juil: 'Jui',
  août: 'Aou',
  sept: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  déc: 'Dec',
};
const getFormatLocal = lang => langLocaleObj[lang] || enUS;
export const dateFormat = date => {
  const formattedDate = format(new Date(date), 'MMM dd, yyyy', {
    locale: getFormatLocal(i18next.language.toLowerCase()),
  });

  if (i18next.language.toLowerCase() === 'fr') {
    const splitDate = formattedDate.split(' ');
    return `${frMonthShortNames[splitDate[0]?.replace('.', '')]} ${
      splitDate[1]
    } ${splitDate[2]}`;
  }

  return formattedDate;
};

export default date => format(new Date(date), 'd MMM yyyy, H:mm');

export const formatDateSimple = date =>
  date ? format(parseISO(date), 'dd.MM.yyyy') : null;

export const getDateDistanceLabelFromNow = date => {
  const compareDate = new Date(date);
  const now = new Date();

  const diffInSec = differenceInSeconds(now, compareDate);
  const diffInMin = differenceInMinutes(now, compareDate);
  const regx = new RegExp('about|environ|ungef�hr|circa|cirka', 'i');
  const formattedTime = formatDistance(compareDate, now, {
    addSuffix: true,
    locale: getFormatLocal(i18next.language.toLowerCase()),
  }).replace(regx, '');

  const time = formattedTime.charAt(0).toUpperCase() + formattedTime.slice(1);

  if (diffInSec < 60) {
    return i18next.t('now');
  } else if (diffInSec < 3600) {
    return i18next.language.toLowerCase() === 'en'
      ? `${diffInMin} min ago`
      : time;
  } else {
    return time;
  }
};

const generateDistanceLabel = (hasInSuffix, diffInDay) => {
  if (i18next.language.toLowerCase() === 'fr') {
    return hasInSuffix
      ? `${diffInDay > 1 ? 'dans' : 'en'} ${diffInDay} ${
          diffInDay > 1 ? 'jours' : 'jour'
        }`
      : `${diffInDay} ${diffInDay > 1 ? 'jours' : 'jour'}`;
  }

  return hasInSuffix
    ? `in ${diffInDay} ${diffInDay > 1 ? 'days' : 'day'}`
    : `${diffInDay} ${diffInDay > 1 ? 'days' : 'day'}`;
};

export const getDayDistanceLabelInTheFuture = (date, hasInSuffix = true) => {
  const compareDate = new Date(date);
  const now = new Date();

  const diffInDay = differenceInCalendarDays(compareDate, now);

  let label = '';

  if (diffInDay === 0) {
    label = i18next.t('today');
  } else if (diffInDay < 0) {
    label = i18next.t('missed');
  } else {
    label = generateDistanceLabel(hasInSuffix, diffInDay);
  }

  return label;
};
