// @flow

import { css, Global } from '@emotion/core';
import {
  AppBar,
  Box,
  colors,
  CssBaseline,
  IconButton,
  Toolbar,
  Tooltip,
  useScrollTrigger,
  useTheme,
} from '@material-ui/core';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import * as React from 'react';
import { Link } from 'react-navi';
import { graphql, useQuery } from 'relay-hooks';
import { injectGlobal } from 'styled-components';
import LanguagePicker from './LanguagePicker/LanguagePicker';
import Logo from './Logo';
import { UserMenu } from './user-menu';

const Header = ({ viewer, refetch }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const theme = useTheme();

  // label to visually detect env to prevent prod testing issues
  const isLocalhost = document.location.origin.includes('localhost');
  const isTest = document.location.origin.includes('cre-test');
  const envLabel = (isLocalhost || isTest) && (
    <span
      css={`
        font-family: monospace;
        color: #fff;
        padding: 1px 4px;
        border-radius: 2px;
        background-color: ${isLocalhost ? colors.red.A400 : colors.blue.A700};
        position: absolute;
        bottom: 0;
        left: 150px;
      `}
    >
      {isLocalhost ? 'local' : 'test'}
    </span>
  );

  return (
    <>
      <AppBar
        color="default"
        position="fixed"
        elevation={trigger ? 4 : 0}
        css={`
          background: #fff;
          border-bottom: ${trigger ? 'transparent' : theme.palette.divider} 1px
            solid;
        `}
      >
        {viewer == null && (
          <>
            <Toolbar>
              <Box flexGrow={1} position="relative">
                <Link href="/">
                  <Logo alt="RealAdvisor" />
                </Link>
                {envLabel}
              </Box>
              {viewer === null && (
                <>
                  <LanguagePicker isViewerNotRequired={true} viewer={null} />
                </>
              )}
            </Toolbar>
          </>
        )}
        {viewer?.id != null && (
          <>
            <Toolbar>
              <Box flexGrow={1} position="relative">
                <Link href="/">
                  <Logo alt="RealAdvisor" />
                </Link>
                {envLabel}
              </Box>
              <Box color="text">
                {(viewer.isAdmin || viewer.isSuperAdmin) && (
                  <Tooltip title="Admin dashboard">
                    <IconButton component="a" href="/admin">
                      <DashboardIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <LanguagePicker viewer={viewer} />
                <UserMenu viewer={viewer} refetch={refetch} />
              </Box>
            </Toolbar>
          </>
        )}
      </AppBar>
      <div
        css={`
          height: 64px;
        `}
      />
    </>
  );
};

// $ExpectError
injectGlobal`
  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;
  }
  #app {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

type Props = {
  children: React.Node,
  disableToolbar?: boolean,
};

export const FrontendLayout = ({ children, disableToolbar }: Props) => {
  const { props, error, retry } = useQuery({
    query: graphql`
      query frontendLayoutQuery {
        viewer {
          id
          firstName
          lastName
          fullName
          isActive
          isAdmin
          isSuperAdmin
          isLoggedInAs
          isAdmin
          isSuperAdmin
          realUser {
            ...UserAvatar_user
            id
            fullName
          }

          ...UserAvatar_user
          ...LanguagePicker_viewer
          ...userMenu_viewer
        }
      }
    `,
  });

  return (
    <>
      <CssBaseline />

      <Global
        styles={css`
          html {
            overflow-x: hidden;
          }
          body {
            overflow: hidden;
          }
        `}
      />

      {disableToolbar !== true && (
        <Header
          viewer={props?.viewer}
          root={props?.root}
          refetch={() => {
            retry();
            // FIXME: retry doesn't update for some reason, so...
            window.location.reload();
          }}
        />
      )}
      {children}
    </>
  );
};
