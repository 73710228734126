/**
 * @flow
 * @relayHash 62650e3becb907d052818de7b85307a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDealProgressStageInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type dealProgressStagesDeleteDealProgressStageMutationVariables = {|
  input: DeleteDealProgressStageInput
|};
export type dealProgressStagesDeleteDealProgressStageMutationResponse = {|
  +deleteDealProgressStage: ?{|
    +deletedNodeId: string
  |}
|};
export type dealProgressStagesDeleteDealProgressStageMutation = {|
  variables: dealProgressStagesDeleteDealProgressStageMutationVariables,
  response: dealProgressStagesDeleteDealProgressStageMutationResponse,
|};
*/


/*
mutation dealProgressStagesDeleteDealProgressStageMutation(
  $input: DeleteDealProgressStageInput!
) {
  deleteDealProgressStage(input: $input) {
    deletedNodeId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteDealProgressStageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteDealProgressStage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDealProgressStagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedNodeId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealProgressStagesDeleteDealProgressStageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dealProgressStagesDeleteDealProgressStageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "dealProgressStagesDeleteDealProgressStageMutation",
    "id": null,
    "text": "mutation dealProgressStagesDeleteDealProgressStageMutation(\n  $input: DeleteDealProgressStageInput!\n) {\n  deleteDealProgressStage(input: $input) {\n    deletedNodeId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3e0e8a886735d378cc156ddd5ef30f4';
module.exports = node;
