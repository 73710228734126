/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type FILE_TYPE_TYPE = "ANALYSIS_REPORT" | "BILL" | "BO_REPORT" | "BO_SUBMITTED" | "BUILDING_PLAN" | "COMMERCIAL_REGISTER" | "DOCUMENT" | "GENERAL_NDA" | "LFAIE" | "MANDATE_AGREEMENT" | "MANDATE_AGREEMENT_SIGNED" | "NBO_REPORT" | "NBO_SUBMITTED" | "NDA" | "PHOTO" | "POA" | "POWER_OF_ATTORNY" | "RENTAL_STATUS" | "SALES_MEMO" | "SELLING_ACT" | "SIGNED_NDA" | "TEASER" | "TOMBSTONE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DictionaryItem_item$ref: FragmentReference;
declare export opaque type DictionaryItem_item$fragmentType: DictionaryItem_item$ref;
export type DictionaryItem_item = {|
  +__typename: string,
  +id: string,
  +label: ?string,
  +position: ?number,
  +labelEn: ?string,
  +labelFr: ?string,
  +labelDe: ?string,
  +group?: ?string,
  +type?: ?FILE_TYPE_TYPE,
  +adminOnlyAccess?: boolean,
  +pipelineStageAccess?: ?{|
    +SELECTED_NOTIFIED: boolean,
    +INTERESTED: boolean,
    +NDA_SIGNED: boolean,
    +NBO_SUBMITTED: boolean,
    +VISIT: boolean,
    +DUE_DILIGENCE: boolean,
    +BO_SUBMITTED: boolean,
    +CLOSING: boolean,
  |},
  +allowMultiple?: boolean,
  +dealState?: ?{|
    +CALL_SELLER: boolean,
    +MEETING_WITH_SELLER: boolean,
    +INVITE_SELLER_TO_CRE: boolean,
    +MANDATE_ANALYSIS: boolean,
    +MANDATE_SIGNATURE: boolean,
    +TEASER_SALE_MEMO: boolean,
    +TEASER_SALE_MEMO_VALIDATION: boolean,
    +TENDERING_PREPARATION: boolean,
    +NDA_FIRST_CIRCLE: boolean,
    +NDA_SECOND_CIRCLE: boolean,
    +NDA_THIRD_CIRCLE: boolean,
    +NBO_PHASE: boolean,
    +NBO_SELECTION: boolean,
    +BO_PHASE: boolean,
    +BO_SELECTION: boolean,
    +CLOSING: boolean,
    +CLOSING_VALIDATION: boolean,
    +SOLD: boolean,
  |},
  +$refType: DictionaryItem_item$ref,
|};
export type DictionaryItem_item$data = DictionaryItem_item;
export type DictionaryItem_item$key = {
  +$data?: DictionaryItem_item$data,
  +$fragmentRefs: DictionaryItem_item$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "CLOSING",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DictionaryItem_item",
  "type": "Dictionary",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "position",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": "labelEn",
      "name": "label",
      "args": [
        {
          "kind": "Literal",
          "name": "lang",
          "value": "en"
        }
      ],
      "storageKey": "label(lang:\"en\")"
    },
    {
      "kind": "ScalarField",
      "alias": "labelFr",
      "name": "label",
      "args": [
        {
          "kind": "Literal",
          "name": "lang",
          "value": "fr"
        }
      ],
      "storageKey": "label(lang:\"fr\")"
    },
    {
      "kind": "ScalarField",
      "alias": "labelDe",
      "name": "label",
      "args": [
        {
          "kind": "Literal",
          "name": "lang",
          "value": "de"
        }
      ],
      "storageKey": "label(lang:\"de\")"
    },
    {
      "kind": "InlineFragment",
      "type": "PipelineStage",
      "selections": (v1/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "DealStatus",
      "selections": (v1/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "FileType",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "adminOnlyAccess",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pipelineStageAccess",
          "storageKey": null,
          "args": null,
          "concreteType": "FileTypePipelineStageAccess",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "SELECTED_NOTIFIED",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "INTERESTED",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_SIGNED",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NBO_SUBMITTED",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "VISIT",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "DUE_DILIGENCE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "BO_SUBMITTED",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "allowMultiple",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "DealPipelineStage",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "dealState",
          "storageKey": null,
          "args": null,
          "concreteType": "DealPipelineStageDealState",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "CALL_SELLER",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "MEETING_WITH_SELLER",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "INVITE_SELLER_TO_CRE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "MANDATE_ANALYSIS",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "MANDATE_SIGNATURE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TEASER_SALE_MEMO",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TEASER_SALE_MEMO_VALIDATION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TENDERING_PREPARATION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_FIRST_CIRCLE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_SECOND_CIRCLE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NDA_THIRD_CIRCLE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NBO_PHASE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "NBO_SELECTION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "BO_PHASE",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "BO_SELECTION",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "CLOSING_VALIDATION",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "SOLD",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '166a443c9ffd2a2ab5a60353a1f98387';
module.exports = node;
