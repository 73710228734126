import { gql } from '@apollo/client';

export const COMPLETE_STEP_ACTIVITIES = gql`
  mutation completeStepActivity($input: completeStepActivityInput!) {
    completeStepActivity(input: $input) {
      deal {
        id
        dealState
        status {
          id
          label
          group
        }
        dealPipelineStage {
          id
          labelEn: label(lang: "en")
          labelFr: label(lang: "fr")
          labelDe: label(lang: "de")
        }
      }
      clientMutationId
    }
  }
`;

export const FILE_TYPES = gql`
  query fileType {
    fileTypes {
      id
      label
      labelEn: label(lang: "en")
      labelFr: label(lang: "fr")
      labelDe: label(lang: "de")
      type
      group
    }
  }
`;

export const GENERATE_SIGNED_URL = gql`
  mutation generateSignedUrl(
    $name: String!
    $type: String!
    $bucket: FileBucket!
  ) {
    generateSignedUrl(input: { name: $name, type: $type, bucket: $bucket }) {
      url
    }
  }
`;

export const FILE_CREATE_MUTATION = gql`
  mutation fileCreateMutation($input: CreateFileInput!) {
    createFile(input: $input) {
      file {
        ...Files_files
        id
      }
    }
  }
  fragment Files_files on File {
    id
    createdAt
    filename
    mimetype
    size
    url
    type {
      id
      label
    }
    uploader {
      id
      fullName
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      deletedFileId
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLeadQuery(
    $id: ID!
    $isRecommendBO: Boolean!
    $isRecommendNBO: Boolean!
  ) {
    updateLead(
      input: {
        id: $id
        isRecommendBO: $isRecommendBO
        isRecommendNBO: $isRecommendNBO
        requestFromSource: "deal"
      }
    ) {
      lead {
        id
        isRecommendBO
        isRecommendNBO
      }
    }
  }
`;

export const QUERY_ACTIVITIES = gql`
  query queryActivities(
    $after: String
    $first: Int = 10
    $dealId: ID!
    $sortBy: String = "created_at"
    $sortDirection: SORT_DIRECTION = DESC
    $filters: ActivityFilters = {}
  ) {
    activities: node(id: $dealId) {
      ... on Deal {
        id
        activitiesConn(
          first: $first
          after: $after
          sortBy: $sortBy
          sortDirection: $sortDirection
          filters: $filters
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              creator {
                id
                fullName
                isSeller
                isBroker
                isBroker
                isInvestor
                isSuperAdmin
                primaryImage {
                  id
                  url
                }
              }
              assignee {
                id
                fullName
                email
                primaryImage {
                  url
                  id
                }
              }
              # user {
              #   id
              #   fullName
              # }
              id
              note
              time
              dateTimePeriod
              isDone
              subject
              date
              location
              isFileValidated
              type
              createdAt
              updatedAt
              importanceLevel
              dueAt
              status
              # pipelineStage {
              #   id
              #   label
              #   group
              #   position
              # }
              dealPipelineStage {
                id
                label
              }
              file {
                id
                filename
                url
                mimetype
                sellerDocument {
                  id
                  isRequestSeller
                }
                isAdminValidated
                isRequireAdminValidate
                isRequireSellerValidate
                # url
                isBrokerValidated
                isSellerValidated
                type {
                  id
                  label
                  group
                  type
                }
              }
            }
          }
          totalCount
        }
      }
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation createActivity(
    $dealPipelineStageId: ID!
    $fileId: ID
    $assigneeId: ID
    $activityType: ACTIVITY_TYPE!
    $status: ACTIVITY_STATUS
    $subject: String
    $note: String
    $time: Time
    $date: Date
    $parentId: ID!
    $dateTimePeriod: ACTIVITY_DATE_TIME_PERIOD
    $isDone: Boolean
    $location: String
    $isFileValidated: Boolean
    $importanceLevel: ACTIVITY_IMPORTANT_LEVEL
  ) {
    createActivity(
      input: {
        type: $activityType
        status: $status
        subject: $subject
        note: $note
        time: $time
        date: $date
        parentId: $parentId
        dateTimePeriod: $dateTimePeriod
        isDone: $isDone
        location: $location
        isFileValidated: $isFileValidated
        dealPipelineStageId: $dealPipelineStageId
        fileId: $fileId
        assigneeId: $assigneeId
        importanceLevel: $importanceLevel
      }
    ) {
      activityEdge {
        node {
          id
          note
          time
          date
          dueAt
          dateTimePeriod
          isDone
          subject
          location
          isFileValidated
          type
          status
          dateTimePeriod
          file {
            id
            isBrokerValidated
            isSellerValidated
          }
          dealPipelineStage {
            id
            label
          }
          importanceLevel
          assignee {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const QUERY_ACTIVITY_TYPES = gql`
  query activityTypeQuery(
    $sortBy: String
    $sortDirection: SORT_DIRECTION
    $filters: ActivityTypeFilters = {}
  ) {
    activityTypes(
      sortBy: $sortBy
      sortDirection: $sortDirection
      filters: $filters
    ) {
      title
      value
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity(
    $updateActivityId: ID!
    $dealPipelineStageId: ID!
    $activityType: ACTIVITY_TYPE!
    $subject: String
    $note: String
    $time: Time
    $date: Date
    $dateTimePeriod: ACTIVITY_DATE_TIME_PERIOD
    $isDone: Boolean
    $location: String
    $isFileValidated: Boolean
    $assigneeId: ID
    $importanceLevel: ACTIVITY_IMPORTANT_LEVEL
    $status: ACTIVITY_STATUS
  ) {
    updateActivity(
      input: {
        id: $updateActivityId
        type: $activityType
        status: $status
        subject: $subject
        note: $note
        time: $time
        date: $date
        dateTimePeriod: $dateTimePeriod
        isDone: $isDone
        location: $location
        isFileValidated: $isFileValidated
        dealPipelineStageId: $dealPipelineStageId
        assigneeId: $assigneeId
        importanceLevel: $importanceLevel
      }
    ) {
      activity {
        id
        note
        time
        date
        dueAt
        dateTimePeriod
        isDone
        subject
        location
        isFileValidated
        type
        status
        file {
          id
          filename
          isBrokerValidated
          url
        }
        dealPipelineStage {
          id
          label
        }
        importanceLevel
        assignee {
          id
          fullName
        }
      }
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation deleteActivity($deleteActivityId: ID!) {
    deleteActivity(input: { id: $deleteActivityId }) {
      deletedActivityId
    }
  }
`;

export const QUERY_ACTIVITY_BY_ID = gql`
  query queryActivityById($activityId: ID!) {
    activity: node(id: $activityId) {
      ... on Activity {
        creator {
          id
          fullName
          primaryImage {
            id
            url
          }
        }
        assignee {
          id
          fullName
        }
        # user {
        #   id
        #   fullName
        # }
        id
        note
        time
        dateTimePeriod
        isDone
        subject
        date
        location
        isFileValidated
        type
        createdAt
        updatedAt
        importanceLevel
        dueAt
        status
        # pipelineStage {
        #   id
        #   label
        #   group
        #   position
        # }
        dealPipelineStage {
          id
          label
        }
        file {
          id
          filename
          # url
          isBrokerValidated
          type {
            id
            label
          }
        }
      }
    }
  }
`;

export const COMPLETE_ACTIVITES_ACTION = gql`
  mutation completedActivities($dealId: ID!, $typeIn: [ACTIVITY_TYPE]) {
    updateDealTypeActivityToCompleted(
      input: { dealId: $dealId, typeIn: $typeIn }
    ) {
      activities {
        id
        subject
        note
        isDone
        doneAt
      }
    }
  }
`;

export const QUESTION_QUERY = gql`
  query dealQuestionQuery(
    $dealId: ID
    $sortBy: String
    $sortDirection: SORT_DIRECTION
    $search: String
    $filters: QuestionFilters
    $first: Int
    $after: String
  ) {
    dealQuestions(
      dealId: $dealId
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      filters: $filters
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          question
          createdAt
          updatedAt
          createdBy {
            firstName
            lastName
            fullName
            primaryImage {
              url
            }
          }
          answers {
            id
            answers
            createdAt
            updatedAt
            createdBy {
              firstName
              lastName
              fullName
              primaryImage {
                url
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export const CREATE_ANSWER = gql`
  mutation createAnswer($parentId: ID!, $answer: String!) {
    createAnswer(input: { parentId: $parentId, answer: $answer }) {
      answer {
        id
        createdAt
        updatedAt
        answers
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation deleteQuestion($id: ID!) {
    deleteQuestion(input: { id: $id }) {
      deletedQuestionId
    }
  }
`;

export const LEAD_FILTERED_QUERY = gql`
  query LeadListQuery(
    $id: ID!
    $count: Int!
    $cursor: String
    $sortBy: String
    $sortDirection: SORT_DIRECTION
    $leadFilters: LeadFilters
    $leadOnly: Boolean
    $leadSearch: String
  ) {
    node(id: $id) {
      __typename
      ...LeadList_deal
      id
    }
  }
  fragment LeadList_deal on Deal {
    id
    name

    leadsConn(
      first: $count
      after: $cursor
      sortBy: $sortBy
      sortDirection: $sortDirection
      filters: $leadFilters
      leadOnly: $leadOnly
      search: $leadSearch
    ) {
      edges {
        node {
          id
          isRecommendBO
          isRecommendNBO
          organisation {
            id
            name
            street
            streetNr
            postcode
            city
            country
            usersCount
            assetsUnderManagement
            primaryImage {
              url
              id
            }
            organisationType {
              id
              label
            }
            primaryContact {
              firstName
              lastName
              email
              id
              mobilePhone
              officePhone
            }
          }
          files {
            id
            filename
            url
            type {
              id
              group
              type
            }
          }
        }
      }
      totalCount
      leadsCount
      totalLeadsCount
    }
  }
`;

export const DEAL_PIPELINE_STAGE_QUERY = gql`
  query DealPipelineStageQuery {
    dealPipelineStages {
      id
      position
      label
    }
  }
`;

export const ACTIVITY_AUTHORS_QUERY = gql`
  query ActivityAuthorsQuery($dealId: ID!) {
    authors: users(
      filters: { isActivityCreator: true, activityDealId: $dealId }
      first: 1000
    ) {
      edges {
        node {
          id
          fullName
          firstName
          lastName
          email
          primaryImage {
            id
            url
          }
        }
      }
      totalCount
    }
  }
`;

export const QUERY_SELLER_AND_COLLEAGUES = gql`
  query QuerySellerAndColleagues($activityDealId: ID) {
    sellerAndColleagues: users(
      filters: {
        isDealSellerAndCollSeller: true
        activityDealId: $activityDealId
      }
      first: 1000
    ) {
      edges {
        node {
          id
          fullName
          email
          primaryImage {
            id
            url
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const UPDATE_MOST_RECENT_CALL_ACTIVITY = gql`
  mutation updateMostRecentActivityByDeal(
    $dealId: ID!
    $completedDescription: String
  ) {
    updateMostRecentActivityByDeal(
      input: {
        dealId: $dealId
        type: CALL
        toStatus: REACHED
        completedDescription: $completedDescription
      }
    ) {
      activity {
        id
        status
        subject
        note
        isDone
        doneAt
        completedDescription
      }
    }
  }
`;
export const UPDATE_MOST_RECENT_VISIT_ACTIVITY = gql`
  mutation updateMostRecentActivityByDeal(
    $dealId: ID!
    $completedDescription: String
  ) {
    updateMostRecentActivityByDeal(
      input: {
        dealId: $dealId
        type: VISIT
        toStatus: COMPLETED
        completedDescription: $completedDescription
      }
    ) {
      activity {
        id
        status
        subject
        note
        isDone
        doneAt
        completedDescription
      }
    }
  }
`;

export const STATISTICS_COMPLETED_ACTIVITY_QUERY = gql`
  query activityStatisticsQuery($id: ID!) {
    dealActivityStatistics: node(id: $id) {
      id
      ... on Deal {
        name
        address
        countCompletedActivityTasksConn: activitiesConn(
          first: 1
          filters: { statusIn: [COMPLETED], typeIn: [TASK] }
        ) {
          totalCount
        }
        countCompletedActivityVisitsConn: activitiesConn(
          first: 1
          filters: { statusIn: [COMPLETED], typeIn: [VISIT] }
        ) {
          totalCount
        }
        countCompletedActivityCallsConn: activitiesConn(
          first: 1
          filters: { statusIn: [REACHED], typeIn: [CALL] }
        ) {
          totalCount
        }
      }
    }
  }
`;

export const CREATE_SELLER_DOC_MUTATION = gql`
  mutation DealDocumentsCreateSellerDocsMutation(
    $input: CreateSellerDocumentInput!
  ) {
    createSellerDocument(input: $input) {
      sellerDocument {
        id
      }
    }
  }
`;
